<template>
    <div>
      <v-card v-if="$store.state.auth.user.type == 'admin'" :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''" >
        <v-container>
          <TitlePages title="عروض الفليكسي">
            <v-btn slot="btn" @click="$router.go(-1)"   large   style="float: left!important;" icon depressed><img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>

          </TitlePages>
          <v-spacer>  </v-spacer>
          <vs-button @click="newModel()"   class="app-regular  ">
            إضافة عرض فليكسي جديد <i class="fas fa-credit-card nav-icon"></i>
          </vs-button>
          <div class="center mt-2" >
            <v-card flat class="pa-5 rounded-xl" :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
              <vs-table :dark="$store.state.dark" class="app-regular  "  >
                <template #thead >
                  <vs-tr dark>
                    <vs-th>
                      تغيير / حذف
                    </vs-th>

                    <vs-th>
                      الصورة
                    </vs-th>
                    <vs-th>
                      السعر
                    </vs-th>
                    <vs-th>
                      التفعيل
                    </vs-th>
                    <vs-th>
                      الاسم
                    </vs-th>

                  </vs-tr>
                </template>
                <template #tbody>
                  <vs-tr
                      v-for="cart in card" :key="cart.id">

                    <vs-td>
                      <v-row class="app-regular">
                        <v-col class="col-6">
                          <vs-button flat icon block
                                     @click="editModel(cart)"
                          >
                            تغيير
                          </vs-button>

                        </v-col>
                        <v-col class="col-6">
                          <vs-button block  danger @click="deleteCard(cart.id)">
                            حذف
                          </vs-button>
                        </v-col>
                      </v-row>
                    </vs-td>
                    <vs-td>
                      <v-row>
                        <v-col>
                          <vs-avatar style="cursor: pointer" size="38">
                            <img :src="setImageOperator(cart.type)" :alt="cart.slug">
                          </vs-avatar>
                        </v-col>


                      </v-row>
                    </vs-td>
                    <vs-td>
                      {{ cart.price }}
                    </vs-td>
                    <vs-td>
                      <vs-switch style="width: 60px;" success v-model="cart.isActive==1?true:false" @click="updateisActive(cart)">
                        <template #off>
                          <i class='bx bx-x' ></i>
                        </template>
                        <template #on>
                          <i class='bx bx-check' ></i>
                        </template>
                      </vs-switch>
                    </vs-td>

                    <vs-td>
                      {{ cart.name }}
                    </vs-td>

                  </vs-tr>
                </template>
                <template #notFound>
                  <span class="app-regular" :class="$store.state.params.color">لا توجد بيانات</span>
                </template>

              </vs-table>
            </v-card>
          </div>



          <!-- /.content -->
          <vs-dialog   v-model="offersShow" >
            <div class="vuertl app-regular"   >
              <v-card-title v-text="editMode?'تغيير البطاقة':'إضافة بطاقة'"></v-card-title>
              <v-card-text>
                <form @submit.prevent="editMode ? updateCard() : addCart()">
                  <v-row dense>
                    <v-col class="col-lg-6 col-md-6 col-xs-12 ">
                      <v-text-field v-model="form.name" :dark ="$store.state.dark"
                                    type="text" placeholder="إسم العرض" outlined dense
                                    name="name"
                                   hint="اسم العرض عند الزبائن"  persistent-hint
                      >

                      </v-text-field>
                    </v-col>
                    <v-col class="col-lg-3 col-md-6 col-xs-12 ">
                      <v-text-field v-model="form.price" :dark ="$store.state.dark"
                                    type="text" placeholder="السعر" outlined dense
                                    name="price"
                                    hint="السعر"  persistent-hint
                        >

                      </v-text-field>
                    </v-col>
                    <v-col class="col-lg-3 col-md-6 col-xs-12 ">
                      <v-select :value="form.type" v-model="form.type" :items="['Djezzy','Mobilis','Ooredoo']" :dark ="$store.state.dark"
                                type="text" placeholder="النوع" outlined dense
                                name="type"
                                hint="النوع"  persistent-hint
                              >

                      </v-select>
                    </v-col>
                    <v-col class="col-lg-6 col-md-6 col-xs-12 ">
                      <v-text-field v-model="form.message" :dark ="$store.state.dark"
                                    type="text" placeholder="الرسالة" outlined dense
                                    name="price"
                                    hint="الرسالة"  persistent-hint
                      >

                      </v-text-field>
                    </v-col>
                    <v-col class="col-lg-6 col-md-6 col-xs-12 ">
                      <v-text-field v-model="form.value" :dark ="$store.state.dark"
                                    type="text" placeholder="القيمة" outlined dense
                                    name="value"
                                    hint="قم بكتابة العرض كما في USSD"  persistent-hint
                      >

                      </v-text-field>
                    </v-col>
                    <v-col class="col-lg-4 col-md-6 col-xs-12 ">
                      <v-text-field v-model="form.excute" :dark ="$store.state.dark"
                                    type="text" placeholder="USSD 01" outlined dense
                                    name="excute"
                                    hint="رقم العرض الاول USSD"  persistent-hint
                                    >

                      </v-text-field>
                    </v-col>
                    <v-col class="col-lg-4 col-md-6 col-xs-12 ">
                      <v-text-field v-model="form.excutetow" :dark ="$store.state.dark"
                                    type="text" placeholder="USSD 02" outlined dense
                                    name="excutetow"     hint=" رقم العرض الثاني USSD إن وجد"  persistent-hint
                      >

                      </v-text-field>
                    </v-col>
                    <v-col class="col-lg-4 col-md-6 col-xs-12 ">
                      <v-text-field v-model="form.parent" :dark ="$store.state.dark"
                                    type="text" placeholder="Parent" outlined dense
                                    name="parent"
                                    hint="Parent" persistent-hint
                                    >

                      </v-text-field>
                    </v-col>
                    <v-col class="col-lg-6 col-md-6 col-xs-12 ">
                      <v-text-field v-model="form.code" :dark ="$store.state.dark"
                                    type="text" placeholder="Code" outlined dense
                                    name="code"
                                    hint="الكود" persistent-hint
                                    >

                      </v-text-field>
                    </v-col>
                    <v-col class="col-lg-6 col-md-6 col-xs-12 ">
                      <v-text-field v-model="form.isSent" :dark ="$store.state.dark"
                                    type="text" placeholder="isSent" outlined dense
                                    name="isSent"
                                     hint="هل تم الارسال 1 نعم 0 لا" persistent-hint

                      >

                      </v-text-field>
                    </v-col>
                  </v-row>

                  <v-card-actions>


                    <v-btn :loading="form.busy" :dark ="$store.state.dark"
                           v-show="editMode"
                           type="submit" class="white--text"
                           color="#03A9F4"
                    >
                      تحديث
                    </v-btn>
                    <v-btn :loading="form.busy" :disabled="form.name == '' || form.image =='' " :dark ="$store.state.dark"
                           v-show="!editMode"
                           type="submit" class="white--text"
                           color="#4CAF50"
                    >
                      إضافة
                    </v-btn>
                    <v-btn :dark ="$store.state.dark" class=" mr-2" type="button" data-dismiss="modal" @click="offersShow = !offersShow">
                      اغلاق
                    </v-btn>
                  </v-card-actions>
                </form>
              </v-card-text>

            </div>
          </vs-dialog>
        </v-container>
      </v-card>
      <NotFound v-else/>
    </div>
</template>
<script>
import Form from 'vform'
import axios from 'axios';
import NotFound from "@/components/comp-tool/NotFound.vue";
import TitlePages from "@/layout/TitlePages";
import {setBaseUrl, setImageOperator} from "@/tools/Services";

export default {
    components: {TitlePages,NotFound},
    data() {
    return {
        offersShow:false,
      color: "#4CAF50",
      editMode: false,
      form: new Form({
        id: "",
        name: "",
        value: "",
        message: "",
        type: "",
        parent: "",
        excute: "",
        excutetow: "",
        code: "",
        isSent: "",
      }),
      card: {},
      imagee: "",
      imageMode: 0,
      type: window.user,
    };
  },
  mounted() {
 
      this.loadcard();


  
  },
  methods: {
    setImageOperator, setBaseUrl,

  async  updateisActive(game) {
 
     try {
     await    axios({
             method: "post",
             url: "api/update-is-active-offers",
             data:game,
             headers: {
                 'content-type': 'multipart/form-data'
             }
         })
       await  this.loadcard()
     }catch (e) {
         await this.$store.dispatch("loadsna", {
             text: e.response.data.message,
             isSuccess: false,
         });
     }
    },

    updateCard() {
      this.form
        .post("api/offers-admin-update")
        .then(() => {
          this.offersShow = !this.offersShow
            this.$store.dispatch("loadsna", {
                text: 'تم تحديث المنتج بنجاح',
                isSuccess: true,
            });
            this.loadcard()

        })
        .catch(() => {
            this.$store.dispatch("loadsna", {
                text: "حدث خطأ ما يرجى اعادة المحاولة",
                isSuccess: false,
            });
        });
    },
    newModel() {
      this.editMode = false;
      this.form.reset();
        this.offersShow = !this.offersShow
    },

    editModel(cart) {
      this.editMode = true;
      this.form.reset();
        this.offersShow = !this.offersShow
      this.form.fill(cart);
    },

    loadcard() {
      axios.get("api/get-offers").then((response) => (this.card = response.data));
    },

    addCart() {
      this.form
        .post("api/offers-admin")
        .then(() => {
         this.offersShow = !this.offersShow
          this.form.reset();
         this.loadcard()
            this.$store.dispatch("loadsna", {
                text: 'تم إضافة المنتج بنجاح',
                isSuccess: true,
            });


        
        })
        .catch(() => {
            this.$store.dispatch("loadsna", {
                text: "حدث خطأ ما يرجى اعادة المحاولة",
                isSuccess: false,
            });
        });
    },
    deleteCard(id) {
      Swal.fire({
        title: "حذف المنتج",
        text: " هل أنت متأكد",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم، قم بالحذف",
      }).then((result) => {
        if (result.value) {
          this.form
            .delete("api/offers-admin/" + id)
            .then((response) => {
                this.loadcard()
                this.$store.dispatch("loadsna", {
                    text: response.data.message,
                    isSuccess: true,
                });


            })
            .catch((error) => {
                this.$store.dispatch("loadsna", {
                    text: error.response.data.message,
                    isSuccess: false,
                });
            });
        }
      });
    },
  },
};
</script>
