
import Vue from "vue";
import store from "@/store";
Vue.directive('vuesax',{
    bind:(el) => {
      
        if(this.$store.state.dark){
            el.style.background = "#1E2023"
            el.style.color = "#fff"
        }else{
            el.style.background = "#fff"
            el.style.color = "#1E2023"
        }

    }
})
Vue.directive('longpress', {
    bind: function (el, binding, vNode) {
        let pressTimer = null
        let start = (e) => {
            if (e.type === 'click' && e.button !== 0) {
                return;
            }
            if (pressTimer === null) {
                pressTimer = setTimeout(() => {
                    handler()
                }, 1000)
            }
        }
        let cancel = (e) => {
            if (pressTimer !== null) {
                clearTimeout(pressTimer)
                pressTimer = null
            }
        }
        const handler = (e) => {
            binding.value(e)
        }
        el.addEventListener("mousedown", start);
        el.addEventListener("touchstart", start);
        el.addEventListener("click", cancel);
        el.addEventListener("mouseout", cancel);
        el.addEventListener("touchend", cancel);
        el.addEventListener("touchcancel", cancel);
    }
})
