<template>
    <v-card  :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''" height="100vh">


    <v-container class="px-0" >
      <v-card :color="$store.state.dark?'app-bg':''" flat class="vuertl">
          <TitlePages title="بحث">
              <v-btn slot="btn" @click="$router.go(-1)"   large   style="float: left!important;" icon depressed>
                  <img style="width: 32px" :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" />
              </v-btn>

          </TitlePages>

    </v-card>
    <v-container>
        <v-row>
            <v-col class="col-12">
                <v-text-field v-model="search" outlined class="rounded-lg  app-bold vuertl" dense placeholder="قم بالبحث عما تريد">
                    <template slot="append">
                        <img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/search.svg">

                    </template>
                </v-text-field>
            </v-col>
        </v-row>
    </v-container>

        <v-row v-show="isShow"  >

            <v-col class="col-12 mt-5">
                <v-card :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''">
                    <v-card-title class=" mt-5">
                        <v-spacer></v-spacer>
                        <img width="100px" src="assets/notifications/search.png">
                        <v-spacer></v-spacer>

                    </v-card-title>
                    <h5 class="app-bold text-center ">قم بالبحث عن المنتجات في خانة البحث</h5>

                </v-card>
            </v-col>
        </v-row>

    <v-row v-if="cartes.length >=0" align="center" justify="center" class="mx-1 p-0" >
        <v-col  class="col-4 col-xs-4 col-sm-4 col-md-3 col-lg-2 my-3 " v-for="(cart,index) in cartes" :key="index">
                <Card :cart="cart" style="cursor:pointer;" />

            </v-col>
        </v-row>

    </v-container>
      
    </v-card>
  </template>
  
  <script>
import axios from 'axios'
  import Card from '../components/CardComponent.vue'
import TitlePages from "@/layout/TitlePages";

    export default {
      name: 'HomeCardInfo',
      components: {TitlePages, Card},
      data(){
        return{
            search:'',
         isShow:true,
         showEmpty:false,
          cartes:[]
        }
      },
        watch:{
          search (newVal) {

              this.getCards(newVal)
          }
        },

      methods:{

        getCards(name){

                axios.get('api/findCardType?q='+name).then((response)=>{
                    this.cartes = response.data
                    if (this.cartes.length >0){
                        this.isShow = false
                        this.showEmpty = false
                    }else {
                        this.isShow = true
                        this.showEmpty = true
                    }
                })

        }
      }
    }
  </script>
  
<style lang="scss" scoped>
.b-game-card {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: 150%;
  perspective: 1000px;
  transition: all 0.2s ease-in-out;
}

.b-game-card__cover {
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
   background-size: cover;
  perspective-origin: 50% 50%;
  transform-style: preserve-3d;
  transform-origin: top center;
  will-change: transform;
  transform: skewX(0.001deg);
  transition: transform 0.25s ease-in-out;
}

.b-game-card__cover::after {
  display: block;
  content: "";
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background: linear-gradient(226deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 35%, rgba(255, 255, 255, 0.1) 42%, rgba(255, 255, 255, 0) 60%);
  transform: translateY(-50%);
  will-change: transform;
  transition: transform 0.12s ease-in-out, opacity 0.1s ease-in-out;
}

.b-game-card:hover .b-game-card__cover::after {
  transform: translateY(0%);
}

.b-game-card::before {
  display: block;
  content: "";
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  transform: translateY(-20%);
  will-change: transform;
  transition: transform 0.5s cubic-bezier(0.18, 0.9, 0.58, 1);
}

.b-game-card:hover .b-game-card__cover {
  transform: rotateX(10deg) translateY(-8px);
}

.b-game-card:hover .b-game-card__cover::after {
  transform: translateY(0%);
}

.img-border-bottom {
  border-bottom-left-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
  -moz-box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
  -webkit-box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
  box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
}
.blur{
  backdrop-filter: blur(3px)importan !important
  
}
.v-dialog__content {
  z-index: 1002 !important;
}
.bottom-sheet-radius{
  border-top-left-radius: 20px!important;
  border-top-right-radius: 20px!important;
}
.bottom-sheet-radius-all{
  border-radius: 20px!important;
}
div.v-slide-group__content{
  display: flex!important;
    justify-content: center!important;
}
div.v-slide-group__wrapper{
  display: flex!important;
    justify-content: center!important;
}
.v-skeleton{
  height: 100%!important;
 }

</style>
