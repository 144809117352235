import {getProfile}from "@/plugins/authUser";

export default {
    name:'strMixins',
    computed:{
        isAdmin:function () {
            return this.$store.state.auth.isAdmin
        },
        user: {
            get:function () {
                return this.$store.state.auth.user
            },
            set:function () {
            }
        },
        params:function () {
            return this.$store.state.params
        },
        dark:function () {
            return this.$store.state.dark
        }

    },

    methods:{
      async  getUserFromMixins(){
            const user = await  getProfile()
            this.$store.commit('auth/SET_USER', user.data.user)
        }
    }
}