
import axios from "axios";
export default {
    namespaced: true,
    state:{
     sendSms:null
    },
    getters:{
    sendSms(state) {
        return state.sendSms
    }
    },
    mutations:{
    SEND_SMS(state , payload){
        state.sendSms = payload
}
    },
    actions:{
         excuteSms({commit},data){
             axios.post('api/sendSms',data).then((response)=>{

                 const noti = this.$vs.notification({
                     color:'primary',
                     position:'top-center',
                     title: 'إرسال رسالة SMS',
                     text: 'تم إرسال رسالة بنجاح'
                 })
             }).catch((e)=>{
                 const noti = this.$vs.notification({
                     color,
                     position,
                     title: 'إرسال رسالة SMS',
                     text: 'حدث خطأ ما .'
                 })
             })
         }
    }
}