<template>
    <div>
            <telecom-rechargemennt-mobile></telecom-rechargemennt-mobile>
    </div>
</template>


<script>
    import TelecomRechargemenntMobile from "@/components/TelecomRechargemenntMobile";
    export default{
        components:{TelecomRechargemenntMobile}
    }
</script>

