import axios from "axios";

export default {
    namespaced: true,
    state:{
        conditions:null
    },
    getters:{
        side(state){
            return state.conditions
        },
 
    },
    mutations:{
        SET_CONDITIONS(state, value) {
            state.conditions = value
        },
     
    },
    actions:{
        loadConditions({commit,state}){
            axios.get('api/get-conditions').then((response) => {
                commit('SET_CONDITIONS',response.data.conditions)

            })
        }
    }
  
}