import router from '../router/index'
import axios from 'axios'
import * as authUser from '../plugins/authUser'
export default {
    namespaced: true,
    state:{
        authenticated:false,
        user:null,
        isAdmin:false
    },
    getters:{
        isAdmin(state){
            return state.isAdmin
        },
        authenticated(state){
            return state.authenticated
        },
        user(state){
            return state.user
        }
    },
    mutations:{
        SET_ISADMIN (state, value) {
            state.isAdmin = value
        },
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },
        SET_USER (state, value) {
             if(value == null){
               state.user = null
                state.authenticated = false

             }else if(value.username){
                 state.user = value
                 state.authenticated = false
                 if(value.type=="admin"){
                     state.isAdmin = true
                 }else {
                     state.isAdmin = false
                 }
             }



        }
    },
    actions:{
        // login({commit}){
        //     return axios.get('/api/user').then(({data})=>{
        //         commit('SET_USER',data.data.user)
        //         authUser.setToken(data.data.token)
        //         commit('SET_AUTHENTICATED',true)
        //
        //         router.push({name:'dashboard'})
        //     }).catch(({response:{data}})=>{
        //         commit('SET_USER',null)
        //         commit('SET_AUTHENTICATED',false)
        //     })
        // },
        logout({commit}){
            commit('SET_USER',{})
            commit('SET_AUTHENTICATED',false)
        },

    }
}