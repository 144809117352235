<template>
    <div v-if=" $route.fullPath != '/auth'">



        <vs-navbar shadow-scroll fixed      shadow square  v-model="active"   style="z-index:124!important "   >
            <template #left>
                <v-btn class="ml-0 mr-2" icon  @click="switshSidBar()"        color="transparant"       >
                    <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/nut.svg"/>
                </v-btn>

                    <img @click="redirect"  v-if="!$store.state.open.side" style="cursor: pointer;margin-top:5px"  width="30px" height="30px" src="assets/img/logo.svg"  >
                <span  @click="redirect" v-if="!$store.state.open.side" class="mt-2"     style="font-family: sans-serif;cursor: pointer;" :class="$store.state.dark?'white--text':'black--text'"><b>DZtechno</b></span>


            </template>
                     <div v-if="items.length >0">


                             <vs-navbar-item   v-for="(item,index) in items" :key="item.title"   @click.prevent="setRouter(item.link,item.title) " :active="active == item.title" :id="item.title">

                                 <v-badge
                                         :content="item.notification"
                                         :value="item.notification"
                                         color="warning black--text"
                                         overlap
                                 >
                                 <span class="app-bold">{{item.arTitle}} </span>
                                 </v-badge>
                             </vs-navbar-item>


                      </div>
            <div v-else class="text-center">
              <v-row class="justify-center">

                  <v-skeleton-loader class="mr-3" style="height:30px;width:60px" :dark="dark"

                                     type="image "
                  ></v-skeleton-loader>
                  <v-skeleton-loader style="height:30px;width:120px" :dark="dark"

                                     type="image "
                  ></v-skeleton-loader>
                  <v-skeleton-loader class="ml-3" style="height:30px;width:100px" :dark="dark"

                                     type="image"
                  ></v-skeleton-loader>
              </v-row>


            </div>
            <template #right>


                <vs-button circle :color="$store.state.params.bg"  v-if="  showSka != '0'"  :class="dark?'app-card':'app-card-light'"   class="rounded-lg  app-medium"  @click="$router.push('/search')"  >
                    <img class="mx-3" :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/search.svg">
                </vs-button>
                <vs-button     :color="$store.state.App.config.colorBackground" v-if="user== null && showSka == 'guest'"    class="rounded-lg  app-medium"   @click="login"   >
                    <span style="direction: ltr;font-size: 17px" class=" app-regular mr-2"  >تسجيل الدخول</span>

                </vs-button>
                <Notifications class="mx-2" v-if="user!= null"/>
                <Menue v-if="user!= null" />
            </template>
        </vs-navbar>
    </div>
</template>

<script>
    import {formatToPrice} from "@/tools/Services";
    import Menue from "@/layout/Menue";
    import {bus} from "@/tools/bus";
    import * as authUser from "@/plugins/authUser";
    import Notifications from "@/components/Notifications";
    import strMixins from '@/plugins/Mixins'
    import axios from 'axios'
     export default {
        mixins:[strMixins],
        components: {Menue,Notifications},
         computed:{
             items:function () {
                return this.$store.state.sidbar.navbar
             }
         },
        data(){
            return{

                route:true,
                search:'',
                width:70,
                active:'admin-config',
                show:true,
                showSka:null
            }
        },
       methods:{
         login(){
           this.$router.push('/login').catch(()=>{})
         },
         setRouter(route ,active){
           this.active = active
           this.$router.push(route).catch(()=>{})
         },
         redirect(){
           window.location.href ='/'
         },
         switshSidBar(){

           this.$store.commit('open/SET_SIDE',!this.$store.state.open.side)
           localStorage.setItem("sidbar",JSON.stringify(this.$store.state.open.side))
         },
         formatToPrice,
         HandelSearsh(){
           if(this.search != null){
             this.route = false
             this.$router.push({name:"HomeCardInfoSearch",params:{name:this.search}})

           }
         },


       },



        mounted(){
            setTimeout(()=>{
              this.user == null? this.showSka= "guest": this.showSka= "user"

            },1500)
            bus.$on('loadSolde',async ()=>{
                const response = await authUser.getProfile();
            this.$store.commit('auth/SET_USER',response.data)
            })
            this.route = true
            this.active =window.localStorage.getItem('mode')=="true"?true:false

        }
    }
</script>

<style>
    .margin-top-app{
        top:30px!important;
    }
    .transi{
        -webkit-transition: .3s ease-in-out;
        -moz-transition: .3s ease-in-out;
        -o-transition: .3s ease-in-out;
        transition: .3s ease-in-out;
    }
</style>