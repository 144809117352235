import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c(VCard,{staticClass:"mt-12",attrs:{"color":"transparent","flat":""}},[_c(VContainer,[_c(VCard,{staticClass:"rounded-xl vuertl",attrs:{"elevation":"0","dark":_vm.$store.state.dark,"color":"transparent"}},[_c(VCardTitle,[_c('h3',[_vm._v("الاسئلة الشائعة")])]),_c(VCardSubtitle,{style:({color:_vm.$store.state.App.config.color})},[_vm._v("الاستفسارات")]),_c(VCardText)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }