<template  >
    <div>
        <v-menu :class="$store.state.dark?'app-card':'app-card-light'"

                :dark="$store.getters.dark" min-width="270px" max-width="350px"
                class="over"
                style="border-radius: 25px;!important;"
                offset-y
                origin="center center"
                :nudge-bottom="10"

        >
            <template v-slot:activator="{ on, attrs }">
                <div>
                    <vs-avatar size="37"    link class="text-decoration-none"    :dark="$store.state.dark" large icon v-bind="attrs" v-on="on">
                        <img  width="30px" height="30px"   src="assets/img/profile.png">
                    </vs-avatar>

                </div>
            </template>

            <v-card flat class="rounded-xl mt-0 pt-0  overflow-hidden" :class="$store.state.dark?'app-card':'app-card-light'">
                <v-progress-linear height="3"
                                   :indeterminate="true"
                                   color="#8564F7"
                ></v-progress-linear>
                <v-card-title class="py-1 ">
                    <img style="cursor: pointer"  width="25px" height="25px"src="assets/img/logoxl.png"  >
                    <span  style="font-family: sans-serif"><b style="font-size: 13px">{{AppName}}</b></span>
                    <v-spacer></v-spacer>
                           <vs-switch  v-model="active" :color="$store.state.params.background" @click.stop="HandelMode"  >
                               <template #off>
                                   <img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/sun.svg"/>
                               </template>
                               <template #on>
                                   <img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/moon.svg"/>
                               </template>
                           </vs-switch>
                </v-card-title>
                <v-list-item class="rounded-xl " dense
                             v-if="user != null"

                        style="text-decoration: none;border-radius: 25px;"
                        :active-class="
               $store.state.dark === true
              ? 'text-grey'
              : 'deep-purple--text text--accent-4'
          "
                >
                    <v-list-item-avatar class="mr-1">
                        <img :class="$store.state.dark?'white-svg':'black-svg'" style="width: 20px" src="assets/icons/vuesax/users.svg">
                    </v-list-item-avatar>

                    <v-list-item-content     >
                        <v-list-item-title  style="text-align: left" class="title text-start">
                            <span class="h6">{{ user.phone }} </span>

                        </v-list-item-title>
                        <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
                    </v-list-item-content>

                </v-list-item>

                <v-divider class="mt-0"></v-divider>

                <v-divider v-if="$vuetify.breakpoint.name == 'xs'"></v-divider>
                <v-container>
                    <v-card class="rounded-xl overflow-hidden elevation-0"  :class="$store.state.dark?'':''">
                        <v-list-item
                                style="text-decoration: none !important"

                                v-for="(item, index) in items"
                                :key="index"
                                :to="!item.href==false ? { name: item.name } : null"


                                @click="item.click"
                        >
                            <v-list-item-action v-if="item.icon">
                               <img :class="$store.state.dark?'white-svg':'black-svg'" :src="item.icon">
                            </v-list-item-action>
                            <v-list-item-content>
                                <v-list-item-title class="fonta">{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-card>
                </v-container>
                <v-row justify="center" align="center" no-gutters>
                    <vs-button to="/privacy-policy" flat size="sm">Privacy policy</vs-button>
                    <vs-button   animation-type="rotate" border  :color="$store.state.dark?'#fff':'#000'" :dark="$store.state.dark"  to="/terms"   >Terms of service</vs-button>

                </v-row>
            </v-card>

        </v-menu>
    </div>
</template>

<script>
    import strMixins from '@/plugins/Mixins'
    export default {
        mixins:[strMixins],
        data() {
            return {
                AppName:'DZtechno',
                active:'',
                langue: [
                    {
                        title: "Français",
                        icon: "mdi-language-fortran",
                        path: "/fr",
                        onClick: () => {
                            location.reload();
                            let vm = this;
                            // vm.$cookie.set("lan_g", "fr", 30);
                            location.reload();
                        },
                    },
                    {
                        title: "العربية",
                        icon: "mdi-abjad-arabic",
                        path: "/ar",
                        onClick: () => {
                            let vm = this;
                            // vm.$cookie.set("lan_g", "ar", 30);
                            location.reload();
                        },
                    },
                ],
                color: "#27AF60",

                dialogSettings: false,
                items: [
                    {
                        icon: "assets/icons/vuesax/home.svg",
                        href: false,
                        title: "Home",
                        click: (e) => {
                            const vm = this;
                            if (vm.$router.currentRoute.path !== "/info") {
                                vm.$router.push("/info").catch(() => {});
                            }
                        },
                    },
                    {
                        icon: "assets/icons/vuesax/user-1.svg",
                        href: false,
                        title: "Profile",
                        click: (e) => {
                            const vm = this;
                            if (vm.$router.currentRoute.path !== "/profile") {
                                vm.$router.push("/profile").catch(() => {});
                            }
                        },
                    },
                    {
                        icon: "assets/icons/vuesax/logout.svg",
                        href: false,
                        title: "Déconnecter",
                        click: () => {
                            const vm = this;
                            localStorage.removeItem('token')
                            localStorage.removeItem('profile')
                            vm.$store.commit('auth/SET_USER',null)
                            vm.$store.commit('auth/SET_AUTHENTICATED',false)
                            window.location.href = "/"
                        },
                    },
                ],
                selectedItem: null,
            };
        },
        methods: {
            HandelMode(){
                if(this.$store.state.dark){
                    this.$store.commit('SET_DARK',false)

                    this.$vs.setTheme('light')
                    window.localStorage.setItem('mode',"false")
                }else{
                    this.$store.commit('SET_DARK',true)
                    window.localStorage.setItem('mode','true')
                    this.$vs.setTheme('dark')
                }

            },
            logout: async function () {
                try {
                    const response = await axios.post("logout");
                    window.location.href = "/login";
                } catch (e) {
                    window.location.href = "/login";
                }
            },
        },
        mounted() {
            this.active =window.localStorage.getItem('mode')=="true"?true:false
        }

    };
</script>

<style lang="scss" scoped>
    .v-ripple-container{

        border-radius: 20px!important;
    }
    .v-menu__content {
        background: none!important;
        border-radius: 20px!important;
    }
    .vs-avatar__badge.top-right {
        top: 6px!important;
        bottom: auto;
    }
</style>