<template>
    <div   >
        <div v-if="$store.state.auth.user.type == 'admin'" class="cont pa-3"  style="direction: ltr" >
            <zv-card    class="py-3 elevation-0 rounded-xl" v-if="userInf || usersInfOrmation.length >0     ">
                <div class="my3">
                    <TitlePages title="إستهلاكات الزبائن">

                        <v-app-bar-nav-icon slot="btn2" :dark="dark"   @click="$router.go(-1)" large
                                            style="float: left!important;" icon depressed><img
                                :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg"/>
                        </v-app-bar-nav-icon>
                        <v-menu slot="btn"
                                :dark="$store.state.dark"

                                ref="menu"
                                transition="slide-x-transition"
                                bottom
                                right
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value="dates"

                                min-width="auto"
                        >
                            <template v-slot:activator="{ on }">
                                <vs-button class="app-regular ml-3"  v-on="on"  color="rgb(254, 44, 85)"  icon >
                                    <img class="white-svg" src="assets/icons/vuesax/calendar.svg">
                                </vs-button>

                            </template>

                            <v-date-picker
                                    range
                                    v-model="dates"
                                    no-title
                                    scrollable
                                    color="#03A9F4"
                            >
                                <v-spacer></v-spacer>

                                <v-btn
                                        text  class="app-regular"

                                        color="#BEBEBE"
                                        @click="menu = false"
                                >
                                    إغلاق
                                </v-btn>

                                <v-btn :disabled="dates[1] > getNow "
                                       text class="app-regular"
                                       color="#03A9F4"
                                       @click="getadminAchatArray(dates)"
                                >
                                    بحث
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </TitlePages>
                    <v-row class="pa-0" >
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="warning"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center;position:relative">


                                    <span> @Balance</span>

                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                         <span class="grand-number"   >
                                                             <animated-number
                                                                     :value="userInf.today.solde"
                                                                     :formatValue="formatToPrice"

                                                                     :duration="1000"
                                                             />
                                                         </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" > الحساب</span>
                                    <p class="app-regular">
                                        الفوائد
                                        <span>{{userInf.today.bonus}}</span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>


                        </v-col>
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="primary"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center">
                                    <span> @Credit Total</span>
                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"  >
                                                       <animated-number
                                                               :value="userInf.today.credit_total"
                                                               :formatValue="formatToPrice"

                                                               :duration="1000"
                                                       />
                                                 </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" >مجموع الديون</span>
                                    <p class="app-regular">
                                        <span v-text="dates==''?'اليوم':'بين التواريخ'"></span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="purple"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center">

                                    <span> @Cartes</span>
                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"  >
                                                       <animated-number
                                                               :value="userInf.today.card_total"
                                                               :formatValue="formatToPrice"

                                                               :duration="1000"
                                                       />
                                                 </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" >مجموع إيدوم</span>
                                    <p class="app-regular">
                                        <span v-text="dates==''?'اليوم':'بين التواريخ'"></span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="success"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center">

                                    <span> @Idoom</span>
                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"   >
                                                         <animated-number
                                                                 :value="userInf.today.idoom_total"
                                                                 :formatValue="formatToPrice"

                                                                 :duration="1000"
                                                         />
                                                 </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" >مجموع إيدوم</span>
                                    <p class="app-regular">
                                        <span v-text="dates==''?'اليوم':'بين التواريخ'"></span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>


                </div>
                <v-card flat :dark="dark" :color="dark?'app-card':'app-card-light'" class="rounded-xl mt-2 mb-5 mx-3 vuertl" >
                    <v-card-title class="app-regular pb-0">
                        أسعار الفليكسي
                        <v-spacer></v-spacer>
                        <vs-button class="app-regular" :loading="flexy.busy"    color="rgb(254, 44, 85)"   @click="saveType()" >

                            تحديث أسعار الفليكسي
                            <img class="white-svg mr-2 " src="assets/icons/vuesax/send.svg">
                        </vs-button>

                    </v-card-title>
                    <v-card-title class="pt-0">

                        <v-spacer></v-spacer>
                        <vs-checkbox  v-model="flexy.name == 'NORMAL'" @click="Handelswitch('NORMAL')"  >
                            NORMAL
                        </vs-checkbox>
                        <vs-checkbox  v-model="flexy.name == 'COMMERCANT'" @click="Handelswitch('COMMERCANT')"  >
                            COMMERCANT
                        </vs-checkbox>
                        <vs-checkbox  v-model="flexy.name == 'DISTRIBUTEUR'" @click="Handelswitch('DISTRIBUTEUR')"    >
                            DISTRIBUTEUR
                        </vs-checkbox>
                        <vs-checkbox  v-model="flexy.name == 'GROSSISTE'"  @click="Handelswitch('GROSSISTE')"    >
                            GROSSISTE
                        </vs-checkbox>
                        <vs-checkbox  v-model="flexy.name == 'SUPERVISEUR'"   @click="Handelswitch('SUPERVISEUR')"   >
                            SUPERVISEUR
                        </vs-checkbox>
                        <v-spacer></v-spacer>

                    </v-card-title>
                    <v-row dense class="px-2">


                        <v-col class="col-lg-4 col-sm-4 col-4 col-xl-4 col-md-4 col-xs-12">
                            <v-text-field maxlength="2"  v-model="flexy.mobilis"   :background-color="dark?'#242426':'#fff'" flat filled
                                          class="app-regular rounded-lg" prefix="%" hide-details
                                          placeholder="سعر موبيليس تجزئة"></v-text-field>
                            <p class="mr-2 pb-0">سعر موبيليس تجزئة</p>
                        </v-col>
                        <v-col class="col-lg-4 col-sm-4 col-4 col-xl-4 col-md-4 col-xs-12">
                            <v-text-field v-model="flexy.djezzy"  persistent-hint hint="سعر جيزي تجزئة"
                                          :background-color="dark?'#242426':'#fff'" flat filled
                                          class="app-regular rounded-lg" prefix="%" hide-details
                                          placeholder="سعر جيزي تجزئة"></v-text-field>
                            <p class="mr-2 pb-0">سعر جيزي تجزئة</p>
                        </v-col>
                        <v-col class="col-lg-4 col-sm-4 col-4 col-xl-4 col-md-4 col-xs-12">
                            <v-text-field  v-model="flexy.ooredoo"   
                                           :background-color="dark?'#242426':'#fff'" flat filled
                                           class="app-regular rounded-lg" prefix="%" hide-details
                                           placeholder="سعر أوريدو"></v-text-field>
                            <p class="mr-2 pb-0">سعر أوريدو تجزئة</p>
                        </v-col>




                    </v-row>
                    <v-card-title class="app-regular">
                        أسعار الفليكسي بالجملة
                        <v-spacer></v-spacer>

                    </v-card-title>
                    <v-row class="px-2" dense>


                        <v-col class="col-lg-4 col-sm-4 col-4 col-xl-4 col-md-4 col-xs-12">
                            <v-text-field maxlength="2"  v-model="flexy.mobilisGross"
                                          :background-color="dark?'#242426':'#fff'" flat filled
                                          class="app-regular rounded-lg" prefix="%"  hide-details
                                          placeholder="سعر موبيليس"></v-text-field>
                            <p class="mr-2 pb-0">سعر موبيليس</p>
                        </v-col>
                        <v-col class="col-lg-4 col-sm-4 col-4 col-xl-4 col-md-4 col-xs-12">
                            <v-text-field v-model="flexy.djezzyGross"  hide-details
                                          :background-color="dark?'#242426':'#fff'" flat filled
                                          class="app-regular rounded-lg" prefix="%"
                                          placeholder="سعر جيزي جملة"></v-text-field>
                            <p class="mr-2 pb-0">سعر أوريدو جملة</p>
                        </v-col>
                        <v-col class="col-lg-4 col-sm-4 col-4 col-xl-4 col-md-4 col-xs-12">
                            <v-text-field  v-model="flexy.ooredooGross"  hide-details
                                           :background-color="dark?'#242426':'#fff'" flat filled
                                           class="app-regular rounded-lg" prefix="   %"
                                           placeholder="سعر أوريدو جملة"></v-text-field>
                            <p class="mr-2 pb-0">سعر أوريدو جملة</p>
                        </v-col>




                    </v-row>
                    <v-card-title class="app-regular">
                                الحد الادنى لتحويل الارباح
                        <v-spacer></v-spacer>

                    </v-card-title>
                    <v-row class="px-2" dense>


                        <v-col class="col-12">
                            <v-text-field maxlength="2"  v-model="flexy.bonusLimit"   :background-color="dark?'#242426':'#fff'" flat filled
                                          class="app-regular rounded-lg"  suffix=" DA" persistent-hint hint="الحد الادنى لتحويل الارباح"
                                          placeholder="المبلغ"></v-text-field>
                        </v-col>
                    </v-row>
                  <v-card-title class="app-regular">
                    أسعار إيدوم و البطاقات
                    <v-spacer></v-spacer>

                  </v-card-title>
                  <v-row class="px-2" dense>


                    <v-col class="col-lg-6 col-sm-6 col-12 col-xl-6 col-md-6 col-xs-12">
                      <v-text-field maxlength="2"  v-model="flexy.idoom"   :background-color="dark?'#242426':'#fff'" flat filled
                                    class="app-regular rounded-lg" prefix="%" persistent-hint hint="سعرإيدوم IDOOM"
                                    placeholder="سعر إيدوم  idoom"></v-text-field>
                    </v-col>
                    <v-col class="col-lg-6 col-sm-6 col-12 col-xl-6 col-md-6 col-xs-12">
                      <v-text-field v-model="flexy.card"  persistent-hint hint="سعر البطاقات"
                                    :background-color="dark?'#242426':'#fff'" flat filled
                                    class="app-regular rounded-lg" prefix="%"
                                    placeholder="سعر البطاقات"></v-text-field>
                    </v-col>





                  </v-row>


                </v-card>
                <v-card flat :dark="dark" :color="dark?'app-card':'app-card-light'" class="rounded-xl mt-2 mx-3" >
                    <v-row>

                        <v-col class="col-12">
                            <vs-table stripped :dark="$store.state.dark" class="mx-2"
                                      v-model="selected"
                            >
                                <template #header>
                                    <h3 style="text-align:center" class="app-regular">جدول يبين حسابات كل مشتربات الزبائن</h3>
                                </template>
                                <template #thead>
                                    <vs-tr>

                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'day')">
                                            <span class="app-regular" >تاريخ</span>
                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'flexy_total')">
                                            <span class="app-regular" >مجموع الفليكسي</span>
                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'mobilis_total')">
                                            <span class="app-regular" > مجموع موبيليس</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'mobilis')">
                                            <span class="app-regular" >موبيليس</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'pixx')">
                                            <span class="app-regular" >بيكس</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'ooredoo')">
                                            <span class="app-regular" >أوريدو</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'djezzy')">
                                            <span class="app-regular" >جيزي</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'card_total')">
                                            <span class="app-regular" >بطاقات</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'idoom_total')">
                                            <span class="app-regular" >إيدوم</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'versement_total')">
                                            <span class="app-regular" >إيداعات</span>

                                        </vs-th>
                                        <vs-th  >
                                            <span class="app-regular" >المجموع</span>

                                        </vs-th>
                                    </vs-tr>
                                </template>
                                <template #tbody>
                                    <vs-tr
                                            :key="i"
                                            v-for="(tr, i) in $vs.getPage($vs.getSearch(usersInfOrmation, search), page, max)"
                                            :data="tr"
                                            :is-selected="!!selected.includes(tr)"
                                            not-click-selected
                                            open-expand-only-td
                                    >

                                        <vs-td>
                                            <b class="app-regular ">{{ tr.day | dateTable}}</b>
                                        </vs-td>
                                        <vs-td>
                                            <vs-button success transparent :active="true">{{ tr.flexy_total }}</vs-button>
                                        </vs-td>
                                        <vs-td>
                                            <vs-button  color="rgb(254, 44, 85)"  transparent :active="true">{{ tr.mobilis_total }}</vs-button>


                                        </vs-td>
                                        <vs-td>
                                            <b>{{tr.mobilis}}</b>
                                        </vs-td>
                                        <vs-td>

                                            <b>{{tr.pixx}}</b>

                                        </vs-td>
                                        <vs-td>
                                            <b>{{tr.ooredoo}}</b>

                                        </vs-td>
                                        <vs-td>

                                            <b>{{tr.djezzy}}</b>
                                        </vs-td>
                                        <vs-td>
                                            <b>{{tr.card_total}}</b>
                                        </vs-td>
                                        <vs-td>
                                            <b>{{tr.idoom_total}}</b>
                                        </vs-td>
                                        <vs-td>
                                            <vs-button danger transparent :active="true">{{ tr.versement_total }}</vs-button>

                                        </vs-td>
                                        <vs-td>
                                            <vs-button success transparent :active="true">{{ Number(tr.flexy_total) + Number(tr.mobilis_total) + Number(tr.mobilis) + Number(tr.ooredoo) + Number(tr.djezzy) +  Number(tr.card_total) + Number(tr.idoom_total) }}</vs-button>

                                        </vs-td>
                                        <!--                                        <template #expand>-->
                                        <!--                                            <div class="con-content">-->
                                        <!--                                                <div>-->
                                        <!--                                                    <vs-avatar>-->
                                        <!--                                                        <img :src="`/avatars/avatar-${i + 1}.png`" alt="">-->
                                        <!--                                                    </vs-avatar>-->
                                        <!--                                                    <p>-->
                                        <!--                                                        {{ tr.name }}-->
                                        <!--                                                    </p>-->
                                        <!--                                                </div>-->
                                        <!--                                                <div>-->
                                        <!--                                                    <vs-button flat icon>-->
                                        <!--                                                        <i class='bx bx-lock-open-alt' ></i>-->
                                        <!--                                                    </vs-button>-->
                                        <!--                                                    <vs-button flat icon>-->
                                        <!--                                                        Send Email-->
                                        <!--                                                    </vs-button>-->
                                        <!--                                                    <vs-button border danger>-->
                                        <!--                                                        Remove User-->
                                        <!--                                                    </vs-button>-->
                                        <!--                                                </div>-->
                                        <!--                                            </div>-->
                                        <!--                                        </template>-->
                                    </vs-tr>
                                </template>
                                <template #notFound>
                                    <h3 class="app-regular">لا توجد بيانات </h3>
                                </template>
                                <template #footer>
                                    <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(usersInfOrmation, search), max)" />
                                </template>
                            </vs-table>
                        </v-col>
                    </v-row>
                </v-card>

            </zv-card>

        </div>
        <NotFound v-else />
    </div>
</template>

<script>

    import Form from 'vform'
    import NotFound from "@/components/comp-tool/NotFound.vue";
    import {mapState} from "vuex";
    import {comparDates,getColorr, iconChange, iconR , userColor,statusTypeOfUser,setBaseUrl,formatToPrice} from "@/tools/Services.js";
    import TitlePages from "@/layout/TitlePages";
    import AnimatedNumber from "animated-number-vue";

    import axios from 'axios'
    export default {
        components: {
            AnimatedNumber,TitlePages , NotFound
        },

        computed:{

            ...mapState([
                'dark'
            ]),
            getNow: function() {
                const date = new Date()
                return date.toISOString().split('T')[0]
            },
        },
        data(){
            return {
                types:{
                    NORMAL:false,
                    COMMERCANT:false,
                    DISTRIBUTEUR:false,
                    GROSSISTE:false,
                    SUPERVISEUR:false
                },
                flexy: new Form({
                    bonusLimit:'',
                    name:"",
                    user_id:'',
                    mobilis:'',
                    djezzy:'',
                    ooredoo:'',
                    ooredooGross:'',
                    djezzyGross:'',
                    mobilisGross:'',
                    idoom:'',
                    card:''
                }),
                editActive: false,
                edit: null,
                editProp: {},
                search: '',
                allCheck: false,
                page: 1,
                max: 31,
                active: 0,
                selected: [],
                userInf:[],
                usersInfOrmation:[],
                dates:[],
                menu:false,
                date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

            }
        },

        mounted() {
            this.dates[0] = this.date
            this.getadminAchat(this.dates,this.$route.params.id)

            // setInterval(()=>this.getadminAchat(this.date),30000)
        },
        methods: {comparDates,
            saveType(){
            this.flexy.user_id = this.$route.params.id
            this.flexy.post('api/save-type-user').then((response)=>{
                this.$vs.notification({
                    classNotification:"vuertl",
                    icon:'<img  class="white-svg" src="assets/icons/vuesax/success.svg" />',
                    title: 'حدث خطأ',
                    position:"top-center",
                    color:"success",
                    text:"تم حفظ الاعدادات بنجاح",
                    duration:"3000"

                })
            }).catch(()=>{
                this.$vs.notification({
                    classNotification:"vuertl",
                    icon:'<img  class="white-svg" src="assets/icons/vuesax/alert.svg" />',
                    title: 'حدث خطأ',
                    position:"top-center",
                    color:"danger",
                    text:"حدث خطأ أعد المحاولة",
                    duration:"3000"

                })
            })
            },
            Handelswitch(type){
            this.flexy.name = type
                axios.post('api/get-type-user',{type:type}).then((response)=>{
                    this.flexy.fill(response.data.pourcentage)
                })
            },
            formatToPrice,

              getadminAchat(date , id){
                      axios.post('api/admin-achat-date-for-user',{dates:date,id:id}).then((response)=>{
                    this.userInf = response.data

                   this.getadminAchatArray([this.date,this.date],id)

                })
            },
              getadminAchatArray(dates , id){
                if(comparDates(dates)){
                          axios.post('api/admin-achat-date-for-user',{dates:dates,id}).then((response)=>{
                              this.usersInfOrmation = response.data.total

                              this.flexy.fill(response.data.pourcentage)


                        this.menu = false
                    })
                }else {
                    this.menu = false
                    this.$vs.notification({
                        classNotification:"vuertl",
                        icon:'<img  class="white-svg" src="assets/icons/vuesax/alert.svg" />',
                        title: 'حدث خطأ',
                        position:"top-center",
                        color:"danger",
                        text:"تحقق من بداية التاريخ حتى النهاية",
                        duration:"3000"

                    })
                }


            },

        },

    }
</script>
<style scoped>
    .con-image{
        border-radius: inherit
    }
    .image-st{
        display :block;
        position :relative;
        border-radius :inherit;
        max-width :850px
    }
    .grand-number{
        font-size: 2.05rem;
        font-weight: 600;
        font-family: app-regular;
    }
    .text-info{
        font-weight: 100;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.4375rem;
    }
    .v-list-item{
        float: right!important;
        direction: ltr!important;
    }
    .for-allert{
        height: 40px!important;
        text-align: center!important;
        padding-top: 7px!important;
    }
    .vs-dialog__header{
        direction: ltr!important;
    }
    >>> .v-input__slot::before {
        border-style: none !important;
    }
    .v-list-item--link{
        direction: rlt;
    }

    img{
        display :block;
        position :relative;
        border-radius: inherit;

    }

    .con-image{
        border-radius :inherit
    }

    img{
        display :block;
        position :relative;
        border-radius: inherit;

    }
</style>
