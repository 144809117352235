<template>
  <div>

    <div>
      <div class="b-game-card  abnf" @click="showSheet(cart)">
        <div class="b-game-card__cover " :class="$vuetify.breakpoint.smAndDown?'rounded-lg':'rounded-xl'">

          <img width="100%" height="100%" :src="setBaseUrl(cart.image)" :alt="cart.name" />
          <div class="d-flex justify-content-center overlay-solde">
            <vs-button :style="{fontSize:$vuetify.breakpoint.smAndDown?'12px':''}"     size="large" border color="orange"   block
                         @click="getGroupe(cart.id)"
                         class="app-regular rounded-lg px-0 mr-n2" >اشتر الان</vs-button>

            {{ cart.price }}
            <sub>DA</sub>
          </div>
        </div>
      </div>
      <h5 class="text-center mt-3 text-truncate app-regular" v-text="cart.name"></h5>
      <vs-button  :style="{fontSize:$vuetify.breakpoint.smAndDown?'11px':''}"
                  @click="showSheet(cart)"    :color="$store.state.App.config.color"
                  block size="large" class="app-bold px-0 ml-0"
                  style="height: 100%;border-radius: 12px;"  border>عرض المنتج</vs-button>


    </div>



    <v-overlay v-if="sheet"   class="blur">
      <component   :origin="'top center'"  :max-width="$vuetify.breakpoint.smAndDown?'':'600px'"  :is="$vuetify.breakpoint.smAndDown?'VBottomSheet':'VDialog'"      :dark="dark"       v-model="sheet" style="z-index: 20000!important" >
        <v-card class="overflow-hidden"
                ref="target"

                v-if="!success"
                v-click-outside="closeSheet" :color="dark ? 'black-vuesax' : ' '"
                :class="!$vuetify.breakpoint.smAndDown?'bottom-sheet-radius-all':'bottom-sheet-radius'">
          <v-card  flat style="position: relative" :color="dark ? 'app-card' : ' '"  :class="$vuetify.breakpoint.smAndDown?'bottom-sheet-radius-all':'bottom-sheet-radius'">
            <v-card-title class="pb-0">
              <v-spacer></v-spacer>
              <v-btn @click="sheet = !sheet" :dark="dark" icon class="ml-2  "  active   >
                <img :class="dark ? 'white-svg' : 'black-svg'"
                     src="assets/icons/vuesax/close-1.svg" />
              </v-btn>
            </v-card-title>
            <v-list  class="mt-n4" :dark="dark" flat :color="dark ? 'app-card' : ' '">
              <v-list-item style="position: relative; ">
                <v-card width="90" class="mx-2 overflow-hidden" rounded="lg" height="130" link @click="showInfoProduct(carte)" c>
                  <img height="100%" :src="setBaseUrl(carte.image)" style="backdrop-filter: blur(0px)!important" />
                  </v-card>

                  <v-list-item-content class="pa-2 mt-2 mr-0 p-1 vuertl">

                  <v-card-title class="pa-0 app-bold" style="line-height: 1.9!important;"  >
                    {{ carte.name }}
                    <v-spacer></v-spacer>
                    <span style="float: right;"  class=" ">
                      <b class="success--text">{{ formatToPrice(form.total) }}</b>

                    <b v-if="$store.state.auth.user" class="ml-3 text-decoration-line-through error--text" >{{formatToPrice( form.count * carte.Price)}}</b>
                    </span>
                  </v-card-title>
                  <v-list-item-subtitle style="line-height: 1.9!important;text-align: center!important;"
                                        class="app-bold danger--text " v-text="carte.isCommand==1 ?carte.command:'تسليم إلكتروني فوري مباشر'"></v-list-item-subtitle>
                    <v-divider vertical style="max-width: 80px"></v-divider>
                  <v-list-item-title :class="$store.state.params.color" @click="showInfoProduct(carte)"
                    class="mt-0 mb-0  app-regular mb-2"  style="cursor:pointer;    line-height: 1.5;">
                    <v-card warn class="rounded-xl elevation-0  "  :class="$store.state.dark?'dashboard':'app-card-light'"  block :dark="$store.state.dark">
                     <v-card-title class="py-1">
                        <span class=" mr-0 text-decoration-none">

                       <span style="font-size: 15px" class="mb-2 orange--text text-decoration-none app-bold">عرض التفاصيل</span>
                      <img width="17px"  class="mr-2  orange-svgg" src="assets/icons/vuesax/blogger.svg">
                    </span>
                       <v-spacer></v-spacer>
                       <v-btn    :dark="dark" icon class="ml-2 " large  active   >
                         <img style="width: 25px" :class="dark ? 'white-svg' : 'black-svg'"
                              src="assets/icons/vuesax/chevron-right.svg" />
                       </v-btn>
                     </v-card-title>
                    </v-card>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-row class="ma-2 vuertl">
              <v-col  class="col-12  pt-0" v-if="inputs.length >=1">
                <p v-text="inputs[0].name" class="mb-1 "></p>
                <v-text-field :background-color="$store.state.dark?'#242426':'#EEF2F5'"  v-model="form.feildOne"  hide-details outlined   flat filled class="app-regular rounded-lg"
                              :placeholder="inputs.name"></v-text-field>
              </v-col>
              <v-col  class="col-12  py-0"  v-if="inputs.length >=2">
                <p v-text="inputs[1].name" class="mb-1 "></p>
                <v-text-field :background-color="$store.state.dark?'#242426':'#EEF2F5'"  v-model="form.feildTwo"  hide-details outlined   flat filled class="app-regular rounded-lg"
                              :placeholder="inputs.name"></v-text-field>
              </v-col>
            </v-row>
            <v-card-title   class="py-0   app-bold   vuertl">اختيار كمية
              المنتج</v-card-title>
              <v-card-actions>
                <vs-button      :disabled="user == null"  :color="dark?'#000':'#EEF2F5'"  block @click="handelCount('+')"

                >
                  <img style="width: 35px"  :class="dark?'white-svg':'black-svg'"
                       src="assets/icons/vuesax/plus.svg" />
                </vs-button>
                <vs-button   :disabled="user == null"   :color="dark?'#000':'#EEF2F5'"  block @click="handelCount('-')"

                >
                  <img style="width: 35px"  :class="dark?'white-svg':'black-svg'"
                       src="assets/icons/vuesax/minus.svg" />
                </vs-button>
                <vs-button active size="small"  v-if="user != null" @click.stop="HandelCardRequest()" :dark="dark"  border   :color="$store.state.App.config.colorBackground"
                           class="  purple-grad  rounded-xl" animation-type="scale"
                             :class="$vuetify.breakpoint.smAndDown?'py-1':'py-2'"
                             block style="border-radius: 16px;">
                                                        <span style="font-size: 16px" class="ml-3  app-bold"
                                                        ><v-badge :offset-x="-5" left :dot="form.count <=0" overlap :color="form.count >0 ? 'orange' : 'grey'"
                                                                  :content="form.count"><strong> شـراء</strong> </v-badge></span
                                                        >
                  <img  class="white-svg" src="assets/icons/vuesax/send.svg"/>
                </vs-button>
                <vs-button v-else    :color="$store.state.App.config.colorBackground"        to="/login"
                           class="white--text rounded-lg  " animation-type="scale"
                           :dark="dark"
                           :class="$vuetify.breakpoint.smAndDown?'py-1':'py-2'"
                           block style="border-radius: 16px;">

                                                        <span style="font-size: 16px" class="ml-3 white--text app-regular"
                                                        > <strong> الدخول</strong></span >

                </vs-button>

              </v-card-actions>

          </v-card>
        </v-card>
        <v-card class="rounded-t-xl overflow-hidden"  v-if="success "  ref="target" flat style="position: relative" :color="dark ? '#000' : ' '" :class="!$vuetify.breakpoint.smAndDown?'bottom-sheet-radius-all':'bottom-sheet-radius'">

          <v-card-title class="p-0 pb-0">
            <v-spacer></v-spacer>
            <img class="success-svg" src="assets/icons/vuesax/success-response.svg" />
            <v-spacer></v-spacer>


          </v-card-title>
          <v-card-title class="app-bold  text-center pt-1" style="font-size: 22px;display: block;">{{response.message}}</v-card-title>
          <v-card-subtitle  class="d-block app-regular text-center mt-4">{{response.description}}</v-card-subtitle>
          <v-card-actions class="app-regular">
            <v-spacer></v-spacer>
             <vs-button :dark="dark" to="/historique-cartes" success gradient class="px-2"   >إظهار المنتج</vs-button>
            <vs-button :dark="dark" @click="handelShop" border :color="$store.state.App.config.colorBackground"  >إتمام عملية الشراء</vs-button>
          </v-card-actions>
        </v-card>

      </component >
    </v-overlay>





    <v-dialog  :dark="dark" :color="dark ? 'app-bg' : 'app-bg-light'" v-model="showProduct" fullscreen hide-overlay
               style="z-index: 12000"
      transition="dialog-bottom-transition" scrollable>
      <v-card flat :color="dark ? 'app-bg' : 'app-bg-light'" height="100%">
       <v-card-text class="px-0">
         <v-container style="direction: rtl" class="mt-2">
           <v-card ripple @click="handelCloseBtn"  elevation="0" :dark="dark" :color="$store.state.dark?'bg-dark':'bg-light'" class="rounded-xl blur"   >

             <v-card-title  >
               <h4>{{ selectedCard.name }}</h4>
               <v-spacer></v-spacer>
               <v-btn @click="showProduct = !showProduct"  :dark="dark" icon class="ml-2 " large  active   >
                 <img style="width: 32px" :class="dark ? 'white-svg' : 'black-svg'"
                      src="assets/icons/vuesax/chevron-right.svg" />
               </v-btn>


             </v-card-title>
             <v-list flat   color="transparent" >
               <v-list-item style=" position: relative; top: -20px">

                 <v-card width="90" class="mx-2 overflow-hidden" rounded="lg" height="130" >

                   <img height="100%" :src="setBaseUrl(carte.image)" />
                 </v-card>
                 <v-list-item-content class="pa-2 mt-8 mr-8 p-1">

                   <v-list-item-subtitle style="line-height: 1.9!important;font-size: 17px"  class="app-medium success--text">
                     <h3> {{formatToPrice(carte.CardPrice)}}</h3>
                   </v-list-item-subtitle>
                   <v-list-item-title style="line-height: 1.9!important;" class="app-regular"
                                      ><h3>{{carte.name}}</h3></v-list-item-title>
                   <v-list-item-subtitle style="line-height: 1.9!important;"  class="app-medium" v-text="carte.command"> </v-list-item-subtitle>

                 </v-list-item-content>
               </v-list-item>
             </v-list>


           </v-card>
           <v-card-title  class="py-0    vuertl mt-3 app-medium">عرض تفاصيل المنتوج</v-card-title>
           <v-card flat :color="dark ? 'app-bg' : 'app-bg-light'">
             <v-container>
               <div :dir="carte.dir" v-html="carte.description_ar" class="mt-3 app-regular"></div>
             </v-container>
           </v-card>
         </v-container>
       </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { formatToPrice,setBaseUrl } from '@/tools/Services';
import axios from 'axios';
import Form from 'vform'
import strMixins from '@/plugins/Mixins'
import {VDialog , VBottomSheet} from "vuetify/lib"
import {bus} from "@/plugins/bus";
import * as authUser from "@/plugins/authUser";
export default {
  name: "CardComponent",
  components:{VBottomSheet , VDialog},
  mixins:[strMixins],
  props: ["cart"],
  computed:{
    component:function(){
      return this.$vuetify.breakpoint.mdAndDown?"v-bottom-sheet":"v-dialog"
    }
  },

  data() {
    return {

      inputs:[],
      response:"",
      activeBtn:null,
      form: new Form({
        count: 1,
        amount: '',
        name:'',
        total:'',

        feildTwo:'',
        feildOne:'',
      }),

      selectedCard: {},
      showProduct: false,

      modeltow: null,
      model: null,
      show: false,
      sheet: false,
      carte: "",
      price: '',
      error: '#d5397b',
      success:false,

    };
   },
  watch:{
    sheet:function (){
      if (this.sheet){
        this.$store.commit('SET_ICARDACTIVE',true)
      }else {
        this.$store.commit('SET_ICARDACTIVE',false)
      }
    },
    'starPost.message':function (newVal,oldVal){
      if(newVal.length >=150){
         this.starPost.message = this.starPost.message.substring(0,149)
      }
    },
    showProduct:function () {
      this.form.count = 1
      if (this.showProduct== false){

        setTimeout(()=>this.sheet = !this.sheet,800)
      }
    }
  },
  methods: {setBaseUrl,
    handelCloseBtn(){
      setTimeout(()=>this.showProduct = false,1000)
    },
    handelShop(){
      this.sheet = !this.sheet
      bus.$emit('loadComments')
    },


    closeSheet(){
      this.sheet = !this.sheet
    },
    HandelCardRequest(){
        this.success = false
      const loading = this.$vs.loading({
        type:'scale',
        target: this.$refs.target,
        background: this.dark?'#000':'#fff',
        color:this.$store.state.params.bg,
        opacity:0.9,
        text:'يتم توفير طلبك الان'})

      if(this.user != null){
        this.response= ""
        this.form.post('api/request-card').then(async (response)=>{
          const res = await authUser.getProfile();
          this.$store.commit('auth/SET_USER',res.data)
          this.response = response.data.resp

            this.success = true
          try {  loading.close()  }catch (e) {  }

        }).catch((error)=>{
          try {  loading.close()  }catch (e) {  }
          this.$store.dispatch("loadsna", {
            text: error.response.data.message,
            isSuccess: false,
          });

        })
      }else {
       this.$router.push('/login')
      }

 },
 
    formatToPrice,
    HandelSelectedPrice(price ,index ) {
      this.activeBtn = index
      this.price = price
      this.form.total = price
      this.form.amount = price
      this.form.count = 1
      this.$emit('change');
    },
    handelCount(opperator) {

      if (opperator == '-' && this.form.count <= 1) {
        this.form.count = 1
        this.form.total = this.cart.CardPrice
      } else if (opperator == '+' && this.form.count >= 100){
        this.form.count = 100
        this.form.total = this.cart.CardPrice * 100

      }else {
        if(opperator == '+' ){this.form.count+=1}else {this.form.count-=1}

        this.form.total = this.cart.CardPrice * this.form.count
      }
    },
    showInfoProduct(carte) {
        this.success = false
       this.sheet = !this.sheet
      this.form.total = carte.CardPrice
      this.model =  null
      this.form.amount = carte.CardPrice
        this.selectedCard = carte
      this.sheet = false
     setTimeout(()=> this.showProduct = true,800)
    },
    showSheet(cart) {
      axios.get('api/get-inputs/'+cart.id).then((response)=>{
        this.inputs = response.data
      })
      this.success = false
      this.form.image = cart.image
      this.form.name = cart.name
      this.form.count = 1
      this.form.amount = cart.CardPrice
      this.form.total = cart.CardPrice
      this.carte = cart;
      this.sheet = !this.sheet;
    },
  },

};
</script>
<style scoped>
.blur {
  -webkit-backdrop-filter: saturate(180%) blur(15px)!important;
  backdrop-filter: saturate(180%) blur(15px)!important;
}

</style>
<style lang="scss" scoped>
.blur {
  -webkit-backdrop-filter: saturate(180%) blur(15px)!important;
  backdrop-filter: saturate(180%) blur(15px)!important;
}

  >>> .v-input__slot::before {
    border-style: none !important;
  }
  .v-list-item--link{
    direction: rlt;
  }
.b-game-card {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: 150%;
  perspective: 1000px;
  transition: all 0.2s ease-in-out;
}

.b-game-card__cover {
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
   
  background-size: cover;
  perspective-origin: 50% 50%;
  transform-style: preserve-3d;
  transform-origin: top center;
  will-change: transform;
  transform: skewX(0.001deg);
  transition: transform 0.25s ease-in-out;
}

.b-game-card__cover::after {
  display: block;
  content: "";
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background: linear-gradient(226deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 35%, rgba(255, 255, 255, 0.1) 42%, rgba(255, 255, 255, 0) 60%);
  transform: translateY(-50%);
  will-change: transform;
  transition: transform 0.12s ease-in-out, opacity 0.1s ease-in-out;
}

.b-game-card:hover .b-game-card__cover::after {
  transform: translateY(0%);
}

.b-game-card::before {
  display: block;
  content: "";
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  transform: translateY(-20%);
  will-change: transform;
  transition: transform 0.5s cubic-bezier(0.18, 0.9, 0.58, 1);
}

.b-game-card:hover .b-game-card__cover {
  transform: rotateX(10deg) translateY(-8px);
}

.b-game-card:hover .b-game-card__cover::after {
  transform: translateY(0%);
}

.img-border-bottom {
  border-bottom-left-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
  -moz-box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
  -webkit-box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
  box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
}
.blur{
  backdrop-filter: blur(3px)importan !important
  
}
.v-dialog__content {
  z-index: 1002 !important;
}
.bottom-sheet-radius{
  border-top-left-radius: 20px!important;
  border-top-right-radius: 20px!important;
}
.bottom-sheet-radius-all{
  border-radius: 12px!important;
}
div.v-slide-group__content{
  display: flex!important;
    justify-content: center!important;
}
div.v-slide-group__wrapper{
  display: flex!important;
    justify-content: center!important;
}

>>> .v-input__slot::before {
  border-style: none !important;
}
>>> .v-input__slot::after {
  border-style: none !important;
}
</style>
