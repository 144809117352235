<template>
    <v-card class="vuertl" flat style="height:100%" :color="dark?'app-bg':''">



        <v-container>
                <TitlePages title="حساباتنا البنكية"  >
                    <v-btn slot="btn"    @click="$router.go(-1)" large   style="float: left!important;" icon depressed><img style="width: 32px" :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>

                </TitlePages>


            <v-row dense>
                <v-col cols="12">
                     <v-row>
                        <v-container>
                            <v-card :dark="dark" :color="dark?'#2d2d39':'#EEF2F5'" link  flat v-for="(bank,index) in accountsBank" class="overflow-hidden col-12 py-0 rounded-lg mb-2"
                                    :key="index" >
                                <v-card-title class="py-1">
                                    <vs-avatar circle class="ml-2"  pointer size="40" :dark="dark"  >
                                        <img  style="height: 100%" :src="setBaseUrl(bank.image)">
                                    </vs-avatar>
                                    <div  class="">
                                        <span class="app-regular">{{bank.feild}}</span><br/><span class="app-regular" v-text="bank.value"></span>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <img style="width: 40px"    :src="$store.state.params.logo">
                                </v-card-title>
                                <v-card-subtitle class="mt-1 app-regular">{{bank.comment}}</v-card-subtitle>

                            </v-card>
                        </v-container>
                    </v-row>
                </v-col>
            </v-row>
        </v-container>
    </v-card>
</template>

<script>
    import axios from 'axios'
    import TitlePages from "@/layout/TitlePages";
    import {setBaseUrl} from "@/tools/Services";

    export default {
        name: "ComptBNK",
        components: {TitlePages},
        computed:{
            dark(){
                return this.$store.state.dark
            }
        },
        data(){
            return{
                accountsBank:[],
                items: [
                    {
                        text:'حساباتي البنكية',
                        disabled: false,
                        href: '/home-deposit',
                    },
                    {
                        text:this.$store.state.params.AppName,
                        disabled: true,
                        href: '/',
                    },


                ],
            }
        },
        methods:{
          setBaseUrl,
        },
        mounted() {
            axios.get("api/get-versement-account").then((response)=>{
                this.accountsBank = response.data.accounts
            })
        }
    }
</script>

<style scoped>

</style>