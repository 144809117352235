
<style scoped>
.text-center {
  text-align: center !important;
}
.vs-dialog__content{
  padding-top: 0px!important;
  padding-bottom: 0px!important;
}
</style>
<template>
  <div class="">
    <div  v-if="$store.state.auth.user.type == 'admin'">
      <div   class="pa-2">
        <v-card    :dark="$store.getters.dark" flat  class="rounded-xl py-5"  :color="$store.state.dark?'app-bg':''"  >
          <v-card-title>



            <v-text-field
                :class="dark?'black--text':'white--text'"
                :background-color="dark?'#2d2d39':'#EEF2F5'"
                :dark="$store.state.dark" v-model="search"
                class=" overflow-hidden rounded-lg transi app-regular"
                hide-details="true"  filled     dir="rtl"
                placeholder="بحث"

            >
              <img   :class="$store.state.dark?'white-svg':'black-svg'"    style="cursor: pointer"  slot="prepend-inner" src="assets/icons/vuesax/search.svg">
            </v-text-field>



            <v-spacer></v-spacer>
            <vs-button   x-large :class="$store.state.dark?'app-card':'app-card-light'"   @click="newModel()" color="transparant" flat   >
              <span class="app-regular mx-3"> إضافة منتج</span>
              <img :class="$store.state.dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/users.svg"/>
            </vs-button>


          </v-card-title>
          <v-row class="justify-content-center align-center" v-if="products.length >0 && showEmpty== false">
            <v-col class="col-xs-12 col-sm-6 col-md-4 col-lg-3 col-xs-4  " v-for="(product , index) in products"  :key="index">
              <v-card    flat class="rounded-lg" style="cursor: pointer;"    :color="product.count== 0 ?'warning':$store.state.dark?' rounded-lg card-shadow':' card-shadow app-card-light'"   >

                <v-container class="app-regular pt-2">
                  <v-switch style="width:fit-content;display:inline-flex" class="mt-0" color="primary"   :dark="$store.state.dark"   @click.stop="updateisActiveCard(product)"
                            v-model="product.isActive"
                            inset
                            :class="$store.state.dark?'white--text':''"

                  > </v-switch> {{product.name}}
                  <v-card-title   class="pa-0">
                    <vs-avatar>
                      <img width="100%" height="100%" :src="setBaseUrl(product.image)" />
                    </vs-avatar>
                    <v-spacer></v-spacer>
                    <vs-button active transparent @click.stop="ajouterCartes(product)"      >
                      <img class="success-svg" src="assets/icons/vuesax/folder.svg"/>
                    </vs-button>
                    <vs-button @click.stop="showDialogEdit(product)" :dark="$store.state.dark"  transparent active  >
                      <img class="white-svg" src="assets/icons/vuesax/edit.svg"/>
                    </vs-button>

                    <vs-button active transparent  @click.stop="showDialogDeleteCode(product)"        >
                      <img class="error-svg" src="assets/icons/vuesax/trash.svg"/>
                    </vs-button>

                  </v-card-title>
                  <v-card-title   class="pa-0 px-3" style="font-size:16px">
                    {{product.count>0 ?product.count:'نفذ المخزون'}}
                    <v-spacer></v-spacer>
                    <span  > مجموع البطاقات</span>
                  </v-card-title>
                </v-container>

              </v-card>
            </v-col>
          </v-row>
          <v-row v-if="showEmpty" class="justify-content-center align-center">
            <v-col class="col-12 mt-5">
              <v-card flat>
                <v-card-title class=" mt-5">
                  <v-spacer></v-spacer>
                  <img src="assets/icons/vuesax/empty-products.svg">
                  <v-spacer></v-spacer>

                </v-card-title>
                <h3 class="app-bold text-center">لا تتوفر منتوجات بهذ الاسم</h3>

              </v-card>
            </v-col>
          </v-row>




        </v-card>
        <v-dialog fullscreen v-model="editDialog"    :dark="$store.state.dark" >
          <v-card flat :dark="$store.state.dark"  >
            <form  >
              <v-container>
                <v-card-title class="app-regular">

                  <v-btn @click="editDialog = !editDialog"  large   style="float: left!important;" icon depressed><img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>
                  <vs-switch class="ml-2" v-model="formPost.dir=='rtl'?true:false" @click="formPost.dir=='rtl'?formPost.dir='ltr':formPost.dir='rtl'">
                    <template #off>
                      FR
                    </template>
                    <template #on>
                      AR
                    </template>
                  </vs-switch>
                  <v-spacer></v-spacer>
                  إضافة منتج جديد
                </v-card-title>
                <v-row class="app-regular vuertl" >

                  <v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-0">
                    <v-text-field outlined
                                  :class="dark?'black--text':'white--text'"
                                  :background-color="dark?'#2d2d39':'#EEF2F5'"
                                  :error-messages="errors.name?errors.name:''"
                                  v-model="formPost.name"
                                  placeholder="اسم المنتج"
                                  filled>

                    </v-text-field>
                  </v-col>
                  <v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-0">
                    <v-select v-model="formPost.groupe_id" outlined chips small-chips
                              item-text="name"
                              item-value="id"
                              :items="cardTypes"
                              placeholder="انواع البطاقات"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'"

                    ></v-select>
                  </v-col>
                  <v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-0">
                    <v-text-field clearable     hide-details outlined
                                  :class="dark?'black--text':'white--text'"
                                  :background-color="dark?'#2d2d39':'#EEF2F5'"
                                  :error-messages="errors.CardPrice?errors.CardPrice:''"
                                  v-model="formPost.CardPrice"
                                  placeholder="سعر المنتج "
                                  filled>

                    </v-text-field>
                  </v-col>
                  <v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6 pb-0">
                    <v-select v-model="formPost.isCommand" outlined class="app-regular" hide-details
                              item-text="name"
                              item-value="value" chips  small-chips
                              :items="commandtype"
                              placeholder="شراء فوري للأكواد و المنتجات"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'"  >

                    </v-select>
                  </v-col>
                  <v-col class="col-12 pb-0">
                    <v-card elevation="0">
                      <v-card-title class="pt-0"  v-if="inputs.length == 0">
                        يمكنك أضافة حقل للمنتج
                        <v-spacer></v-spacer>
                        <vs-button color="rgb(254, 44, 85)" class="rounded-xl px-3" @click.prevent="btnClicked+=1" >إضافة حقل</vs-button>

                      </v-card-title>

                      <v-card-subtitle  v-if="inputs.length == 0"> يمكنك إضاقة حقول في حالة ما المنتج ليس شحن فوري و يتطلب الانتظار</v-card-subtitle>
                      <v-card-title class="py-1">
                        <v-chip link ripple small :dark="dark" @click="formPost.command +=' تسليم فوري للبطاقة' ">تسليم فوري للبطاقة </v-chip>
                        <v-chip class="mx-2" link ripple small :dark="dark"  @click="formPost.command +=' يرجى الانتضار لمدة تقارب 15 دقيقة' ">يرجى الانتضار لمدة تقارب 15 دقيقة</v-chip>
                        <v-chip link ripple small :dark="dark" @click="formPost.command +=' يتم الشحن الان إنتظر بضع دقائق' ">يتم الشحن الان إنتظر بضع دقائق</v-chip>
                      </v-card-title>
                      <v-card-text class="pa-0" >
                        <v-text-field outlined block v-if="btnClicked >= 1"
                                      :class="dark?'black--text':'white--text'"
                                      :background-color="dark?'#2d2d39':'#EEF2F5'"

                                      v-model="formPost.feildOne"
                                      placeholder="قم بتعريف الحقل للزبون مثال : أدخل رقم هاتفك"
                                      filled>

                        </v-text-field>
                        <v-text-field outlined block v-if="btnClicked >= 2"
                                      :class="dark?'black--text':'white--text'"
                                      :background-color="dark?'#2d2d39':'#EEF2F5'"

                                      v-model="formPost.feildTwo"
                                      placeholder=" مثال : أدخل بريدك الالكتروني"
                                      filled>

                        </v-text-field>
                      </v-card-text>
                      <v-card-text class="pa-0" v-if="inputs.length >=1">

                        <p v-if="inputs[0]" v-text="inputs[0].name"></p>
                        <v-text-field outlined block v-if="inputs[0]" hide-details
                                      :class="dark?'black--text':'white--text'"
                                      :background-color="dark?'#2d2d39':'#EEF2F5'"

                                      v-model="formPost.feildOne"
                                      placeholder="قم بكتابة عنوان الحقل للزبون مثال : أدخل رقم هاتفك"
                                      filled>
                          <template v-slot:append-outer>
                            <v-btn icon class="mr-2 mb-4"  @click="deleteInput(inputs[0].id)">
                              <img style="font-size: 12px" :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/trash.svg">

                            </v-btn>
                          </template>

                        </v-text-field>
                        <p v-if="inputs[1]" v-text="inputs[1].name"></p>
                        <v-text-field outlined block v-if="inputs[1]" hide-details
                                      :class="dark?'black--text':'white--text'"
                                      :background-color="dark?'#2d2d39':'#EEF2F5'"

                                      v-model="formPost.feildTwo"
                                      placeholder="قم بكتابة عنوان الحقل مثال : أدخل بريدك الالكتروني"
                                      filled>
                          <template v-slot:append-outer>
                            <v-btn icon class="mr-2 mb-4" @click="deleteInput(inputs[1].id)">
                              <img style="font-size: 12px" :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/trash.svg">

                            </v-btn>
                          </template>
                        </v-text-field>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col class="col-12 pb-0 pt-0">
                    <v-text-field outlined
                                  :class="dark?'black--text':'white--text'"
                                  :background-color="dark?'#2d2d39':'#EEF2F5'"
                                  :error-messages="errors.command?errors.command:''"
                                  v-model="formPost.command"
                                  placeholder="رسالة الانتظار بعد الطلب"
                                  filled>

                    </v-text-field>
                  </v-col>
                  <v-col class="col-12 py-0">
                    <v-btn @click="pickFile()" outlined block x-large >
                      <v-icon v-if="formPost.image==''" x-large>mdi-camera</v-icon>
                      <v-icon  v-else class="green--text" x-large>mdi-check-circle</v-icon>
                    </v-btn>
                    <input v-show="false" type="file" @change="updatePicture()" ref="image"  />

                  </v-col>
                  <v-col class="col-12">
                    <VueEditor v-model="formPost.description_ar"></VueEditor>

                  </v-col>

                </v-row>
                <v-card-actions >
                  <v-spacer></v-spacer>

                  <v-btn :loading="formPost.busy" block  v-show="editMode"  @click="updateCard()" class="px-2 mx-auto success app-regular"
                  >تغيير بيانات المنتج</v-btn
                  >
                  <v-btn :loading="formPost.busy" block  v-show="!editMode" @click="Addproduct()" class="mx-auto primary app-regular"
                  >إضافة المنتج</v-btn
                  >

                </v-card-actions>
              </v-container>




            </form>
          </v-card>
        </v-dialog>
        <v-dialog width="600" v-model="showimg">
          <v-card width="600" class=""  flat :dark="$store.state.dark" >
            <img :src="setBaseUrl(form.image)" style="width: 170px; margin-left: 30%" />
          </v-card>
        </v-dialog>
      </div>
      <vs-dialog width="350px" not-center v-model="deleteDialog" no-margin class="app-regular"  flat :dark="$store.state.dark" >

        <h2 slot="header" class="text-center py-0  ">
          تأكيد الحذف
        </h2>
        <div class="text-right mb-0">
          <p class="mb-1" style="direction: rtl;" v-text="`هل أنت متأكد لحذف المنتج ${prodDelete.name}` ">

          </p>
        </div>
        <v-card-actions slot="footer" class="py-0">
          <vs-button   transparent danger @click="DeleteProduct(prodDelete)">
            موافق
          </vs-button>
          <vs-button @click="deleteDialog=false"  transparent>
            الغاء
          </vs-button>
        </v-card-actions>

      </vs-dialog>
      <vs-dialog width="350px" not-center v-model="deleteDialogCode" no-margin class="app-regular"  flat :dark="$store.state.dark" >

        <h2 slot="header" class="text-center py-0  ">
          تحذير من الحذف
        </h2>



        <div class="text-right mb-0">
          <p class="mb-1" style="direction: rtl;" v-text="`نحذرك من حذف المنتج سوف يتم حذف جميع الاكواد المرتبطة بالمنتج` ">

          </p>
        </div>


        <v-card-actions slot="footer" class="py-0">
          <vs-button   transparent danger @click="DeleteProduct (deletedCode)">
            موافق
          </vs-button>
          <vs-button @click="deleteDialogCode = false"  transparent>
            الغاء
          </vs-button>
        </v-card-actions>

      </vs-dialog>
      <v-dialog :dark="dark"
                v-model="dialogCard"
                width="850"


      >
        <v-card  :dark="dark" class="rounded-xl overflow-hidden position-relative">
          <v-card-title class="app-regular pt-0">
            <v-btn @click="dialogCard = !dialogCard"  large   style="float: left!important;" icon depressed><img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close-1.svg" /></v-btn>

            <v-btn v-if="tab == 'tab-2'" @click="showFile()" color="primary"  depressed elevation="0" class="white--text rounded-lg">
              <img class="white-svg" src="assets/icons/vuesax/file.svg" />
              تحميل ملف

            </v-btn>
            <v-spacer></v-spacer>
            إضافة أكواد المنتجات
          </v-card-title>
          <div  >
            <v-tabs  :class="dark?'app-card':'app-card-light'"
                     v-model="tab"


                     fixed-tabs
                     slider-color="warning" class="app-regular"
                     icons-and-text
            >

              <v-tabs-slider :color="$store.state.params.bg"></v-tabs-slider>
              <v-tab href="#tab-1">
                البطاقات المتوفرة
              </v-tab>
              <v-tab href="#tab-2">
                <span> إضافة أكواد بطاقات</span>
              </v-tab>
            </v-tabs>
            <v-tabs-items  v-model="tab" class="vuertl" :dark="dark">
              <v-tab-item

                  :value="'tab-1'"
              >
                <v-card flat  :ripple="false"
                        loader-height="5"  :dark="dark"

                        :class="dark?'app-card':'app-card-light'"

                >
                  <template slot="progress">
                    <v-progress-linear color="#2196F3" indeterminate></v-progress-linear>
                  </template>
                  <v-card-title class="py-0 pt-2">
                    <vs-button   danger primary @click="dialogDeleteAll = !dialogDeleteAll">حذف البطاقات المستعملة</vs-button>

                    <h5> سوف يتم حذف جميع البطاقات التي تم شحنها - المكتوب عليهل <b class="primary--text  sans-serif">Recharged</b></h5>
                   </v-card-title>
                  <v-virtual-scroll
                      :items="cards"
                      height="300"
                      item-height="64"
                  >

                    <template v-slot:default="{ item }">
                      <v-list    >

                        <v-list-item-group>
                          <v-list-item :ripple="false" :key="item.id" style="height: 60px" >
                            <template v-slot:default="{ active }">
                              <v-list-item-content>
                                <v-row>
                                  <v-col class="col-3">
                                    <vs-avatar >
                                      {{item.id}}
                                    </vs-avatar>
                                  </v-col>
                                  <v-col v-if="item.message != null" :class="item.message !=''?'col-7':''">
                                    <vs-tooltip    >
                                      <vs-button      flat >
                                        {{splitName(item.message)}}
                                      </vs-button>
                                      <template #tooltip>
                                        <span class="white--text app-regular">{{item.message}}</span>
                                      </template>
                                    </vs-tooltip>
                                  </v-col>
                                </v-row>
                              </v-list-item-content>
                              <v-list-item-content class="p-0">
                                <div v-if="textFeildValue != item.code">
                                  <v-list-item-title style="font-family: sans-serif" v-text="item.code"></v-list-item-title>
                                </div>
                                <div v-if="textFeildValue == item.code">
                                  <v-text-field :autofocus="textFeildValue == item.code" hide-details class="pt-n3" style="padding-top: 0px!important;font-size:17px"    v-model="textofFeild">
                                    <v-template  v-slot:append-outer-icon>
                                      <v-icon>mdi-account</v-icon>
                                    </v-template>
                                  </v-text-field>

                                </div>
                              </v-list-item-content>
                              <v-list-item-action  >
                                <div>
                                  <vs-button-group class="mt-0 app-regular" style="direction: ltr">
                                    <vs-button :dark="!dark" :color="dark?'#2d2d39':'#EEF2F5'"     icon @click="editFeild(item)">

                                      <img style="font-size: 12px" :class="textFeildValue == item.code?'primary-svg':dark?'white-svg':'black-svg'" src="assets/icons/vuesax/edit.svg">
                                      <span class="ml-2" :class="!dark?'black--text':'white--text'">تعديل</span>
                                    </vs-button>
                                    <vs-button  :dark="!dark"  :color="dark?'#2d2d39':'#EEF2F5'" icon  @click="showDialogDeleteCode(item)">
                                      <img style="font-size: 12px" :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/trash.svg">
                                      <span  class="ml-2"  :class="!dark?'black--text':'white--text'">حذف</span>
                                    </vs-button>

                                    <vs-button  :dark="!dark"  :color="dark?'#2d2d39':'#EEF2F5'"  icon @click="Updateidoom(item)">
                                      <img style="font-size: 12px" :class="textFeildValue == item.code?'purple-svg':dark?'white-svg':'black-svg'" src="assets/icons/vuesax/send.svg">
                                      <span class="ml-2"  :class="!dark?'black--text':'white--text'">حفض</span>
                                    </vs-button>
                                    <vs-switch v-model="item.isRecharged==1" class="ml-2" @click="switchToNotRecharged(item)">
                                      <template #off>

                                      </template>
                                      <template #on>
                                        Recharged
                                      </template>
                                    </vs-switch>
                                  </vs-button-group>



                                </div>
                              </v-list-item-action>
                            </template>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                    </template>
                  </v-virtual-scroll>
                </v-card>
              </v-tab-item>
              <v-tab-item
                  :value="'tab-2'"
              >

                <v-card flat :dark="dark"  :class="dark?'app-card':'app-card-light'"  >
                  <v-container>
                    <form @submit.prevent="addCart">
                      <v-card-actions>
                        <v-row>
                          <v-col class="col-6 py-0">

                            <v-text-field :background-color="dark?'#000':'#fff'"  hide-details filled  style="font-size: 18px" class="mt-0 app-regular rounded-lg" label="الاسم" name="name" v-model="form.name"

                                          :error-messages="form.errors.name"  >

                            </v-text-field>

                          </v-col>
                          <v-col class="col-6  py-0">
                            <v-text-field :background-color="dark?'#000':'#fff'"  hide-details filled style="font-size: 18px" class="rounded-lg mt-0 app-regular" label="السعر"   v-model="form.amount"
                                          :error-messages="form.errors.amount"   name="amount">
                            </v-text-field>

                          </v-col>


                          <v-col class="col-12 py-0 mt-2" v-if="!form.isFileActive">
                            <v-text-field :error-messages="form.errors.code"
                                          :background-color="dark?'#000':'#fff'"  hide-details filled    style="font-size: 30px"  class="app-regular rounded-lg"  label="كود البطاقة" v-model="form.code" type="text" name="code" :class="{ 'is-invalid': form.errors.has('code') }" ></v-text-field>

                          </v-col>



                          <input v-show="false" ref="files" style="display:none" type="file" class="form-control" v-on:change="onFileChange">



                        </v-row>




                      </v-card-actions>
                      <v-card-actions>


                        <v-btn elevation="0" depressed large block :color="form.isFileActive?'success':'primary'" class="app-regular rounded-lg white--text" :loading="form.busy"      type="submit" >
                          <span v-if="!form.busy" v-text="form.isFileActive?'إرفاق ملف':'إضافة كود ENTER'"> </span>
                          <img class="white-svg mx-2" :src="form.isFileActive?'assets/icons/vuesax/file.svg':'assets/icons/vuesax/send.svg'">
                        </v-btn>

                      </v-card-actions>
                    </form>
                  </v-container>

                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </div>

        </v-card>
        <vs-dialog width="350px" not-center v-model="dialogDeleteAll" no-margin class="app-regular">

          <h2 slot="header" class="text-center py-0  ">
            تأكيد الحذف
          </h2>



          <div class="text-right mb-0">
            <p class="mb-1" style="direction: rtl;" v-text="`هل أنت متأكد لحذف جميع البطاقات المستعملة` ">

            </p>
          </div>


          <v-card-actions slot="footer" class="py-0">
            <vs-button  :loading="load"  transparent danger @click="DestroyUsedCard()">
              موافق
            </vs-button>
            <vs-button @click="dialogDeleteAll= false"  transparent>
              الغاء
            </vs-button>
          </v-card-actions>

        </vs-dialog>
      </v-dialog>
      <vs-dialog width="350px" not-center v-model="dialogDeleteInput" no-margin class="app-regular">

        <h2 slot="header" class="text-center py-0  ">
          تأكيد الحذف
        </h2>



        <div class="text-right mb-0">
          <p class="mb-1" style="direction: rtl;" v-text="'هل أنت متأكذ لحذف الحقل' ">

          </p>
        </div>


        <v-card-actions slot="footer" class="py-0">
          <vs-button    transparent danger @click.prevent="deleteDirect()">
            موافق
          </vs-button>
          <vs-button @click="dialogDeleteInput= false"  transparent>
            الغاء
          </vs-button>
        </v-card-actions>

      </vs-dialog>
      <vs-dialog width="350px" not-center v-model="dialogDeleteCode" no-margin class="app-regular">

        <h2 slot="header" class="text-center py-0  ">
          تأكيد الحذف
        </h2>



        <div class="text-right mb-0">
          <p class="mb-1" style="direction: rtl;" v-text="'هل أنت متأكذ لحذف الكود' ">

          </p>
        </div>


        <v-card-actions slot="footer" class="py-0">
          <vs-button    transparent danger @click.prevent="DestroyCard(deletedCode)">
            موافق
          </vs-button>
          <vs-button @click="dialogDeleteCode= false"  transparent>
            الغاء
          </vs-button>
        </v-card-actions>

      </vs-dialog>
    </div>
    <not-found v-else></not-found>
  </div>
</template>
<script>
import axios from "axios";
import Form from 'vform'
import { VueEditor } from "vue2-editor";
import{setBaseUrl} from "@/tools/Services";
export default {
  components:{VueEditor },
  data() {
    return {
      dialogDeleteCode:false,
        dialogDeleteInput:false,
        inputs:[],
        btnClicked:0,
        load:false,
        dialogDeleteAll:false,
        deletedCode:'',
        deleteDialogCode:false,
        showEmpty:false,
      cardTypes:[],
      textFeildValue:'',
      textofFeild:'',
      tab:'',
      dialogCard:false,
      deleteDialog:false,
        sousCategorie:[],
        categories:[],
        errors:[],
      showimg: false,
      editDialog: false,
      commandtype:[
          {name:"شحن فوري بدون إنتطار",value:false},
          {name:"إنتظار بعض الوقت",value:true},

      ]  ,
      links: null,
      search: "",
      headers: [
        { text: "البطاقة", value: "name", filterable: true },
        { text: "الصورة", value: "image", filterable: true },
        { text: "السعر", value: "CardPrice", filterable: true },
         { text: "تفصيل", value: "description_ar", filterable: true },
        { text: "الانتماء", value: "groupe_id", filterable: true },
        { text: "التفعيل", value: "isActive", filterable: true },
      ],
      mode:'',
      form: new Form({
                    id:'',
                    name:'',
                    code:'',
                    CardPrice:'',
                    groupe_id:'',
                    isFileActive:false,
                    file:''

                }),
      item: [],
      sum: 0,
        count:0,
        countNow:0,
      imageMode: 0,
      editMode: false,
      loading: true,
      formPost: new Form({
         idInput:'',
         id:'',
         name:"",
         image:[],
         groupe_id:"",
         CardPrice:"",
         isCommand:false,
         command:'',
         dir:"rtl",
         description_ar:"",
         feildOne:'',
         feildTwo:'',

      }),
      getedecard: [],
      products: [],
      cards:[],
      name: "", 
      type: window.user,
      prodDelete:'',
    };
  },
  
   mounted() {
    this.getProducts();
  // await this.getcardgroupe();
  },
    computed:{
      dark:function () {
        return this.$store.state.dark
      }
    },
  watch: {

      search(after) {
          this.searchProduct(after);
      },
    editDialog(){
      this.getProducts()
    },

    dialogCard(){
              if(this.dialogCard == false){
                 // this.CountCards()
              }
            },
            tab:function () {

                    if (this.tab=='tab-2'){
                       this.form.CardPrice = this.getedecard.CardPrice
                       this.form.name = this.getedecard.name
                    }else{
                        axios.get('api/get-info-card/'+this.form.name).then((response)=>{
                            this.cards = response.data

                        })
                    }


            },

  },
  methods: {setBaseUrl,
      deleteDirect(){
          this.formPost.post('api/delete-input').then((response)=>{
            this.inputs = response.data
              this.getProducts();
          })
      },
      deleteInput(numberId){
      this.formPost.idInput = numberId
      this.dialogDeleteInput = ! this.dialogDeleteInput
          this.formPost.idInput = numberId
      },
      addInput(){
        this.btnClicked+=1
      },
      async  DestroyUsedCard(){
          this.load = true
          axios.post("api/destroy-all-used-cards",{name:this.form.name}).then((response)=>{
              axios.get('api/get-info-card/'+this.form.name).then((response)=>{
                  this.cards = response.data
                  this.load = false
                  this.dialogDeleteAll = false
              }).catch(()=>{
                this.load = false
                this.dialogDeleteAll = false
              })
          })


      },
      async switchToNotRecharged(item){
          await  axios.post('api/update-cards-recharged',item)
          await  axios.get('api/get-info-card/'+item.name).then((response)=>{
              this.cards = response.data

          })
      },
      showFile(){
          if(this.form.isFileActive == true){
              this.form.isFileActive = false
          }else{
              this.form.isFileActive = true
              this.pickFiles()
          }
      },
      formSubmit() {
          const config = {
              headers: { 'content-type': 'multipart/form-data' }
          }
          this.form.post('api/upload-file-code-cards', config)
              .then((response) => {


                  // this.editDialog = !this.editDialog;
                  this.form.code = ""
                  Toast.fire({
                      timer:7000,
                      background:this.$store.state.dark?'#000':'#fff',
                      color:this.$store.state.dark?'#fff':'#000',
                      customClass:"app-regular vuertl",
                      icon: "success",
                      title:  "تمت الاضافة بنجاح - "+ ' ' + 'مجموع أكواد الملف'+' ' +response.data.fileCount   ,
                  });
                  this.getProducts();
              })
              .catch((e) => {
                  this.form.code = ""
                  Toast.fire({
                      timer:7000,
                      background:this.$store.state.dark?'#000':'#fff',
                      color:this.$store.state.dark?'#fff':'#000',
                      customClass:"app-regular vuertl",
                      icon: "error",
                      title: e.response.data.message   ,
                  });


              });
      },
      onFileChange(e){
           this.form.file = e.target.files[0];
      },
      pickFiles(){
          this.$refs.files.click()
      },
      showDialogDeleteCode(item){
           this.deletedCode = item
         this.dialogDeleteCode = ! this.dialogDeleteCode
     },
      DestroyCard(item){

        axios.delete('api/delete-code/'+item.id).then(r => {
            this.$store.dispatch("loadsna", { text:"تم حذف الكود بنجاح", isSuccess: true});
            this.getCardFromDataBase(item.name)
            this.loading = false
            this.dialogDeleteCode = false
        }).then((response)=>{
            this.textFeildValue = ''
            this.loading = false
        }).catch((err)=>{
            this.$store.dispatch("loadsna", {
                text: err.response.data.message || 'حدث خطأ مجهول يرجى إعادة المحاولة',
                isSuccess: false,
            });
        })
      },
      searchProduct(after) {
          if(after !=''){
              axios.get("api/findProducts?q=" + after).then(response => {

                  if (response.data.length >0){
                      this.showEmpty = false
                      this.products = response.data;
                  }else {
                      this.showEmpty = true
                  }

              })
                  .catch(() => {});
          }else {
              this.showEmpty = false
              this.getProducts();
          }
      },
      Addproduct(){

          this.formPost.post('api/product').then((response)=>{
              this.getProducts()
              this.formPost.clear()
            this.editDialog = false
              this.$store.dispatch("loadsna", {
                  text:"تم إضافة المنتج بنجاح",
                  isSuccess: true
              });
          }).catch((err)=>{

              this.$store.dispatch("loadsna", {
                  text: err.response.data.message || "حدث خطأ ما يرجى المحاولة " ,
                  isSuccess: false
              });

          })
      }  ,
    async getCardFromDataBase(card){
        this.mode = 'cards'
        axios.post('api/get-card-from-database', {
            name: card,
        })
            .then((response)=>{
                this.tab = 'tab-1'
                this.textofFeild = ''
                this.cards = response.data.cards;
                this.selectedName = response.data.name

                this.form.id = response.data.id
                this.form.groupe_id = response.data.id
                this.dialogCard = true

            })
            .catch(function (error) {


            });

        },
    UpdateCard(item){
                if (this.textofFeild != item.code){
                    axios({
                        url:'api/update-card-id/'+item.id,
                        method:'put',
                        data:{
                            code:item.code,
                            name:this.selectedName,
                            textFeild:this.textofFeild

                        }
                    }).then(r => {
                        this.$store.dispatch("loadsna", {
                            text:"تم التحديث بنجاح",
                            isSuccess: true,
                        });
                        this.getCardFromDataBase(item.name)
                        this.loading = false

                    }).then( this.textFeildValue = '', this.loading = false).catch(()=>{
                        this.$store.dispatch("loadsna", {
                            text: error.response.data.message || 'حدث خطأ مجهول يرجى إعادة المحاولة',
                            isSuccess: false,
                        });
                    })
                }else {
                    this.textFeildValue = ''
                }

            },
    editFeild(item){
                this.textFeildValue = item.code
                this.textofFeild = this.textFeildValue
            },
    ajouterCartes(product){
      this.inputs = []
      this.form.file = ''
        this.form.name = product.name
        this.textFeildValue = ''
      this.form.isFileActive =false
      this.form.amount = product.CardPrice
          this.deletedCode = product
         this.count = 0
         this.countNow = 0
      this.getedecard = product

      axios.get('api/get-info-card/'+product.name).then((response)=>{
        this.cards = response.data

        this.dialogCard = !this.dialogCard
      }).catch((error)=>{
          this.dialogCard = !this.dialogCard
          this.tab = ('tab-2')
            this.$store.dispatch("loadsna", {
              text: error.response.data.message,
              isSuccess: false,
          });

      })
    },
    DeleteProduct(product){
      axios.delete('api/delete-product/'+product.id).then((response)=>{
          this.deleteDialogCode = false
          this.$store.dispatch("loadsna", {
              text: 'تم حذف المنتج بنجاح',
              isSuccess: true,
          });
          this.getProducts();
      }).catch((err)=>{
          this.$store.dispatch("loadsna", {
              text: err.response.data.message || "حدث خطأ ما يرجى المحاولة ",
              isSuccess: false
          });
      })
    },
      showDialogEdit(product){
          this.editDialog = true
            this.inputs = []
          this.btnClicked = 0
          axios.get('api/get-inputs/'+product.id).then((response)=>{
              this.inputs = response.data
              if(response.data.length >=1){
                  this.formPost.feildOne = this.inputs[0].value
              }
              if(response.data.length >=2){
                  this.formPost.feildOne = this.inputs[1].value
              }
              this.editMode = true
              this.formPost.fill(product)

          })

      },
    showDialogDelete(product){

    this.prodDelete = product
    this.deleteDialog = true
  },
    updateisActiveCard(game) {
      axios.post('api/update-is-active-card',game).then((response)=>{
          this.editDialog = false
        this.getProducts()
      }).catch((err)=>{
          this.editDialog = false
          this.$store.dispatch("loadsna", {
              text: err.response.data.message || "حدث خطأ ما يرجى المحاولة " ,
              isSuccess: false
          });

      })
    
    },
      getCardType(){
        axios.get('api/admin-card-type').then((response)=>{
            this.cardTypes = response.data
        })
      },
      pickFile(){
          this.$refs.image.click()
      },
      updatePicture(){
          let fileInput = this.$refs.image
            let imgFile = fileInput.files

            if (imgFile && imgFile[0]) {
              let reader = new FileReader
              reader.onload = e => {
                this.formPost.image = e.target.result
              }
              reader.readAsDataURL(imgFile[0])
              
        
          }

      },

    updateisActive(carte) {
      const carteID = carte.isActive;
      axios({
        method: "post",
        url: "api/update-is-active-card/" + carte.id,
        data: {
          isActive: carteID,
        },
      }).then(() =>
       this.getProducts()
      
      );
    },
    getcardgroupe() {
      axios.get("api/products-admin").then((response) => {
        this.getedecard = response.data;
      });
    },
    showImage(image) {
      this.form.image = "";
      this.form.image = image;
      (this.imageMode = 0)
      (this.imageMode = 1);
      this.showimg = !this.showimg;
    },
    uploadImage(e) {
      let file = e.target.files[0];
      let reader = new FileReader();
      if (file["size"] < 5111745) {
        reader.onloadend = () => {
          this.form.image = reader.result;
        };
        reader.readAsDataURL(file);
      } else {
          this.$store.dispatch("loadsna", { text:"تحقق من حجم الصورة لا يتعدى 5 MB", isSuccess: false});
      }

    },
    updateCard() {

      this.formPost
        .put("api/products/" + this.formPost.id)
        .then(() => {
          this.editDialog = !this.editDialog;
            this.$store.dispatch("loadsna", { text:"تم تحديث المنتج بنجاح", isSuccess: true});

           
          this.getProducts();
        })
        .catch(() => {
            this.$store.dispatch("loadsna", { text:'حدث خطأ مجهول يرجى إعادة المحاولة', isSuccess: false});
        });
    },
    newModel() {
    this.inputs = []
      this.editMode = false;
      this.formPost.reset();
      this.editDialog = !this.editDialog;
        this.getCardType();

    },
  async  editModel(item) {
  
      this.editMode = true;

      await this.form.fill(item)
      await   this.getCardType();
      // this.form.image = cart.image

      this.editDialog = !this.editDialog;
     
    },
    getProducts() {
      axios.get("api/admin-products").then((response) => {
        this.products = response.data;
        
      });
    },
    addCart() {
        if(this.form.isFileActive == true){
            let vm = this
            vm.formSubmit()
        }else{
            if(this.form.code != ""){
                this.form.post("api/add-code")
                    .then((response) => {
                        this.countNow +=1
                        this.count = response.data.count

                        // this.editDialog = !this.editDialog;
                        this.form.code = ""
                        Toast.fire({
                            background:this.$store.state.dark?'#000':'#fff',
                            color:this.$store.state.dark?'#fff':'#000',
                            customClass:"app-regular",
                            icon: "success",
                            title:  "تمت الاضافة بنجاح",

                        });
                        this.getProducts();
                    })
                    .catch((e) => {
                        this.$store.dispatch("loadsna", { text:e.response.data.message || 'تحقق من الكود المدخل', isSuccess: false});
                    });
            }else{
                this.$store.dispatch("loadsna", { text:'تحقق من الكود المدخل', isSuccess: false});

            }
        }

    },
    deleteCard(id) {
      Swal.fire({
          background:this.$store.state.dark?'#000':'#fff',
          color:this.$store.state.dark?'#fff':'#000',
          customClass:"app-regular",
        title: "هل أنت متأكد ؟",
        text: "هل تريد الحذف",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم، متأكد",
      }).then((result) => {
        if (result.value) {
          this.form
            .delete("api/CardType/" + id)
            .then(() => {
                this.$store.dispatch("loadsna", { text:"تم حذف بنجاح", isSuccess: true});



              this.getProducts();
            })
            .catch(() => {
                this.$store.dispatch("loadsna", { text:'حدث خطأ مجهول يرجى إعادة المحاولة', isSuccess: false});
             });
        }
      });
    },
  },
};
</script>
<style scoped>
    .swal2-modal{
        font-family: 'app-regular'!important;
    }
    >>> .v-input__slot::before {
        border-style: none !important;
    }
    .v-text-field--outlined >>> fieldset {
         border-color: #fff;
       }
</style>