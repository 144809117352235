<template>
    <v-card elevation="0" height="1000px" flat :color="$store.state.dark?'app-bg':'app-bg-light'">
       
      <v-container fluid class="px-2" >
        <v-card   :dark="dark"  :color="dark?'app-card':'app-card-light'" style="z-index: 10;margin-top: 20vh!important;z-index: 3;"  
         class="card-shadow rounded-xl ">
          <v-card-title>
           
            Salut ! {{ auth.user.username }}  <v-spacer></v-spacer>
            <img  src="assets/icons/vuesax/chevron-left.svg" />
          </v-card-title>
          <v-card-subtitle>
            {{ $store.state.phone }}
          </v-card-subtitle>
           <v-divider></v-divider>
           <v-card-subtitle class="font-weight-bold   pb-1" style="font-size: 13px;">Parametres des forfaits exclisifs</v-card-subtitle>
           <v-card-subtitle class="text-secondary  pt-0" style="font-size: 13px;"> Parametres des forfaits exclisifs Parametres des forfaits exclisifs </v-card-subtitle>
           
           <v-divider></v-divider>
           <v-row>
            <v-col class="col-6">
              <v-card-subtitle class="pb-0   ">
             MON CREDIT
             </v-card-subtitle>
             <v-card-title class="pt-0 pb-2 font-weight-bold">{{ formatToPrice(auth.user.solde) }} DA</v-card-title>
             <v-card-subtitle class="font-weight-bold  " style="font-size: 13px;">
             Recharger votre compt
             </v-card-subtitle>
            </v-col>
            <v-col class="col-6 align-item">
                <vs-button to="/rechargement" warn class="black--text font-weight-bold" >RECHARGER</vs-button>
            </v-col>
           </v-row>
           
    </v-card>
      </v-container>
      <mobile></mobile>
    </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { formatToPrice } from '@/tools/Services';
import Mobile from '../components/Mobile.vue';
  export default {
    name: 'Home',
    
    computed:{
      ...mapState(["auth","dark"]),
     
      params:function(){
        return this.$store.state.params
      }
    },
    methods:{
      formatToPrice
    },
    components: {
        Mobile
 
    },
  }
</script>
<style scoped>
.align-item{
  align-items: center!important;
    display: flex!important;;
    justify-content:center!important;
}
</style>