<template>

    <div>

     <v-overlay   v-if="showMain =='enter'"  opacity="1" :color="$store.state.dark?'#000':'#fff'" :value="overlay" absolute   :z-index="100000">

                    <div class="m-auto" style="text-align:center">
                        <img  width="70px" :src="$store.state.dark?'assets/img/black-logo.gif':'assets/img/white-logo.gif'" />
                    </div>

                   <h2   class="app-bold "
                   :style="{color:$store.state.dark?'#bebebe':'#bebebe'}"
                   >{{$store.state.App.config.AppName}}</h2>
        </v-overlay>
     <v-app app v-if="showMain =='entered'"  :class="$store.state.dark?'app-bg':''"  >
       <v-main   :class="$store.state.dark?'app-bg ':'app-bg-light'"  class="mb-10" >
           <Navbar   app v-if="$vuetify.breakpoint.mdAndUp && $route.fullPath != '/login'"  />
           <SidBar v-if="$route.fullPath != '/login'" app  />
              <router-view :class="$vuetify.breakpoint.smAndDown  ?'': $route.fullPath == '/login'?'':'mt-14'">
              </router-view>
         <Alert/>
   </v-main>


    <v-footer app  >
        <BottomTabBar v-if="$route.fullPath  !='/auth' && $route.fullPath  !='/login'" />
    </v-footer>
      <SnabB/>






   </v-app>
        <DialogWalpapper/>
<MobileMessageDialog/>
    </div>
<!--    </pull-to>-->
</template>
<script>
    import * as authUser from "@/plugins/authUser";

    import {bus} from "@/tools/bus";
      import BottomTabBar from './components/BottomTabBar.vue';
   import Navbar from './layout/Navbar.vue';
   import Alert from './layout/Alert'
  import SnabB from "@/components/comp-tool/SnabB";
  import Footer from "@/layout/Footer";
    import SidBar from "@/layout/SidBar";

  import BottomSheet from "@/layout/BottomSheet";
  import DialogWalpapper from "@/layout/DialogWallpaper";
    import { PushNotifications } from '@capacitor/push-notifications';
    import MobileMessageDialog from "@/components/comp-tool/MobileDialogMessage.vue";
    const addListeners = async () => {
        await PushNotifications.addListener('registration', token => {
            console.info('Registration token: ', token.value);
        });
        await PushNotifications.addListener('registrationError', err => {
            console.error('Registration error: ', err.error);
        });
        await PushNotifications.addListener('pushNotificationReceived', notification => {
            console.log('Push notification received: ', notification);
        });
        await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
            console.log('Push notification action performed', notification.actionId, notification.inputValue);
        });
    }
    const registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions();
        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }
        if (permStatus.receive !== 'granted') {
            throw new Error('User denied permissions!');
        }
        await PushNotifications.register(); 
    }
    const getDeliveredNotifications = async () => {
        const notificationList = await PushNotifications.getDeliveredNotifications();
        console.log('delivered notifications', notificationList);
    }
export default {
components: {MobileMessageDialog, DialogWalpapper, BottomSheet, Footer, Navbar, BottomTabBar ,SnabB,SidBar,Alert},
  name: 'App',
  watch:{
    "$store.state.open.side":function (){
      if(this.$store.state.open.side == true && this.$store.state.sidbar.sidbar.length == 2 ){
        this.$store.dispatch('getNavbarItems')
      }
    }
  },
    async beforeCreate(){

        try {

            if(authUser.isRealToken()){
             let response =  await authUser.getProfile()
             this.$store.commit('auth/SET_USER',response.data)
                   this.showMain = "entered"
                await this.$store.dispatch('getNavbarItems')

            }else{
                this.showMain = "entered"
                await authUser.logOut()
                this.$store.commit('auth/SET_USER',null)
                 await  this.$router.push('/').catch(()=>{})
            }
        } catch (error ) {
             this.showMain = "entered"
             await authUser.logOut()
              this.$store.commit('auth/SET_USER',null)
              await this.$router.push('/ ')
        }
        this.showMain = "entered"
        await authUser.CkeckWeb()

    },
    data(){
    return{
        showMain:"enter",
        overlay:true,

    }
    },
    mounted(){
    if(window.localStorage.getItem('mode')=="true"){
      this.$store.commit('SET_DARK',true)
      this.$vs.setTheme('dark')
     }else{
      this.$store.commit('SET_DARK',false)
      this.$vs.setTheme('light')
    }

  }
};
</script>
<style scoped>
.v-toolbar{
  height: 70px!important;
}
.vs-navbar{
  padding-top: 7px!important;
  padding-bottom: 7px;
}
.my-padding{
  padding-bottom: 0px!important;
    -webkit-transition: all 0.25s ease;
    transition: all 0.25s ease;
    -webkit-transform: translate(-110%);
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
    .padding-web{
        padding-left: 55px !important;

    }
    .class-transition{
        -webkit-transition: all 0.25s ease;
        transition: all 0.25s ease;
        -webkit-transform: translate(-110%);
    }
.loader {
    width: 48px;
    height: 48px;
    display: inline-block;
    position: relative;
}
.loader::after,
.loader::before {
    content: '';
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgb(82, 78, 237);
    position: absolute;
    left: 0;
    top: 0;
    animation: animloader 2s linear infinite;
}
.loader::after {
    animation-delay: 1s;
}

@keyframes animloader {
    0% {
        transform: scale(0);
        opacity: 1;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}


</style>
