import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VToolbar,{staticClass:"mt-n5",attrs:{"flat":"","color":"transparent"}},[_c(VToolbarTitle,[_c('b',[_vm._v("Historique Bonus")])])],1),_c(VCard,{staticClass:"rounded-lg mt-n3",attrs:{"color":_vm.$store.state.dark?'#141417':'#ebecf0',"to":"/idoom","link":"","dark":_vm.$store.state.dark,"flat":""}},[_c('h5',{staticClass:"ml-2 pa-1"},[_vm._v("Total Bonus")]),(_vm.bonuses.length <0)?_c(VList,{staticClass:"mt-n2",attrs:{"color":"transparent","dense":""}},_vm._l((_vm.bonuses),function(bonus){return _c(VListItem,{key:bonus.id,attrs:{"link":""}},[_c(VListItemAvatar,[_c('img',{attrs:{"src":"assets/notifications/historique-bonus.png"}})]),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"",domProps:{"textContent":_vm._s(bonus.method)}}),_c(VListItemSubtitle,{staticClass:"grey--text",domProps:{"textContent":_vm._s(bonus.updated_at)}})],1),_c(VListItemAction,[_c('h4',{staticClass:"success--text"},[_vm._v(_vm._s('+ '+ _vm.formatToPrice(bonus.bonus)))])])],1)}),1):_c(VList,{staticClass:"mt-n2",attrs:{"color":"transparent","dense":""}},_vm._l((2),function(bonus,index){return _c(VListItem,{key:index,attrs:{"link":""}},[_c(VListItemAvatar,[_c('img',{attrs:{"src":"assets/notifications/historique-bonus.png"}})]),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(index==0?'Flexy':'Idoom')}}),_c(VListItemSubtitle,{staticClass:"grey--text",domProps:{"textContent":_vm._s('Date de transaction')}})],1),_c(VListItemAction,[_c('h4',{staticClass:"success--text"},[_vm._v(_vm._s('+ 0.00'))])])],1)}),1)],1),_c('infinite-loading',{on:{"infinite":_vm.infiniteHandler}},[_c('div',{staticClass:"app-medium",class:[_vm.$store.state.params.color],attrs:{"slot":"no-more"},slot:"no-more"}),_c('div',{staticClass:"mb-5 app-medium",class:_vm.$store.state.params.color,attrs:{"slot":"no-results"},slot:"no-results"})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }