<template>
    <v-card :dark="dark" flat :color="dark?'app-bg':''"  style="overflow: hidden">



     
        <v-container fluid>
            <v-sheet     class="px-0"  :color="$store.state.dark?'app-bg':''" >
                <v-card :color="dark?'app-bg':''" flat class="vuertl">
                    <TitlePages title="" v-if="$vuetify.breakpoint.smAndDown">
                        <Menue :user="user" slot="menue" v-if="user != null" />

                        <v-app-bar-nav-icon slot="btn" @click="$router.push('/search')">
                            <v-btn   icon>
                                <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/search.svg">
                            </v-btn>
                        </v-app-bar-nav-icon>

                    </TitlePages>

                    <Carousel v-if="$route.fullPath == '/' && caroucelIsActive"  ></Carousel>

                    <v-app-bar :color="dark?'#14151a':'#fff'"  class="  elevation-0 vuertl pa-xl-0" :class="$vuetify.breakpoint.smAndDown?'pa-0':''">


                        <h3    :class="dark?'white--text':''"><b>اختر البطاقة المناسبة لك</b></h3>
                        <v-spacer></v-spacer>

                        <vs-switch     :loading="caroucelIsActive==null"   v-model="caroucelIsActive"  @click="updateCaroucel()"  >
                            <template #off>
                                <img v-if="caroucelIsActive!=null" :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/image.svg"/>
                            </template>
                            <template #on>
                                <img class="white-svg" src="assets/icons/vuesax/image.svg"/>

                            </template>
                        </vs-switch>
                    </v-app-bar>
                </v-card>

                <v-card  v-if="  cartes.length == 0 && isShow == true" :dark="dark" flat :color="dark?'app-bg':''" style="    margin-top: 20vh !important;">
                    <v-card-title   >
                        <v-spacer></v-spacer>
                        <img   style="width: 100px" src="assets/notifications/products.png">
                        <v-spacer></v-spacer>

                    </v-card-title>
                    <h5 class="app-bold text-center mb-3">سوف يتم توفير المنتجات في أقرب وقت</h5>

                </v-card>
                <v-row   v-if="  cartes == null  " align="center" justify="center" class="mx-1 p-0" >
                    <v-col  class="col-4 col-xs-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 my-3   "  v-for="(ca , index) in $vuetify.breakpoint.mdAndUp?12:9" :key="index">

                        <div v-if="mode == '0'" ref="card" class="b-game-card" style="cursor: pointer; " :class="$vuetify.breakpoint.mdAndUp?'mx-1 rounded-xl':''">
                            <div class="b-game-card__cover " >
                                <v-skeleton-loader height="100%" class="ma-0 m-0" style="width: 100%;height: 100%!important"  :dark="dark"   type="card" >
                                </v-skeleton-loader>
                            </div>
                        </div>

                        <v-card v-else class="mx-auto rounded-xl"
                                max-width="280" min-width="200"
                                :class="$store.state.dark?'app-card':'app-card-light'"
                        >
                            <v-skeleton-loader height="100%" class="ma-0 m-0 " style="width: 100%;height: 100%!important"  :dark="dark"   type="card" >
                            </v-skeleton-loader>


                        </v-card>
                    </v-col  >

                </v-row>
                <v-row  v-else     class="justify-center mx-1 p-0" >

                     <v-col   :class="mode =='1'?'col-4 col-xs-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 my-0 ':'col-12 col-xs-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 col-xl-2 my-0  d-flex justify-center'"   v-for="(cart,index) in cartes" :key="index+'100'" style="cursor:pointer;">
                        <div  v-if="mode == '1'" >
                            <div class="b-game-card " @click="getGroupe(cart.id)"  >
                                <div class="b-game-card__cover " :class="$vuetify.breakpoint.smAndDown?'rounded-lg':'rounded-xl'">

                                    <img width="100%" height="100%" :src="setBaseUrl(cart.image)" :alt="cart.name" />

                                </div>
                            </div>
                            <h5 class="text-center mt-3 text-truncate"  v-text="splitNamewithLength(cart.name , 15)"></h5>
                          <vs-button  :style="{fontSize:$vuetify.breakpoint.smAndDown?'11px':'14px'}"
                                      @click="getGroupe(cart.id)"   :color="$store.state.App.config.color"
                                      block size="large" class="app-bold px-0 ml-0"
                                      style="height: 100%;border-radius: 12px;"  border>عرض المنتج</vs-button>


                        </div>
                       <vs-card  v-else   :type="mode"   active :dark="$store.state.dark"  @click="getGroupe(cart.id)">
                         <div slot="title">

                           <h3 class="app-regular blue-grey--text text-center" style="font-size: 14px">{{splitNamewithLength(cart.name , 30)}}</h3>
                         </div>
                         <template #img>
                           <img :src="setBaseUrl(cart.image)" :alt="cart.name"  style="max-height: 100%!important;max-width:100%">
                         </template>
                         <template #text>
                           <p class="app-regular vuertl">

                           </p>
                         </template>
                         <template #interactions>

                          </template>
                       </vs-card>



                    </v-col>
                </v-row>
            <Questions class="mt-16" v-if="cartes == null"/>
            </v-sheet>
        </v-container>
      
    </v-card>
  </template>
  
  <script>
  import {setBaseUrl, splitNamewithLength} from "@/tools/Services";
import axios from 'axios'
  import Card from '../components/CardComponent.vue'
   import Carousel from '../components/CarouselComponent.vue'
import TitlePages from "@/layout/TitlePages";
import Menue from "@/layout/Menue";
import Questions from "@/layout/Questions.vue";
    export default {
      name: 'HomeCard',
  
      components: {
        Questions,
          Menue,
          TitlePages,
      Carousel,   
      Card
      },
        computed:{
          user:function(){
             return this.$store.state.auth.user
          },
          dark:function () {
            return this.$store.state.dark
          }
        },
      data(){
        return{
            mode:localStorage.getItem('cardMode')=='1'?'1':'0',
            isShow:false,
            caroucelIsActive:false,
            slides:[],
            activationCaroucel:'',
          cartes:[

          ]
        }
      },
      mounted(){
          if (this.$store.state.sidbar.sidbar.length <=2){
            this.$store.dispatch('getNavbarItems')
          }
          this.caroucelIsActive = localStorage.getItem('caroucel_activation')=='false'?false:true

        this.getCards()
       },
      methods:{
        splitNamewithLength, setBaseUrl,

          switchMode(){
              localStorage.getItem('cardMode')=='1'?localStorage.setItem('cardMode',"0"):localStorage.setItem('cardMode',"1")
              this.mode = localStorage.getItem('cardMode')=='1'?'1':'0'
          },
          updateCaroucel(){
            localStorage.setItem("caroucel_activation",JSON.stringify(!this.caroucelIsActive))
          },
        getGroupe(id){
         
          this.$router.push({name:"HomeCardInfo",params:{id:id}})
        },
        getCards(){
              this.isShow = false
          if (this.$store.state.itemsProd.cartes != null){
            this.cartes = this.$store.state.itemsProd.cartes.cards
            this.mode =  this.$store.state.itemsProd.cartes.mode
            this.activationCaroucel =  this.$store.state.itemsProd.cartes.caroucel_activation
             setTimeout(()=>this.slides = this.$store.state.itemsProd.cartes.caroucel,1000)
              this.isShow = false
        }else {
            axios.get('api/get-cards').then((response)=>{
                this.cartes = response.data.cards
              this.mode =  response.data.mode
                this.$store.commit('itemsProd/SET_CARTES',response.data)
                if (this.cartes.length == 0 ){
                    this.isShow = true
                }
                setTimeout(()=>this.slides = response.data.caroucel,1000)
                this.activationCaroucel =response.data.caroucel_activation



            })
        }


        }
      }
    }
  </script>
  
<style lang="scss" scoped>
    .v-image__image {
        transition: padding-bottom 0.2s cubic-bezier(0.25, 0.8, 0.5, 1);
        flex: 0 0 0px!important;
    }
    .b-game-card {
        position: relative;
        z-index: 1;
        width: 100%;
        padding-bottom: 150%;

        perspective: 1000px;
        transition: all 0.2s ease-in-out;
    }

    .b-game-card__cover {
        border-radius: 10px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        background-size: cover;
        perspective-origin: 50% 50%;
        transform-style: preserve-3d;
        transform-origin: top center;
        will-change: transform;
        transform: skewX(0.001deg);
        transition: transform 0.25s ease-in-out;
    }

    .b-game-card__cover::after {
        display: block;
        content: "";
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        height: 110%;
        background: linear-gradient(226deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 35%, rgba(255, 255, 255, 0.1) 42%, rgba(255, 255, 255, 0) 60%);
        transform: translateY(-50%);
        will-change: transform;
        transition: transform 0.12s ease-in-out, opacity 0.1s ease-in-out;
    }

    .b-game-card:hover .b-game-card__cover::after {
        transform: translateY(0%);
    }

    .b-game-card::before {
        display: block;
        content: "";
        position: absolute;
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
        transform: translateY(-20%);
        will-change: transform;
        transition: transform 0.5s cubic-bezier(0.18, 0.9, 0.58, 1);
    }

    .b-game-card:hover .b-game-card__cover {
        transform: rotateX(10deg) translateY(-8px);
    }

    .b-game-card:hover .b-game-card__cover::after {
        transform: translateY(0%);
    }

    .img-border-bottom {
        border-bottom-left-radius: 23px !important;
        border-bottom-right-radius: 23px !important;
        -moz-box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
        -webkit-box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
        box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
    }
.blur{
  backdrop-filter: blur(3px)importan !important
  
}
.v-dialog__content {
  z-index: 1002 !important;
}
.bottom-sheet-radius{
  border-top-left-radius: 20px!important;
  border-top-right-radius: 20px!important;
}
.bottom-sheet-radius-all{
  border-radius: 20px!important;
}
div.v-slide-group__content{
  display: flex!important;
    justify-content: center!important;
}
div.v-slide-group__wrapper{
  display: flex!important;
    justify-content: center!important;
}
.v-skeleton{
  height: 100%!important;
 }

</style>
<style lang="scss" scoped>

    .blur{
        backdrop-filter: blur(3px)importan !important

    }
    .v-dialog__content {
        z-index: 1002 !important;
    }
    .bottom-sheet-radius{
        border-top-left-radius: 20px!important;
        border-top-right-radius: 20px!important;
    }
    .bottom-sheet-radius-all{
        border-radius: 12px!important;
    }
    div.v-slide-group__content{
        display: flex!important;
        justify-content: center!important;
    }
    div.v-slide-group__wrapper{
        display: flex!important;
        justify-content: center!important;
    }

</style>