<template>
  <div>
    <v-card v-if="$store.state.auth.user.type == 'admin'" class="py-3 elevation-0 rounded-xl">
      <v-containter fluid>
        <v-row>
          <v-col class="col-12">
            <v-card class="py-3 elevation-0 rounded-xl">
              <v-container fluid>
                <v-row class="pa-0" >
                  <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <v-menu
                        :dark="$store.state.dark"

                        ref="menu"
                        transition="slide-x-transition"
                        bottom
                        right
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value="dates"

                        min-width="auto"
                    >
                      <template v-slot:activator="{ on }">
                        <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                          <v-progress-linear  top color="warning"></v-progress-linear>
                          <v-card-title style="display: block;text-align: center;position:relative">

                            <v-btn v-on="on" class="v-btn--has-bg" icon color="warning" style="position: absolute;left: 13px;top: 13px;">
                              <img class="warning-svg" src="assets/icons/vuesax/calendar.svg" />
                            </v-btn>
                            <span> @Balance</span>

                          </v-card-title>
                          <v-card-text class="pb-0" style="text-align: center">
                                                         <span class="grand-number"   >
                                                             <animated-number
                                                                 :value="counterSpendMoney.solde"
                                                                 :formatValue="formatToPrice"

                                                                 :duration="1000"
                                                             />
                                                         </span>
                          </v-card-text>
                          <v-card-actions style="display: block;text-align: center">
                            <span class="app-bold" > الحساب</span>
                            <p class="app-regular">
                              مجموع الفوائد
                              <span>{{counterSpendMoney.bonus}}</span>
                              <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                            </p>
                          </v-card-actions>
                        </v-card>


                      </template>
                      <v-date-picker
                          v-model="dates"
                          no-title
                          scrollable
                          color="#03A9F4"
                      >
                        <v-spacer></v-spacer>

                        <v-btn
                            text
                            color="#03A9F4"
                            @click="menu = false"
                        >
                          Annulé
                        </v-btn>

                        <v-btn :disabled="dates > getNow "
                               text
                               color="#03A9F4"
                               @click="getPanel()"
                        >
                          OK
                        </v-btn>
                      </v-date-picker>
                    </v-menu>

                  </v-col>
                  <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                      <v-progress-linear  top color="primary"></v-progress-linear>
                      <v-card-title style="display: block;text-align: center">
                        <span> @Flexy</span>
                      </v-card-title>
                      <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"  >
                                                       <animated-number
                                                           :value="counterSpendMoney.flexy_total"
                                                           :formatValue="formatToPrice"

                                                           :duration="1000"
                                                       />
                                                 </span>
                      </v-card-text>
                      <v-card-actions style="display: block;text-align: center">
                        <span class="app-bold" >مجموع الفليكسي</span>
                        <p class="app-regular">
                          <span v-text="dates==''?'اليوم':dates"></span>
                          <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                        </p>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                      <v-progress-linear  top color="purple"></v-progress-linear>
                      <v-card-title style="display: block;text-align: center">

                        <span> @Cartes</span>
                      </v-card-title>
                      <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"  >
                                                       <animated-number
                                                           :value="counterSpendMoney.card_total"
                                                           :formatValue="formatToPrice"

                                                           :duration="1000"
                                                       />
                                                 </span>
                      </v-card-text>
                      <v-card-actions style="display: block;text-align: center">
                        <span class="app-bold" >مجموع إيدوم</span>
                        <p class="app-regular">
                          <span v-text="dates==''?'اليوم':dates"></span>
                          <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                        </p>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                    <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                      <v-progress-linear  top color="success"></v-progress-linear>
                      <v-card-title style="display: block;text-align: center">

                        <span> @Idoom</span>
                      </v-card-title>
                      <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"   >
                                                         <animated-number
                                                             :value="counterSpendMoney.idoom_total"
                                                             :formatValue="formatToPrice"

                                                             :duration="1000"
                                                         />
                                                 </span>
                      </v-card-text>
                      <v-card-actions style="display: block;text-align: center">
                        <span class="app-bold" >مجموع إيدوم</span>
                        <p class="app-regular">
                          <span v-text="dates==''?'اليوم':dates"></span>
                          <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                        </p>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>

          </v-col>

        </v-row>
      </v-containter>
    </v-card>
    <NotFound v-else/>


  </div>

</template>

<script>
    import AnimatedNumber from "animated-number-vue";
    import {formatToPrice} from "@/tools/Services";
    import NotFound from "@/components/comp-tool/NotFound.vue";
    export default {
        name: "TestDashboardCard",
        props:['counterSpendMoney'],

        components:{AnimatedNumber , NotFound},
        computed:{
            getNow: function() {
                const date = new Date()
                return date.toISOString().split('T')[0]
            },
        },
        data(){
            return{
                dates:'',
                menu:false,
            }
        },
        methods:{formatToPrice,
            getPanel() {
                if( !this.successResponse == true){
                    this.successResponse = false
                }

                this.$refs.menu.save(this.dates)
                axios.post("api/pannel/"+this.dates).then((response) => {

                    let count = response.data.count;
                    this.counted = count.total
                   
                    this.successResponse = true

                });
                setTimeout(()=> {
                    this.successResponse = false
                    this.dates = ''
                    this.forCa = this.forCards

                },10000)
            },
        }
    }
</script>

<style scoped>
.grand-number{
    font-size: 2.75rem;
    font-weight: 600;
    font-family: app-regular;
}
 .text-info{
     font-weight: 100;
     font-size: 12px;
     text-transform: uppercase;
     letter-spacing: 0.4375rem;
 }
</style>