<template>
    <div>
        <v-navigation-drawer color="transparent"
                              class="elevation-0 mt-12 "
                              hide-overlay

                              floating
                              v-model="RightSidbar"
                              fixed
                              width="220"
                              right
        >



            <PricesFlexy :prices-today.sync="prices" class="mt-2"/>
<!--            <InfoStatus/>-->
        </v-navigation-drawer>
        <v-fab-transition  >
            <v-btn :dark="$store.state.dark"
                   @click.prevent="RightSidbar = !RightSidbar"
                   style="z-index: 124;bottom: 60px!important;right: 18px!important;"
                   class="text-decoration-none " :color="!$store.state.dark?'app-card':'app-card-light'"


                   fixed
                   bottom
                   right
                   fab
            >
                <img style="width:50px"   src="assets/notifications/price.png">
            </v-btn>
        </v-fab-transition>
    </div>
</template>

<script>
    import PricesFlexy from "@/components/PricesFlexy";
    import InfoStatus from "@/components/InfoStatus";
    export default {
        name: "RightSidBar",
        components: {InfoStatus, PricesFlexy},
        props:['prices'],
        data(){
            return{
                RightSidbar:false,
            }
        }
    }
</script>

<style scoped>

</style>