<template>
  <div>

    <v-card v-if="  show"   :color="dark?'app-bg':''" flat>
      <v-container v-if="product">
        <v-card elevation="0" :dark="dark" :color="dark ? 'app-card' : 'app-card-light'" class="rounded-xl vuertl" >
          <v-card-title  >
             <v-spacer></v-spacer>
            <vs-button border class="px-3 app-bold"  warn @click="show = !show" v-if="$store.state.auth.user.type == 'admin'">إظهار محتوى المنتج</vs-button>
            <v-btn @click="$router.go(-1)"  :dark="dark" icon class="ml-2 v-btn--has-bg" large  active   >
              <img style="width: 28px" :class="dark ? 'white-svg' : 'black-svg'"
                   src="assets/icons/vuesax/chevron-right.svg" />
            </v-btn>


          </v-card-title>
          <v-list flat   :color="dark ? 'app-card' : 'app-card-light'" >
            <v-list-item style="position: relative; top: -20px">

              <v-card  elevation="0" class="mx-2" rounded="lg" height="100" >
                <img height="100%"  :src="product.type" />

              </v-card>
              <v-list-item-content class="pa-2 mt-8 mr-3 p-1">
                <v-list-item-title style="line-height: 1.9!important;" class="app-bold"
                                   v-text="product.name"></v-list-item-title>
                <v-list-item-subtitle style="line-height: 1.9!important;"  class="app-bold">{{product.method}}</v-list-item-subtitle>

              </v-list-item-content>
            </v-list-item>
          </v-list>


        </v-card>

        <v-card class="mt-2" flat style="position:relative" height="1000" width="100%" :color="dark?'app-bg':'app-bg-light'">
          <v-card-title   class="vuertl py-1 app-bold">
            <h3 class="mb-2 ">المنتوج</h3>
          </v-card-title>
          <v-card class="rounded-xl" flat :dark ="dark" :color="dark?'app-card':'app-card-light'">
            <v-card-title class="pb-0" >
              <div  class="app-bold success--text " v-text="product.CardType"></div>
              <v-spacer></v-spacer>
              <span class="app-regular">الكمية</span>
            </v-card-title>
            <v-card-title class="py-0 text-end">
              <h3 class="app-bold">{{ formatToPrice(product.total) }}</h3>
              <v-spacer></v-spacer>
              <span   class="app-medium success--text "  v-text=" product.amount+ ' x ' + product.count"  ></span>
            </v-card-title>

            <v-card-title class="py-0">
              <v-rating
                  dense
                  readonly
                  size="10"
                  color="orange"
                  :value="2"

              />
            </v-card-title>
          </v-card>
          <v-card-title    class="vuertl py-0 app-bold" style="text-align: center;display: block" >
            {{CardCode != null?'كود البطاقة':'يرجى إنتظار الطلب '}}

          </v-card-title>

          <div  >
            <v-card    :ripple="false"  v-for="(code, index) in CardCode" :key="index" class="pa-0 vuertl rounded-lg py-0 overflow-hidden mb-2" flat  :color="dark?'app-card':'app-card-light'">

              <v-list-item :ripple="false"   two-line  :dark ="dark" :color="dark?'app-card':'app-card-light'" class="pa-0"   >
                <v-list-item-avatar class="mx-3">
                  <vs-avatar>
                    {{index + 1}}
                  </vs-avatar>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title class="samsung app-medium" v-text="code.code"> </v-list-item-title>
                </v-list-item-content>
                <v-card-actions    >


                  <vs-avatar @click="active = code.code"  v-clipboard="code.code"   class="ml-1" style="margin-right: 8px; cursor: pointer;" size="45" :dark ="dark"    >
                    <img style="width: 25px"   :class="active ==code.code?'success-svg':dark?'white-svg':'black-svg'" src="assets/icons/vuesax/copy.svg" />
                  </vs-avatar>
                  <vs-avatar :loading="!load && selected == code.code" @click="getPdf(product.id)" class="ml-1" style="margin-right: 8px; cursor: pointer;" size="45" :dark ="dark"    >
                    <img style="width: 25px"   class="error-svg" src="assets/icons/vuesax/pdf.svg" />
                  </vs-avatar>

                </v-card-actions>
              </v-list-item>

            </v-card>

          </div>




                           <v-card  v-if="product.code"  class="mt-2 vuertl rounded-lg py-0 " flat  :color="dark?'app-card':'app-card-light'">

                                 <v-list dense :dark ="dark" :color="dark?'app-card':'app-card-light'" class="py-0 my-1"    >
                                     <v-list-item dense >
                                         <v-list-item  dense   class="px-0"  >
                                             <v-list-item-content>
                                                 <v-list-item-title class="samsung app-medium"  >تحميل نسخة TEXT</v-list-item-title>
                                             </v-list-item-content>
                                             <v-card-actions   >
                                                 <v-spacer></v-spacer>
                                                 <span class="mr-3">{{product.name}}</span>

                                                 <vs-avatar  @click="downloadTxt(product.id)"  class="ml-1" style="margin-right: 8px; cursor: pointer;" size="45" :dark ="dark"    >
                                                     <img style="width: 25px"   :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/text.svg" />
                                                 </vs-avatar>
                                             </v-card-actions>
                                         </v-list-item>
                                     </v-list-item>
                                 </v-list>
                             </v-card>
          <div v-html="product.response" class="app-regular">

          </div>













        </v-card>
      </v-container>
    </v-card>
    <v-card  v-if="$store.state.auth.user.type == 'admin' && !show" :color="dark?'app-bg':''" flat>


      <v-container v-if="product" class="vuertl">
        <v-card elevation="0" :dark="dark" :color="dark ? 'app-card' : 'app-card-light'" class="rounded-xl vuertl" >
          <v-card-title  >
            {{ product.CardType }}
            <v-spacer></v-spacer>
            <vs-button border class="px-3 app-bold"  warn @click="show = !show"  v-if="$store.state.auth.user.type == 'admin'">إظهار محتوى المنتج</vs-button>
            <v-btn @click="$router.go(-1)"  :dark="dark" icon class="ml-2 v-btn--has-bg" large  active   >
              <img style="width: 28px" :class="dark ? 'white-svg' : 'black-svg'"
                   src="assets/icons/vuesax/chevron-right.svg" />
            </v-btn>


          </v-card-title>
          <v-list flat   :color="dark ? 'app-card' : 'app-card-light'" >
            <v-list-item style="position: relative; top: -20px">

              <v-card elevation="0"   class="mx-2" rounded="lg" height="100" >
                <img height="100%"  :src="product.type"
                />
              </v-card>
              <v-list-item-content class="pa-2 mt-8 mr-3 p-1">
                <v-list-item-title style="line-height: 1.9!important;" :class="$store.state.params.fontBold"
                                   v-text="product.name"></v-list-item-title>
                <v-list-item-subtitle style="line-height: 1.9!important;"  class="app-bold">تسليم
                  إلكتروني فوري مباشر</v-list-item-subtitle>

              </v-list-item-content>
            </v-list-item>
          </v-list>


        </v-card>
















        <v-card class="mt-2" flat style="position:relative" height="1000" width="100%" :color="dark?'app-bg':'app-bg-light'">

          <v-card class="rounded-xl mt-2" flat :dark ="dark" >
            <v-row>
              <v-col class="col-lg-6 col-sm-12 col-md-6 col-xs-12 col-12">
                <p class="mb-1"> اسم المنتج</p>
                <v-text-field v-model="form.name"  type="text" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder=" اسم المنتج">

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/shopping.svg" >


                </v-text-field>
              </v-col>
              <v-col class="col-lg-6 col-sm-12 col-md-6 col-xs-12 col-12">
                <p class="mb-1"> الكمية</p>
                <v-text-field v-model="form.count"  type="number" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder="الكمية ">

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/text.svg" >


                </v-text-field>
              </v-col>
              <v-col class="col-lg-6 col-sm-12 col-md-6 col-xs-12 col-12">
                <p class="mb-1"> المجموع</p>
                <v-text-field v-model="form.total" type="number" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder="المجموع">

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/banking.svg" >


                </v-text-field>
              </v-col>
              <v-col class="col-lg-6 col-sm-12 col-md-6 col-xs-12 col-12">
                <p class="mb-1"> السعر</p>
                <v-text-field v-model="form.amount" type="number" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder="السعر">

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/bank.svg" >


                </v-text-field>
              </v-col>
              <v-col class="col-lg-6 col-sm-12 col-md-6 col-xs-12 col-12">
                <p class="mb-1"> الوقت</p>
                <v-text-field v-model="form.time" type="number" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder="الوقت">

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/date.svg" >


                </v-text-field>
              </v-col>
              <v-col class="col-lg-6 col-sm-12 col-md-6 col-xs-12 col-12">
                <p class="mb-1"> التاريخ</p>
                <v-text-field v-model="form.created_at" type="text" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder="التاريخ">

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/shopping.svg" >


                </v-text-field>
              </v-col>
              <v-col class="col-lg-6 col-sm-12 col-md-6 col-xs-12 col-12">
                <p class="mb-1">الزبون</p>
                <v-text-field v-model="form.username" type="text" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder="الزبون">

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/shopping.svg" >


                </v-text-field>
              </v-col>
              <v-col class="col-lg-6 col-sm-12 col-md-6 col-xs-12 col-12">
                <p class="mb-1"> رقم التحويل</p>
                <v-text-field v-model="form.num_recharge" type="text" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder="رقم التحويل">

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/shopping.svg" >


                </v-text-field>
              </v-col>
              <v-col class="col-lg-6 col-sm-12 col-md-6 col-xs-12 col-12">
                <p class="mb-1">رقم هاتف العملية</p>
                <v-text-field v-model="form.num_trans" type="text" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder="هاتف العملية">

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/mobile.svg" >


                </v-text-field>
              </v-col>
              <v-col class="col-lg-6 col-sm-12 col-md-6 col-xs-12 col-12">
                <p class="mb-1"> تعريف الزبون</p>
                <v-text-field v-model="form.ncli" type="text" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder="تعريف الزبون">

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/shopping.svg" >


                </v-text-field>
              </v-col>
              <v-col class="col-lg-6 col-sm-12 col-md-6 col-xs-12 col-12">
                <p class="mb-1">العملية</p>
                <v-text-field v-model="form.method" type="text" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder="العملية">

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/shopping.svg" >


                </v-text-field>
              </v-col>
              <v-col class="col-lg-6 col-sm-12 col-md-6 col-xs-12 col-12">
                <p class="mb-1">الرسالة</p>
                <v-text-field v-model="form.message" type="text" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder="الرسالة">

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/shopping.svg" >


                </v-text-field>
              </v-col>
              <v-col class="col-12">
                <p class="mb-1">حالة المنتج</p>
                <v-select v-model="form.status" outlined class="app-regular" hide-details
                          :error-messages="status"
                          item-text="name"
                          item-value="value" chips  small-chips
                          :items="allStatus"
                          :placeholder="form.status"
                          :class="dark?'black--text':'white--text'"
                          :background-color="dark?'#2d2d39':'#EEF2F5'"  >

                </v-select>
              </v-col>

              <v-col class="col-12">
                <p class="mb-1"> كود البطاقة</p>
                <v-textarea v-model="form.code" type="text" filled hide-details solo flat
                              class="app-regular rounded-lg input-class overflow-hidden" rows="3"
                              :class="dark?'black--text':'white--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder=" كود البطاقة" >

                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/moneyo.svg" >


                </v-textarea>
              </v-col>


              <v-col class="col-12 mb-15">
                <vs-button  :loading="form.busy" block color="" size="large"   class="mr-0"  @click="pdateProduct()" >
                  تحديث الطلب
                </vs-button>
              </v-col>

            </v-row>

          </v-card>










        </v-card>
      </v-container>
    </v-card>
  </div>
     
  </template>
  
  <script>
 import axios from 'axios'
 import {formatToPrice, getColor, setBaseUrl} from '@/tools/Services';
    import TitlePages from "@/layout/TitlePages";
    import Form from 'vform'
   export default {
      components: {TitlePages},

      methods:{getColor,setBaseUrl,
        pdateProduct(){
          if(this.$store.state.auth.user.type == "admin"){
            if(this.form.Status != ""){
              this.form.post('api/update-idooms').then((response)=>{
                this.product = response.data.data
                this.$store.dispatch("loadsna", {
                  text:response.data.message,
                  isSuccess: true,
                });
              }).catch((e)=>{
                this.$store.dispatch("loadsna", {
                  text:e.response.data.message,
                  isSuccess: false,
                });
              })
            }else{
              this.status = "قم بتحديث حالو المنتج"
            }
          }
        },
        downloadTxt(code){
                this.selected = code.code
                this.load = true
                axios.get('api/txt/'+this.product.id, { headers: {
                        'Content-Type': 'application/json',
                    },
                    'responseType': 'blob' // responseType is a sibling of headers, not a child
                })
                    .then(response=>{
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', this.product.id+'_carte.txt');
                        document.body.appendChild(link);
                        link.click();
                        this.selected =""
                        this.load = false
                    })
                    .catch(error=>{
                        this.selected =""
                        this.load = false

                        this.$store.dispatch("loadsna", {
                            text:"حدث خطأ ما يرجى إعادةالمجالة",
                            isSuccess: false,
                        });
                    })
            },
            getPdf(id){

                this.selected = code.code
                axios.post('api/download-pdf-idoom/'+id, { headers: {
                        'Content-Type': 'application/json',
                    },
                    'responseType': 'blob' // responseType is a sibling of headers, not a child
                })
                    .then(response=>{
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', id+'_carte.pdf');
                        document.body.appendChild(link);
                        link.click();
                        this.selected = ''
                    })
                    .catch(error=>{

                        this.selected = ''
                        this.$store.dispatch("loadsna", {
                            text:"حدث خطأ ما يرجى إعادةالمجالة",
                            isSuccess: false,
                        });
                    })



            },
            formatToPrice
        },
        data(){
          return{
            status:'',
            allStatus:['Confirmé','Annulé','En attente',"Sous traitement",'Remboursé'],
            show:false,
            form:new Form({
              id:'',
              name:'',
              count:'',
              total:'',
              amount:'',
              time:'',
              created_at:'',
              num_recharge:'',
              num_trans:'',
              ncli:'',
              method:'',
              message:'',
              status:'',
              code:'',
            }),
              load:false,
              active:'',
              selected:'',
          }
        },
      computed:{
        CardCode(){
            return this.$store.state.product.code
        }   ,
        codes(){
          return JSON.parse(this.product.code)
          // return this.product.code.replace('"','').replace('[','{').replace(']','}')
        },
        product(){
             return this.$store.state.product
        }  ,
          dark(){
              return this.$store.state.dark
          }  ,
      },
      
  }
  </script>
  
  <style>
   .v-responsive__content{
    backdrop-filter: blur(3px)
   }
   .icon-right{
    position: absolute!important;
    top: 7px!important;
    right: 11px!important;
   }   
  </style>
  <style lang="sass" scoped>
  #pic .topright
    position: absolute
    top:  11px
    left: 8px
    z-index: 1
    background: rgb(189, 189, 189, 0.4)
  
  </style>