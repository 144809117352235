<template>
    <v-dialog style="z-index: 20000"  v-model="isOffline" fullscreen flat transition="slide-x-transition" :color="$store.state.params.bg" :class="dark?'app-card':'app-card-light'" >
        <v-card flat  style="position: absolute"    :color="dark?'#14151A':'#EEF2F5'"   >
            <v-card height="100%" class="center" :dark="dark" flat style="margin-top: 35vh;"   :color="dark?'#14151A':'#EEF2F5'"   >

                <v-card-title class="pb-1"  >
                    <v-spacer></v-spacer>
                    <h2 class="app-bold ">خطأ</h2>
                    <v-spacer></v-spacer>
                </v-card-title>
                <h5 class="app-bold mt-2 blue-grey--text" style="text-align: center;display: block">لقد حدث خطأ، تحقق من الانترنت</h5>
                <v-card-title class="pb-1"  >
                    <v-spacer></v-spacer>
                    <vs-button @click="reloadHref()" transparent active :dark="dark" :class="dark?'white--text':'black--text'" class="app-bold   mx-auto mx-3"  >إعادة المحاولة</vs-button>
                    <v-spacer></v-spacer>
                </v-card-title>
            </v-card>
        </v-card>
    </v-dialog>

</template>

<script>
    export default {
        name: "Alert",
        data() {
            return {

                snackbar: false,
                isOnline: true,
                isOffline: false,
                loading: false,

            };
        },
        methods:{
            reloadHref(){
                window.location.href = '/'
            }
        },
        watch:{
            isOffline:function () {

                // if (this.isOffline == true) {
                //     this.$store.dispatch("loadsna", {
                //         text: 'انت غير متصل بالانترنت',
                //         textShow:false,
                //         isSuccess: false,
                //         wifi : {value:true,icon:'mdi-wifi-off'}
                //     });
                // } else{
                //
                //     this.$store.dispatch("loadsna", {
                //         text: 'تمت استعادة الاتصال بالانترنت',
                //         textShow:false,
                //         isSuccess: true,
                //         wifi : {value:false,icon:'mdi-wifi'}
                //     });
                // }
            }
        },
        computed: {
            dark() {
                return this.$store.state.dark
            },
            networkStatus() {
                return this.isOnline ? console.log('My network is fine') : console.log('I am offline')
            }
        }
    };
</script>
<style>
    .v-snack__content{
        padding-left: 0px;
        padding-right: 0px;
    }
</style>
