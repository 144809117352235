<template>
    <v-app-bar hide-on-scroll :color="$store.state.dark?'#14151a':'#fff'" :app="$vuetify.breakpoint.xs"  class="  elevation-0 vuertl pa-xl-0" :class="$vuetify.breakpoint.smAndDown?'pa-0':''">
        <slot name="menue"></slot>
        <v-spacer v-if="title==''"></v-spacer>
        <span v-if="title==''" style="font-family: sans-serif"><b>DZtechno</b></span>
        <img style="cursor: pointer" v-if="title==''" width="30px" height="30px" src="assets/img/logo.svg" @click="HandelReload">

          <h3  v-else :class="$store.state.dark?'white--text':''" class="mr-2"><b class="app-bold">{{title}}</b></h3>
        <v-spacer></v-spacer>
        <slot name="btn"></slot>
        <slot name="btn2"></slot>
        <slot slot="extension" name="extension"></slot>
    </v-app-bar>
</template>

<script>
    import Menue from "@/layout/Menue";
    export default {
        name: "TitlePages",
        components: {Menue},
        props:['title',],
        data(){return{isShow:false}},methods:{
            HandelReload(){
                window.location.href = '/'
            }
        },
        watch:{
            '$route.fullPath':function (newVal) {
                if (newVal == '/'){this.isShow = true}
            }
        }
    }
</script>

<style scoped>

</style>