
export default {
    namespaced: true,
    state:{
        config:{
            colorText:'tik--text',
            colorBackground:'rgb(254, 44, 85)',
            color:'#fdb807',
            AppName:'Dztechno',
            name:"Dztechno",
        }
    },
    getters:{
        config(state){
            return state.config
        },
    },
    mutations:{
        SET_CONFIG (state, value) {
            state.config = value
        },
     
    },
  
}