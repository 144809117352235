import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,{staticClass:"rounded-xl pa-0 mt-2",staticStyle:{"overflow":"hidden"},attrs:{"elevation":"0","dark":_vm.$store.state.dark,"color":_vm.$store.state.dark?'#2d2d39':'#EEF2F5'}},[_c(VCardTitle,{staticClass:"pa-2"},[_c(VRow,{staticClass:"justify-center align-center d-flex",attrs:{"dense":"","align-content":"center"}},[_c(VCol,{staticClass:"col-12"},[_c(VBtn,{staticClass:"v-btn--has-bg rounded-lg",attrs:{"large":"","elevation":"0","depressed":"","block":"","text":"","color":"success"}},[_c('img',{class:_vm.colorSvg('Confirmé'),attrs:{"src":"assets/icons/vuesax/success.svg"}}),_c(VSpacer),_c('span',{staticClass:"app-regular mx-2"},[_vm._v(" عملية ناجحة")])],1)],1),_c(VCol,{staticClass:"col-12"},[_c(VBtn,{staticClass:"v-btn--has-bg rounded-lg",attrs:{"large":"","elevation":"0","depressed":"","block":"","text":"","color":"error"}},[_c('img',{class:_vm.colorSvg('Annulé'),attrs:{"src":"assets/icons/status/annule.svg"}}),_c(VSpacer),_c('span',{staticClass:"app-regular mx-2"},[_vm._v("عملية خاطئة")])],1)],1),_c(VCol,{staticClass:"col-12x"},[_c(VBtn,{staticClass:"v-btn--has-bg rounded-lg",attrs:{"large":"","elevation":"0","depressed":"","block":"","text":"","color":"warning"}},[_c('img',{class:_vm.colorSvg('En attente'),attrs:{"src":"assets/icons/status/enattante.svg"}}),_c(VSpacer),_c('span',{staticClass:"app-regular mx-2"},[_vm._v("عملية مشتبهة")])],1)],1),_c(VCol,{staticClass:"col-12"},[_c(VBtn,{staticClass:"v-btn--has-bg rounded-lg",attrs:{"large":"","elevation":"0","depressed":"","block":"","text":"","color":"primary"}},[_c('img',{class:_vm.colorSvg('Sous traitement'),attrs:{"src":"assets/icons/status/soustraitment.svg"}}),_c(VSpacer),_c('span',{staticClass:"app-regular mx-2"},[_vm._v("عملية في المعالجة")])],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }