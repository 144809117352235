<template>
    <v-card :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''" >



        <div class="pa-2" >
           <TitlePages title="المدونة">

               <v-btn slot="btn" @click="$router.go(-1)"  large   style="float: left!important;" icon depressed><img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>

           </TitlePages>


            <v-card v-if="cartes.length ==0" height="70vh" :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''" >

                    <v-card v-show="isShow " class="center" :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''">
                        <v-card-title >
                            <v-spacer></v-spacer>
                            <img   style="width: 100px" src="assets/notifications/blog.png">
                            <v-spacer></v-spacer>

                        </v-card-title>
                        <h5 class="app-bold text-center">سوف يتم شرح و توفير كل مل يتعلق بمنتجاتنا</h5>

                    </v-card>


            </v-card>
            <v-card elevation="0" flat color="transparent"
                    v-if="cartes.length >0"
            >
              <v-row     align="center" justify="center" class="mx-1 p-0 mt-5" >
                <v-col class="col-12 col-xs-12 col-sm-4 col-md-4 col-lg-3 col-xl-3  my-4 pt-0" v-for="(cart) in cartes" :key="cart.id" style="cursor:pointer;">
                  <vs-card class=""  active :dark="$store.state.dark" @click="redirect(cart.slug,cart.title)">
                    <template #title>
                      <h3 class="app-regular vuertl">{{cart.title}}</h3>
                    </template>
                    <template #img>
                      <img :src="cart.image" alt="" style="max-height: 100%!important;max-width:100%">
                    </template>
                    <template #text>
                      <p class="app-regular vuertl">
                        {{ splitNamewithLength(cart.subtitle , 30) }}
                      </p>
                    </template>
                    <template #interactions>
                        <h5>Posted by admin</h5>
                    </template>
                  </vs-card>
                </v-col>

              </v-row>
            </v-card>

        </div>

    </v-card>
</template>

<script>
    import axios from 'axios'
    import Card from '../components/CardComponent.vue'
    import Carousel from '../components/CarouselComponent.vue'
    import TitlePages from "@/layout/TitlePages";
    import {setBaseUrl, splitNamewithLength} from "@/tools/Services";


    export default {
        name: 'HomeBlog',

        components: {
            TitlePages,
            Carousel,
            Card
        },
        data(){
            return{
                isShow:false,
                cartes:[

                ],
                items: [
                    {
                        text:'الرئيسية',
                        disabled: false,
                        href: '/',
                    },
                    {
                        text:this.$store.state.params.AppName,
                        disabled: true,
                        href: '/',
                    },


                ],
            }
        },
        computed:{
          dark:function (){
            return this.$store.state.dark
          }
        },
        created(){
            this.getCards()
        },
        methods:{
          splitNamewithLength,
          splitName() {
            return splitName
          }, setBaseUrl,
            redirect(slug,title){
                this.$store.commit('SET_BROADCRUMP',{title:title,broadCrump:'المدونة',link:'/blog'})
                this.$router.push({name:"HomeBlogDetail",params:{slug:slug}})
            },
            getGroupe(id){

                this.$router.push({name:"HomeCardInfo",params:{id:id}})



            },
            getCards(){

                axios.get('api/get-blog').then((response)=>{
                    this.cartes = response.data
                    if (this.cartes.length <= 0){
                        this.isShow = true
                    }else{
                        this.isShow = true
                    }

                }).catch(()=>{

                })
            }
        }
    }
</script>

<style lang="scss" scoped>
    .center {

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }
    .b-game-card {
        position: relative;
        z-index: 1;
        width: 100%;

        perspective: 1000px;
        transition: all 0.2s ease-in-out;
    }

    .b-game-card__cover {
        border-radius: 10px;
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background-size: cover;
        perspective-origin: 50% 50%;
        transform-style: preserve-3d;
        transform-origin: top center;
        will-change: transform;
        transform: skewX(0.001deg);
        transition: transform 0.25s ease-in-out;
    }

    .b-game-card__cover::after {
        display: block;
        content: "";
        position: absolute;
        z-index: 100;
        top: 0;
        left: 0;
        width: 100%;
        max-height: 100%;
        background: linear-gradient(226deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 35%, rgba(255, 255, 255, 0.1) 42%, rgba(255, 255, 255, 0) 60%);
        transform: translateY(-50%);
        will-change: transform;
        transition: transform 0.12s ease-in-out, opacity 0.1s ease-in-out;
    }

    .b-game-card:hover .b-game-card__cover::after {
        transform: translateY(0%);
    }

    .b-game-card::before {
        display: block;
        content: "";
        position: absolute;
        top: 5%;
        left: 5%;
        width: 90%;
        height: 90%;
        transform: translateY(-20%);
        will-change: transform;
        transition: transform 0.5s cubic-bezier(0.18, 0.9, 0.58, 1);
    }

    .b-game-card:hover .b-game-card__cover {
        transform: rotateX(10deg) translateY(-8px);
    }

    .b-game-card:hover .b-game-card__cover::after {
        transform: translateY(0%);
    }

    .img-border-bottom {
        border-bottom-left-radius: 23px !important;
        border-bottom-right-radius: 23px !important;
        -moz-box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
        -webkit-box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
        box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
    }
    .blur{
        backdrop-filter: blur(3px)importan !important

    }
    .v-dialog__content {
        z-index: 1002 !important;
    }
    .bottom-sheet-radius{
        border-top-left-radius: 20px!important;
        border-top-right-radius: 20px!important;
    }
    .bottom-sheet-radius-all{
        border-radius: 20px!important;
    }
    div.v-slide-group__content{
        display: flex!important;
        justify-content: center!important;
    }
    div.v-slide-group__wrapper{
        display: flex!important;
        justify-content: center!important;
    }
    .v-skeleton{
        height: 100%!important;
    }

</style>
