<template>
    <v-card   flat color="transparent" class="rounded-xl vuertl mb-5 ">
        <div class="ma-4"  v-if="current_page >0" :class="$route.fullPath !='/flexy-pc'?'':' container--fluid'">
            <TitlePages title="مشتريات الفليكسي" v-if="$route.fullPath !='/flexy-pc'">
                <v-btn slot="btn"  @click="$router.go(-1)"  large   style="float: left!important;" icon depressed><img style="width: 32px" :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>

            </TitlePages>

            <v-card-title class="px-0" v-if="$route.fullPath !='/flexy-pc' && historiqueData.length >0">
                <v-text-field  clearable :dark="$store.state.dark" v-model="search"     class="transi app-regular " style=" border-radius: 14px!important" hide-details="true"  filled rounded   dir="rtl"        :placeholder="'  بحث'" :background-color="$store.state.dark?'app-card':'app-card-light'">
                    <img   :class="$store.state.dark?'white-svg':'black-svg'"  class="ml-2"    style="cursor: pointer"  slot="prepend-inner" src="assets/icons/vuesax/search.svg">
                </v-text-field>
                <v-spacer></v-spacer>
                <v-menu
                        :dark="$store.state.dark"

                        ref="menu"
                        transition="slide-x-transition"
                        bottom
                        right
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value="dates"

                        min-width="auto"
                >
                    <template v-slot:activator="{ on }">
                        <vs-button class="app-regular"  v-on="on" primary icon>
                            <img class="white-svg " src="assets/icons/vuesax/calendar.svg">
                        </vs-button>

                    </template>

                    <v-date-picker

                            v-model="dates"
                            no-title
                            scrollable

                    >
                        <v-spacer></v-spacer>

                        <v-btn
                                text
                                color="#03A9F4"
                                @click="menu = false"
                        >
                            اغلاق
                        </v-btn>

                        <v-btn :disabled="dates  > getNow "
                               @click="getWithdate()"
                               text
                               color="#03A9F4"
                        >
                            موافق
                        </v-btn>
                    </v-date-picker>
                </v-menu>




            </v-card-title>
            <v-row :no-gutters="$vuetify.breakpoint.smAndDown"   v-if="show">
                <v-col    class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-0 mb-2"  v-for="(item,index) in historiqueData" :key="index"  >
                    <v-card  flat  ripple  @click.stop="HandelProduct(item)"     class="rounded-xl  "  style="height: 90px;cursor: pointer"  :color="dark?'app-card':'app-card-light'">
                        <v-card-title style="margin-right: 30px;" class=" py-0 app-regular  " >
                            <span class="success--text" style="font-size:18px">{{ formatToPrice(item.Amount) }}</span>

                            <v-spacer></v-spacer>

                            <span v-text="item.PhoneN" ></span>
                            <vs-button size="small"
                                    class="mt-1 mr-1 "
                                    icon
                                    flat
                                    :danger="item.Status=='Annulé'"
                                    :warn="item.Status=='En attente'"
                                    :success="item.Status=='Confirmé'"

                            >

                                <img :class="colorSvg(item.Status)" :src="iconResponse(item.Status)" />
                            </vs-button>



                            <v-card flat     :dark="dark" style="position: absolute; right: 0;top: 0;z-index: 1;"  class=" mt-n3 rounded-lg elevation-2 "  rounded="lg" height="45" width="45" >
                                <img v-if="item.rapport != null" :src="item.rapport.adminResponse!=null?'assets/img/notification-1.png':'assets/img/notification-success.png'"
                                     class="img-border-bottom "
                                     height="100%"  width="100%"
                                />
                                <v-img v-else
                                       class="img-border-bottom "
                                       height="100%"  width="100%"  :src="OperateurChar(item.PhoneN)" ></v-img>
                            </v-card>
                        </v-card-title>
                        <v-card-title style="margin-right: 0px;    font-size: 17px;" class="pt-0">
                             <span  class="mr-4 app-medium" style="direction: ltr;font-size: 16px;"
                                    v-text="item.Operateur + ' ' +item.Mode" > </span>
                            <v-spacer></v-spacer>
                            <p  class="text-center app-regular" style="font-size:13px" :class="dark?'grey--text':''">{{ item.created_at | datee }}</p>
                        </v-card-title>

                    </v-card>

                </v-col>
            </v-row>

            <infinite-loading v-if="!notUser"  @infinite="infiniteHandler" class="pb-5"  >
                <div  v-show="historiqueData.length==0 && !notUser" :style="{marginTop:$route.fullPath !='/flexy-pc'?'25vh':''}"  class="mb-5 mt-3 app-medium"  slot="spinner"  >جارٍ البحث</div>
                <div     slot="no-more" class="app-medium" :class="[$store.state.params.color]">لا يوجد المزيد</div>
                <div :style="{marginTop:$route.fullPath !='/flexy-pc'?'10vh':''}" class="mb-5 app-medium"  slot="no-results" :class="$store.state.params.color">

                    <v-card   :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''" :style="{marginTop:$route.fullPath !='/flexy-pc'?'20vh':''}" >
                        <v-card-title class="mt-4"  >
                            <v-spacer></v-spacer>
                            <img  style="width: 100px" src="assets/notifications/mobile-histo.png">
                            <v-spacer></v-spacer>

                        </v-card-title>
                        <h5 :class="$route.fullPath !='/flexy-pc'?'':'pb-5'" class="app-bold text-center">لا يوجد أي تحويلات فليكسي</h5>

                    </v-card>
                </div>

            </infinite-loading>

<!--                 <v-card style="position: relative;margin-top: 25vh" v-if="notUser"     class="center" :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''">-->
<!--                     <v-card-title class="pb-0" >-->
<!--                         <v-spacer></v-spacer>-->
<!--                         <img  style="width: 100px" src="assets/notifications/login.png">-->
<!--                         <v-spacer></v-spacer>-->

<!--                     </v-card-title>-->
<!--                     <h4 class="app-bold text-center">قم بتسجيل الدخول</h4>-->

<!--                 </v-card>-->


        </div>
        <v-overlay
                color="#3c3e5f" @click="dialogHisto.isActive = false"
                opacity="0.20" style="z-index:126"
                :value="dialogHisto.isActive"
                :style="{backdropFilter:dialogHisto.isActive?'blur(3px)':''}"   >
    <v-bottom-sheet     v-model="dialogHisto.isActive" :inset="$vuetify.breakpoint.mdAndUp" :class="$store.state.dark?'#1E2023':''" >
      <v-card class="rounded-t-xl" flat :dark="dark" :class="$store.state.dark?'#1E2023':''"  >
          <v-container class="py-0  ">

              <v-btn @click="dialogHisto.isActive = !dialogHisto.isActive" style="float: left!important;" icon depressed><img
                      :class="dark?'white-svg':'black-svg'"
                      src="assets/icons/vuesax/close-1.svg"/></v-btn>
              <h3 class="app-regular" :class="dark?'white--text':'black--text'" style="text-align: right">
                  معلومات الفليكسي </h3>
              <div>
                  <v-card v-if="dialogHisto.item" :dark="dark"        style=" cursor: pointer"   flat  :class="$store.state.dark?'#1E2023':''" >

                      <v-card-title style="margin-right: 60px;" class="   app-regular" >
                          <img :class="colorSvg(dialogHisto.item.Status)" :src="iconResponse(dialogHisto.item.Status)" />

                          <v-spacer></v-spacer>
                          <span v-text="dialogHisto.item.PhoneN" ></span>
                          <v-card flat :dark="dark" style="position: absolute; right: 0;top: 0;z-index: 1;"  class=" mt-5 mr-2  " rounded="lg" height="50" width="50" >
                              <img
                                      class="img-border-bottom"
                                      height="100%"  width="100%"  :src="OperateurChar(dialogHisto.item.PhoneN)" />
                          </v-card>
                      </v-card-title>
                      <v-card-title style="margin-right: 60px;    font-size: 17px;" class="pt-0">
                     <span  class="mr-4 app-medium" style="direction: ltr;font-size: 16px;"
                            v-text="dialogHisto.item.Operateur + ' ' +dialogHisto.item.Mode" > </span>
                          <v-spacer></v-spacer>
                          <span class="success--text headline">{{ formatToPrice(dialogHisto.item.Amount) }}</span>
                      </v-card-title>

                      <v-card-subtitle  class="app-regular py-0 pb-2" style="display: block;text-align: center"   >
                          <v-alert class="rounded-xl rounded-tr-0"
                                  text
                                  prominent
                                  :type="AlertIcon(dialogHisto.item.Status)"

                                  v-text="dialogHisto.item.response"
                          >
                              <template slot="">
                                  <img :class="colorSvg(dialogHisto.item.Status)" :src="iconResponse(dialogHisto.item.Status)" />
                              </template>
                          </v-alert>
                      </v-card-subtitle>
                      <v-card-text v-if="AdminRapportActivation=='true' && raport != null" class="py-0"  >

                          <v-card v-if="raport.showAdmin==false" color="transparent" flat class="mb-4">
                              <v-row dense   >
                                  <v-col class="col-12"  >
                                      <v-card-title    class="app-regular py-0"   >

                                          <vs-switch   :value="raport.isRapored"    @click="raport.isRapored==1?raport.isRapored=0:raport.isRapored=1"  class="app-regular z-index:2"    :color="$store.state.params.bg"  style="width: 100px">
                                              <template #off>
                                                  <span class="mx-1">نعم</span>

                                                  <img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/dislike.svg"/>
                                              </template>
                                              <template #on>
                                                  <span class="mx-1">لا</span>
                                                  <img  class="white-svg" src="assets/icons/vuesax/like.svg"/>

                                              </template>
                                          </vs-switch>
                                          <v-spacer></v-spacer>
                                          <span style="font-size: 14px">يمكنك ترك شكوى بخصوص هذه العملية</span>


                                      </v-card-title>

                                  </v-col>



                              </v-row>
                          </v-card>
                          <v-card v-else>
                              <v-row  >
                                  <v-col class="col-12"  >
                                      <v-card-title  class="app-regular py-0 " style="display: block;text-align: center" v-html="raport.adminResponse"   >
                                      </v-card-title>
                                  </v-col>
                              </v-row>
                          </v-card>



                      </v-card-text>

                  </v-card>

              </div>

                  <v-row >
                      <v-col class="col-12" v-if="raport.isRapored==1 && raport.message==null"   >
                          <v-expand-transition mode="in-out"  >
                              <v-textarea maxlength="100"  clearable :disasbled="raport.isRapored==1"
                                          solo-inverted
                                          rows="2" outlined class="py-0 app-regular rounded-lg" style="direction: rtl"
                                          name=""
                                          :value="raport.message==null?message:raport.message"
                                          v-model="raport.message"
                                          hide-details
                              ></v-textarea>
                          </v-expand-transition>
                      </v-col>
                      <v-col class="col-12" v-if="$store.state.auth.user.type =='admin'"   >
                          <v-expand-transition mode="in-out"  >
                              <v-textarea maxlength="100"  clearable :disasbled="raport.isRapored==1"
                                          solo-inverted
                                          rows="1" outlined class="py-0 app-regular rounded-lg" style="direction: rtl"
                                          name=""

                                          v-model="raport.adminResponse"
                                          hide-details
                              ></v-textarea>
                          </v-expand-transition>
                          <v-select :items="['En attente','Confirmé','Annulé','Sous traitement']"
                                    v-model="raport.status"
                                    item-value="mode"
                                    persistent-hint hint="تحديث الحالة"   item-text="name"
                                    :background-color="dark?'#242426':'#fff'" flat filled
                                    class="app-regular rounded-lg vuertl" placeholder="تعديل الحالة">
                              <template style="direction: ltr!important;" v-slot:item="{item}"
                              ><span class="app-regular" style="float:right">{{ item }}  </span>
                              </template>
                          </v-select>
                      </v-col>

              </v-card-actions>
                  </v-row>

              <v-card-actions    v-if="raport.adminResponse != null" >

                      <v-avatar  >
                      <img src="assets/img/profile.png" />
                      </v-avatar>
                      <v-chip class="rounded-tl-0 app-medium mx-2"   v-text="raport.message"></v-chip>

              </v-card-actions>

              <v-card-actions  class="py-1" v-if="raport.adminResponse != null" >
                  <v-spacer></v-spacer>

                            <v-chip class="rounded-tr-0 app-medium mx-2 primary mt-3"   v-text="raport.adminResponse"></v-chip>
                            <vs-avatar class="mt-n4">
                                <img src="assets/img/admin.png" />
                            </vs-avatar>



              </v-card-actions>

              <v-card-actions class="pt-0" v-if="raport">
                  <v-spacer></v-spacer>
                  <v-btn  :disabled="raport.message==''" v-if="!raport.showAdmin  && AdminRapportActivation=='true' " :loading="raport.busy"    color="success" @click="HandelRaport(dialogHisto.item)"  class="white--text app-regular rounded-lg" >طلب التحقق من العملية</v-btn>

                  <vs-button v-if="$store.state.auth.user.type == 'admin'" color="primary" @click="UpdateRaport(dialogHisto.item)"  class="mt-2 white--text app-regular rounded-lg" >تحديث العملية</vs-button>

              </v-card-actions>
          </v-container>
      </v-card>



    </v-bottom-sheet>
        </v-overlay>
    </v-card>
</template>

<script>
    import axios from 'axios';
    import { formatToPrice ,iconResponse ,colorSvg, OperateurChar,AlertIcon} from '@/tools/Services';
    import {mapState} from "vuex";
    import Form from 'vform'
    import TitlePages from "@/layout/TitlePages";
    import strMixins from '@/plugins/Mixins'
    export default {
        components:{TitlePages},
        mixins:strMixins,
        computed:{
            getNow: function() {
                const date = new Date()
                return date.toISOString().split('T')[0]
            },
          ...mapState({

              params:'params', dark:'dark'})
        },
        data(){
            return{
                productFlexy:null,

                date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                dates:'',
                menu:false,
                notUser:false,
                AdminRapportActivation:false,
                dialogHisto:{
                    isActive:false,
                    item:null
                },
                search: "",
                show:false,
                current_page:1,
                historiqueData:[],
                raport:new Form({
                    message:'',
                    isRapored:false,
                    idFlexy:'',
                    method:'',
                    adminResponse:'',
                    status:'',


                })
            }
        },
        watch:{
            'raport.isRapored':function(newVal){
                if(newVal == true && this.form.message != null){
                    this.btnIsActive = true
                }else {
                    this.btnIsActive = false
                }
            },
            search(after) {
                this.searchProduct(after);
            },
        },
        methods:{
            UpdateRaport(){
                this.raport.post('api/update-rapport').then(()=>{
                    this.$store.dispatch("loadsna", { text:"تم تحديث الشكوى بنجاح", isSuccess: true});
                    this.dialogHisto.isActive = false
                }).catch((e)=>{
                    this.dialogHisto.isActive = false
                    this.$store.dispatch("loadsna", { text:"يرجى إعادة المحاولة بعد حين", isSuccess: false});
                })
            },
            AlertIcon,
            HandelRaport(item){
                this.raport.idFlexy = item.id
                this.raport.method = 'flexy'
                this.raport.post('api/raport-from-user').then(()=>{
                    this.$store.dispatch("loadsna", { text:"تم إرسال الشكوى بنجاح", isSuccess: true});
                    this.dialogHisto.isActive = false
                }).catch((e)=>{
                    this.dialogHisto.isActive = false
                    this.$store.dispatch("loadsna", { text:"يرجى إعادة المحاولة بعد حين", isSuccess: false});
                })
            },
            searchProduct(after) {
                if(after !=''){
                    axios.get("api/findFlexy?q=" + after).then(response => {
                        this.historiqueData = response.data.data
                         if(!response.data.data.length){
                            this.show = true
                        }

                    })
                        .catch(() => {});
                }else {
                    this.show = false
                    this.getProducts();
                }
            },
            OperateurChar,
               HandelProduct(item){

                let vm = this;

                   vm.$store.commit('SET_PRODUCT',item)
                   vm.$router.push({name:"InfoFlexy",params:{id:item.id}}).catch((e)=>{ })

            },
            formatToPrice,iconResponse,colorSvg,
            getHistoriqueData($state){
                if (this.$store.state.auth.user != null){
                    axios.get('api/flexy-historique', {page: this.current_page}
                    ).then((response)=>{
                        if(response.data.data.length >0){
                            this.current_page +=1
                            this.historiqueData.push(...response.data.data);

                        } else{
                            $state.complete();
                        }
                        this.show = true
                    }).catch(()=>{

                    })
                }

            },
            getWithdate(){
                if (this.dates != ''){
                    axios.post('api/get-flexy-dates',{date:this.dates}).then((response)=>{
                        if (response.data.length){
                            this.show = true
                            this.historiqueData = response.data
                        }else {
                            this.show = false
                        }

                    })
                }
            },
            infiniteHandler($state){
                if (this.$store.state.auth.user != null){
                    if(this.$route.fullPath =='/flexy-pc'){
                        if(this.current_page == 1){
                            axios.get('api/flexy-historique?page='+this.current_page).then((response)=>{

                                if(response.data.data.length){
                                    this.current_page +=1

                                    this.historiqueData.push(...response.data.data)
                                    $state.loaded()
                                }else{

                                }
                                this.show = true
                            }) .catch(()=>{

                            })
                        }

                    }else {
                        axios.get('api/flexy-historique?page='+this.current_page
                        ).then((response)=>{

                            if(response.data.data.length >0){
                                this.current_page +=1

                                this.historiqueData.push(...response.data.data)
                                $state.loaded()
                            }else{
                                $state.complete();
                            }
                            this.show = true
                        }) .catch(()=>{

                        })
                    }
                }else {
                    this.notUser = true
                }


            }
        },
        mounted() {
            setTimeout(()=>{
                 if ( this.historiqueData.length <=0){
                     this.infiniteHandler()
                    this.notUser = false
                }else {
                    this.notUser = true
                }
            },1500)
        }

    }
</script>

<style lang="scss" scoped>
    .v-ripple-container{

        border-radius: 20px!important;
    }
    .v-card--link:before {
        background: none!important;
    }
    >>> .v-input__slot::before {
        border-style: none !important;
    }
</style>