

<template>
  <div>
    <v-card-title>
      <vs-button @click.stop="showComment" size=" " border color="warn"

                 class="app-regular  px-5 rounded-xl px-0 mr-n2" dence>
        أترك تعليق
      </vs-button>
      <v-spacer></v-spacer>
      <h4 class="app-medium">أترك لنا تعليق</h4>


    </v-card-title>

    <v-dialog  hide-overlay  :max-width="$vuetify.breakpoint.smAndDown?'':'600px'"    :dark="$store.state.dark"       v-model="sheet" style="z-index: 20000!important" >

      <v-dialog v-model="sheet"   :max-width="$vuetify.breakpoint.smAndDown?'':'600px'"
                :dark="$store.state.dark">

        <v-card class="rounded-xl overflow-hidden px-1" ref="target" flat style="position: relative"
                :color="$store.state.dark ? '#000' : ' '"
                :class="!$vuetify.breakpoint.smAndDown?'bottom-sheet-radius-all':'bottom-sheet-radius'">

          <v-card-title class="app-regular mb-2">

            <v-btn @click="sheet = !sheet" :dark="$store.state.dark" icon class="ml-2  " active>
              <img :class="$store.state.dark ? 'white-svg' : 'black-svg'"
                   src="assets/icons/vuesax/close-1.svg"/>
            </v-btn>
            <v-spacer></v-spacer>
            تعليق لمنتجاتنا

          </v-card-title>
          <v-card-subtitle class="app-regular vuertl pb-0" > قم بإختيار تنقيط المنتج و سرعة الخدمة</v-card-subtitle>
          <v-card-subtitle class="vuertl pb-1 py-0">
            <v-rating ripple half-increments hover color="orange"
                      v-model="starPost.stars"

            ></v-rating>

          </v-card-subtitle>
          <v-card-text class="pa-0">
            <v-textarea maxlength="150" rows="2" style="    font-size: 17px;" flat
                        v-model="starPost.message" type="text" required filled hint=""
                        class="app-regular rounded-lg vuertl"
                        :class="$store.state.dark?'white--text':'black--text'"
                        :background-color="$store.state.dark?'#2d2d39':'#EEF2F5'" block
                        placeholder="أترك تعليقا من فضلك">
              <img slot="prepend-inner" :class="$store.state.dark?'white-svg':'black-svg'" class=" mx-3"
                   src="assets/icons/vuesax/message.svg">

            </v-textarea>
          </v-card-text>
          <v-card-text>
            <v-row class="justify-center">
              <v-btn v-for="(imo,index) in emoji" :key="index" icon @click.prevent="setEmo(imo)">{{ imo }}</v-btn>

            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <vs-button @click="postComment"   class="app-regular" color="rgb(254, 44, 85)" :loading="starPost.busy">إرسال
              التعليف
            </vs-button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-dialog>
  </div>

</template>


<script>
  import Form from "vform";
  import {bus} from "@/plugins/bus";

  export default {
    props:['idProduct'],
    data(){
      return{
        sheet:false,
        isShow:false,
        emoji:['😂','🌹','💚','💛','✅','😃','👍🏻','🤣','😍','🤍','🖤','🤪','✨','💙','♥️'],
        comment:false,
        starPost:new Form({
          show:false,
          username:'',
          card_types_id:'',
          stars:1,
          message:''
        }),
      }
    },
    methods:{
      showComment(){
        this.starPost.card_types_id = this.idProduct
        this.starPost.show = true
        this.starPost.message = ''
        this.starPost.username = this.$store.state.auth.user.username
        this.sheet = true
      },
      postComment(){
        this.starPost.post("api/post-comment").then((response)=>{
          this.success = true
          this.starPost.show=true
          this.sheet = !this.sheet
          this.$vs.notification({
            classNotification:"arabic",
            title:"تعليقات الزبائن",
            background: this.dark?'#000':'#fff',
            color:'success',
            duration:3000,
            position:'bottom-center',
            progress: 'auto',
            opacity:0.9,
            text:'نشكرك لتفاعلك معنا شكرا..'})
          bus.$emit('loadComments')
        })
      },
      setEmo(imo){
        this.starPost.message+=imo
      },
    }
  }
</script>
<style scoped>
.blur {
  -webkit-backdrop-filter: saturate(180%) blur(15px)!important;
  backdrop-filter: saturate(180%) blur(15px)!important;
}
</style>