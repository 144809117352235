<template>

        <v-bottom-sheet   :dark="$store.state.dark" class="rounded-t-lg" v-model="contactsDialog" :retain-focus="false"  >
            <v-sheet :dark="$store.state.dark" class=" radius-bottom-sheet" :color="$store.state.dark?'black-vuesax':''"  >
                <v-card-title class="app-regular">
                    <img :class="$store.state.dark?'white-svg':'black-svg'" @click="contactsDialog = !contactsDialog" style="cursor: pointer" src="assets/icons/vuesax/close-1.svg"/>
                    <v-spacer></v-spacer>
                    <h4> إضافة زبون</h4>
                </v-card-title>
                <v-card-text class="vuertl">
                    <v-text-field outlined v-model="contact.name" class="app-regular"
                                  placeholder="الاسم"

                    >
                        <template slot="append-outer-icon">
                            <img src="assets/icons/vuesax/users.svg"/>
                        </template>
                    </v-text-field>
                    <v-text-field :error-messages="error" outlined v-model="contact.number" maxlength="10" class="app-regular"
                                  placeholder="رقم الهاتف"

                    >
                        <template slot="append-outer-icon">
                            <img src="assets/icons/vuesax/users.svg"/>
                        </template>
                    </v-text-field>
                    <v-card-actions class="pt-0">




                        <vs-button :dark="!$store.state.dark" size="lg" :loading="contact.busy" @click="AjouterContact()" block class="app-bold" style="font-size: 17px">
                              <span style="font-size: 18px" class="ml-3 white--text app-regular py-2" >إضافة رقم الهاتف</span  >
                            <img class="white-svg" src="assets/icons/vuesax/add-user.svg"/>
                        </vs-button>
                    </v-card-actions>
                </v-card-text>
            </v-sheet>


        </v-bottom-sheet>

</template>

<script>
    import Form from "vform";
    import {bus} from "@/tools/bus";

    export default {
        name: "ContactAdd",
        data(){
            return{
                contactsDialog: false,
                error:'',
                contact:new Form({
                    number: '',
                    name: '',
                    autre: 'فليكسي',
                }),
            }
        },
        methods:{
            AjouterContact() {
                if ( this.contact.number.length >= 9) {
                    this.contact.post('api/ajouter-contacts').then((response) => {
                        this.contactsDialog = false
                        this.error = ''
                    }).catch(()=>{
                        this.error = "حدث خطأ، أعد المحاولة"
                    })
                }else {
                    this.error = "تحقق من رقم الهاتف"
                }
            },
        },
        mounted() {
            bus.$on('callContacts',(number)=>{
                this.number = number
                this.contactsDialog = !this.contactsDialog

            })
        }
    }
</script>

<style scoped>

</style>