
export default {
    namespaced: true,
    state:{
        side:true
    },
    getters:{
        side(state){
            return state.side
        },
 
    },
    mutations:{
        SET_SIDE (state, value) {
            state.side = value
        },
     
    },
  
}