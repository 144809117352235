<template>
    <v-card :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''" >


        <v-container style="direction: rtl" v-if="blog!=null">
            <v-breadcrumbs large style="direction: rtl" class="app-regular py-0"
                           :items="items"
                           divider="\"
            ></v-breadcrumbs>
            <TitlePages :title="blog.title">
                <v-btn slot="btn" @click="$router.go(-1)" large   style="float: left!important;" icon depressed><img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>

            </TitlePages>
            <v-card-subtitle class="app-regular pt-0">{{blog.subtitle}}</v-card-subtitle>
            <v-card  style="color: #fff!important;" elevation="0" color="transparent" :dark="$store.state.dark" class="app-regular mt-5"  >
              <v-card-text  v-html="blog.text">

              </v-card-text>
            </v-card>
        </v-container>

    </v-card>
</template>

<script>
    import axios from 'axios'
    import BreadCrup from "@/components/BreadCrup";
    import TitlePages from "@/layout/TitlePages";
    export default {
        name: "HomeBlogDetail",
        components: {TitlePages, BreadCrup},
        data(){
            return{
                blog:null,
                items: [

                    {
                        text: 'المدونة',
                        disabled: false,
                        href: '/blog',
                    },

                    {
                        text:this.$store.state.params.AppName,
                        disabled: true,
                        href: '/',
                    },
                ],

            }
        },

        created() {

            const loading = this.$vs.loading()
            axios.get('api/get-blog/'+this.$route.params.slug).then((response)=>{
                this.blog = response.data.blog
                loading.close()
            }).catch(()=>{
                 loading.close()
            })
        }
    }
</script>

<style scoped>

</style>