<template>

        <v-card   class="ml-14 rounded-xl"  height="100vh" flat >
        <v-container fluid>
            <v-row>
                <v-col class="col-9">
                    <v-card height="30vh" class="rounded-xl"   flat img="https://images.unsplash.com/photo-1508247967583-7d982ea01526?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80">

                        <v-card height="50%">
                            <v-tabs height="58"
                                    v-model="tab"
                                    :background-color="$store.state.params.bg"
                                    centered
                                    dark
                                    icons-and-text
                            >
                                <v-tabs-slider></v-tabs-slider>

                                <v-tab  href="#tab-1" >
                                    Recents
                                    <v-icon>mdi-phone</v-icon>
                                </v-tab>

                                <v-tab href="#tab-2">
                                    Favorites
                                    <v-icon>mdi-heart</v-icon>
                                </v-tab>

                                <v-tab href="#tab-3">
                                    Nearby
                                    <v-icon>mdi-account-box</v-icon>
                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tab" style="height: 100%">
                                <v-tab-item style="height: 100%"
                                        v-for="i in 3"
                                        :key="i"
                                        :value="'tab-' + i"
                                >
                                    <v-card flat>
                                        <v-card-text>{{ text }}</v-card-text>
                                    </v-card>
                                </v-tab-item>
                            </v-tabs-items>
                        </v-card>
                    </v-card>
                </v-col>
                <v-col class="col-3"></v-col>
            </v-row>
        </v-container>


        </v-card>

</template>

<script>
    export default{
        name: "HomeOptions",
        data(){
            return{
                tab: null,
                text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
            }
        }
    }
</script>

<style scoped>

</style>