*<template>




    <vs-dialog   blur  v-on:close="closeDial"  style="z-index: 1000" prevent-close :dark="$store.state.dark"      auto-width not-padding   v-model="dial">
        <div   class="con-image"  >
            <img   style="width:100%" class="overflow-hidden" :src="setBaseUrl(InterDialog.image)" alt="DZtechno">
        </div>

    </vs-dialog>

</template>

<script>
    import {setBaseUrl} from "@/tools/Services";
    import axios from 'axios'
    export default {
        name: "DialogWalpapper",

        data() {
            return {

                InterDialog:[],
                dial: false,
            };
        },

        methods: {setBaseUrl,
            closeDial() {
                this.dial = false;
                localStorage.setItem("dialog", this.InterDialog.image);
            },
        },


        async created() {
          const dd = window.localStorage.getItem("dialog");
          setTimeout(()=>{
            axios.get("api/dialog").then((response) => {
              this.InterDialog = response.data.dialog;
              if(dd !=null){
                if (  this.InterDialog.value == "true" &&  this.InterDialog.image != "" &&  dd !== this.InterDialog.image   )
                {  this.dial = true; }
              }else {
                if ( this.InterDialog.value == "true" &&  this.InterDialog.image != ""  ) { this.dial = true;   }
              }
            }).catch(()=>{});
          },30000)
        },

    };
</script>
<style  scoped >


    img{
        display :block;
        position :relative;
        border-radius: inherit;

    }

    .con-image{
    border-radius :inherit
    }

    img{
    display :block;
    position :relative;
    border-radius: inherit;

    }
</style>
