




export default {
    namespaced:true,
    state:{
        flexy:{Show:false,PhoneN:'',Amount:'',Total:'',Mode:''},
    },
    getters:{
        flexy(state){
            return state.flexy
        }
    },
    mutations:{
        SET_FLEXY(state , payload){
            state.flexy = payload
        }
    },
    actions:{}
}