<template>
    <div>
        <div v-if="$store.state.auth.user.type == 'admin'" class="pa-2"     >
            <v-row    >

                <v-col class="col-12  mb-2 vuertl" >
                    <v-card   width="100%" img="assets/img/footer-bg.png" class="rounded-xl  py-4" elevation="3">

                        <v-card-actions>
                            <v-row dense no-gutters>
                                <v-col class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0">
                                    <v-list-item class="overflow-hidden" >
                                        <v-list-item-icon class="mx-2">
                                            <vs-avatar size="40" color="primary "  >
                                                <img src="assets/notifications/credit.png" />
                                            </vs-avatar>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <p class="grey--text text--lighten-2 font-weight-bold app-bold">مجموع المدفوعات</p>
                                            <v-list-item-subtitle class="white--text font-weight-bold" v-if="credits.total_solde?credits.total_solde:'0'" v-text="formatToPrice(credits.total_solde?credits.total_solde:'0')"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0">
                                    <v-list-item>
                                        <v-list-item-avatar class="mx-2">
                                            <vs-avatar size="40" color="primary "  >
                                                <img src="assets/notifications/pay.png" />
                                            </vs-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <p class="grey--text text--lighten-2 font-weight-bold app-bold">مجموع الديون</p>
                                            <v-list-item-subtitle class="white--text font-weight-bold" v-if="credits.total?credits.total:'0'" v-text="formatToPrice(credits.total?credits.total:'0')"></v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>


                                <v-col class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0">
                                    <v-list-item>
                                        <v-list-item-avatar class="mx-2">
                                            <vs-avatar size="40" color="primary "  >
                                                <img src="assets/notifications/historique-bonus.png" />
                                            </vs-avatar>
                                        </v-list-item-avatar>
                                        <v-list-item-content>
                                            <p class="grey--text text--lighten-2 font-weight-bold app-bold">مجموع الارباح</p>
                                            <v-list-item-subtitle class="font-weight-bold white--text"  v-text="credits.bonus?credits.bonus:'/'" > </v-list-item-subtitle>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                            </v-row>

                        </v-card-actions>
                    </v-card>
                </v-col>
               <v-col class="col-12">
                   <v-card flat elevation="0" color="transparent" :dark="dark" >
                       <v-tabs v-model="tab" background-color="transparent" :dark="dark"
                               color="orange accent-2"
                                align-with-title
                       >
                        <v-spacer></v-spacer>
                           <v-tab :dark="dark" :class="$store.state.dark?'app-card':'app-card-light'"  class="app-bold rounded-lg ">جميع ديون زبائني</v-tab>
                           <v-tab :dark="dark" :class="$store.state.dark?'app-card':'app-card-light'" class="app-bold rounded-lg mx-1">ديون زبائني اليوم</v-tab>
                           <v-tab :dark="dark" :class="$store.state.dark?'app-card':'app-card-light'" class="app-bold rounded-lg">ديون الادارة</v-tab>
                            <v-tabs-slider style="max-width: 90%;margin: auto"></v-tabs-slider>
                           <v-tab-item      :class="dark?'app-bg':''" :style="{backgroundColor:dark?'#14151A':'#fff'}"

                           >
                           <v-container>
                               <v-row class="justify-center" v-if="credits.hasOwnProperty('data')">

                                   <v-col   class="pa-0 col-12 mt-2" v-for="(item,index) in credits.data" :key="index">

                                       <v-card :dark="$store.state.dark"  color="transparent"  link  tile  @click="handelCredit(item)"  class="rounded-lg overflow-hidden elevation-0 my-1 mx-1 " >

                                           <v-list class="py-0"  color="transparent" :dark="dark" >
                                               <v-list-item :dark="dark"     :class="dark?'app-card':'app-card-light '"     >
                                                   <v-list-item-content class="py-0">

                                                       <v-list-item-title class="mb-1"  >
                                                           <span style="font-size: 16px;font-weight: bold;font-family: sans-serif" class="primary--text text--darken-1">{{item.username}}</span>

                                                       </v-list-item-title>
                                                   </v-list-item-content>
                                                   <v-list-item-content>
                                                       <v-list-item-title >
                                                           <span style="color:#4CAF50;font-size: 20px" class="mt-2 text--h5"><b>{{item.oldCredit}}</b><sub class="mx-2">DA</sub></span>
                                                       </v-list-item-title>
                                                       <v-list-item-subtitle class="app-bold"  >ديون قديمة</v-list-item-subtitle>
                                                   </v-list-item-content>
                                                   <v-list-item-content>
                                                       <v-list-item-title :class="getTextColor(item.status)" class="mt-1"  >
                                                           <span style="font-weight: bold;font-size: 17px;font-family: sans-serif;color: #ED1C24  "><span class="mx-1" v-text="item.operation"></span> {{formatToPrice(item.credit)}} <sub class="mx-2">DA</sub></span>

                                                           <v-list-item-subtitle >
                                                               <span class="app-bold">{{item.updated_at | fromNow}}</span>
                                                           </v-list-item-subtitle>
                                                       </v-list-item-title>

                                                   </v-list-item-content>

                                                   <v-list-item-content class="py-">
                                                       <v-list-item-title >
                                                           <span style="color:#4CAF50;font-size: 20px" class=" text--h5"><b>{{item.total}}</b><sub class="mx-2">DA</sub></span>
                                                       </v-list-item-title>
                                                       <v-list-item-subtitle class="app-bold"  >مجموع الديون</v-list-item-subtitle>


                                                   </v-list-item-content>

                                               </v-list-item>
                                           </v-list>
                                       </v-card>









                                   </v-col>

                               </v-row>
                               <v-row  v-else  align="center" class="justify-center mt-5 ">
                                   <v-col class="col-5" >

                                       <div   >
                                           <h4 style="text-align: center" class="mb-2 app-bold">لا يوجد ديون</h4>
                                           <div  class="mt-8" style="    display: grid;
                                                    justify-content: center;">
                                               <img height="75px" width="75px" src="assets/notifications/credit.png"/>
                                           </div>

                                       </div>
                                   </v-col>
                               </v-row>
                           </v-container>
                           </v-tab-item>
                           <v-tab-item eager   :class="dark?'app-bg':''" :style="{backgroundColor:dark?'#14151A':'#fff'}"
                           >
                               <v-row dense v-if="credits.hasOwnProperty('credit')">

                                       <v-col class="col-12 pt-2" v-for="(item,index) in credits.credit" :key="index">
                                           <v-card    color="transparent"  :dark="$store.state.dark"  tile   class="rounded-lg overflow-hidden elevation-0 my-1 mx-1 " >

                                               <v-list class="py-0">
                                                   <v-list-item     :class="dark?'app-card':'app-card-light '"     >
                                                       <v-list-item-content class="py-0">

                                                           <v-list-item-title class="mb-1"  >
                                                               <span style="font-size: 16px;font-weight: bold;font-family: sans-serif" class="primary--text text--darken-1">{{item.username}}</span>

                                                           </v-list-item-title>
                                                       </v-list-item-content>
                                                       <v-list-item-content>
                                                           <v-list-item-title >
                                                               <span style="color:#4CAF50;font-size: 20px" class="mt-2 text--h5"><b>{{item.oldCredit}}</b><sub class="mx-2">DA</sub></span>
                                                           </v-list-item-title>
                                                           <v-list-item-subtitle class="app-bold"  >ديون قديمة</v-list-item-subtitle>
                                                       </v-list-item-content>
                                                       <v-list-item-content>
                                                           <v-list-item-title :class="getTextColor(item.status)" class="mt-1"  >
                                                               <span style="font-weight: bold;font-size: 17px;font-family: sans-serif;color: #ED1C24  "><span class="mx-1" v-text="item.operation"></span> {{formatToPrice(item.credit)}} <sub class="mx-2">DA</sub></span>

                                                               <v-list-item-subtitle >
                                                                   <span class="app-bold">{{item.updated_at | fromNow}}</span>
                                                               </v-list-item-subtitle>
                                                           </v-list-item-title>

                                                       </v-list-item-content>

                                                       <v-list-item-content class="py-">
                                                           <v-list-item-title >
                                                               <span style="color:#4CAF50;font-size: 20px" class=" text--h5"><b>{{item.total}}</b><sub class="mx-2">DA</sub></span>
                                                           </v-list-item-title>
                                                           <v-list-item-subtitle class="app-bold"  >مجموع الديون</v-list-item-subtitle>


                                                       </v-list-item-content>

                                                   </v-list-item>
                                               </v-list>
                                           </v-card>
                                       </v-col>

                               </v-row>
                               <v-row v-else  align="center" class="justify-center mt-5 ">
                                   <v-col class="col-5" >

                                       <div   >
                                           <h4 style="text-align: center" class="mb-2 app-bold">لا يوجد ديون</h4>
                                           <div  class="mt-8" style="    display: grid;
                                                    justify-content: center;">
                                               <img height="75px" width="75px" src="assets/notifications/credit.png"/>
                                           </div>

                                       </div>
                                   </v-col>
                               </v-row>
                           </v-tab-item>
                           <v-tab-item  :class="dark?'app-bg':''" :style="{backgroundColor:dark?'#14151A':'#fff'}"
                           >
                               <v-row dense v-if="credits.hasOwnProperty('myCredit')">

                                       <v-col class="col-12 pt-2" v-for="(item,index) in credits.length" :key="index">
                                           <v-card color="transparent"   :dark="$store.state.dark"  tile   class="rounded-lg overflow-hidden elevation-0 my-1 mx-1 " >

                                               <v-list class="py-0">
                                                   <v-list-item     :class="dark?'app-card':'app-card-light '"     >
                                                       <v-list-item-content class="py-0">

                                                           <v-list-item-title class="mb-1"  >
                                                               <span style="font-size: 16px;font-weight: bold;font-family: sans-serif" class="primary--text text--darken-1">{{item.username}}</span>

                                                           </v-list-item-title>
                                                       </v-list-item-content>
                                                       <v-list-item-content>
                                                           <v-list-item-title >
                                                               <span style="color:#4CAF50;font-size: 20px" class="mt-2 text--h5"><b>{{item.oldCredit}}</b><sub class="mx-2">DA</sub></span>
                                                           </v-list-item-title>
                                                           <v-list-item-subtitle class="app-bold"  >ديون قديمة</v-list-item-subtitle>
                                                       </v-list-item-content>
                                                       <v-list-item-content>
                                                           <v-list-item-title :class="getTextColor(item.status)" class="mt-1"  >
                                                               <span style="font-weight: bold;font-size: 17px;font-family: sans-serif;color: #ED1C24  "><span class="mx-1" v-text="item.operation"></span> {{formatToPrice(item.credit)}} <sub class="mx-2">DA</sub></span>

                                                               <v-list-item-subtitle >
                                                                   <span class="app-bold">{{item.updated_at | fromNow}}</span>
                                                               </v-list-item-subtitle>
                                                           </v-list-item-title>

                                                       </v-list-item-content>

                                                       <v-list-item-content class="py-">
                                                           <v-list-item-title >
                                                               <span style="color:#4CAF50;font-size: 20px" class=" text--h5"><b>{{item.total}}</b><sub class="mx-2">DA</sub></span>
                                                           </v-list-item-title>
                                                           <v-list-item-subtitle class="app-bold"  >مجموع الديون</v-list-item-subtitle>


                                                       </v-list-item-content>

                                                   </v-list-item>
                                               </v-list>
                                           </v-card>
                                       </v-col>

                               </v-row>
                               <v-row v-else  align="center" class="justify-center mt-5 ">
                                   <v-col class="col-5" >

                                       <div   >
                                           <h4 style="text-align: center" class="mb-2 app-bold">لا يوجد ديون</h4>
                                           <div  class="mt-8" style="    display: grid;
                                                    justify-content: center;">
                                               <img height="75px" width="75px" src="assets/notifications/credit.png"/>
                                           </div>

                                       </div>
                                   </v-col>
                               </v-row>
                           </v-tab-item>
                       </v-tabs>
                   </v-card>
               </v-col>
            </v-row>

            <vs-dialog  width="550px" not-padding  :dark="$store.state.dark"  blur color="#000" prevent-close  v-model="active"  >
                <v-card  :dark="$store.state.dark" flat v-if="credits.length"  append  loader-height="5"  >

                    <v-card-title style="display: block;text-align: center">
                        <h3 class="mt-2 text-center  "  >
                            <span class="fonta text-center" >  Credits</span>
                        </h3>
                    </v-card-title>

                    <v-container>
                        <v-virtual-scroll
                                class="scrolling"
                                :items="credits"
                                height="200"
                                item-height="70"
                        >
                            <template v-slot:default="{ index ,item }">
                                <v-card  :disabled="index != 0"     :dark="$store.state.dark" tile  style="border-radius: 15px!important;" class="card-sharow my-1 mx-1 " >
                                    <v-list class="py-0"  >
                                        <v-list-item    dense     >

                                            <v-list-item-content>
                                                <v-list-item-title >
                                                    <span style="color:#4CAF50;font-size: 20px" class="mt-2 text--h5"><b>{{item.oldCredit}}</b><sub class="mx-2">DA</sub></span>
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="text-center fonta">Ancien crédit</v-list-item-subtitle>
                                            </v-list-item-content>
                                            <v-list-item-content>
                                                <v-list-item-title :class="getTextColor(item.status)"  >
                                                    <span style="font-weight: bold;font-size: 17px;font-family: sans-serif;color: #FF5252  "><span class="mx-1" v-text="item.operation"></span> {{formatToPrice(item.credit)}} <sub class="mx-2">DA</sub></span>

                                                    <v-list-item-subtitle class="fonta">
                                                        {{item.updated_at | fromNow}}
                                                    </v-list-item-subtitle>
                                                    <v-list-item-subtitle class="fonta">
                                                        {{$t('Dernier transfert')}}
                                                    </v-list-item-subtitle>
                                                </v-list-item-title>

                                            </v-list-item-content>

                                            <v-list-item-content class="py-0" v-if="index == 0">
                                                <v-list-item-title >
                                                    <span style="color:#4CAF50;font-size: 20px" class=" text--h5"><b>{{item.total}}</b><sub class="mx-2">DA</sub></span>
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="text-center fonta">Total credit</v-list-item-subtitle>


                                            </v-list-item-content>

                                        </v-list-item>
                                    </v-list>
                                </v-card>
                            </template>
                        </v-virtual-scroll>
                        <v-card-actions>

                            <v-spacer></v-spacer>
                            <vs-button  @click="active = !active"    >
                                <span  class="mx-2">Ok</span>
                            </vs-button>
                        </v-card-actions>
                    </v-container>

                </v-card>
                <v-container   >
                    <v-row dense no-gutters   align="center">
                        <v-col class="col-12 justify-grade">
                            <img src="assets/img/search.svg" width="70px" />

                        </v-col>
                        <v-col class="col-12  " >
                            <p style="text-align: center"   >Pas de données disponibles</p>
                        </v-col>
                    </v-row>
                </v-container>


            </vs-dialog>
        </div >
        <NotFound v-else/>
    </div>
</template>

<script>
    import { formatToPrice, iconR,getTextColor,iconResponse,getColorr,scrollToElement} from "@/tools/Services";
    import strMixins from '@/plugins/Mixins'
    import axios from 'axios'
    import NotFound from "@/components/comp-tool/NotFound.vue";
    export default {
        mixins:[strMixins, NotFound],
        data () {
            return {
                tab:"tab-1",
                date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                bonus:false,
                active:false,
                lastPage: null,
                curentPage: 1,
                singleSelect: false,
                selected: [],
                headers: [
                    {
                        text: 'id',
                        align: 'start',
                        sortable: false,
                        value: 'id',
                    },
                    { text: 'user', value: 'username' },
                    { text: 'Admin', value: 'admin' },
                    { text: 'Credit', value: 'credit' },
                    { text: 'Total', value: 'total' },
                    { text: 'Sms', value: 'message' },
                    { text: 'Status', value: 'status' },
                ],
                credits: [],

            }
        },
        methods:{

            handelCredit(user){
                axios.get('api/credit-user-credit/'+user.username) .then((response)=>{
                    this.credits = response.data.credits
                    this.bonus = false
                    this.active = true
                })
            },


            iconR,getTextColor,iconResponse,getColorr,scrollToElement,formatToPrice,
            paginatePage(pageNumber) {
                axios.get("api/credits-total?page=" + pageNumber).then((response) => {
                    this.credits = response.data

                    // this.lastPage = response.data.last_page;
                    // this.curentPage = response.data.current_page;
                });
            },
            getcredits(){
                axios.get('api/credits-total?page=0').then((response)=>{
                      this.credits = response.data
                    // this.lastPage = response.data.last_page;
                    // this.curentPage = response.data.current_page;
                })
            },
        },
        watch: {
            curentPage(newVal, oldVal) {
                this.paginatePage(newVal)
            },
        },
        created() {
            this.getcredits();
        }
    }
</script>
