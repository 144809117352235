<template>
    <v-card dir="ltr" :color="$store.state.dark?'app-bg':''" flat   class="elevation-0 rounded-lg">

       <v-container>
           <v-card-title class="vuertl" :class="$store.state.params.fontBold">
               إشعــارات
               <v-spacer></v-spacer>
               <v-btn @click="$router.go(-1)"  large   style="float: left!important;" icon depressed><img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>


           </v-card-title>
           <v-card :class="$store.state.dark?'app-card':'app-card-light'"  dir="ltr" :dark="$store.getters.dark"   class="elevation-0 rounded-lg">


               <v-list three-line >

                   <v-list-item-group
                           v-model="selected"
                           :active-class="$store.state.dark?'':'pink--text'"
                           multiple
                   >
                       <template v-for="(item, index) in notification">
                           <v-list-item dense :key="index.id" to="/notify-hi" style="direction: ltr!important;">
                               <template v-slot:default="{ active }">
                                   <vs-avatar class="mx-2">
                                       <img :src="NotificationsImg(item.data.method)" alt="">
                                   </vs-avatar>

                                   <v-list-item-content style="direction: rtl;line-height: normal;">
                                       <v-list-item-title class="app-regular" >

                                           {{item.data.method}}
                                       </v-list-item-title>

                                       <v-list-item-subtitle style="line-height: normal;"
                                                             class="app-regular ">

                                           {{item.data.message}}</v-list-item-subtitle>

                                       <v-list-item-subtitle class="app-regular" v-text="item.status"></v-list-item-subtitle>
                                   </v-list-item-content>

                                   <v-list-item-action>
                                       <v-list-item-action-text class="app-regular"  >{{ (item.created_at) | fromNow }}</v-list-item-action-text>

                                       <v-icon
                                               v-if="!item.read_at!=null"
                                               color="#BDBDBD"
                                       >
                                           mdi-star-outline
                                       </v-icon>
                                       <v-icon
                                               v-else
                                               color="#F9A825"
                                       >
                                           mdi-star
                                       </v-icon>
                                   </v-list-item-action>
                               </template>
                           </v-list-item>

                           <v-divider class="my-0"
                                      v-if="index < notification.length - 1"
                                      :key="index.id"
                           ></v-divider>
                       </template>
                   </v-list-item-group>
               </v-list>



           </v-card>
           <infinite-loading  @infinite="infiniteHandler"  >
               <div :style="{marginTop:!notification.length?'25vh':''}"  class="mb-5 app-bold"  slot="spinner" >جارٍ البحث</div>
               <div     slot="no-more" class="app-bold">لا يوجد المزيد</div>
               <div style="margin-top: 25vh!important;"   class="mb-5 app-bold"  slot="no-results"  >لا يوجد بيانات</div>

           </infinite-loading>
       </v-container>

    </v-card>

</template>

<script>
    import axios from "axios"
    import { bus } from "@/tools/bus";
    import {getColorr, NotificationsImg} from "@/tools/Services";
    export default {
        data() {
            return {
                show:false,
                current_page:1,
                not:true,
                stopUserCommit: false,
                notification: [],
                selected:[]
            };
        },
        computed: {

            filters() {
                let unreaded = this.notification.filter(  (notify) => {
                        return  notify.read_at === null
                    }
                ).length;
                let selecr = this.notification.filter( (notify,index) => {
                    if(notify.read_at === null){
                        this.selected.push(index)
                    }
                });

                if (unreaded > 0) {
                    return unreaded;
                } else return 0;
            },
        },
        methods: {
            getColorr,
            invokUserUpdate(messages) {
                messages.forEach((msg) => {
                    if (

                        msg.data.method === "Versement" &&
                        msg.data.status === "Confirmé" &&
                        msg.read_at === null &&
                        this.stopUserCommit === false
                    ) {

                        let vm = this;
                        vm.$store.dispatch("loadUser");
                        this.stopUserCommit = true;
                    }else if(msg.read_at === null){
                        this.not = false
                    }
                });
            },
            NotificationsImg,
            setToRead() {
                this.not = true
                axios({ url: "api/notify", method: "post" })
                    .then(() => {
                        bus.$emit("updateNotifications");
                    })
                    .then(() => {
                        this.selected = []

                    });
            },
            infiniteHandler($state){
                axios.get('api/notifications-request?page='+this.current_page
                ).then((response)=>{

                    if(response.data.data.length >0){
                        this.current_page +=1
                        this.invokUserUpdate(this.notification);
                        this.notification.push(...response.data.data)
                        $state.loaded()
                    }else{
                        $state.complete();
                    }
                    this.show = true
                }) .catch(()=>{
                    $state.complete();
                })
            },

        },
        beforeMount() {


        },
    };
</script>

<style scoped>
    .pink--text {
        color: #673AB7!important;
        caret-color: #673AB7!important;
    }
</style>

<style scoped>

</style>