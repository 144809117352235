<template>
    <div   >
        <div v-if="$store.state.auth.user.type =='admin'" class="cont pa-3"  style="direction: ltr" >
            <v-card :dark="$store.state.dark"  class="py-3 elevation-0 rounded-xl" v-if="userInf || usersInfOrmation.length >0     ">
                <div class="my3">
                    <TitlePages title="إستهلاكات الزبائن">

                        <v-app-bar-nav-icon slot="btn2" :dark="dark"   @click="$router.go(-1)" large
                                            style="float: left!important;" icon depressed><img
                                :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg"/>
                        </v-app-bar-nav-icon>
                        <v-menu slot="btn"
                                :dark="$store.state.dark"

                                ref="menu"
                                transition="slide-x-transition"
                                bottom
                                right
                                v-model="menu"
                                :close-on-content-click="false"
                                :return-value="dates"

                                min-width="auto"
                        >
                            <template v-slot:activator="{ on }">
                                <vs-button class="app-regular ml-3"  v-on="on"  :color="$store.state.App.config.colorBackground"  icon >
                                    <img class="white-svg" src="assets/icons/vuesax/calendar.svg">
                                </vs-button>

                            </template>

                            <v-date-picker
                                    range
                                    v-model="dates"
                                    no-title
                                    scrollable
                                    color="#03A9F4"
                            >
                                <v-spacer></v-spacer>

                                <v-btn
                                        text  class="app-regular"

                                        color="#BEBEBE"
                                        @click="menu = false"
                                >
                                    إغلاق
                                </v-btn>

                                <v-btn :disabled="dates[1] > getNow "
                                       text class="app-regular"
                                       color="#03A9F4"
                                       @click="getadminAchatArray(dates)"
                                >
                                    بحث
                                </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </TitlePages>
                    <v-row class="pa-0" >
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="warning"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center;position:relative">


                                    <span> @Balance</span>

                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                         <span class="grand-number"   >
                                                             <animated-number
                                                                     :value="userInf.today.solde"
                                                                     :formatValue="formatToPrice"

                                                                     :duration="1000"
                                                             />
                                                         </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" > الحساب</span>
                                    <p class="app-regular">
                                         الفوائد
                                        <span>{{userInf.today.bonus}}</span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>


                        </v-col>
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="primary"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center">
                                    <span> @Credit Total</span>
                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"  >
                                                       <animated-number
                                                               :value="userInf.today.credit_total"
                                                               :formatValue="formatToPrice"

                                                               :duration="1000"
                                                       />
                                                 </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" >مجموع الديون</span>
                                    <p class="app-regular">
                                        <span v-text="dates==''?'اليوم':'بين التواريخ'"></span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="purple"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center">

                                    <span> @Cartes</span>
                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"  >
                                                       <animated-number
                                                               :value="userInf.today.card_total"
                                                               :formatValue="formatToPrice"

                                                               :duration="1000"
                                                       />
                                                 </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" >مجموع إيدوم</span>
                                    <p class="app-regular">
                                        <span v-text="dates==''?'اليوم':'بين التواريخ'"></span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="success"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center">

                                    <span> @Idoom</span>
                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"   >
                                                         <animated-number
                                                                 :value="userInf.today.idoom_total"
                                                                 :formatValue="formatToPrice"

                                                                 :duration="1000"
                                                         />
                                                 </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" >مجموع إيدوم</span>
                                    <p class="app-regular">
                                        <span v-text="dates==''?'اليوم':'بين التواريخ'"></span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>

                        <v-col class="col-12">
                            <vs-table stripped :dark="$store.state.dark" class="mx-2"
                                    v-model="selected"
                            >
                                <template #header>
                                  <h3 style="text-align:center" class="app-regular">جدول يبين حسابات كل مشتربات الزبائن</h3>
                                </template>
                                <template #thead>
                                    <vs-tr>

                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'day')">
                                             <span class="app-regular" >تاريخ</span>
                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'flexy_total')">
                                            <span class="app-regular" >مجموع الفليكسي</span>
                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'mobilis_total')">
                                            <span class="app-regular" > مجموع موبيليس</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'mobilis')">
                                            <span class="app-regular" >موبيليس</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'pixx')">
                                            <span class="app-regular" >بيكس</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'ooredoo')">
                                            <span class="app-regular" >أوريدو</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'djezzy')">
                                            <span class="app-regular" >جيزي</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'card_total')">
                                            <span class="app-regular" >بطاقات</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'idoom_total')">
                                            <span class="app-regular" >إيدوم</span>

                                        </vs-th>
                                        <vs-th sort @click="usersInfOrmation = $vs.sortData($event ,usersInfOrmation, 'versement_total')">
                                        <span class="app-regular" >إيداعات</span>

                                        </vs-th>
                                        <vs-th  >
                                            <span class="app-regular" >المجموع</span>

                                        </vs-th>
                                    </vs-tr>
                                </template>
                                <template #tbody>
                                    <vs-tr
                                            :key="i"
                                            v-for="(tr, i) in $vs.getPage($vs.getSearch(usersInfOrmation, search), page, max)"
                                            :data="tr"
                                            :is-selected="!!selected.includes(tr)"
                                            not-click-selected
                                            open-expand-only-td
                                    >

                                        <vs-td>
                                            <b class="app-regular ">{{ tr.day | dateTable}}</b>
                                        </vs-td>
                                        <vs-td>
                                            <vs-button success transparent :active="true">{{ tr.flexy_total }}</vs-button>
                                        </vs-td>
                                        <vs-td>
                                            <vs-button primary transparent :active="true">{{ tr.mobilis_total }}</vs-button>


                                        </vs-td>
                                        <vs-td>
                                             <b>{{tr.mobilis}}</b>
                                        </vs-td>
                                        <vs-td>

                                            <b>{{tr.pixx}}</b>

                                        </vs-td>
                                        <vs-td>
                                            <b>{{tr.ooredoo}}</b>

                                        </vs-td>
                                        <vs-td>

                                            <b>{{tr.djezzy}}</b>
                                        </vs-td>
                                        <vs-td>
                                             <b>{{tr.card_total}}</b>
                                        </vs-td>
                                        <vs-td>
                                             <b>{{tr.idoom_total}}</b>
                                        </vs-td>
                                        <vs-td>
                                            <vs-button danger transparent :active="true">{{ tr.versement_total }}</vs-button>

                                        </vs-td>
                                        <vs-td>
                                            <vs-button success transparent :active="true">{{ Number(tr.flexy_total) + Number(tr.mobilis_total) + Number(tr.mobilis) + Number(tr.ooredoo) + Number(tr.djezzy) +  Number(tr.card_total) + Number(tr.idoom_total) }}</vs-button>

                                        </vs-td>
<!--                                        <template #expand>-->
<!--                                            <div class="con-content">-->
<!--                                                <div>-->
<!--                                                    <vs-avatar>-->
<!--                                                        <img :src="`/avatars/avatar-${i + 1}.png`" alt="">-->
<!--                                                    </vs-avatar>-->
<!--                                                    <p>-->
<!--                                                        {{ tr.name }}-->
<!--                                                    </p>-->
<!--                                                </div>-->
<!--                                                <div>-->
<!--                                                    <vs-button flat icon>-->
<!--                                                        <i class='bx bx-lock-open-alt' ></i>-->
<!--                                                    </vs-button>-->
<!--                                                    <vs-button flat icon>-->
<!--                                                        Send Email-->
<!--                                                    </vs-button>-->
<!--                                                    <vs-button border danger>-->
<!--                                                        Remove User-->
<!--                                                    </vs-button>-->
<!--                                                </div>-->
<!--                                            </div>-->
<!--                                        </template>-->
                                    </vs-tr>
                                </template>
                                <template #notFound>
                                    <h3 class="app-regular">لا توجد بيانات </h3>
                                </template>
                                <template #footer>
                                    <vs-pagination v-model="page" :length="$vs.getLength($vs.getSearch(usersInfOrmation, search), max)" />
                                </template>
                            </vs-table>
                        </v-col>
                    </v-row>
                </div>
            </v-card>

        </div>
        <NotFound v-else/>
    </div>
</template>

<script>

     import {mapState} from "vuex";
    import {comparDates,formatToPrice} from "@/tools/Services.js";
    import TitlePages from "@/layout/TitlePages";
    import AnimatedNumber from "animated-number-vue";
    import NotFound from "@/components/comp-tool/NotFound.vue";
    import axios from 'axios'
    export default {
        components: {  AnimatedNumber,TitlePages , NotFound  },
        computed:{

            ...mapState([
                'dark'
            ]),
            getNow: function() {
                const date = new Date()
                return date.toISOString().split('T')[0]
            },
        },
        data(){
            return {
                editActive: false,
                edit: null,
                editProp: {},
                search: '',
                allCheck: false,
                page: 1,
                max: 31,
                active: 0,
                selected: [],
                userInf:[],
                usersInfOrmation:[],
                dates:[],
                menu:false,
                date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

            }
        },

        mounted() {
            this.getadminAchat(this.date)
         },
        methods: {comparDates,
            formatToPrice,
            async  getadminAchat(date){
                await      axios.post('api/admin-achat-date',{dates:date}).then((response)=>{
                    this.userInf = response.data
                    this.getadminAchatArray([this.date,this.date])

                })
            },
            async  getadminAchatArray(dates){

                if(comparDates(dates)){
                    await      axios.post('api/admin-achat-date-array',{dates:dates}).then((response)=>{
                        this.usersInfOrmation = response.data.total
                        this.menu = false
                    })
                }else {
                    this.menu = false
                    this.$vs.notification({
                        classNotification:"vuertl",
                        icon:'<img  class="white-svg" src="assets/icons/vuesax/alert.svg" />',
                        title: 'حدث خطأ',
                        position:"top-center",
                        color:"danger",
                        text:"تحقق من بداية التاريخ حتى النهاية",
                        duration:"3000"

                    })
                }


            },

        },

    }
</script>
<style scoped>
    .con-image{
        border-radius: inherit
    }
    .image-st{
        display :block;
        position :relative;
        border-radius :inherit;
        max-width :850px
    }
    .grand-number{
        font-size: 2.05rem;
        font-weight: 600;
        font-family: app-regular;
    }
    .text-info{
        font-weight: 100;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.4375rem;
    }
</style>