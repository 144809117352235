<template>
   <v-card flat>
       <v-card-title style="direction: rtl" class="app-regular"><router-link :to="broadCrump.link">{{broadCrump.broadCrump}}</router-link>
           <span :style="{color:this.$store.state.params.bg }" style="font-size: 18px">{{this.$store.state.params.AppName  }}{{' / '}}</span>
       </v-card-title>

   </v-card>

</template>

<script>
    export default {
        name: "BreadCrup",
        computed:{
            broadCrump:function () {
                return this.$store.state.broadCrump
            }
        }
    }

</script>

<style scoped>

</style>