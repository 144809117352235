<template>
    <div>

      <v-container  v-if="$store.state.auth.user.type == 'admin'" >
        <vs-button @click="newModel()" block class="app-regular  ">
          إضافة بوست جديد <i class="fas fa-credit-card nav-icon"></i>
        </vs-button>
        <div class="center mt-2" >
          <v-card flat class="pa-5 rounded-xl" :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
            <vs-table :dark="$store.state.dark" class="app-regular  "  >
              <template #thead >
                <vs-tr dark>
                  <vs-th>
                    تغيير / حذف
                  </vs-th>
                  <vs-th>
                    الصورة
                  </vs-th>
                  <vs-th>
                    التفعيل
                  </vs-th>
                  <vs-th>
                    الاسم
                  </vs-th>

                </vs-tr>
              </template>
              <template #tbody>
                <vs-tr
                    v-for="cart in card" :key="cart.id">

                  <vs-td>
                    <v-row class="app-regular">
                      <v-col class="col-6">
                        <vs-button flat icon block
                                   @click="editModel(cart)"
                        >
                          تغيير
                        </vs-button>

                      </v-col>
                      <v-col class="col-6">
                        <vs-button block  danger @click="deleteCard(cart.id)">
                          حذف
                        </vs-button>
                      </v-col>
                    </v-row>
                  </vs-td>
                  <vs-td>
                    <vs-avatar style="cursor: pointer" size="38" @click="redirect(cart.slug,cart.title)">
                      <img style="height: 18px" src="assets/icons/vuesax/blog.svg" :class="$store.state.dark?'white-svg':'black-svg'">
                    </vs-avatar>

                  </vs-td>
                  <vs-td>
                    <vs-switch style="width: 60px;" success v-model="cart.isActive==1?true:false" @click="updateisActive(cart)">


                      <template #off>
                        <i class='bx bx-x' ></i>
                      </template>
                      <template #on>
                        <i class='bx bx-check' ></i>
                      </template>
                    </vs-switch>



                  </vs-td>
                  <vs-td>
                    {{ cart.title }}
                  </vs-td>

                </vs-tr>
              </template>
              <template #notFound>
                <span class="app-regular" :class="$store.state.params.color">لا توجد بيانات</span>
              </template>

            </vs-table>
          </v-card>
        </div>



        <!-- /.content -->
        <v-dialog transition="v-fab-transition" fullscreen    v-model="groupeShow" style="height: 100%!important;" >
          <v-card  :dark="$store.state.dark">
            <v-container>
              <v-card-title>
                <div class="vuertl app-regular"  :dark ="$store.state.dark">
                  <v-card-title v-text="editMode?'تغيير البطاقة':'إظافة بطاقة'"></v-card-title>

                  <v-card-text>
                    <form @submit.prevent="editMode ? updateCard() : addCart()">
                      <v-row>
                        <v-col class="col-lg-6 col-sm-6 col-xs-12 col-6">
                          <v-text-field v-model="form.title" :dark ="$store.state.dark"
                                        type="text" placeholder="اسم البوست" outlined dense
                                        name="title" >

                          </v-text-field>
                        </v-col>
                        <v-col class="col-lg-6 col-sm-6 col-xs-12 col-6">
                          <v-text-field  :dark ="$store.state.dark"  @click.prevent="pick" style="cursor: pointer;"
                                         :placeholder="form.image!=''?'تم التحميل':'حمل الصورة'"
                                         outlined
                                         :success="form.image!=''"
                                         dense
                                         append-icon="mdi-file-image-outline"
                                         hint="تحقق من حجم الصورة  180 * 320 "
                          ></v-text-field>

                        </v-col>
                        <v-col class="col-lg-6 col-sm-6 col-xs-12 col-6">
                          <v-text-field v-model="form.subtitle" :dark ="$store.state.dark"
                                        type="text" placeholder="العنوان المصغر" outlined dense
                                        name="subtitle" >

                          </v-text-field>
                        </v-col>
                        <v-col class="col-lg-6 col-sm-6 col-xs-12 col-6">
                          <v-text-field v-model="form.slug" :dark ="$store.state.dark"
                                        type="text" placeholder="رابط البوست " outlined dense
                                        name="text" >

                          </v-text-field>
                        </v-col>
                        <v-col class="col-12">
                          <VueEditor v-model="form.text"></VueEditor>

                        </v-col>
                      </v-row>
                      <div class="modal-body">

                        <div>

                          <input v-show="false"
                                 type="file" ref="test"
                                 name="image"
                                 @change="uploadImage"
                                 class="form-control"
                                 :class="{ 'is-invalid': form.errors.has('method') }"
                          />
                        </div>
                      </div>
                      <div class="modal-footer app-medium">
                        <v-spacer></v-spacer>

                        <v-btn :loading="form.busy" :dark ="$store.state.dark"
                               v-show="editMode"
                               type="submit" class="white--text"
                               color="#03A9F4"
                        >
                          تحديث التغييرات
                        </v-btn>
                        <v-btn :loading="form.busy" :disabled="form.name == '' || form.image =='' " :dark ="$store.state.dark"
                               v-show="!editMode"
                               type="submit" class="white--text"
                               color="#4CAF50"
                        >
                          إضافة
                        </v-btn>
                        <v-btn :dark ="$store.state.dark" class=" mr-2" type="button" data-dismiss="modal" @click="groupeShow = !groupeShow">
                          اغلاق
                        </v-btn>
                      </div>
                    </form>
                  </v-card-text>
                </div>
              </v-card-title>
            </v-container>
          </v-card>
        </v-dialog>
      </v-container>
      <NotFound v-else/>
    </div>




</template>
<script>
    import Form from 'vform'
    import axios from 'axios';
    import { VueEditor } from "vue2-editor";
    import {setBaseUrl} from "@/tools/Services";
    import NotFound from "@/components/comp-tool/NotFound.vue";
    export default {
        components:{VueEditor , NotFound},

        data() {
            return {
                groupeShow:false,
                color: "#4CAF50",
                editMode: false,
                form: new Form({
                    id: "",
                    title: "",
                    subtitle: "",
                    slug: "",
                    image: "",
                    text: "",
                }),
                card: {},
                imagee: "",
                imageMode: 0,
                type: window.user,
            };
        },
        watch:{
          'form.title':function (newVal,oledVal) {
              this.form.slug = newVal.replaceAll(" ",'-')
          }
        },
        mounted() {

            this.loadcard();



        },
        methods: {setBaseUrl,
            redirect(slug,title){
                this.$store.commit('SET_BROADCRUMP',{title:title,broadCrump:'المدونة',link:'/blog'})
                this.$router.push({name:"HomeBlogDetail",params:{slug:slug}})
            },
            pick(){
                this.$refs.test.click()
            },
            updateisActive(game) {

                axios({
                    method: "post",
                    url: "api/update-is-active-blog/",
                    data:game,
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }).then(() => this.loadcard());
            },
            showImage() {
                (this.imageMode = 0), (this.imageMode = 1);
                this.groupeShow = !this.groupeShow
            },
            uploadImage(e) {
                let file = e.target.files[0];

                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if (file["size"] > limit) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "You are uploading a large file",
                    });
                    return false;
                }
                reader.onloadend = (file) => {
                    this.form.image = reader.result;
                };
                reader.readAsDataURL(file);

            },
            updateCard() {

                this.form
                    .post("api/blog-admin-update")
                    .then(() => {
                        this.groupeShow = !this.groupeShow
                        this.$store.dispatch("loadsna", {
                            text: 'تم تحديث البوست بنجاح',
                            isSuccess: true,
                        });
                        this.loadcard()

                    })
                    .catch(() => {
                        this.$store.dispatch("loadsna", {
                            text: "حدث خطأ ما يرجى اعادة المحاولة",
                            isSuccess: false,
                        });
                    });
            },
            newModel() {
                this.editMode = false;
                this.form.reset();
                this.groupeShow = !this.groupeShow
            },

            editModel(cart) {
                this.editMode = true;
                this.form.reset();
                this.groupeShow = !this.groupeShow
                this.form.fill(cart);
            },

            loadcard() {
                axios.get("api/get-admin-blog").then((response) => (this.card = response.data));
            },

            addCart() {
                this.form
                    .post("api/blog-admin")
                    .then(() => {
                        this.groupeShow = !this.groupeShow
                        this.form.reset();
                        this.loadcard()
                        this.$store.dispatch("loadsna", {
                            text: 'تم إظافة المنتج بنجاح',
                            isSuccess: true,
                        });



                    })
                    .catch(() => {
                        this.$store.dispatch("loadsna", {
                            text: "حدث خطأ ما يرجى اعادة المحاولة",
                            isSuccess: false,
                        });
                    });
            },
            deleteCard(id) {
                Swal.fire({
                    title: "حذف البوست",
                    text: " هل أنت متأكد",
                    type: "warning",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "نعم، قم بالحذف",
                }).then((result) => {
                    if (result.value) {
                        this.form
                            .delete("api/blog-admin/" + id)
                            .then((response) => {
                                this.loadcard()
                                this.$store.dispatch("loadsna", {
                                    text: response.data.message,
                                    isSuccess: true,
                                });


                            })
                            .catch((error) => {
                                this.$store.dispatch("loadsna", {
                                    text: error.response.data.message,
                                    isSuccess: false,
                                });
                            });
                    }
                });
            },
        },
    };
</script>
