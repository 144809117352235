<template>
    <v-bottom-sheet  v-model="sheet" style="z-index: 1501"     >
        <v-sheet :dark="dark"    v-touch="options"
                 class="text-center rounded-xl p-2"


                 v-vuesax
        >


                <v-card-title class="vuertl pb-0 pt-2 app-regular"  >
                    <h4 :class="dark?'white--text':'black--text'" style="text-align: right">
                        روابط</h4>
                    <v-spacer></v-spacer>
                    <v-btn @click="sheet = !sheet" style="float: left!important;" icon depressed><img
                            :class="dark?'white-svg':'black-svg'"
                            src="assets/icons/vuesax/close-1.svg"/></v-btn>


                </v-card-title>

                <v-card class="overflow-hidden rounded-xl py-0 " v-vuesax flat :dark="dark"

                >

                    <v-card-text class="pa-0">
                        <v-list   :dark="dark" class="py-0" v-vuesax >

                                <v-list-item dense :to="item.link" @click="sheet = !sheet"
                                             v-for="(item, i) in sidbar"
                                             :key="i"
                                >
                                    <v-list-item-icon>
                                        <img :class="dark?'white-svg':'black-svg'" width="50px" :src="item.icon"/>
                                    </v-list-item-icon>
                                    <v-list-item-content class="text-start">
                                        <v-list-item-title   >
                                            <p style="font-family: app-regular" class="mb-1  " v-text="item.title"></p>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-action>
                                        <img class="mt-1" style="width: 18px" :class="dark?'white-svg':'black-svg'"
                                             width="50px" src="assets/icons/vuesax/chevron-left.svg"/>
                                    </v-list-item-action>
                                </v-list-item>

                        </v-list>
                    </v-card-text>
                </v-card>




        </v-sheet>

    </v-bottom-sheet>
</template>

<script>
    import {bus} from "@/tools/bus";

    export default {
        data: () => ({

            sheet: false,
            options:{
                down:()=>{
                   bus.$emit('bottom-sheet')
                }
            }

        }),
        watch:{
            sheet:function (newVal) {
                if(newVal){

                }
            }
        },
        methods:{
            closeSheet(){
                this.sheet = !this.sheet
            }
        },
        computed:{
            sidbar:function(){
              return this.$store.state.sidbar.SidbarItems
            },
            dark:function () {
                return this.$store.state.dark
            }
        },
        mounted() {
            bus.$on('bottom-sheet',()=>{
                let vm = this;
                vm.sheet = !vm.sheet
            })
        }
    }
</script>

