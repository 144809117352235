<template>
     <v-card class="vuertl" style="height:100vh" :color="dark?'app-bg':''">
  
  <v-container >
          <TitlePages title="الملف الشخصي">
              <v-btn slot="btn" @click="$router.go(-1)"   large   style="float: left!important;" icon depressed><img style="width: 32px" :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>

          </TitlePages>

      <v-card flat elevation="0" color="transparent" :dark="dark" >
          <v-tabs v-model="tab" background-color="transparent" :dark="dark"
                  color="orange accent-2"
                  right align-with-title
          >

              <v-tab  :class="$store.state.dark?'app-card':'app-card-light'"  class="app-bold rounded-lg ml-2 overflow-hidden ">المعلومات الشخصية</v-tab>
              <v-tab  :class="$store.state.dark?'app-card':'app-card-light'"  class="app-bold rounded-lg overflow-hidden">تغيير كلمة السر</v-tab>
              <v-tabs-slider style="max-width: 90%;margin: auto"></v-tabs-slider>

              <v-tab-item      :class="dark?'app-bg':''"

              >
                  <v-container fluid>
                      <v-expansion-panels  flat class="mt-3 app-regular "   >
                          <v-expansion-panel readonly   v-for="(config ,index) in configData" :key="index"   class="mt-4"
                                             :class="auth.user.phone != undefined && config.name =='خروج'?'dztechno-color':dark?'app-card':'app-card-light '"  style="border-radius: 14px;">
                              <v-expansion-panel-header @click.prevent="config.onClick?config.onClick():''"
                                  hide-actions
                                  class="rounded-xl"
                                  style="height: 65px;text-align: right;" >

                                  <div>
                                      <v-btn icon  >
                                          <img :class="dark || config.name =='خروج'?'white-svg':'black-svg'" :src="'assets/icons/vuesax/'+config.icon"/>
                                      </v-btn>
                                      {{ config.name }}
                                  </div>

                                  <v-spacer></v-spacer>
                                  <span v-if="config.title != null" style="text-align: left">{{ config.title }}</span>

                              </v-expansion-panel-header>
                              <v-expansion-panel-content v-if="!config.disabled">
                                  <div v-text="config.text">

                                  </div>
                              </v-expansion-panel-content>
                          </v-expansion-panel>
                      </v-expansion-panels>
                  </v-container>
              </v-tab-item>
              <v-tab-item  :class="dark?'app-bg':''"

              >
                  <v-container fluid>
                    <v-card color="transparent" flat elevation="0" class="pt-0" :dark="dark">
                        <v-card-title  >
                            تغيير كلمة السر
                            <v-spacer></v-spacer>

                        </v-card-title>
                        <v-card-subtitle>
                            <v-spacer></v-spacer>
                            تحقق من المعلومات المدخلة لتغيير كلمة السر
                        </v-card-subtitle>
                        <v-card-title class="pt-0">
                            <v-row>
                                <v-col class="col-lg-6 col-sm-6 col-md-6 col-xs-12 col-12">
                                    <v-text-field  type="password" filled hide-details solo flat
                                                   v-model="form.password"
                                                   class="mt-3 app-regular rounded-lg input-class overflow-hidden"
                                                   :class=" dark?'black--text':'white--text'"
                                                   :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                                   placeholder="كلمة السر الحالية">
                                        <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/secure.svg" >
                                    </v-text-field>
                                </v-col>
                                <v-col class="col-lg-6 col-sm-6 col-md-6 col-xs-12 col-12  ">
                                    <v-text-field  type="password" filled hide-details solo flat
                                                   v-model="form.new_password"
                                                   class="mt-3 app-regular rounded-lg input-class overflow-hidden"
                                                   :class=" dark?'black--text':'white--text'"
                                                   :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                                   placeholder="كلمة السر الجديدة">

                                        <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/password.svg" >


                                    </v-text-field>
                                </v-col>
                                <v-col class="col-lg-6 col-sm-6 col-md-6 col-xs-12 col-12">
                                    <v-text-field  type="password" filled hide-details solo flat
                                                   v-model="form.new_password_confirmation"
                                                   class=" app-regular rounded-lg input-class overflow-hidden"
                                                   :class=" dark?'black--text':'white--text'"
                                                   :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                                   placeholder="إعادة كلمة السر الجديدة">
                                        <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/password.svg" >
                                    </v-text-field>
                                </v-col>
                                <v-col class="col-lg-6 col-sm-6 col-md-6 col-xs-12 col-12  ">
                                <v-card-actions class="pt-0" >
                                    <vs-button  color="rgb(254, 44, 85)"  large block
                                           @click="editpassword"

                                           :loading="form.busy"
                                           class="rounded-lg white--text "
                                    >
                                        <img class="px-2 white-svg" src="assets/icons/vuesax/send.svg"/>
                                       تغيير كلة السر

                                    </vs-button>
                                </v-card-actions>
                                </v-col>
                            </v-row>




                        </v-card-title>
                    </v-card>
                  </v-container>
              </v-tab-item>
          </v-tabs>
      </v-card>


 
 </v-container>
 </v-card>
</template>

<script>
import { mapState } from 'vuex'
import * as auth from "@/plugins/authUser";
import { formatToPrice } from '@/tools/Services';
import TitlePages from "@/layout/TitlePages";
import Form from 'vform'
    export default {
        components: {TitlePages},
        data(){
            return{
                form: new Form({
                    password: "",
                    new_password: "",
                    new_password_confirmation: "",
                }),
                tab:"tab-1",
                activeBtnSolde:'Solde',
                configData:[
     
                   ]
            }
        },
        methods:{
            editpassword: async function () {
                
                try {


                    const response = await  this.form.post('api/change-pass')
                    this.form.reset();
                    await this.$store.dispatch("loadsna", {
                        text: "تم تغيير كلمة السر بنجاح",
                        isSuccess: true,
                    });

                    
                } catch (e) {
                    switch (e.response.status) {
                        case 429:
                          
                            await this.$store.dispatch("loadsna", {
                                text: e.response.data.message,
                                isSuccess: false,
                            });
                            this.form.reset();
                            break;
                        default:

                            await this.$store.dispatch("loadsna", {
                                text: "حدث خطأ ما أعد المحاولة",
                                isSuccess: false,
                            });
                            this.form.reset();

                    }
                }
            },
          formatToPrice,
        },
        computed:{
    ...mapState(['auth','dark']),
    params:function(){
    return this.$store.state.params
  },
    dark:function(){
    return this.$store.state.dark
  },
  },
        created(){
            this.configData = [
                {
                    name:'إسم المستخدم',
                    icon:'users.svg',
                    disabled:false,
                    title:this.auth.user.username,
                },
            {
            name:'رقم الهاتف',
            icon:'mobile.svg',
            disabled:false,
            title:this.auth.user.phone,
            },
            {
            name:'البريد الالكتروني',
            icon:'mail.svg',
            disabled:false,
            title:this.auth.user.email,
            },
            {
            name:'حسابي',
            icon:'wallet.svg',
            disabled:false,
            title:this.formatToPrice(this.auth.user.solde)    ,
            },
                {
                    name:'أرباحي',
                    icon:'money.svg',
                    disabled:false,
                    title:this.formatToPrice(this.auth.user.bonus)    ,
                },
            {
            name:'تاريخ انشاء الحساب',
            icon:'date.svg',
            disabled:false,
            title:  this.auth.user.created_at  ,
             },
            {
            name:'خروج',
            icon:'out.svg',
            disabled:true,
            title:  '' ,
            onClick:()=>{
              auth.logOut()

             location.href = "/"

            }}
            ]
        }
    }
</script>

<style scoped>
    div.vs-table__th__content{
        display: grid;
        justify-content: center;
        font-size: 15px;
    }
    input label .vs-input__label{
        font-size: 15px!important;
    }
    .vs-table__th__content{
        text-align: start!important;
        display: block!important;
    }
    .arabic-rtl{
        direction: initial;
        justify-content: center;
        text-align: center;
        display: inherit;
    }
    .telecom-text-success{
        color: #20B471!important;
    }
    .telecom-text-primary{
        color: #0D407D!important;
    }
    .telecom-bg-success{
        background-color: #20B471!important;
    }
    .telecom-db-primary{
        background-color: #0D407D!important;
    }
    #text-field-edit  {
        text-align: center!important;
        font-size: 15px!important;

    }
    #text-field-card  {
        text-align: center;
        font-size: 15px;


    }
    #text-field-card::placeholder {
        font-size: 16px;
    }
    .vs-avatar{
        background: transparent!important;
    }
    .new-purple{
        color: #4100f4!important;
    }
    .v-menu__content{
        border-radius: 2px 2px 2px 2px!important;
    }
    .v-select__slot{
        display: inline-block;
        text-align: center!important;
    }
    .v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-input__icon--append {
        transform: none;
    }
    .v-dialog {
        border-radius: 25px!important;
    }
    .active-class{
        border: 2px solid #000!important;
    }
    .purple-grad{
        color: #fff!important;
        background: linear-gradient(262deg, rgba(35,125,231,1) 0%, #4100f4 100%);
        border: 1px solid #fff;
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05abe0', endColorstr='#8200f4',GradientType=1 );
        transition-duration:0.6s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    .grey-grad{

        background:#E2E4E7
    }
    .transparent-grad{

        background: transparent!important;

    }
    .selector{

        border-radius:50px;
        transition-duration:0.6s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

        background: #05abe0;
        background: -moz-linear-gradient(45deg, #05abe0 0%, #4100f4 100%);
        background: -webkit-linear-gradient(45deg, #05abe0 0%,#4100f4 100%);
        background: linear-gradient(45deg, #05abe0 0%,#4100f4 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05abe0', endColorstr='#8200f4',GradientType=1 );
    }
    .v-text-field > .v-input__control > .v-input__slot:before {
        border-style: none;
    }

    .disable-btn{
        background:#EEF2F5!important;
        color:#000!important;
    }
    .enable-btn{
        background: #2d2d39 !important;
        color: #e7e7e7 !important;
    }
    .v-messages__wrapper .v-messages__message{
        text-align: center!important;
    }
    .active-b{
        transition: none!important;
        border:2px solid #fff!important;
        border-radius:20px!important;
        color:#fff!important;
    }
    .v-dialog__content {
        z-index: 1002 !important;
    }
    .bottom-sheet-radius{
        border-top-left-radius: 20px!important;
        border-top-right-radius: 20px!important;
    }
    .vs-loading__load__text{
        font-family:inherit;
    }
</style>