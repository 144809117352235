<template>

       <CurvedBottomNavigation  replace-route  :options="options"  style="z-index:120!important"
                               v-model="selected"
                               v-if="$vuetify.breakpoint.smAndDown"
                               :foreground-color="$store.state.App.config.color"
                               :badge-color="params.color"
                               :background-color="dark?'#2d2d39':'#EEF2F5'"
                               :icon-color='dark?"#fff":params.color'   >
           <template #icon="{ props }"    >
               <div  @click="switchOff(props.path)" >
                   <img :class='dark?"white-svg":"black-svg"' class="mt-2" :src="props.icon"/>
               </div>
           </template>
           <template #title="{ props }" >
               <b  class="app-regular">{{ props.title }}</b>
            </template>

       </CurvedBottomNavigation>


</template>

<script>
    import { mapState } from 'vuex';
    import { CurvedBottomNavigation } from "bottom-navigation-vue";
    import {bus} from "@/tools/bus";

    export default {
        components: { CurvedBottomNavigation },
        data (){
    return{
      selected: 3,
      options: [
        {
          id: 1,
          icon: "assets/icons/vuesax/settings.svg",
          title: "خصائض",
          path:'',

        },
        {
          id: 2,
          icon: "assets/icons/vuesax/shopping.svg",
          title: "مشتريات",
          path: '/historique-cartes',
        },
         {
          id: 3,
          icon: "assets/icons/vuesax/shopping-cart.svg",
          title: "بطاقات",
          path: '/',
        },
        {
          id: 4,
          icon: "assets/icons/vuesax/book.svg",
          title: "إيدوم",
          path:'/idoom',
        },
        {
          id: 5,
          icon: "assets/icons/vuesax/mobile.svg",
          title: "فليكسي",
          path: { name: "Flexy" },
        },
      ],
        open:"",
      active: '/',
      activeSidebar:false,
          }
           },

        methods:{
          switchOff(val){
            if (this.selected == 1 || (this.selected == 3 && val == "" )  ){
              this.$store.commit('open/SET_SIDE',true)
              localStorage.setItem("sidbar",JSON.parse(true))
            }else{
              this.$router.push('/login')
            }

          }
        },
        computed:{
            ...mapState({
                user:'auth/user',params:'params',dark:'dark'
            }),

          },
        mounted() {
            this.open = localStorage.getItem("side")=="true"?true:false

          if (this.$store.state.auth.user){
            this.options.childs = [
              { id: 301, icon: "assets/icons/vuesax/plus.svg", title: "روابط",onClick:function () {
                  bus.$emit('switchSidbar')                  } },
              { id: 302, icon: "assets/img/logo.svg", title: this.user != null ?this.user.solde:'خدمات',path:'/info'  }
            ]
          }
        }

    }
</script>

<style  scoped>
    .btn-container_foreground{
        position: fixed!important;
        bottom: 0px!important;
        left: 0px!important;
        right: 0px!important;
    }
</style>