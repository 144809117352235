<template>
</template>
<script>
import axios from "axios";
export default {
  name: "MobileMessageDialog",
  methods:{
    closeBotton(){
      axios.post('api/btn-close').catch(()=>{})
    },
    loadmessages(){
      axios.get('api/new-message').then(response=>{
        if (response.data.message != "" ){
          const notification = this.$vs.notification({
            classNotification:'arabic',
            title: response.data.title,
            text: response.data.message,
            duration:'none',
            border:response.data.color,
            icon:`<img class="${this.$store.state.dark?'white-svg':'black-svg'}" src="assets/img/logo.svg">`,
            color:response.data.background,
            position:response.data.position,
            buttonClose:true,
            onDestroy: () => {
              notification.close()
              let vm = this;
              vm.closeBotton()
            },
            onClick:()=>{
              notification.close()
              let vm = this;
              vm.closeBotton()
            }
          })
        }
      })
    },
  },
  created(){
    setTimeout(()=>{
      if(this.$store.state.auth.user){
        this.loadmessages()
      }
    },
        10000)
  }

}
</script>
