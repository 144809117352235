import axios from "axios";

export default {
    namespaced: true,
    state:{
            data:{
                data:[],
                caroucel_show:false,
            },
    },
    getters:{
        data(state){
            return state.data
        },

 
    },
    mutations:{

        SET_DATA (state, value) {
            state.data = value


        },
     
    },
    actions:{
        loadcaroucel({commit}) {
           setTimeout(()=>{
               axios.get("api/get-slider").then((response) => {
                   commit('SET_DATA',response.data)
               }).catch((err)=>{

               });
           },2000)
        }
    }
  
}