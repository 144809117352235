
<template>

        <div  >
            <v-card   class=" mx-2 rounded-xl px-1"  flat :dark="$store.state.dark" >
                <v-row  >
                    <v-col :class="message!=null?'':'py-0'" class="col-12 mt-3" @click="handelAlert()" v-if="message!=null && showAlert && message.msgActive== 'true' && $vuetify.breakpoint.mdAndUp" >
                        <vs-alert  closable  style="direction: rtl;z-index:1"
                                  :class="[message.msgRelief=='true'?'vs-alert--relief':'',message.msgGradient=='true'?'vs-alert--gradient':'']"
                                  class="app-regular for-allert rounded-lg"
                                  v-text="message.msgFlexy"
                                  :warn="message.msgColor=='warn'"
                                  :primary="form.msgColor=='primary'"
                                  :danger="message.msgColor=='danger'"
                        >
                        </vs-alert>
                    </v-col>

                    <v-col class="col-12 py-0  " v-show="$vuetify.breakpoint.mdAndUp" >
                        <CardOfFlexy :pricesToday="responsePrice" :flexy-card="flexyCard" />
                    </v-col>
                   <v-col class="col-12  ">
                    <v-card   flat class="mt-5">
                        <v-row   >
                            <v-app-bar dark app   v-if="$vuetify.breakpoint.smAndDown && $store.state.auth.user" flat hide-on-scroll class="app-regular" color="transparent"   >
                              <v-app-bar-nav-icon   @click="$router.go(-1)"  large   style="float: left!important;background-color: transparent" icon depressed><img  src="assets/icons/vuesax/chevron-right.svg" /></v-app-bar-nav-icon>


                              <v-spacer></v-spacer>
                              <v-slide-group      :dark="$store.state.dark" center-active
                                             style="max-width: 100% ;text-align: center;display: block;justify-content: center!important;"  class="pa-0" max="10"  >
                                <v-slide-item >
                                  <vs-button to="/info"  :dark="dark" class="ml-0      "  block primary gradient size="lg"  v-if="user!= null"          >

                                    <sub class="mx-1 app-regular">دج</sub>

                                    <span style="direction: ltr;font-size: 17px" class="  mr-2  "  ><b>{{  " " +  formatToPrice(user.solde)}}</b></span>
                                    <span class="app-regular">حسابي </span>
                                  </vs-button>

                                </v-slide-item>
                                <v-slide-item >
                                  <vs-button to="/historique-bonus" :dark="dark" class="ml-0   text--accent-3  " block gradient size="lg"  v-if="user!= null"       :color="$store.state.App.config.colorBackground"     >


                                    <sub class="mx-1 app-regular">دج</sub>

                                    <span style="direction: ltr;font-size: 17px;font-family:sans-serif" class="  mr-2  "  ><b>{{  " " +  formatToPrice(user.bonus)}}</b></span>
                                    <span class="app-regular">أرباحي </span>

                                  </vs-button>

                                </v-slide-item>
                                <v-slide-item >
                                  <vs-button to="/historique-bonus" :dark="dark" class="ml-0   blue-grey--text text--lighten-2  " dark gradient block size="lg"  v-if="user!= null"    border   flat   >
                                    <sub class="mx-1 app-regular">دج</sub>

                                    <span style="direction: ltr;font-size: 17px;font-family:sans-serif" class="  mr-2  "  ><b>{{  " " +  formatToPrice(user.credit)}}</b></span>
                                    <span class="app-regular">ديوني </span>

                                  </vs-button>
                                </v-slide-item>
                              </v-slide-group>
                              <v-spacer></v-spacer>
                            </v-app-bar>

                            <v-col class="col-lg-12 pa-0 "  :class="$vuetify.breakpoint.mdAndUp?'':'py-1'"  >

                                <v-slide-group center-active       v-model="form.Amount" :dark="$store.state.dark"
                                        style="max-width: 100%"  class="pa-0" max="10"


                                >
                                    <v-slide-item
                                            v-for="(number,index) in allActivation"
                                            :key="index"
                                            v-slot="{ active, toggle }"

                                    >
                                        <v-btn   :retain-focus-on-click="false" @click="form.Amount = number.amount,toggle"
                                                :disabled="disableBtn(number.amount) "
                                                :class="form.Amount == number.amount?'primary':''"
                                                class=" rounded-lg elevation-0 mr-2" min-width="90px"
                                                :color="$store.state.dark?'#14151a':'#EEF2F5'"
                                                  height="50px">
                                                    <span
                                                            :style="{color:$store.state.dark?'#fff':'#000'}"
                                                            style="font-size: 23px" class="text-primary-darken-4 "
                                                            :class="form.Amount == number.amount?'white--text':''"
                                                    ><b v-text="number.amount" class="app-bold"></b> </span>


                                        </v-btn>

                                    </v-slide-item>
                                </v-slide-group>

                            </v-col>
                            <v-col class="col-lg-12 py-1  " style="height:100%">
                                <v-row
                                        justify="center"
                                        align-content="center"

                                >
                                    <v-col  class="col-12  ">
                                        <v-card class="rounded-xl pa-2" elevation="0" :class="$store.state.dark?'app-card':'app-card-light'"  >
                                            <form @submit.prevent="newModell">
                                                <div >
                                                    <v-row  dense  >
                                                        <v-col class="col-lg-5  col-sm-8 col-12 pb-1">
                                                            <v-card elevation="0"  :style="{
                                                              backgroundColor: $store.state.dark === true ? '#000' : '#fff',
                                                            color: $store.state.dark === true ? '#fff' : '#000',
                                                             }" :ripple="false"  :dark="$store.state.dark"   style="border-radius: 22px" >
                                                                <v-card-title :style="{
                                                        backgroundColor: $store.state.dark === true ? '#25252f' : '#fff',
                                                        color: $store.state.dark === true ? '#fff' : '#2d2d39',fontFamily:'sans-serif'
                                                      }" class="py-1" style="direction:rtl">
                                                                    <img class="ml-2" :class="dark?'white-svg':'black-svg'" style="height: 25px" src="assets/icons/vuesax/mobile.svg" />
                                                                    <span  class="app-regular">
                                                        رقم الهاتف
                                                        </span>
                                                                    <v-spacer></v-spacer>
                                                                  <v-tooltip top  :color="dark?'#000':''">
                                                                    <template v-slot:activator="{on , attrs}">
                                                                      <v-btn
                                                                          v-bind="attrs"
                                                                          v-on="on"
                                                                          small color="#FFBA00" :outlined="form.PhoneN.length != 10"  :class="form.PhoneN.length == 10?'white--text':''"   depressed class="rounded-lg app-regular  " @click="checkOffers()" :loading="OffersMode.busy"  >
                                                                        <span v-text="OffersMode.busy?'':'عروض '"></span>

                                                                        <img class=" mr-2" :class="form.PhoneN.length == 10?'white-svg':'orange-svgg'" style="height: 18px" src="assets/icons/vuesax/star.svg" />
                                                                      </v-btn>
                                                                    </template>
                                                                    <span class="app-regular vuertl">أدخل رقم الهاتف و قم بالضغط هنا لمعرفة <span><b>عروض الشريحة</b></span></span>

                                                                  </v-tooltip>




                                                                </v-card-title>
                                                                <v-progress-linear class="mx-auto"   height="2" bottom v-if="focused"
                                                                                   :indeterminate="focused"
                                                                                   :color="$store.state.dark?'#8564F7':'#55c226'"
                                                                ></v-progress-linear>

                                                                <input  style="width: 100%"
                                                                        :style="{
                                            backgroundColor: $store.state.dark === true ? '#25252f' : '#fff',
                                            color: $store.state.dark === true ? '#fff' : '#2d2d39',fontFamily:'sans-serif'
                                          }"
                                                                        type="tel"
                                                                        autocomplete="off"

                                                                        v-model="form.PhoneN"
                                                                        pattern="[0]{1}[5-7]{1}[0-9]{8}"
                                                                        class="form-control fonta-2  shipili-mobile app-regular"
                                                                        maxlength="10"
                                                                        placeholder="0x xx xx xx xx"
                                                                        name="PhoneN"
                                                                        :required="true"
                                                                />

                                                            </v-card>
                                                        </v-col>
                                                        <v-col class="col-lg-4 col-sm-4 col-12 pb-1">
                                                            <v-card  elevation="0"  :dark="$store.state.dark"  style="border-radius: 22px" class="       ">
                                                                <v-card-title :style="{
                                            backgroundColor: $store.state.dark === true ? '#25252f' : '#fff',
                                            color: $store.state.dark === true ? '#fff' : '#2d2d39',fontFamily:'sans-serif'
                                          }" class="py-1"  style="direction:rtl">
                                                                    <img class="ml-2" :class="dark?'white-svg':'black-svg'" style="height: 25px" src="assets/icons/vuesax/money.svg" />

                                                                    <span   class="app-regular">
                                                            المبلغ
                                                           </span>
                                                                    <v-spacer></v-spacer>
                                                                  <v-tooltip top  :color="dark?'#000':''">
                                                                    <template v-slot:activator="{on , attrs}">
                                                                  <vs-switch   v-bind="attrs"
                                                                               v-on="on"
                                                                               type="span" :value="isDirectMode" warn @click="isDirectMode = true" class="app-regular"    :color="$store.state.params.bg"  style="width: 90px;z-index:2">

                                                                           <template #off>
                                                                             <span class="mx-1">غير مفعل</span>

                                                                           </template>
                                                                           <template #on>
                                                                             <span class="mx-1">فليكسي عادي</span>


                                                                         </template>


                                                                  </vs-switch>
                                                                    </template>
                                                                    <span class="app-regular vuertl">بعد التفعيل يمكنك الفليكسي مباشرة بالضغط على <span><b class="mr-2">إرسال</b></span></span>

                                                                  </v-tooltip>

                                                                </v-card-title>
                                                                <input style="width: 100%"
                                                                       :style="{
                                            backgroundColor: $store.state.dark === true ? '#25252f' : '#fff',
                                            color: $store.state.dark === true ? '#fff' : '#2d2d39',fontFamily:'sans-serif'
                                          }"
                                                                       type="number"
                                                                       v-model="form.Amount"
                                                                       :max="100000"
                                                                       :min="40"
                                                                       class="form-control fonta-2   shipili-mobile app-regular"
                                                                       placeholder="0.00"
                                                                       maxlength="4"
                                                                       name="Amount"
                                                                       required

                                                                />
                                                            </v-card>
                                                        </v-col>
                                                        <v-col class="col-lg-3 col-sm-12 col-12 pb-1">
                                                            <v-btn   class="primary rounded-xl" :class="$store.state.dark?'app-card-lighten rounded-lg':'app-card-light'"
                                                                     ripple
                                                                     @click="showContacts()"
                                                                     role="button"
                                                                     type="submit"
                                                                     :style="{height:$vuetify.breakpoint.smAndDown?'90px':'120px'}"
                                                                     style="width:100%; font-size: 50px;border-radius: 22px" >
                                                                <span style="font-size: 22px" class="app-medium white--text text-capitalize">إرسال</span>
                                                                <img class="white-svg" style="height: 35px" src="assets/icons/vuesax/send.svg">
                                                            </v-btn>
                                                        </v-col>




                                                    </v-row>



                                                </div>

                                            </form>
                                        </v-card>
                                    </v-col>
                                    <v-col class="col-12 pt-0"  :class="$vuetify.breakpoint.xs?'px-0':''" >
                                        <HistoriqueFlexy/>

                                    </v-col>

                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card>


                   </v-col>
                </v-row>
            <v-overlay v-if="dialogo"
                        color="#3c3e5f" @click="dialogo = false"
                        opacity="0.20"
                        :value="dialogo" style="z-index:128!important"
                        :style="{backdropFilter:dialogo?'blur(3px)':''}"   >
            <v-dialog hide-overlay class="hide-border rounded-lg" activator="hide-border"

                    style="z-index: 128"
                    v-model="dialogo"

                    max-width="800"
                    :dark="dark"
                    :fullscreen="$vuetify.breakpoint.smAndDown"
                    :transition="$vuetify.breakpoint.smAndDown?'slide-x-transition':false"
            >
                <v-card :color="dark?'app-card':''"
                        :dark="dark"

                        :class="$vuetify.breakpoint.smAndDown?'':'rounded-xl'"
                        class=" vuertl p-0 "
                        max-width="800"



                >
                    <v-card-title   class="headline pb-0 mb-0">
                        <v-btn @click="dialogo = !dialogo"  :dark="dark" icon class="ml-2 " large     >
                            <img style="width: 35px" :class="dark ? 'white-svg' : 'black-svg'"
                                 src="assets/icons/vuesax/close.svg" />
                        </v-btn>
                           <span class="success--text font-whight-bold">{{form.Amount}}</span>
                         <v-spacer></v-spacer>
                        <span  class="vueltr font-whight-bold" >{{ form.PhoneN | splitNumber }}</span>
                    </v-card-title>

                    <div v-if="form.Amount >=5000">

                    </div>
                    <div v-else>
                        <v-container v-if="OffersMode.Offers != ''" >
                            <v-row class="justify-content-center"  align="center" dense>
                                <v-col class="col-sm-12 col-md-6 col-lg-6 col-12 col-xs-12"   v-for="(offer,index) in OffersMode.Offers.data" :key="index"  >
                                    <v-card

                                            :class="selected == offer.name?'primary white--text' :dark?'':'app-card-light'"

                                            class="rounded-xl app-regular elevation-0"

                                            :dark="dark"
                                            @click="changeModeOffers(offer)"

                                    >
                                        <v-card-title class="py-2">
                                            <div>
                                                <v-card-subtitle
                                                        :class="selected == offer.name?'primary white--text' : ''"
                                                        class="py-0">{{ form.price }}
                                                </v-card-subtitle>

                                                <p :class="selected == offer.name?'white--text' : 'text-web-purple '"
                                                   style="margin-bottom: 0;">{{offer.type}}</p>
                                            </div>
                                            <v-spacer></v-spacer>
                                            <h6 class=" ml-2"  >{{offer.name}}</h6>
                                            <div>
                                                <vs-avatar circle  >
                                                    <img :src="OffersMode.Offers.image">
                                                </vs-avatar>
                                            </div>
                                        </v-card-title>
                                    </v-card>
                                </v-col>








                            </v-row>
                            </v-container>
                        <v-container  v-else >
                            <v-row class="justify-content-center"  align="center">

                                <div
                                        style="direction:rtl"
                                        v-if="OperateurChar() == 6"
                                        class="col-12"
                                        v-model="form.Mode"
                                >
                                    <v-row justify="center" style="justify-content: center" dense>
                                        <v-col class="col-sm-12 col-md-6 col-lg-6 col-12 col-xs-12" >
                                            <v-card

                                                    :class="selected == 'Normal'?'primary white--text' :dark?'':'app-card-light'"

                                                    class="rounded-xl app-regular elevation-0"

                                                    :dark="dark"
                                                    @click="changeMode('Normal')"

                                            >
                                                <v-card-title class="py-1">
                                                    <div>
                                                        <v-card-subtitle
                                                                :class="selected == 'Normal'?'primary white--text' : ''"
                                                                class="py-0">{{ form.Amount }}
                                                        </v-card-subtitle>

                                                        <p :class="selected == 'Normal'?'white--text' : 'text-web-purple '"
                                                           style="margin-bottom: 0;">Mobilis</p>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                    <h6 class=" ml-2"  >Normal</h6>
                                                    <div>
                                                        <vs-avatar circle  >
                                                            <img :src="mobilis.image">
                                                        </vs-avatar>
                                                    </div>
                                                </v-card-title>
                                            </v-card>
                                        </v-col>
                                        <v-col class="col-sm-12 col-md-6 col-lg-6 col-12 col-xs-12" >
                                            <v-card

                                                    :class="selected == 'Facturation'?'primary white--text' :dark?'':'app-card-light'"

                                                    class="rounded-xl app-regular elevation-0"

                                                    :dark="dark"
                                                    @click="changeMode('Facturation')"

                                            >
                                                <v-card-title class="py-1">
                                                    <div>
                                                        <v-card-subtitle
                                                                :class="selected == 'Facturation'?'primary white--text' : ''"
                                                                class="py-0">{{ form.Amount }}
                                                        </v-card-subtitle>

                                                        <p :class="selected == 'Facturation'?'white--text' : 'text-web-purple '"
                                                           style="margin-bottom: 0;">Mobilis</p>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                    <h6 class=" ml-2"  >Facturation</h6>
                                                    <div>
                                                        <vs-avatar circle  >
                                                            <img :src="mobilis.image">
                                                        </vs-avatar>
                                                    </div>
                                                </v-card-title>
                                            </v-card>
                                        </v-col>
                                        <v-col class="col-sm-12 col-md-6 col-lg-6 col-12 col-xs-12"  v-if="form.Amount == mobi.amount"
                                               v-for="mobi in mobilis.modes"
                                               :key="mobi.mode">
                                            <v-card

                                                    :class="selected == mobi.mode?'primary white--text' :dark?'':'app-card-light'"

                                                    class="rounded-xl app-regular elevation-0"

                                                    :dark="dark"
                                                    @click="changeMode(mobi.mode)"

                                            >
                                                <v-card-title class="py-1">
                                                    <div>
                                                        <v-card-subtitle
                                                                :class="selected == mobi.mode?'primary white--text' : ''"
                                                                class="py-0">{{ mobi.amount }}
                                                        </v-card-subtitle>

                                                        <p :class="selected == mobi.mode?'white--text' : 'text-web-purple '"
                                                           style="margin-bottom: 0;">Mobilis</p>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                    <h6 class=" ml-2"  >{{mobi.mode}} </h6>
                                                    <div>
                                                        <vs-avatar circle  >
                                                            <img :src="mobilis.image">
                                                        </vs-avatar>
                                                    </div>
                                                </v-card-title>
                                            </v-card>
                                        </v-col>


                                    </v-row>
                                </div>
                                <div
                                        style="direction:rtl"
                                        v-if="OperateurChar() == 7"
                                        class="col-12"
                                        v-model="form.Mode"
                                >
                                    <v-row justify="center" style="justify-content: center" dense>
                                        <v-col class="col-sm-12 col-md-6 col-lg-6 col-12 col-xs-12" >
                                            <v-card

                                                    :class="selected == 'Normal'?'primary white--text' :dark?'':'app-card-light'"

                                                    class="rounded-xl app-regular elevation-0"

                                                    :dark="dark"
                                                    @click="changeMode('Normal')"

                                            >
                                                <v-card-title class="py-1">
                                                    <div>
                                                        <v-card-subtitle
                                                                :class="selected == 'Normal'?'primary white--text' : ''"
                                                                class="py-0">{{ form.Amount }}
                                                        </v-card-subtitle>

                                                        <p :class="selected == 'Normal'?'white--text' : 'text-web-purple '"
                                                           style="margin-bottom: 0;">Djezzy</p>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                    <h6 class=" ml-2"  >Normal</h6>
                                                    <div>
                                                        <vs-avatar circle  >
                                                            <img :src="djezzy.image">
                                                        </vs-avatar>
                                                    </div>
                                                </v-card-title>
                                            </v-card>
                                        </v-col>
                                        <v-col class="col-sm-12 col-md-6 col-lg-6 col-12 col-xs-12" >
                                            <v-card

                                                    :class="selected == 'Facturation'?'primary white--text' :dark?'':'app-card-light'"

                                                    class="rounded-xl app-regular elevation-0"

                                                    :dark="dark"
                                                    @click="changeMode('Facturation')"

                                            >
                                                <v-card-title class="py-1">
                                                    <div>
                                                        <v-card-subtitle
                                                                :class="selected == 'Facturation'?'primary white--text' : ''"
                                                                class="py-0">{{ form.Amount }}
                                                        </v-card-subtitle>

                                                        <p :class="selected == 'Facturation'?'white--text' : 'text-web-purple '"
                                                           style="margin-bottom: 0;">Djezzy</p>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                    <h6 class=" ml-2"  >Facturation</h6>
                                                    <div>
                                                        <vs-avatar circle  >
                                                            <img :src="djezzy.image">
                                                        </vs-avatar>
                                                    </div>
                                                </v-card-title>
                                            </v-card>
                                        </v-col>
                                        <v-col class="col-sm-12 col-md-6 col-lg-6 col-12 col-xs-12"
                                               v-for="djeez in djezzy.modes" v-if="form.Amount == djeez.amount"
                                               :key="djeez.mode">
                                            <v-card

                                                    :class="selected == djeez.mode?'primary white--text' :dark?'':'app-card-light'"

                                                    class="rounded-xl app-regular elevation-0"

                                                    :dark="dark"
                                                    @click="changeMode(djeez.mode)"

                                            >
                                                <v-card-title class="py-1">
                                                    <div>
                                                        <v-card-subtitle
                                                                :class="selected == djeez.mode?'primary white--text' : ''"
                                                                class="py-0">{{ djeez.amount }}
                                                        </v-card-subtitle>

                                                        <p :class="selected == djeez.selected?'white--text' : 'text-web-purple '"
                                                           style="margin-bottom: 0;">Djezzy</p>
                                                    </div>
                                                    <v-spacer></v-spacer>
                                                    <h6 class=" ml-2"  >{{djeez.mode}} </h6>
                                                    <div>
                                                        <vs-avatar circle  >
                                                            <img :src="djezzy.image">
                                                        </vs-avatar>
                                                    </div>
                                                </v-card-title>
                                            </v-card>
                                        </v-col>


                                    </v-row>
                                </div>
                              <div
                                  style="direction:rtl"
                                  v-if="OperateurChar() == 5"
                                  class="col-12"
                                  v-model="form.Mode"
                              >
                                <v-row justify="center" style="justify-content: center" dense>
                                  <v-col class="col-sm-12 col-md-6 col-lg-6 col-12 col-xs-12" >
                                    <v-card

                                        :class="selected == 'Normal'?'primary white--text' :dark?'':'app-card-light'"

                                        class="rounded-xl app-regular elevation-0"

                                        :dark="dark"
                                        @click="changeMode('Normal')"

                                    >
                                      <v-card-title class="py-1">
                                        <div>
                                          <v-card-subtitle
                                              :class="selected == 'Normal'?'primary white--text' : ''"
                                              class="py-0">{{ form.Amount }}
                                          </v-card-subtitle>

                                          <p :class="selected == 'Normal'?'white--text' : 'text-web-purple '"
                                             style="margin-bottom: 0;">Ooredoo</p>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <h6 class=" ml-2"  >Normal</h6>
                                        <div>
                                          <vs-avatar circle  >
                                            <img :src="ooredoo.image">
                                          </vs-avatar>
                                        </div>
                                      </v-card-title>
                                    </v-card>
                                  </v-col>
                                  <v-col class="col-sm-12 col-md-6 col-lg-6 col-12 col-xs-12" >
                                    <v-card

                                        :class="selected == 'Facturation'?'primary white--text' :dark?'':'app-card-light'"

                                        class="rounded-xl app-regular elevation-0"

                                        :dark="dark"
                                        @click="changeMode('Facturation')"

                                    >
                                      <v-card-title class="py-1">
                                        <div>
                                          <v-card-subtitle
                                              :class="selected == 'Facturation'?'primary white--text' : ''"
                                              class="py-0">{{ form.Amount }}
                                          </v-card-subtitle>

                                          <p :class="selected == 'Facturation'?'white--text' : 'text-web-purple '"
                                             style="margin-bottom: 0;">Ooredoo</p>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <h6 class=" ml-2"  >Facturation</h6>
                                        <div>
                                          <vs-avatar circle  >
                                            <img :src="ooredoo.image">
                                          </vs-avatar>
                                        </div>
                                      </v-card-title>
                                    </v-card>
                                  </v-col>
                                  <v-col class="col-sm-12 col-md-6 col-lg-6 col-12 col-xs-12"
                                         v-for="ooree in ooredoo.modes" v-if="form.Amount == ooree.amount"
                                         :key="ooree.mode">
                                    <v-card

                                        :class="selected == ooree.mode?'primary white--text' :dark?'':'app-card-light'"

                                        class="rounded-xl app-regular elevation-0"

                                        :dark="dark"
                                        @click="changeMode(ooree.mode)"

                                    >
                                      <v-card-title class="py-1">
                                        <div>
                                          <v-card-subtitle
                                              :class="selected == ooree.mode?'primary white--text' : ''"
                                              class="py-0">{{ ooree.amount }}
                                          </v-card-subtitle>

                                          <p :class="selected == ooree.selected?'white--text' : 'text-web-purple '"
                                             style="margin-bottom: 0;">Ooredoo</p>
                                        </div>
                                        <v-spacer></v-spacer>
                                        <h6 class=" ml-2"  >{{ooree.mode}} </h6>
                                        <div>
                                          <vs-avatar circle  >
                                            <img :src="ooree.image">
                                          </vs-avatar>
                                        </div>
                                      </v-card-title>
                                    </v-card>
                                  </v-col>
                                </v-row>
                              </div>
                            </v-row>
                        </v-container>
                    </div>

                        <v-card-actions :class="$vuetify.breakpoint.smAndDown?'small-size':''" >
                            <vs-button  color="rgb(254, 44, 85)"    class=" app-regular rounded-xl"
                                   @click.passive.stop="TopUp()"
                                   role="button"
                                   :disabled=" selected ==''"
                                   type="submit"
                                   style="height: 60px!important; width:100%; font-size: 42px"
                            >
                                <v-img
                                        class="mx-2"
                                        max-width="45px"
                                        alt="shipili"
                                        src="/assets/img/logo.png"></v-img>
                                <span style="font-size: 22px">Payer</span><sup style="font-size: 10px" class="px-4 text-capitalize">{{form.Mode}}</sup>
                            </vs-button>
                        </v-card-actions>


                </v-card>
            </v-dialog>
                </v-overlay>
             <v-overlay   :dark="dark"   v-if=" dialog" style="z-index:128!important; backdrop-filter: blur(3px)!important">
            <v-card    eager  class="overflow-hidden " content-class="rounded-xl" :dark="dark"
                       style="z-index:1000"
                       v-model="dialog"
                       :persistent="form.busy"
                       :max-width="$vuetify.breakpoint.smAndDown?'600':'400'"

            >

                <v-card      :color="dark?'app-card':'app-card-light'"

                             :dark="dark"
                >

                    <v-toolbar flat elevation="0" v-if="form.busy" :dark="dark"  :color="$store.state.dark?'app-card':'app-card-light'">
                        <vs-avatar :loading="form.busy"  >
                            <v-img :src="'images/flexy/'+opperator+'_carte.png'"/>
                        </vs-avatar>
                        <v-spacer></v-spacer>
                        <span style="font-size: 15px;direction: rtl" class="app-bold">إنتظر ، الهاتف قيد الشحن ... </span>


                    </v-toolbar>

                    <v-bottom-navigation v-else style="box-shadow: none" hide-on-scroll   flat mandatory grow  v-if="respDialog != null  && respDialog.Status != 'Confirmé'"
                                         :dark="dark"
                                         class="app-regular"
                    >
                        <v-btn  @click="TopUp(1)" :disabled="firstClick == 3">
                            <span class="app-regular" >إعادة المحاولة</span>
                            <img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/repeat.svg">

                        </v-btn>
                        <v-btn v-if="respDialog.solde!=null">
                            <span class="app-regular">رصيدي</span>

                            <span class="text-web-success">{{respDialog.solde}}</span>
                        </v-btn>

                        <v-btn @click="dialog = false ">
                            <span  class="app-regular">إغلاق</span>

                            <img height="24" :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close-1.svg">
                        </v-btn>
                    </v-bottom-navigation>


                    <v-progress-linear :active="form.busy"  height="2"
                                       indeterminate  :color="$store.state.dark?'#8564F7':'warning'"
                                       class="mb-0 pt-1"
                    ></v-progress-linear>
                    <v-card-text class="py-0">
                        <v-list-item v-if="forma !=null">

                            <v-list-item-content>
                                <v-list-item-title style="text-align:start;font-family: sans-serif" class="h5 app-bold" >{{form.PhoneN}}</v-list-item-title>
                                <v-list-item-subtitle style="font-family: sans-serif"  class="text-capitalize app-regular">{{opperator +' ' +form.Mode}}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <h3><strong class="success--text" style="font-family: sans-serif">{{form.Amount}}<span style="font-size: 15px">{{' DA'}}</span> </strong></h3>
                            </v-list-item-action>
                        </v-list-item>
                    </v-card-text>
                </v-card>
                <v-expand-transition v-if="respDialog != null"  >
                    <v-card   class="rounded-xk mt-1" flat tile

                              :dark="$store.state.dark"
                    >
                        <v-toolbar  flat elevation="0" :color="getColorr(respDialog.Status)" >
                            <v-spacer></v-spacer>
                            <v-icon x-large color="#fff" style="font-size: 60px"   v-text="iconR(respDialog.Status)" ></v-icon>
                            <v-spacer></v-spacer>
                        </v-toolbar>

                        <v-card-text class="p-0 pb-0">
                            <v-list-item>
                                <v-list-item-content class="pb-0">

                                    <p style="text-align: center" class="app-regular "><strong  >{{respDialog.response}}</strong></p>
                                </v-list-item-content>

                            </v-list-item>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn text block class="v-btn--active rounded-lg text-capitalize app-regular" :color="getColorr(respDialog.Status)"  flat depressed @click="closeDial"  >
                                <strong>موافق</strong>
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-expand-transition>

            </v-card>
                </v-overlay>

            <v-fab-transition  >
                <v-btn :dark="$store.state.dark" v-if="user != null"
                    to="/historique-flexy"
                       style="z-index: 126  ;bottom: 180px!important;right: 18px!important;"
                       class="text-decoration-none " :color="!$store.state.dark?'app-card':'app-card-light'"


                       fixed
                       bottom
                       right
                       fab
                >
                    <img style="width:50px"   src="assets/notifications/search.png">
                </v-btn>
            </v-fab-transition>
            <RightSidBar :prices.sync="pricesToday"/>
            <ContactSidBar v-if="user != null"/>
            </v-card>
        </div>
        </div>



</template>

<style scoped>

    .hide-border{
        border-radius: 20px!important;
    }
    .shipili-mobile{height:80px!important;font-size:40px!important;text-align:center!important;border:none!important;width:100%!important;}

</style>


<script>
     import CardOfFlexy from "@/components/CardOfFlexy";
     import Form from 'vform'
    import axios from 'axios'
    const ContactSidBar  = ()=> import (/* webpackPrefetch: true */ "@/components/fordesktop/ContactSidBar");
    import { bus } from "@/tools/bus";
     import {totalParce, formatToPrice,getTextColor , getColorr , iconR,getColorClass,splitName} from "@/tools/Services";
    import HistoriqueFlexy from "@/views/HistoriqueFlexy";
    import RightSidBar from "@/components/RightSidBar";
    import strMixis from '@/plugins/Mixins'

    export default {
        components: {RightSidBar, HistoriqueFlexy ,CardOfFlexy,ContactSidBar},
        mixins:[strMixis],
        computed:{
          active(newVal){
            if (newVal == false){
              localStorage.setItem('hideAllert','true')
              return true

            }else{
              return  false
            }

          },
            focused(){
                return this.form.PhoneN.length !=10 && this.form.PhoneN!=''
            },

        },
        data() {
            return {
                active:false,
                showAlert:true,
                model:null,
                allActivation:[{amount:"50",selected:'203'},{amount:"100",selected:'204'},
                    {amount:"200",selected:'205'},{amount:"500",selected:'206'},{amount:"650",selected:'207'},
                    {amount:"1000",selected:'208'},{amount:"1500",selected:'209'},{amount:"2000",selected:'210'},
                    {amount:"2500",selected:'211'},{amount:"3000",selected:'212'},{amount:"3500",selected:'13'},
                    {amount:"4000",selected:'14'},{amount:"5000",selected:'0122'},{amount:"10000",selected:'523'},{amount:"15000",selected:'204'},
                    {amount:"20000",selected:'205'},{amount:"25000",selected:'206'},{amount:"30000",selected:'207'},
                    {amount:"35000",selected:'207'},{amount:"40000",selected:'207'},
                    {amount:"50000",selected:'208'},{amount:"100000" } ],
                activeLoading:false,
                firstClick:0,
                isDirectMode:false,
                pricesToday:[],
                flexyCard:[],
                respDialog:null,
                showCard:false,
                color:this.$store.state.dark == true?'rgba(244,244,246,0.7)':'rgb(205 192 192)',

                djezzy: [],
                ooredoo: [],
                mobilis:[],
                dialogo: false,
                dialog: false,
                form: new Form({
                    PhoneN: "",
                    Amount: "",
                    Mode: "",
                }),
                OffersMode: new Form({
                    PhoneN: "",
                    Offers: "",
                }),
                responsePrice: [],
                solde:'',
                remise: "",
                selected: undefined,
                miniPrice: 40,
                sendqueues: [],
                message:null,
                HandelAlert:false,
                serveur:{
                    mobilis:true,
                    djezzy:true,
                    ooredoo:true,
                },
                opperator:'',
                forma:{
                    PhoneN: "",
                    Amount: "",
                    Mode: "",


                },

            };
        },

        created() {

            setTimeout(()=>this.getprice(),1500)
            if(this.$store.state.prixFlexy == null){
                // this.solde = this.$store.state.user.solde
            }
            if (this.$store.state.contacts ==null){
                this.$store.dispatch('getContacts')
            }

            this.showCard = localStorage.getItem('flexy_cards')==='true'?true:false


          if(localStorage.getItem('hideAllert') == "true"){

            this.showAlert = false
          }


        },

        watch: {
          dialogo:function (newVal) {
            if(this.dialogo == false){
              this.$store.commit('SET_ICARDACTIVE',false)
            }
          },
            dialog:function () {
                if (this.dialog == false){
                    this.form.reset()

                }
            },
            '$store.state.selectedContact'(newVal){
                this.form.PhoneN = ''
                this.form.PhoneN = newVal
            },
            "form.Amount": function (newVal){
                if(this.$store.state.auth.user.categorie == "NORMAL" || this.$store.state.auth.user.categorie == "COMMERCANT"){
                    if(Number(newVal) >4000){
                        this.form.Amount = '4000'
                    }
                }else{
                    if(Number(newVal) >100000){
                        this.form.Amount = '100000'
                    }
                }
            },
            "form.PhoneN": function (newVal) {
                const vm = this;


                if (newVal.length >=1){
              //      vm.$store.dispatch('loadontacts',newVal)
                }else if(newVal.length == 10){

                    this.form.PhoneN = this.$store.state.selectedContact
                }else if (newVal.length == ''){
             //       this.$store.dispatch('loadontacts',null)
                }
            },
        },
        methods: { splitName,
          disableBtn(amount){
           if(amount > 4000 && this.$store.state.auth.user.categorie =='NORMAL' || amount > 4000 && this.$store.state.auth.user.categorie =='COMMERCANT' || this.$store.state.auth.user.categorie == "new"){
            return  true
           }else{
            return  false
           }
          },
          handelAlert(){
            localStorage.setItem('hideAllert',"true")
            this.showAlert = false
          },
            checkOffers(){
                if(this.$store.state.auth.user != null && this.form.PhoneN.length ==10){
                    this.OffersMode.PhoneN = this.form.PhoneN
                    this.OffersMode.post('api/check-mode-flexy').then((response)=>{
                      if(response.data.data.length >=1){
                        this.OffersMode.Offers = response.data
                        this.dialogo = true
                        this.isDirectMode = false
                      }
                    }).catch((e)=>{
                        this.$vs.notification({
                            icon:`<img class="white-svg" src="assets/icons/vuesax/close-1.svg" />`,
                            classNotification:'vuertl',
                            color:"danger",
                            position:'top-center',
                            duration:'3000',
                            progress: 'auto',
                            title: 'عروض الشريحة',
                            text: e.response.data.message || "حدث خطأ ما / أو الشريحة الزبون لا تقبل التحقق من العروض "
                        })
                        this.isDirectMode = false
                    })
                }else{
                  this.$vs.notification({
                        icon:`<img class="white-svg" src="assets/icons/vuesax/alert.svg" />`,
                        classNotification:'vuertl',
                        color:"warn",
                        position:'top-center',
                        duration:'3000',
                        progress: 'auto',
                        title: this.$store.state.auth.user == null?'تسجيل الدخول':'رقم الهاتف',
                        text: this.$store.state.auth.user == null?`يمكنك تسجيل الدخول عن طريق الايمايل أو فتح حساب جديد`:'تحقق من رقم الهاتف' })
                    this.isDirectMode = false
                }
            },

            totalParce,
            closeDial(){
                this.dialog = !this.dialog
                this.form.reset()
            },

            getColorr,iconR,getColorClass,
            CloseAlert(data){
                this.HandelAlert = false
                localStorage.setItem("alert_flexy",data)
            },
            HandelToggle(){
                // this.$store.commit('SET_DRAWER',!this.$store.state.drawer)
            },
            handelCards(){
                this.showCard = !this.showCard
                localStorage.setItem("flexy_cards",JSON.stringify(this.showCard))
            },
            getTextColor,
            formatToPrice,
            showContacts(){

                  let newVal = this.$store.state.contacts
              //   this.$store.dispatch('loadontacts',null)
              this.$store.commit("SET_ICARDACTIVE",true)
            },
            getprice() {
                axios({
                    url: "api/get-price",
                    method: "get",
                }).then((response) => {
                    this.responsePrice = response.data.price;
                    this.djezzy = response.data.djezzy;
                    this.ooredoo = response.data.ooredoo;
                    this.mobilis = response.data.mobilis
                    this.message = response.data.message
                    this.pricesToday = response.data.price
                    this.flexyCard = response.data.flexyCard
                    let lastMessage = localStorage.getItem('alert_flexy')
                    if(lastMessage === response.data.message){
                        this.HandelAlert = false
                    } else {this.HandelAlert = true}

                });
            },

            changeMode(mode) {
                this.form.Mode = mode;
                this.selected = mode
            },
            changeModeOffers(offer) {

                this.form.Mode = offer.name;
                this.selected = offer.name
                this.form.Amount = offer.price
            },
            direction() {
                if (this.$store.state.auth.user.type !== "new") {
                    this.selected = undefined;
                }
            },
            TopUp: async function (data) {

                if(data == 1){this.firstClick +=1}else {this.firstClick = 0}
                if(this.isDirectMode == true){
                    this.forma.Mode= this.form.Mode
                }
                this.OffersMode.Offers = ""
                this.respDialog = null
                this.forma.Amount= this.form.Amount
                this.forma.PhoneN= this.form.PhoneN
                 if (this.form.Amount[0]!=0 && this.form.busy != true) {
                    this.dialogo = false
                    this.dialog =true;
                    try {
                          const response = await this.form.post("api/Sendque-"+this.opperator)
                        this.respDialog = await  response.data.data
                        if (this.respDialog.Status!= "En attente"){
                            this.forma.Mode= ''
                            this.forma.Amount=  ''
                            this.forma.PhoneN= ''
                        }
                        this.model = null
                      bus.$emit('loadSolde')
                      //  audioFile.play().catch(()=>{})
                    } catch (e) {

                            if(e.response.data.data.response == 'failed'){
                                this.dialog = false
                            }  else {
                                this.respDialog = response.data.data
                            }


                    }
                    if (this.respDialog.Status != "En attente"){
                       // bus.$emit("loadNumbers", null);
                        // await this.$store.dispatch('loadontacts', null)
                    }


                }

            },

            newModell() {
                this.OffersMode.Offers = ""
                if(this.isDirectMode){
                    this.form.Mode = "Normal"
                    this.selected = 1500
                    this.TopUp()
                }else{
                    this.form.Mode = "";
                    this.selected = ''
                    this.dialogo =  true
                }
                if (this.form.Amount >=5000){
                    this.form.Mode = "GROSS"
                    this.selected = 5555
                    this.dialogo =  true
                }
                // if(this.user != null ){
                //
                // }else {
                //     this.$vs.notification({
                //         icon:`<img class="white-svg" src="assets/icons/vuesax/alert.svg" />`,
                //         classNotification:'vuertl',
                //         color:"#7d33ff",
                //         position:'top-center',
                //         duration:'5000',
                //         progress: 'auto',
                //         title: 'تسجيل الدخول',
                //         text: `يمكنك تسجيل الدخول عن طريق الايمايل أو فتح حساب جديد`
                //     })
                // }

            },

            OperateurChar() {
                let output = this.form.PhoneN.split("");
                if(output[1] == 6){this.opperator= 'mobilis'}
                else if(output[1] == 7){this.opperator= 'djezzy'}
                else if(output[1] == 5){this.opperator= 'ooredoo'}

                return output[1];
            },
        },
    };
</script>
<style scoped>
    /*.bord{*/
    /*    border-radius:25px!important*/
    /*}*/
    .for-allert{
        height: 40px!important;
        text-align: center!important;
        padding-top: 7px!important;
    }
    .v-card--link:before {
        background: none!important;
    }
</style>
