import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"ml-14 rounded-xl",attrs:{"height":"100vh","flat":""}},[_c(VContainer,{attrs:{"fluid":""}},[_c(VRow,[_c(VCol,{staticClass:"col-9"},[_c(VCard,{staticClass:"rounded-xl",attrs:{"height":"30vh","flat":"","img":"https://images.unsplash.com/photo-1508247967583-7d982ea01526?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2250&q=80"}},[_c(VCard,{attrs:{"height":"50%"}},[_c(VTabs,{attrs:{"height":"58","background-color":_vm.$store.state.params.bg,"centered":"","dark":"","icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),_c(VTab,{attrs:{"href":"#tab-1"}},[_vm._v(" Recents "),_c(VIcon,[_vm._v("mdi-phone")])],1),_c(VTab,{attrs:{"href":"#tab-2"}},[_vm._v(" Favorites "),_c(VIcon,[_vm._v("mdi-heart")])],1),_c(VTab,{attrs:{"href":"#tab-3"}},[_vm._v(" Nearby "),_c(VIcon,[_vm._v("mdi-account-box")])],1)],1),_c(VTabsItems,{staticStyle:{"height":"100%"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((3),function(i){return _c(VTabItem,{key:i,staticStyle:{"height":"100%"},attrs:{"value":'tab-' + i}},[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_vm._v(_vm._s(_vm.text))])],1)],1)}),1)],1)],1)],1),_c(VCol,{staticClass:"col-3"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }