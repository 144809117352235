import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
    
    rtl:false,
    icons: {
        iconfont: 'mdi'||  'fa4'
    },

    theme: {
        themes: {
            light: {
                primary: '#03A9F4',
                warning: "#ffce3d",
                success: '#4CAF50',
                error: '#E40814',
                dark:'#1E2023',
                purple:'#651FFF',
                greyy:'rgba(244,244,246,0.7)',
                commerce:'#FBC02D',
                // commerce:'#ff037d',


            },
        },
    },

});
