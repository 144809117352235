<template>
    <v-card   class="vuertl" flat style="height:100%" :color="dark?'app-bg':'app-bg-light'">

        <v-container  >
            <TitlePages title="خصائص">
                <v-app-bar-nav-icon slot="btn" :dark="dark"   @click="$router.go(-1)" large
                       style="float: left!important;" icon depressed><img
                        :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg"/>
                </v-app-bar-nav-icon>
            </TitlePages>






             <vs-dialog overflow-hidden blur v-model="dialogSupport" not-padding class="px-1">
                <v-sheet :color="dark?'black-vuesax':'app-bg-light'" :dark="dark"
                         class="text-center  rounded-xl"
                         :class="component=='v-dialog'?'bottom-sheet-radius-all':'bottom-sheet-radius'"

                >
                    <v-container>
                        <v-btn @click="dialogSupport = !dialogSupport" style="float: left!important;" icon depressed>
                            <img :class="dark?'white-svg':'black-svg'"
                                 src="assets/icons/vuesax/chevron-right.svg"/></v-btn>
                        <h2 :class="[params.font,dark?'white--text':'black--text']" style="text-align: right ">الدعم
                            الفني</h2>
                        <v-card-title class="vuertl py-0" :class="params.font">

                            <p :class="dark?'grey--text':''" style="font-size:13px">
                                يمكنك التواصل معنا في أي وقت تريد عبر وسائل التواصل التالية
                            </p>
                        </v-card-title>
                        <v-row class=" mb-2">
                            <v-col class="col-6">
                                <v-card flat class="rounded-lg" :to="params.WatsApp" style="cursor: pointer;"
                                        :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                                    <v-card-title style="display:block" class="  mx-auto pb-1">
                                        <img :class="dark?'white-svg':'black-svg'"
                                             src="assets/icons/vuesax/Whatsapp.svg"/>
                                    </v-card-title>
                                    <p :class="[params.font,dark?'white--text':'black--text']"> واتس اب</p>

                                </v-card>
                            </v-col>
                            <v-col class="col-6">
                                <v-card
                                        flat class="rounded-lg" style="cursor: pointer;" :to="params.Telegram"
                                        :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                                    <v-card-title style="display:block" class="  mx-auto pb-1">
                                        <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/send.svg"/>
                                    </v-card-title>
                                    <p :class="[params.font,dark?'white--text':'black--text']">تلغرام</p>
                                </v-card>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-sheet>
            </vs-dialog>

        </v-container>
        <v-dialog transition="fade" class="transition-swing" v-model="modelConditions" fullscreen>
            <v-card :dark="dark" flat class="vuertl" :color="dark?'app-bg':'app-bg-light'">


                <v-container>
                    <v-card-title :class="params.fontBold">الشروط والاحكام
                        <v-spacer>
                        </v-spacer>
                        <v-btn   @click="switchDialog('conditions')" style="float: left!important;" icon
                               depressed><img :class="dark?'white-svg':'black-svg'"
                                              src="assets/icons/vuesax/close-1.svg"/></v-btn>

                    </v-card-title>
                    <v-card-subtitle :class="[params.color,params.font]" v-text="params.AppName"></v-card-subtitle>
                    <v-card-text :class="params.font">
                        <p class="text-justify">بسم الله الرحمن الرحيم</p>
                        <p class="text-justify">• في هذه الصفحة تجدون كل مايهمكم بشأن شروط وأحكام متجر "كارتاك"</p>
                        <p class="text-justify">• شرائك لأي من المنتجات تعبر عن موافقتك لجميع هذه البنود في الصفحة.</p>
                        <p class="text-justify">• جميع المنتجات إلكترونية، غير عينية، وتصل لصفحة “الطلبات” على حسابك
                            بالمتجر.</p>
                        <p class="text-justify">• قبل الدفع يتوجب على العميل قراءة وصف المنتج بعناية.</p>
                        <p class="text-justify">• شراء العميل لاي منتج يعبر عن موافقته لمواصفات وشروط المنتجات المذكورة
                            في هذه الصفحة.</p>
                        <p class="text-justify">• جميع المنتجات غير قابلة للأسترداد والأسترجاع نهائياُ.</p>
                        <p class="text-justify">• أي بيانات يخطئ في تزويدها العميل للمتجر تخص الطلب لايتحمل المتجر أي
                            مسؤولية في ذلك.
                        </p>
                        <p class="text-justify">• في حالة حصول خلل لأي من المنتجات, يجب على العميل توفير فيديو كامل
                            اثناء لحظة شراءه يثبت ذلك ( ولن تقبل الشكوى بدون فيديو )
                        </p>
                        <p class="text-justify">• لا يتحمل متجر إشحنها أي مسؤولية لمشتريات خاطئة قمت بها بذاتك، بسبب
                            الاهمال أو إدخال معلومات زائفة /خاطئة، أو أي سبب آخر مما قد يؤدي إلى • أضرار/خسارات كما أن
                            متجر إشحنها غير ملزم بتبديل أو أسترجاع اي منتج تم وصول بياناتها إليك وبهذا تكون قد فهمت و
                            أقررت وقبلت إخلاء متجر • إشحنها من المسؤولية تماماً.
                        </p>
                        <p class="text-justify">• بعد التسليم، لا يعتبر متجر إشحنها مسؤول عن أي ضياع أو ضرر للسلع
                            الإلكترونية التي تم شرائها من خلال متجر إشحنها، وأي خسارة أو ضرر قد يعاني منه المشتري لهذا
                            السبب.
                        </p>
                        <p class="text-justify">• يتم تغيير الاسعار فالموقع بشكل يومي/اسبوعي/شهري ولا يحق للعميل مطالبة
                            الفرق لان هناك عروض يوميا ربما يكون هناك ارتفاع/انخفاض في الاسعار، وليس ملزوم متجر "إشحنها"
                            بدفع الفرق او تثبيت السعر
                        </p>
                        <p class="text-justify">• يحق للمتجر تغيير أو إضافة بنود في هذه الصفحة في اي وقت تراه مناسب و
                            يجب على العميل متابعة البنود حتى بدون تنبيه
                        </p>

                    </v-card-text>
                </v-container>
            </v-card>
        </v-dialog>
    </v-card>

</template>

<script>
    import {VBottomSheet , VDialog} from 'vuetify/lib'
    import {mapActions, mapState} from 'vuex';
    import {Form} from 'vform';
    import TitlePages from "@/layout/TitlePages";

    export default {
        components: {TitlePages,VBottomSheet,VDialog},
        data() {
            return {
                dialogEmail:false,

                modelConditions: false,
                mode: '',
                sheetTow: false,
                configData: [],
                sheet: false,
                dialogSupport: false,
                nextSteep: false,

                error: '',
                checkTimes: 0,
                processing: false,
                loadingSocial:false,

              icon:'',
            }
        },
        methods: {

          switchChevron(icon){
            if(this.icon == icon){
              this.icon = ''
            }else{
              this.icon = icon
            }
          },





            switchDialog(reseved) {
                if(reseved == "conditions"){
                    this.modelConditions = !this.modelConditions
                  this.sheet = !this.sheet
                } else   {
                    this.sheet = !this.sheet
                  this.modelConditions = !this.modelConditions
                }


            },
            setApp() {
                window.location.href = this.params.playStore
            },

            ...mapActions({
                signIn: 'auth/login'
            }),
            async getConfig() {
                if (this.$store.state.conditions.conditions == null){
                    await this.$store.dispatch('conditions/loadConditions')
                }

            },


        },
        watch: {
            'auth.user': function () {
                this.configData = [

                    {
                        name: 'الاسئلة الشاسعة',
                        icon: 'qustion.svg',
                        text: ` متجر ${this.$store.state.params.AppName} هو متجر حديث لبيع بطاقات الشحن المحلية و كذا الدولية سواء العاب سارفيرات و كذا جميع متعاميلي الشبكات المحلية ـ يمكنك ايضا تفعيل بطاقات الشحن IDOOM  بأسرع و أحدث الطرق التي نوفرها بلكم
                        فليكسي لجميع متعاميلي الشبكات موبيليش أوريدو و جيزي
                        لا تتردد في التواصل معنا `,
                        disabled: false,
                        onClick: () => {

                        }
                    },
                    {
                        name: 'الشروط و الاحكام',
                        icon: 'secure.svg',
                        disabled: true,
                        onClick: () => {
                            let vm = this;
                            vm.sheet = !vm.sheet
                            vm.modelConditions = !vm.modelConditions
                        }
                    },
                    {
                        name: 'تقييم التطبيق',
                        icon: 'star.svg',
                        disabled: true,
                        onClick: () => {
                            window.location.href = this.params.playStore
                        }
                    },
                    {
                        name: 'الدعم الفني',
                        icon: 'support.svg',
                        disabled: true,
                        onClick: () => {
                            this.dialogSupport = !this.dialogSupport
                        }
                    }
                ]
            }
        },
        computed: {
            ...mapState(['auth']),
            component: function () {
                return this.$vuetify.breakpoint.smAndDown ? 'v-bottom-sheet' : 'v-dialog'
            },
            params: function () {
                return this.$store.state.params
            },
            dark: function () {
                return this.$store.state.dark
            },
            allConditions: function () {
                return this.$store.state.conditions.conditions
            },
        },

        mounted() {
          this.getConfig()

        }

    }
</script>

<style scoped>
.v-dialog__content {
  border-radius: 21px!important;
}
    .vs-loading__load .vs-loading__load__animation .vs-loading__load__text {
        font-size: 17px !important;
    }

    /* .v-text-field--outlined >>> fieldset {
      border-color: #fff;
    } */
    .v-dialog__content {
        z-index: 1001 !important;
    }

    .v-expansion-panel-header__icon {
        margin-left: 5px !important;
    }

    .bottom-sheet-radius-all {
        border-radius: 20px !important;
    }

    .vs-loading {
        border-radius: 19px !important;
    }

    .index-z {
        z-index: 1000 !important;
    }

    .input-class {
        max-height: 50px !important;
        height: 50px !important;
    }

    >>> .v-input__slot::before {
        border-style: none !important;
    }

    .for-avatar {
        text-align: center;
        font-size: 20px;
        text-transform: capitalize;
        padding-bottom: 0px;
    }
    .vs-button{
        margin-right: 0px;
    }
    .notif-class{
        font-size: 18px!important;
    }
    .blur {
      -webkit-backdrop-filter: saturate(180%) blur(15px)!important;
      backdrop-filter: saturate(180%) blur(15px)!important;
    }


</style>
