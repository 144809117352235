import axios from "axios";
import moment from 'moment';
export function getColor(res) {
    if (res === "Confirmé") return 'success'
    else if (res === "Annulé") return 'error'
    else if (res === "Information") return '#FF5722'
    else if (res === "Remboursé") return 'FF1744'
    else if (res === "Sous traitement") return 'primary'
    else return '#2196F3'
}
export function NotificationsImg(method){

    if (method === "Retrait du solde") {
        return 'assets/notifications/retrait-solde.png'
    } else if (method === "Rechargement") {
        return 'assets/notifications/deposit.png'
    } else if (method === "Bonus") {
        return 'assets/notifications/bonus.png'
    }else if (method === "Réinitialision") {
        return 'assets/notifications/pass.png'
    }else if (method === "Deposit") {
        return 'assets/notifications/depot.png'
    }else if (method === "Credit") {
        return 'assets/notifications/credit.png'
    }else if (method === "Transfer") {
        return 'assets/notifications/transfer.png'
    }else if (method === "Register") {
        return 'assets/notifications/register.png'
    }else if (method === "Direct Topup") {
        return 'assets/notifications/topup.png'
    } else {
        return 'assets/notifications/notification.png'
    }
}

export function getTextColor(res) {
    if (res === "En attente") return 'orange--text'
    else if (res === "Confirmé") return 'success--text'
    else if (res === "Annulé") return 'error--text'
    else if (res === "Remboursé") return 'info--text'
    else if (res === "Sous traitement") return 'primary--text'



}
export function setBaseUrl(image) {
     return 'https://app.shipilistore.dz'+ image
 }
export function getBgColor(res) {
    if (res === "En attente") return 'bg-web-warning'
    else if (res === "Confirmé") return 'bg-web-success'
    else if (res === "Carte CIB") return 'bg-web-cib'
    else if (res === "Annulé") return 'bg-web-error'
    else if (res === "Remboursé") return 'bg-web-remb'
    else if (res === "Sous traitement") return 'bg-web-primary'


}

export function vuesaxColor(status) {
    if (status === "En attente") return 'v-button--warn'
    else if (status === "Confirmé") return 'v-button--success'
    else if (status === "Annulé") return 'v-button--danger'
    else if (status === "Remboursé") return 'v-button--primary'
    else if (status === "Sous traitement") return 'bg-web-dark'

}

export function iconChange(icon) {
    if (icon === "En attente") {
        return 'mdi-checkbox-marked-circle'
    } else if (icon === "Confirmé") {
        return 'mdi-eye'
    }else if (icon === "Carte CIB") {
        return 'mdi-card-account-details-star-outline'
    }else if (icon === "Sous traitement") {
        return 'mdi-progress-check'
    } else if (icon === "Annulé") {
        return 'mdi-close-circle-outline'
    }else if(icon === "Remboursé"){
        return 'mdi-arrow-top-right-bold-outline'
    }
}
export function OperateurChar(Phone) {
    let output = Phone.split("");
    if(output[1] == 6){return 'assets/img/flexy/mobilis.png'}
    else if(output[1] == 7){return  'assets/img/flexy/djezzy.png' }
    else if(output[1] == 5){return 'assets/img/flexy/ooredoo.png'}
}

export function setImage(Phone) {
    let output = Phone.split("");
    if(output[1] == 6){return '/assets/img/flexy/mobilis.png'}
    else if(output[1] == 7){return  '/assets/img/flexy/djezzy.png' }
    else if(output[1] == 5){return '/assets/img/flexy/ooredoo.png'}
}

export function setImageOperator(type) {

    if('Mobilis' == type){return '/assets/img/flexy/mobilis.png'}
    else if('Djezzy' == type){return  '/assets/img/flexy/djezzy.png' }
    else if('Ooredoo' == type){return '/assets/img/flexy/ooredoo.png'}
}
export function colorSvg(res) {

    if (res === "Confirmé") return 'success-svg'
    else if (res === "Annulé") return  'error-svg'
    else if (res === "Remboursé") return 'error-svg'
    else if (res === "En attente") return 'orange-svgg'
    else if (res === "Sous traitement") return 'primary-svg'
    else return 'white-svg'
}

export function AlertIcon(res) {
    if (res === "Confirmé") return 'success'
    else if (res === "Annulé") return 'error'
    else if (res === "En attente") return 'warning'
    else if (res === "Sous traitement") return 'primary'
}


export function iconResponse(resp) {
    if (resp === "En attente") {
        return 'assets/icons/status/enattante.svg'
    } else if (resp === "Confirmé") {
        return 'assets/icons/status/success-response.svg'
    } else if (resp === "Sous traitement") {
        return 'assets/icons/status/soustraitment.svg'
    }
    else if (resp === "Annulé") {
        return 'assets/icons/status/annule.svg'
    }
}
export function getColorr(res) {
    if (res === "En attente") return '#FFC107'
    else if (res === "Confirmé") return '#4CAF50'
    else if (res === "Carte CIB") return '#FF5722'
    else if (res === "Remboursé") return '#FF1744'
    else if (res === "Sous traitement") return '#2196F3'
    else if (res === "Annulé") return '#ED1C24'
    else return '#FFC107'
}

export function getColorClass(res) {

if (res === "En attente") return 'warning--text '
else if (res === "Confirmé") return 'success--text'
else if (res === "Remboursé") return 'primary--text'
else if (res === "Annulé") return 'error--text'

}
export function arabResp(res) {

    if (res === "En attente") return 'في الانتظار'
    else if (res === "Confirmé") return 'تم بنجاح'
    else if (res === "Remboursé") return 'تم الرد'
    else if (res === "Annulé") return 'تم الالغاء'

}



export function formatToPrice(value) {
    return   (value * 1).toFixed(2)
}
 export function toggleConatcer(data){
    data= !data
}
export function pushRouter(){
    this.$router.push('/map')
}
export function setHtmlColor() {
    if (window.localStorage.getItem('mode') === 'true') {
      //  store.commit('SET_DARK', true)
         $(".main-color").css({
             backgroundColor: "#323434",
            color: "#f6faff",
        });
        $(".content-wrapper").css({
            backgroundColor: "#f4f7f8",
            color: "#ecf4f8",
        });

        // $(".v-application--wrap").css({
        //     backgroundColor: "#f4f7f8",
        //     color: "#ecf4f8",
        // });

    } else {
      //  store.commit('SET_DARK', false)

        $(".content-wrapper").css({
            backgroundColor: "#e9f7fc",
            color: "rgba(0,0,0,.87)",
        })
        $(".main-color").css({
            backgroundColor: "#e9f7fc",
            color: "rgba(0,0,0,.87)",
        })
        // $(".v-application--wrap").css({
        //     backgroundColor: "#f6faff",
        //     color: "rgba(0,0,0,.87)",
        // })

    }

}
export function checkAdsl(number) {
    if (number.startsWith("047") || number.startsWith("213") || number.startsWith('47')){
        return "assets/img/4g.png"
    }else return "assets/img/idoom.png"

}


export function splitCard() {
    return  value.replace(/\B(?=(\d{4})+(?!\d))/g, " ");
}


export   function    gradeType(){
      //  const user  =    this.$store.state.user

    if (user.type==0)
    {
        return  'assets/img/agent.png'
    }
    if (user.type=='new') {return  'assets/img/bronze-shipili.png' } else if(user.type == 'user')
    {return  'assets/img/silver-shipili.png'
    }else if(user.premium ==1 && user!='admin')
    {return  'assets/img/gold-shipili.png'
    }else if(user=='admin')
    {return  'assets/img/platinium-shipili.png'}
    else {
        return 'assets/img/logo.png'
    }

}

export function userColor(user){
    if (user.type == "admin"){return "purple--text"}
    else if (user.type == "user"){return "success--text"}
    else if (user.type == "new"){return "primary--text"}
}

export function statusTypeOfUser(user) {

        if (user.type == "user"){
            return "assets/img/user.png"
        }else if (user.type == "new"){
            return "assets/img/profile.png"
        }else if(user.type == "admin"){
            return "assets/img/admin.png"
        }




}
export function ico(meth) {
    if (meth === "Retrait") {
        return 'fas fa-bell bg-web-error '
    } else if (meth === "Deposit") {
        return 'mdi-cash-usd bg-web-success'

    } else if (meth === "Réinitialisation") {
        return 'fas fa-lock fa-fw bg-web-error'
    } else if (meth === "Confirmation") {
        return 'fas fa-user-plus bg-web-primary'
    } else if (meth === "Commande") {
        return 'fas fa-cart-plus bg-web-primary'
    } else if (meth === "Transfer") {
        return 'fas fa-cart-plus bg-web-primary'
    }
    else {
        return 'fas fa-user bg-web-success'
    }
}


export function iconR(resp) {
    if (resp === "En attente") {
        return 'mdi-alert-circle-outline'
    } else if (resp === "Confirmé") {
        return 'mdi-check-circle-outline'
    } else if (resp === "Carte CIB") {
        return 'mdi-card-account-details-star-outline'
    } else if (resp === "Annulé") {
        return 'mdi-close-circle-outline'
    }else if(resp === "Sous traitement"){
        return 'mdi-progress-check'
    }else if(resp === "Remboursé"){
        return 'mdi-arrow-top-right-bold-outline'
    }
    else {return 'mdi-close-circle-outline'}

}

    export function splitName(count , length) {

        if (count.length >= length) {
          return count.substring(0, length) + "...";
        } else {
          return count;
        }
      }
export function splitNamewithLength(count , length) {

    if (count.length >= length) {
        return count.substring(0, length) + "...";
    } else {
        return count;
    }
}
    export function excuteSound(sound) {
        const audioFile = new Audio("../assets/sound/"+sound+".wav")
        audioFile.muted = true
        audioFile.muted = false
        audioFile.play()
        setTimeout(() => {
             audioFile.pause();
            audioFile.currentTime = 0;
            }, 3000);
    }

    export function totalParce(Amount , Operateur) {
       return  parseFloat((Amount * Operateur) / 100 + "" ).toFixed(2);
    }
export function setVuesaxDark() {
     var element = document.getElementsByClassName('vs-dialog');
    setTimeout(()=> ""?element.
    classList.add("vuesax-dark"):element.classList.add("vuesax-light"),300)

}


export function scrollToElement() {
    window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
    });
}




export function excuteLoading(time) {
    const loading = this.$vs.loading({opacity:0.9})
    setTimeout(() => {
        loading.close()
    }, time?time:3000)
}


export function ptg() {
   // const user  =    this.$store.state.user

   return (Number(user.solde) * 100 ) / Number(user.addsolde)


}
export function  formatCardNumber(value){
    return value ? (value.replace(/ /g, '')).match(/.{4}/g).join(' ') : '';
}

export function comparDates(dates) {
    var date1 = moment(dates[0])
    var date2 = moment(dates[1])

    if(date1 <=  date2){
        return true
    } else {
        false
    }


}


export function loadRecaptcha() {
    const $el = document.createElement('script')
    $el.src = `https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit&hl=fr`
    $el.async = true
    document.head.appendChild($el)
}
