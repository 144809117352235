<template>




    <div v-if=" $route.fullPath != '/auth'">
    <v-navigation-drawer


        v-click-outside="openSid"   style="z-index:3!important" :hide-overlay="!$vuetify.breakpoint.xs"
                         :temporary="$vuetify.breakpoint.mdAndDown"
                         floating :dark="$store.state.dark"
                         :class="$vuetify.breakpoint.mdAndDown?'':'mt-11'"
                         app       width="220px"
                         :color="dark?'#1E2023':''"
                         v-model="open"  >

       <v-card flat color="transparent" class="mt-11" :dark="dark">
         <v-list-item :dark="dark" color="transparent"  >
           <v-list-item-content class="pb-0">
             <v-list-item-title >
               <v-card-title class="py-0">
                 <img style="cursor: pointer;margin-top:5px"  width="30px" height="30px" src="assets/img/logo.svg"  >
                 <span class="mt-2"  :class="dark?'white--text':'back--text'"  style="font-family: sans-serif"><b>{{$store.state.App.config.AppName}}</b></span>
               </v-card-title>
             </v-list-item-title>
             <v-list-item-subtitle style="text-align: center">
               Services électroniques
             </v-list-item-subtitle>
           </v-list-item-content>
         </v-list-item>
         <v-card class="rounded-lg ma-2 "  flat  :dark="dark">
           <vs-button to="/info"  :dark="dark" class="ml-0      " block dark size="lg"  v-if="user!= null"          >

             <sub class="mx-1 app-regular">دج</sub>

             <span style="direction: ltr;font-size: 19px" class="  mr-2  "  ><b>{{  " " +  formatToPrice(user.solde)}}</b></span>
             <span class="app-regular">حسابي </span>
           </vs-button>
         </v-card>
         <v-card class="rounded-lg ma-2 mx-2"  flat  :dark="dark">
           <vs-button to="/historique-bonus" :dark="dark" class="ml-0   text--accent-3  " block size="lg"  v-if="user!= null"       :color="$store.state.App.config.colorBackground"     >


             <sub class="mx-1 app-regular">دج</sub>

             <span style="direction: ltr;font-size: 19px;font-family:sans-serif" class="  mr-2  "  ><b>{{  " " +  formatToPrice(user.bonus)}}</b></span>
             <span class="app-regular">أرباحي </span>

           </vs-button>
         </v-card>
         <v-card class="rounded-lg ma-2 mx-2"  flat  :dark="dark"  v-if="user!= null && user.credit >0 " >
           <vs-button to="/historique-bonus" :dark="dark" class="ml-0   text--accent-3  " block size="lg"  v-if="user!= null"       color="#1821c2" flat   >
             <sub class="mx-1 app-regular">دج</sub>

             <span style="direction: ltr;font-size: 19px;font-family:sans-serif" class="  mr-2  "  ><b>{{  " " +  formatToPrice(user.credit)}}</b></span>
             <span class="app-regular">ديوني </span>

           </vs-button>

         </v-card>


           <v-list :dark="dark"  nav dense >
             <v-list-item-content class="pt-0" >
               <v-list-item-title>Pannel</v-list-item-title>
             </v-list-item-content>
             <v-list-item-group
                 v-model="group"
                 active-class="app-grey"
             >

               <v-list-item   v-if="Firstitem.items == null" @click="group = Firstitem.index" :dark="dark" selectable class=" rounded-lg overflow-hidden" :to="Firstitem.link"
                              v-for="(Firstitem,index) in sidbara" :key="index" link>
                 <v-list-item-icon>
                   <img style="width:23px" :class="dark?'white-svg':'black-svg'" :src="Firstitem.icon">
                 </v-list-item-icon>

                 <v-list-item-title class="app-bold "  style="text-align: center; line-height: normal;font-size: 15px" >{{Firstitem.title}}</v-list-item-title>

               </v-list-item>
               <v-list-group sub-group v-else



                             active-class="app-grey"
               >
                 <template v-slot:activator>

                   <v-list-item inactive :ripple="false" >
                     <v-list-item-icon>
                       <img style="width:23px" :class="dark?'white-svg':'black-svg'" :src="Firstitem.icon">
                     </v-list-item-icon>
                     <v-list-item-title style="line-height: normal"  class="app-bold  " v-text="Firstitem.title"></v-list-item-title>
                   </v-list-item>
                 </template>

                 <v-list-item :to="item.link"
                              v-for="(item, i) in Firstitem.items"
                              :key="i"

                 >
                   <v-list-item-icon>
                     <img style="width:23px" :class="dark?'white-svg':'black-svg'" :src="item.icon">
                   </v-list-item-icon>

                   <v-list-item-title style="line-height: normal"  class="app-bold " v-text="item.title"></v-list-item-title>


                 </v-list-item>


               </v-list-group>
               <v-list-item  selectable class=" rounded-lg overflow-hidden"  to="/config"
               >
                 <v-list-item-icon>
                   <img style="width:23px" :class="dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/app.svg">
                 </v-list-item-icon>

                 <v-list-item-title class="app-bold  "  style="text-align: center; line-height: normal;font-size: 15px" >خصائص</v-list-item-title>

               </v-list-item>
               <v-list-item   class=" rounded-lg overflow-hidden" @click.prevent="getNumbers()"
               >
                 <v-list-item-icon>
                   <img  style="width:23px" :class="dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/alert.svg">
                 </v-list-item-icon>

                 <v-list-item-title class="app-bold  "  style="text-align: center; line-height: normal;font-size: 15px" >إتصل بنا</v-list-item-title>

               </v-list-item>
               <v-list-item v-if="user != null"  selectable class=" rounded-lg overflow-hidden" @click.prevent="logOut('logout')"
               >
                 <v-list-item-icon>
                   <img style="width:23px" :class="dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/logout.svg">
                 </v-list-item-icon>

                 <v-list-item-title v-if="$store.state.auth.user.username" class="app-bold   " :class="$store.state.App.config.colorText" style="text-align: center; line-height: normal;font-size: 15px" >خروج</v-list-item-title>

               </v-list-item>
               <v-list-item v-else  selectable class=" rounded-lg overflow-hidden" @click.prevent="logOut('login')"
               >
                 <v-list-item-icon>
                   <img style="width:23px" :class="dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/logout.svg">
                 </v-list-item-icon>

                 <v-list-item-title   class="app-bold   " :class="$store.state.App.config.colorText" style="text-align: center; line-height: normal;font-size: 15px" >تسجيل الدخول</v-list-item-title>

               </v-list-item>
             </v-list-item-group>


           </v-list>
           <v-card flat color="transparent" :dark="dark">
             <v-card-title style="display: block;text-align: center">
               <span  style="font-size: 13px">version 1.0.2</span>
             </v-card-title>
           </v-card>

       </v-card>


    </v-navigation-drawer>

      <v-navigation-drawer v-if="$store.state.isCardActive && $vuetify.breakpoint.mdAndUp"
          disable-resize-watcher disable-route-watcher  stateless  hide-overlay



          v-click-outside="openSid"
          style="z-index:100000000!important;position: fixed;
          left: 5px!important;margin-left: 5px!important;background-color: transparent"
          :style="{maxHeight:user.credit >0?'235px ':'200px '}"
                           :dark="dark"
          class="mt-14 ml-2 overflow-hidden  rounded-xl elevation-0 " floating
             width="230px"
          :color="dark?'#1E2023':''"
          v-model="$store.state.isCardActive"  >

        <v-card   color="transparent"  class="rounded-xl" flat     >
          <v-card class="pa-2 rounded-xl"  elevation="" :class="dark?'vuesax-3':'app-card-light'"  >
            <v-list-item     >
              <v-list-item-content class="pb-0">
                <img @click="hideCard" style="width:30px;
                height: 30px;position: fixed;right: 10px;top:10px;cursor: pointer"
                     :class="$store.state.dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/close-1.svg">

                <v-list-item-title >
                  <v-card-title class="py-0">
                    <img style="cursor: pointer;margin-top:5px"  width="30px" height="30px" src="assets/img/logo.svg"  >
                    <span class="mt-2"   style="font-family: sans-serif"><b  :class="dark?'white--text':'black--text'">{{$store.state.App.config.AppName}}</b></span>
                  </v-card-title>
                </v-list-item-title>

              </v-list-item-content>

            </v-list-item>
            <v-card class="rounded-lg ma-2 "  flat  :dark="dark">
              <vs-button to="/info"  :dark="dark" class="ml-0      " block dark size="lg"  v-if="user!= null"          >

                <sub class="mx-1 app-regular">دج</sub>

                <span style="direction: ltr;font-size: 19px" class="  mr-2  "  ><b>{{  " " +  formatToPrice(user.solde)}}</b></span>
                <span class="app-regular">حسابي </span>
              </vs-button>
            </v-card>
            <v-card class="rounded-lg ma-2 mx-2"  flat  :dark="dark">
              <vs-button to="/historique-bonus" :dark="dark" class="ml-0   text--accent-3  " block size="lg"  v-if="user!= null"        :color="$store.state.App.config.colorBackground"       >


                <sub class="mx-1 app-regular">دج</sub>

                <span style="direction: ltr;font-size: 19px;font-family:sans-serif" class="  mr-2  "  ><b>{{  " " +  formatToPrice(user.bonus)}}</b></span>
                <span class="app-regular">أرباحي </span>

              </vs-button>
            </v-card>
            <v-card class="rounded-lg ma-2 mx-2"  flat  :dark="dark"  v-if="user!= null && user.credit >0 " >
              <vs-button to="/historique-bonus" :dark="dark" class="ml-0   text--accent-3  " block size="lg"  v-if="user!= null"       color="#283edb" flat   >
                <sub class="mx-1 app-regular">دج</sub>

                <span style="direction: ltr;font-size: 19px;font-family:sans-serif" class="  mr-2  "  ><b>{{  " " +  formatToPrice(user.credit)}}</b></span>
                <span class="app-regular">ديوني </span>

              </vs-button>

            </v-card>
          </v-card>
          <div v-if="!$store.state.isCardActive">

            <v-list :dark="dark"  nav dense >
              <v-list-item-content class="pt-0" >
                <v-list-item-title>Pannel</v-list-item-title>
              </v-list-item-content>
              <v-list-item-group
                  v-model="group"
                  active-class="app-grey"
              >

                <v-list-item   v-if="Firstitem.items == null" @click="group = Firstitem.index" :dark="dark" selectable class=" rounded-lg overflow-hidden" :to="Firstitem.link"
                               v-for="(Firstitem,index) in sidbara" :key="index" link>
                  <v-list-item-icon>
                    <img style="width:23px" :class="dark?'white-svg':'black-svg'" :src="Firstitem.icon">
                  </v-list-item-icon>

                  <v-list-item-title class="app-bold "  style="text-align: center; line-height: normal;font-size: 15px" >{{Firstitem.title}}</v-list-item-title>

                </v-list-item>
                <v-list-group sub-group v-else



                              active-class="app-grey"
                >
                  <template v-slot:activator>

                    <v-list-item inactive :ripple="false" >
                      <v-list-item-icon>
                        <img style="width:23px" :class="dark?'white-svg':'black-svg'" :src="Firstitem.icon">
                      </v-list-item-icon>
                      <v-list-item-title style="line-height: normal"  class="app-bold  " v-text="Firstitem.title"></v-list-item-title>
                    </v-list-item>
                  </template>

                  <v-list-item :to="item.link"
                               v-for="(item, i) in Firstitem.items"
                               :key="i"

                  >
                    <v-list-item-icon>
                      <img style="width:23px" :class="dark?'white-svg':'black-svg'" :src="item.icon">
                    </v-list-item-icon>

                    <v-list-item-title style="line-height: normal"  class="app-bold " v-text="item.title"></v-list-item-title>


                  </v-list-item>


                </v-list-group>
                <v-list-item  selectable class=" rounded-lg overflow-hidden"  to="/config"
                >
                  <v-list-item-icon>
                    <img style="width:23px" :class="dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/app.svg">
                  </v-list-item-icon>

                  <v-list-item-title class="app-bold  "  style="text-align: center; line-height: normal;font-size: 15px" >خصائص</v-list-item-title>

                </v-list-item>
                <v-list-item   class=" rounded-lg overflow-hidden" @click.prevent="getNumbers()"
                >
                  <v-list-item-icon>
                    <img  style="width:23px" :class="dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/alert.svg">
                  </v-list-item-icon>

                  <v-list-item-title class="app-bold  "  style="text-align: center; line-height: normal;font-size: 15px" >إتصل بنا</v-list-item-title>

                </v-list-item>
                <v-list-item v-if="user != null"  selectable class=" rounded-lg overflow-hidden" @click.prevent="logOut"
                >
                  <v-list-item-icon>
                    <img style="width:23px" :class="dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/logout.svg">
                  </v-list-item-icon>

                  <v-list-item-title class="app-bold tik--text "  style="text-align: center; line-height: normal;font-size: 15px" >خروج</v-list-item-title>

                </v-list-item>

              </v-list-item-group>


            </v-list>
            <v-card flat color="transparent" :dark="dark">
              <v-card-title style="display: block;text-align: center">
                <span  style="font-size: 13px">version 1.0.2</span>
              </v-card-title>
            </v-card>
          </div>
        </v-card>


      </v-navigation-drawer>
        <vs-dialog   blur v-model="SHOWnUM" :dark="dark" width="350px"  >
            <v-card flat  style="position:relative" width="350px" color="transparent" :dark="dark">
                <v-card-title class="py-0 pr-0">
                    <v-spacer></v-spacer>
                    <vs-avatar class="mt-n10"  circle size="60" @click="SHOWnUM=!SHOWnUM">
                        <img   style="width:35px;height:35px" src="assets/img/logo.svg" alt="DZtechno">
                    </vs-avatar>
                    <v-spacer></v-spacer>
                </v-card-title>
                <v-card-title class="py-0 pr-0">

                    <v-row no-gutters>
                        <v-col class="col-12">
                            <span  style="text-align:center;display:block " class="app-bold">قائمة الارقام الهاتفية</span>
                          <h6 style="text-align:end;display:block " class="app-regular">قم بإختيار قم الهاتف الخاص بالادارة</h6>
                            <vs-select class="app-regular" placeholder="رقم الهاتف" v-model="phone" block >

                                <vs-option  v-for="(number,index) in numbers" :key="index" :label="number.value" :value="number.value"  class="app-regular">
                                    {{number.value }}
                                    <v-spacer></v-spacer>
                                    {{number.comment}}
                                </vs-option>


                            </vs-select>

                        </v-col>


                    </v-row>
                </v-card-title>

                <v-card-actions class="pr-0">
                    <v-spacer></v-spacer>
                    <vs-button  block active :disabled="phone=='' && $vuetify.breakpoint.smAndDown"   :href="'tel://'+phone"    onclick="window.open('tel://'+phone"
                                :color="$store.state.App.config.colorBackground" class="app-bold mx-3  " size="large" >

                        <img width="20px" class="white-svg mx-2" src="assets/icons/vuesax/mobile.svg" />
                        إتصال
                    </vs-button>

                </v-card-actions>

            </v-card>



        </vs-dialog>



      <vs-dialog overflow-hidden prevent-close not-close :blur="$vuetify.breakpoint.mdAndUp" v-model="dialogSupport" not-padding class="px-1">
        <v-sheet :color="dark?'black-vuesax':'app-bg-light'" :dark="dark"
                 class="text-center  rounded-xl"
                 :class="$vuetify.breakpoint.mdAndUp?'bottom-sheet-radius-all':'bottom-sheet-radius'"

        >
          <v-container>

            <h2 class="app-regular" :class="dark?'white--text':'black--text'" style="text-align: right ">إختر العضوية</h2>
            <v-card-title class="vuertl py-0 app-regular"  >

              <p :class="dark?'grey--text':''" style="font-size:13px">
                إن كنت تاجر أو موزع قم باختيار العرض المناسب لك
              </p>
            </v-card-title>
            <v-row class=" mb-2" v-if="name !=  'success' ">
              <v-col class="col-6">
                <v-card :loading="name == 'GROSSISTE' " flat class="rounded-lg"   style="cursor: pointer;"

                        @click="updateType('GROSSISTE')"
                        :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                  <v-card-title style="display:block" class="  mx-auto pb-1">
                    <img :class="dark?'white-svg':'black-svg'"
                         src="assets/icons/vuesax/comerce.svg"/>
                  </v-card-title>
                  <p class="app-regular" :class="dark?'white--text':'black--text'">موزع جملة</p>

                </v-card>
              </v-col>
              <v-col class="col-6">
                <v-card  @click="updateType('COMMERCANT')"  :loading="name == 'COMMERCANT' "
                    flat class="rounded-lg" style="cursor: pointer;"
                    :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                  <v-card-title style="display:block" class="  mx-auto pb-1">
                    <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/shopping.svg"/>
                  </v-card-title>
                  <p  :class="dark?'white--text':'black--text'"  class="app-regular">تاجر</p>
                  <template slot="progress">
                    <v-progress-linear
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="2"
                    ></v-progress-linear>
                  </template>
                </v-card>
              </v-col>

            </v-row>
            <v-row class=" mb-2" v-if="name ==  'success' ">
              <v-col class="col-12 py-0">
                <v-card class="rounded-t-xl overflow-hidden"     ref="target" flat style="position: relative"
                        :color="dark ? 'black-vuesax' : ' '" :class="!$vuetify.breakpoint.smAndDown?'bottom-sheet-radius-all':'bottom-sheet-radius'">
                  <v-card-title class="p-0  pt-0">
                    <v-spacer></v-spacer>
                    <img class="success-svg" src="assets/icons/vuesax/success-response.svg" />
                    <v-spacer></v-spacer>


                  </v-card-title>
                  <v-card-title class="app-bold  text-center  py-0" style="font-size: 22px;display: block;">تم تحديث عضويتك بنجاح</v-card-title>
                  <v-card-actions class="app-regular">
                    <v-spacer></v-spacer>
                    <vs-button @click="dialogSupport = false" success gradient class="px-2"   >إغلاق</vs-button>

                  </v-card-actions>
                </v-card>



              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </vs-dialog>
    </div>






</template>
<script>
    import Notifications from "@/components/Notifications";
  
    import {formatToPrice } from "@/tools/Services";
    import {logOut , getProfile} from "@/plugins/authUser";
    import axios from 'axios'
    import {bus} from "@/tools/bus";
    import strMixins from '@/plugins/Mixins'
    export default {
        mixins:[strMixins],
        components:{Notifications},

        data:() => ({
            dialogSupport:false,
            phone:'',
            sidbar:null,
            SHOWnUM:false,
            group:'',
            active: 'home',
            numbers:[],
            name:"",
        }),
        methods:{
          getProfile,
          updateType: function (type) {
            this.name = type;
            axios.post('api/update-user-type', {name: type}).then((response) => {
              bus.$emit('loadSolde');
              this.name = "success";
            });

          },
          hideCard(){
            this.$store.commit('SET_ICARDACTIVE',false)
          },
          formatToPrice,logOut,openSid(){
                if (localStorage.getItem('sode')=="true"){
                    this.$store.commit('open/SET_SIDE',false)
                    localStorage.setItem("sidbar",JSON.stringify(false))
                  this.$store.dispatch('getNavbarItems')
                }
            },
            getNumbers(){
                axios.get('api/admin-numbers').then((response)=>{
                    this.numbers = response.data.numbers
                    this.SHOWnUM = !this.SHOWnUM
                })

            },
          async  logOutFrom(){
              localStorage.removeItem('token')
              this.$store.commit('auth/SET_USER',null)
              this.$router.push({name:'Configuration'})
            },

        },

        computed:{
          user:function (){
            return this.$store.state.auth.user
          },
          dark:function(){
            return this.$store.state.dark
          },
            sidbara:function(){
              return this.$store.state.sidbar.sidbar
            },
            message:function(){
                return this.$store.state.message
            },
            open:{
                 get:function () {
                     if (localStorage.getItem('sidbar')=="false"){
                         this.$store.commit('open/SET_SIDE',false)
                         return this.$store.state.open.side
                     }else{
                         this.$store.commit('open/SET_SIDE',this.$store.state.open.side)
                         return this.$store.state.open.side
                     }
                 }  ,
                set:function (value) {

                 }
            },

        },

        mounted() {
          this.sidbar = this.$store.state.sidbar.sidbar
          if (this.$vuetify.breakpoint.mdAndDown){
            this.$store.commit('open/SET_SIDE',false)
            return false
          }
          setTimeout(()=>{

            if(this.user != undefined || this.user != null){
              if (this.user.categorie == ""){
                this.dialogSupport = true
              }
              bus.$on('getProfile',function (){
                getProfile()
              })
            }

          },15000)




        }
    }
</script>
<style scoped>
    div.vs-sidbar_logo{
        padding-top: 5px!important;
        padding-bottom: 5px!important;
    }
    .v-navigation-drawer__content{
        overflow:hidden!important;
    }
    .v-navigation-drawer__content {
        height: 100%;
        overflow-y: auto!important;
        overflow-x: hidden!important;
    }
    #scroll::-webkit-scrollbar-thumb
    {
        border-radius: 10px!important;
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
        background-color: #555!important;
    }
    .v-navigation-drawer__content::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px #5d5d5d!important;
        background-color: #5d5d5d!important;
    }
    .v-navigation-drawer__content::-webkit-scrollbar{
        width: 0px!important;
    }
    .v-navigation-drawer__content::-webkit-scrollbar-thumb{
        -webkit-box-shadow: inset 0 0 6px #424242!important;
        background-color: #424242!important;
    }
</style>
