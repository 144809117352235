<template>
    <div>
        <v-card style="overflow: hidden" elevation="0" class="rounded-xl pa-0 mt-2" :dark="$store.state.dark"  :color="$store.state.dark?'#2d2d39':'#EEF2F5'" >
            <v-card-title class="pa-2">
                <v-row dense class="justify-center align-center d-flex" align-content="center" >
                    <v-col  class="col-12 " >
                       <v-btn  large elevation="0" depressed block  text color="success" class="v-btn--has-bg rounded-lg">
                           <img :class="colorSvg('Confirmé')" src="assets/icons/vuesax/success.svg" />
                           <v-spacer></v-spacer>
                           <span class="app-regular mx-2"> عملية ناجحة</span>



                       </v-btn>
                    </v-col>
                    <v-col class="col-12 " >
                        <v-btn  large elevation="0" depressed block  text color="error" class="v-btn--has-bg rounded-lg">

                            <img :class="colorSvg('Annulé')" src="assets/icons/status/annule.svg" />
                            <v-spacer></v-spacer>
                            <span class="app-regular mx-2">عملية خاطئة</span>

                        </v-btn>

                    </v-col>
                    <v-col class="col-12x" >
                        <v-btn  large elevation="0" depressed block  text color="warning" class="v-btn--has-bg rounded-lg">

                            <img :class="colorSvg('En attente')" src="assets/icons/status/enattante.svg" />
                            <v-spacer></v-spacer>
                            <span class="app-regular mx-2">عملية مشتبهة</span>

                        </v-btn>

                    </v-col>
                    <v-col class="col-12 " >
                        <v-btn    large elevation="0" depressed block  text color="primary" class="v-btn--has-bg rounded-lg">
                            <img :class="colorSvg('Sous traitement')" src="assets/icons/status/soustraitment.svg" />
                            <v-spacer></v-spacer>
                            <span class="app-regular mx-2">عملية في المعالجة</span>


                        </v-btn>

                    </v-col>

                </v-row >
            </v-card-title>
        </v-card>
    </div>
</template>

<script>
    import {colorSvg} from "@/tools/Services";

    export default {
    methods:{colorSvg}
    }
</script>

<style scoped>

</style>