<template>
   <div>
     <v-card v-if="$store.state.auth.user.type == 'admin'" :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''" >
       <v-container>
         <TitlePages title="أصناف بطاقات">
           <v-btn slot="btn" @click="$router.go(-1)"   large   style="float: left!important;" icon depressed><img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>

         </TitlePages>
         <vs-button @click="newModel()" block class="app-regular  ">
           إضافة نوع بطاقة جديد <i class="fas fa-credit-card nav-icon"></i>
         </vs-button>
         <div class="center mt-2" >
           <v-card flat class="pa-5 rounded-xl" :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
             <vs-table :dark="$store.state.dark" class="app-regular  "  >
               <template #thead >
                 <vs-tr dark>
                   <vs-th>
                     تغيير / حذف
                   </vs-th>
                   <vs-th>
                     الصورة
                   </vs-th>
                   <vs-th>
                     التفعيل
                   </vs-th>
                   <vs-th>
                     الاسم
                   </vs-th>

                 </vs-tr>
               </template>
               <template #tbody>
                 <vs-tr
                     v-for="cart in card" :key="cart.id">

                   <vs-td>
                     <v-row class="app-regular">
                       <v-col class="col-6">
                         <vs-button flat icon block
                                    @click="editModel(cart)"
                         >
                           تغيير
                         </vs-button>

                       </v-col>
                       <v-col class="col-6">
                         <vs-button block  danger @click="deleteCard(cart.id)">
                           حذف
                         </vs-button>
                       </v-col>
                     </v-row>
                   </vs-td>
                   <vs-td>
                     <v-row>
                       <v-col>
                         <vs-avatar style="cursor: pointer" size="38">
                           <img :src="setBaseUrl(cart.image)" :alt="cart.slug">
                         </vs-avatar>
                       </v-col>
                       <v-col>
                         <vs-avatar style="cursor: pointer" size="38" @click="redirect(cart.id)">
                           <img style="height: 18px" src="assets/icons/vuesax/card.svg" :class="$store.state.dark?'white-svg':'black-svg'">
                         </vs-avatar>
                       </v-col>
                     </v-row>
                   </vs-td>
                   <vs-td>
                     <vs-switch style="width: 60px;" success v-model="cart.isActive==1?true:false" @click="updateisActive(cart)">


                       <template #off>
                         <i class='bx bx-x' ></i>
                       </template>
                       <template #on>
                         <i class='bx bx-check' ></i>
                       </template>
                     </vs-switch>



                   </vs-td>
                   <vs-td>
                     {{ cart.name }}
                   </vs-td>

                 </vs-tr>
               </template>
               <template #notFound>
                 <span class="app-regular" :class="$store.state.params.color">لا توجد بيانات</span>
               </template>

             </vs-table>
           </v-card>
         </div>



         <!-- /.content -->
         <vs-dialog   v-model="groupeShow" >
           <div class="vuertl app-regular"  :dark ="$store.state.dark">
             <v-card-title v-text="editMode?'تغيير البطاقة':'إضافة بطاقة'"></v-card-title>
             <v-card-text>
               <form @submit.prevent="editMode ? updateCard() : addCart()">
                 <div class="modal-body">
                   <div class="form-group">

                     <v-text-field v-model="form.name" :dark ="$store.state.dark"
                                   type="text" placeholder="إسم البطاقة" outlined dense
                                   name="name" >

                     </v-text-field>


                   </div>
                   <div>
                     <v-text-field  :dark ="$store.state.dark"  @click.prevent="pick" style="cursor: pointer;"
                                    :placeholder="form.image!=''?'تم التحميل':'حمل الصورة'"
                                    outlined
                                    :success="form.image!=''"
                                    dense
                                    append-icon="mdi-file-image-outline"
                     ></v-text-field>

                     <input v-show="false"
                            type="file" ref="test"
                            name="image"
                            @change="uploadImage"
                            class="form-control"
                            :class="{ 'is-invalid': form.errors.has('method') }"
                     />
                   </div>
                 </div>
                 <div class="modal-footer app-medium">
                   <v-spacer></v-spacer>

                   <v-btn :loading="form.busy" :dark ="$store.state.dark"
                          v-show="editMode"
                          type="submit" class="white--text"
                          color="#03A9F4"
                   >
                     تحديث التغييرات
                   </v-btn>
                   <v-btn :loading="form.busy" :disabled="form.name == '' || form.image =='' " :dark ="$store.state.dark"
                          v-show="!editMode"
                          type="submit" class="white--text"
                          color="#4CAF50"
                   >
                     إضافة
                   </v-btn>
                   <v-btn :dark ="$store.state.dark" class=" mr-2" type="button" data-dismiss="modal" @click="groupeShow = !groupeShow">
                     اغلاق
                   </v-btn>
                 </div>
               </form>
             </v-card-text>
           </div>
         </vs-dialog>
       </v-container>

     </v-card>
     <NotFound v-else/>
   </div>
</template>
<script>
import Form from 'vform'
import axios from 'axios';
import TitlePages from "@/layout/TitlePages";
import {setBaseUrl} from "@/tools/Services";

export default {
    components: {TitlePages},
    data() {
    return {
        groupeShow:false,
      color: "#4CAF50",
      editMode: false,
      form: new Form({
        id: "",
        name: "",
        image: "",
      }),
      card: {},
      imagee: "",
      imageMode: 0,
      type: window.user,
    };
  },
  mounted() {
 
      this.loadcard();


  
  },
  methods: {setBaseUrl,
      redirect(id){
          this.$router.push({name:"HomeCardInfo",params:{id:id}})
      },
      pick(){
        this.$refs.test.click()
      },
  async  updateisActive(game) {
 
     try {
     await    axios({
             method: "post",
             url: "api/update-is-active-groupe",
             data:game,
             headers: {
                 'content-type': 'multipart/form-data'
             }
         })
       await  this.loadcard()
     }catch (e) {
         await this.$store.dispatch("loadsna", {
             text: e.response.data.message,
             isSuccess: false,
         });
     }
    },
    showImage() {
      (this.imageMode = 0), (this.imageMode = 1);
        this.groupeShow = !this.groupeShow
    },
    uploadImage(e) {
      let file = e.target.files[0];

      let reader = new FileReader();
      let limit = 1024 * 1024 * 2;
      if (file["size"] > limit) {
          this.groupeShow = false
        Swal.fire({
          icon: "error",
          title: "حجم الصورة",
          text: "حجم الصورة أكبر من 05 ميغا بيت قم بتغيير الصورة",
        });
        return false;
      }
      reader.onloadend = (file) => {
        this.form.image = reader.result;
      };
      reader.readAsDataURL(file);

    },
    updateCard() {

      this.form
        .post("api/groupe-admin-update")
        .then(() => {
          this.groupeShow = !this.groupeShow
            this.$store.dispatch("loadsna", {
                text: 'تم تحديث المنتج بنجاح',
                isSuccess: true,
            });
            this.loadcard()

        })
        .catch(() => {
            this.$store.dispatch("loadsna", {
                text: "حدث خطأ ما يرجى اعادة المحاولة",
                isSuccess: false,
            });
        });
    },
    newModel() {
      this.editMode = false;
      this.form.reset();
        this.groupeShow = !this.groupeShow
    },

    editModel(cart) {
      this.editMode = true;
      this.form.reset();
        this.groupeShow = !this.groupeShow
      this.form.fill(cart);
    },

    loadcard() {
      axios.get("api/get-card-groupe").then((response) => (this.card = response.data));
    },

    addCart() {
      this.form
        .post("api/groupe-admin")
        .then(() => {
         this.groupeShow = !this.groupeShow
          this.form.reset();
         this.loadcard()
            this.$store.dispatch("loadsna", {
                text: 'تم إضافة المنتج بنجاح',
                isSuccess: true,
            });


        
        })
        .catch(() => {
            this.$store.dispatch("loadsna", {
                text: "حدث خطأ ما يرجى اعادة المحاولة",
                isSuccess: false,
            });
        });
    },
    deleteCard(id) {
      Swal.fire({
        title: "حذف المنتج",
        text: " هل أنت متأكد",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "نعم، قم بالحذف",
      }).then((result) => {
        if (result.value) {
          this.form
            .delete("api/groupe-admin/" + id)
            .then((response) => {
                this.loadcard()
                this.$store.dispatch("loadsna", {
                    text: response.data.message,
                    isSuccess: true,
                });


            })
            .catch((error) => {
                this.$store.dispatch("loadsna", {
                    text: error.response.data.message,
                    isSuccess: false,
                });
            });
        }
      });
    },
  },
};
</script>
