<template>
<div>
   <component :is="$vuetify.breakpoint.mdAndUp?'VDialog':'VBottomSheet'"  :max-width="$vuetify.breakpoint.mdAndUp?'500':''" :inset="$vuetify.breakpoint.mdAndUp"    :class="$store.state.dark?'app-bg':''"  v-model="active3">
            <v-card  :max-width="$vuetify.breakpoint.mdAndUp?'500':''" :dark="$store.getters.dark" flat   :class="$vuetify.breakpoint.mdAndUp?'rounded-xl':'rounded-t-xl'">

                <v-card-title class="vuertl pb-0 pt-2"  >

                    <p class="mb-0" :class="$store.state.dark?'grey--text':''" style="font-size:14px">
                        قم بتحويل رصيد لجميع زبائن المنصة
                    </p>
                </v-card-title>
                <v-stepper :class="$store.state.dark?'app-bg':''"    :dark="$store.getters.dark" color="transparent" flat v-model="e1" width="100%"    class="vuertl rounded-t-xl elevation-0 overflow-hidden"       >
                    <v-stepper-header   v-if="e1 !=4"   >
                        <div  v-if="e1 == 1" class="text-center mx-1 mt-4" v-text="'رقم هاتف الزبون'" ></div>
                        <v-stepper-step
                                :complete="e1 > 1"
                                step="1"
                                color="#2196F3"

                        >
                        </v-stepper-step>
                        <div  v-if="e1 == 2" class="text-center mt-4">{{'أدخل المبلغ بالدينار'}}</div>
                        <v-divider v-if="e1 == 1 "></v-divider>
                        <v-stepper-step
                                :complete="e1 > 2"
                                step="2"
                                color="#2196F3"  >

                        </v-stepper-step>
                        <div  v-if="e1 == 3" class="text-center mt-4">{{'أدخل كود التحقق'}}</div>
                        <v-divider v-if="e1 != 3"></v-divider>
                        <v-stepper-step color="#2196F3" :step="3" :complete="form.image!=''&& form.amount>='2000' && form.method!=''">
                        </v-stepper-step>



                    </v-stepper-header>

                    <v-stepper-items    >
                        <v-stepper-content  step="1" color="transparant" >
                            <v-card flat >



                                <v-text-field
                                        style="font-size: 19px;height:50px!important" maxlength="10" minlength="10"
                                        type="number" filled hide-details
                                        class="app-regular rounded-lg input-class"
                                        :class="dark?'black--text':'white--text'"
                                        :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                        placeholder="أدخل رقم الهاتف"

                                        v-model="form.phone"
                                        :success="form.phone.length == 10 &&form.phone !=$store.state.auth.user.phone"
                                        :success-messages="SuccessMessage"
                                        :placeholder="'رقم الزبون'"

                                >

                                    <template v-slot:append>
                                        <v-icon v-if="form.phone.length===10 &&form.phone !=$store.state.auth.user.phone " color="#4CAF50" >
                                            mdi-check-circle-outline
                                        </v-icon>
                                        <v-icon  v-if="form.phone.length===10 && form.phone==$store.state.auth.user.phone" color="#ED1C24" >
                                            mdi-close-circle-outline
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-card>

                            <v-btn large
                                    :disabled="form.phone.length!=10 || form.phone == $store.state.auth.user.phone"
                                    class="mt-2 primary text-white mb-2 px-5  mt-3  rounded-lg" block
                                    @click="e1 = 2"
                            >
                                التالي
                            </v-btn>

                        </v-stepper-content>
                        <v-stepper-content step="2"  >
                            <v-card flat  >
                                <v-text-field
                                        style="font-size: 19px;" minlength="10"
                                        type="number" filled hide-details
                                        class="app-regular rounded-lg input-class"
                                        :class="dark?'black--text':'white--text'"
                                        :background-color="dark?'#2d2d39':'#EEF2F5'" block


                                        v-model="form.Amount"
                                        :placeholder=" 'أدنى مبلغ 1000 دج '"
                                        maxlength="6"

                                >
                                    <template v-slot:append>
                                        <v-icon   v-if="  form.Amount>=1000 && form.Amount<=50000" color="#4CAF50" >
                                            mdi-check-circle-outline
                                        </v-icon>
                                        <v-icon     v-if="form.Amount>50000" color="#ED1C24" >
                                            mdi-close-circle-outline
                                        </v-icon>
                                    </template>
                                </v-text-field>
                            </v-card>

                            <v-btn :loading="form.busy"
                                   :disabled="form.Amount <1000 && $store.state.auth.user.solde <= 1000"
                                   class="primary text-white mt-2 mb-2  px-5"
                                   @click.prevent="redirectPayment()"

                            >
                                التالي
                            </v-btn>
                            <v-btn class="mt-2" :disabled="form.busy" text @click="e1 = 1" style="float: left!important;" icon depressed><img
                                    :class="dark?'white-svg':'black-svg'"
                                    src="assets/icons/vuesax/chevron-right.svg"/></v-btn>

                        </v-stepper-content>
                        <v-stepper-content step="3"  >

                            <v-card color="transparent" flat  :dark="$store.state.dark">

                                <v-card-text class="py-0">
                                    <v-icon color="#4CAF50">mdi-information-outline</v-icon>
                                    {{'قم يإدخال رمز التأكيد المرسل الى البريد الالكتروني'}}
                                </v-card-text>
                                <v-row dense no-gutters class="justify-grade align-center ">
                                    <v-col class="col-12 mt-2 pb-1">
                                        <v-text-field style="font-size: 19px;" minlength="10"
                                                      type="number" filled hide-details
                                                      class="app-regular rounded-lg input-class"
                                                      :class="dark?'black--text':'white--text'"
                                                      :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                                      :placeholder="'أدخل رمز التحقق '"

                                                      v-model="form.pin"></v-text-field>
                                    </v-col>


                                </v-row>
                                <v-card-actions>
                                    <v-btn  :loading="form.busy"
                                            :disabled="form.pin.length!=4"

                                            class="primary text-white my-2 mb-2"
                                            @click="addPayements"
                                    >
                                        <span v-if="!form.busy">{{'إرسال'}}</span>
                                    </v-btn>
                                    <v-spacer></v-spacer>
                                    <v-btn class="mt-2" :disabled="form.busy" text @click="e1 = 2" style="float: left!important;" icon depressed><img
                                            :class="dark?'white-svg':'black-svg'"
                                            src="assets/icons/vuesax/chevron-right.svg"/></v-btn>
                                </v-card-actions>
                            </v-card>



                        </v-stepper-content>
                        <v-stepper-content step="4"  >
                            <v-card flat color="transparent" >

                                 <v-card     ref="target" flat style="position: relative" :color="dark ? 'black-vuesax' : ' '">

                                    <v-card-title class="app-regular  text-center pt-1" style="font-size: 22px;display: block;">تم تحويل الرصيد بنجاح</v-card-title>
                                     <v-card-subtitle style="direction: ltr;
                                                 text-align: center;" v-text="success? dataResponse.amount + ' ' + 'تم الخصم من رصيدك '  :'حدث خطأ ما يرجى إعادة المحاولة'"></v-card-subtitle>
                                     <v-card style="display: block;text-align: center"   :color="dark?'#2d2d39':'#EEF2F5'" class="elevation-0 rounded-xl  "
                                     >
                                         <v-card-title class="pb-0">
                                             <v-spacer></v-spacer>
                                             <vs-avatar size="50" pointer @click="$router.push('/profile')" >
                                                 <img width="40px" src="assets/img/profile.png">
                                             </vs-avatar>
                                             <v-spacer></v-spacer>

                                         </v-card-title>
                                         <v-card-title class="pt-0">
                                             <h5  style="color: #4CAF50" >{{dataResponse.solde}}</h5>
                                             <v-spacer></v-spacer>
                                             {{$store.state.auth.user.username}}
                                             <v-spacer></v-spacer>
                                              <span  style="color: #E40814;direction: ltr">{{ '-' + dataResponse.amount}}</span>
                                         </v-card-title>
                                          <v-card-subtitle>
                                              {{dataResponse.updated_at | datee}}
                                          </v-card-subtitle>
                                     </v-card>


                                    <v-card-actions class="app-regular">
                                        <vs-button   color="rgb(254, 44, 85)"   >إتمام عملية الشراء</vs-button>
                                    </v-card-actions>
                                </v-card>

                            </v-card>
                        </v-stepper-content>

                    </v-stepper-items>
                </v-stepper>

            </v-card>
         </component>
    <component  :max-width="$vuetify.breakpoint.mdAndUp?'500':''" :class="$store.state.dark?'app-bg':''"  :is="$vuetify.breakpoint.mdAndUp?'VDialog':'VBottomSheet'"      v-model="transferBonus" :inset="$vuetify.breakpoint.mdAndUp">
        <v-card   :max-width="$vuetify.breakpoint.mdAndUp?'500':''" :dark="$store.getters.dark" flat v-vuesax :class="$vuetify.breakpoint.mdAndUp?'rounded-xl':'rounded-t-xl'" >

            <v-stepper  v-vuesax  :dark="$store.getters.dark" flat v-model="e2" width="100%"    class="vuertl rounded-t-xl elevation-0 overflow-hidden"       >
             
                <v-stepper-items   >
                    <v-stepper-content  step="1"  >
                        <vs-alert class="px-2 rounded-xl" primary       style="text-align: center">
                            <h4 v-text="'عند تحويل الارباح الى رصيدك يتم حذف جميع التعاملات ( ليستوريك تاع الونيس )  تحقق من المبلغ قبل التحويل'"></h4>
                        </vs-alert>

                        <v-btn @click="transfer()" large block color="tik"

                                class="mt-2 white--text mb-2 px-5 rounded-lg"

                        >
                            تحويل الارباح
                        </v-btn>

                    </v-stepper-content>
                    <v-stepper-content step="2"  >
                        <v-card flat v-if="message != null">

                            <v-card     ref="target" flat style="position: relative" :color="dark ? 'black-vuesax' : ' '">

                                <v-card-title class="app-regular  text-center pt-1" style="font-size: 22px;display: block;"  >
                                  <h3>{{message.message}}</h3>
                                </v-card-title>
                                 <v-card style="display: block;text-align: center"   :color="dark?'#2d2d39':'#EEF2F5'" class="elevation-0 rounded-xl  "
                                >
                                    <v-card-title class="pb-0 pt-1">
                                        <v-spacer></v-spacer>
                                        <vs-avatar size="50" pointer @click="$router.push('/profile')" >
                                            <img width="40px" src="assets/img/profile.png">
                                        </vs-avatar>
                                        <v-spacer></v-spacer>

                                    </v-card-title>
                                    <v-card-title class="pt-0">
                                        <h3 style="color: #4CAF50;font-family: sans-serif" >{{formatToPrice($store.state.auth.user.solde)}}</h3>
                                        <v-spacer></v-spacer>
                                        {{$store.state.auth.user.username}}
                                        <v-spacer></v-spacer>
                                        <h3  style="color: #E40814;direction: ltr" >{{formatToPrice($store.state.auth.user.bonus)}}</h3>
                                    </v-card-title>
                                    <v-card-subtitle class="pb-2 app-bold"  >
                                        {{$store.state.auth.user.updated_at | datee}}
                                    </v-card-subtitle>
                                </v-card>


                                <v-card-actions class="app-regular" @click="transferBonus = !transferBonus">
                                    <vs-button   primary  >تمت عملية التحويل</vs-button>
                                </v-card-actions>
                            </v-card>


                        </v-card>
                    </v-stepper-content>

                </v-stepper-items>
            </v-stepper>

        </v-card>
    </component>
    <component  :is="$vuetify.breakpoint.mdAndUp?'VDialog':'VBottomSheet'" class="overflow-hidden"     :max-width="$vuetify.breakpoint.mdAndUp?'500':''"  v-model="demandeCredit" :inset="$vuetify.breakpoint.mdAndUp">
        <v-card  :dark="$store.getters.dark" flat v-vuesax  :max-width="$vuetify.breakpoint.mdAndUp?'500':''" :class="$vuetify.breakpoint.mdAndUp?'rounded-xl':'rounded-t-xl'">
            <v-card-text class="vuertl pb-0 pt-2 px-3"  >



                <vs-alert class="px-1" primary       style="text-align: center"  v-if="$store.state.auth.user.refferal == ''">
                 <h4>يمكنك إرسال طلب شحن رصيد الى أي متعامل يمكنه شحن رصيدك وذلك بكتابة رقم هاتف الموزع لتلقي طلب الشحن </h4>
                </vs-alert>
              <vs-alert class="px-1 rounded-lg" success       style="text-align: center" v-else>
                <h4>طلب الاحالة مفعل من طرف الادارة  </h4>
              </vs-alert>
            </v-card-text>
            <v-card>
               <v-container  class="elevation-0 " >
                   <v-text-field :disabled="$store.state.auth.user.refferal != '' "
                           style="font-size: 19px;height:43px!important" maxlength="10" minlength="10"
                           type="number" filled hide-details
                           class="  app-regular vuertl rounded-lg input-class mb-4"
                           :class="dark?'black--text':'white--text'"
                           :background-color="dark?'#2d2d39':'#EEF2F5'" block
                           placeholder="أدخل رقم الهاتف"

                           v-model="demande.phone"
                            :success-messages="SuccessMessage"
                           :placeholder="'رقم هاتف موزعك'"

                   >

                       <template v-slot:append>
                           <v-icon v-if="demande.phone.length ==10  "  color="#4CAF50" >
                               mdi-check-circle-outline
                           </v-icon>
                           <v-icon  v-if="demande.phone.length >=11" color="#ED1C24" >
                               mdi-close-circle-outline
                           </v-icon>
                       </template>
                   </v-text-field>
                   <v-btn @click.prevent="handelDemandeCredit()" large block :loading="demande.busy"

                          class="mt-2 primary text-white mb-2 px-5 rounded-lg app-regular" :disabled="demande.phone.length != 10"

                   >
                       إرسال طلب شحن رصيد
                   </v-btn>



               </v-container>
            </v-card>
        </v-card>
    </component>
</div>
</template>

<script>

import {formatToPrice, getTextColor} from "@/tools/Services";
    import {VDialog , VBottomSheet} from "vuetify/lib/components"
    import {getProfile} from "@/plugins/authUser";
    import Form from 'vform'
    import axios from 'axios'
    import {bus} from "@/tools/bus";
import VueRecaptcha from 'vue-recaptcha';
     export default {
        name: "DialogAddTansfer",
         components:{VDialog, VBottomSheet , VueRecaptcha},
        computed:{
            dark:function (){
                return this.$store.state.dark
            },

        },
        data(){
            return{
              sitekey:'6LeXWxUqAAAAAMTfpynU4tVXgzFYB9o0ghaU_82k',
                demandeCredit:false,
                loadingBonus:false,
                message:null,
                transferBonus:false,
                active3:false,
                dataResponse:[],
                e2:1,
                e1:1,
                mode:'',
                isSuccess:false,
                language:'ar',
                isRight:false,
                disabledBtn:true,
                SuccessMessage:'',
                success:false,
                lastSteppe:false,
                form: new Form({
                    phone:'',
                    Amount:'',
                    pin:'',
                    user:'',
                  recaptcha:''
                }),
              passeCaptchaLog:false,
                demande: new Form({
                    phone:'',

                }),

            }
        },
         mounted() {
            bus.$on('loadDialogTransfer',()=>{

                this.transferBonus =  false
                this.demandeCredit = false
                this.active3 = !this.active3
              this.$store.commit('SET_ICARDACTIVE',true)
            })
             bus.$on('loadDialogTransferBonus',()=>{
                 this.active3 =false
                 this.demandeCredit = false
                 this.transferBonus = !this.transferBonus
               this.$store.commit('SET_ICARDACTIVE',true)
             })
             bus.$on('loadDialogDemandeCredit',()=>{
                 this.transferBonus =  false
                 this.active3 =false
                 this.demandeCredit = !this.demandeCredit
               this.$store.commit('SET_ICARDACTIVE',true)
             })
         },
         methods:{
           formatToPrice,
             handelDemandeCredit(){
             this.demandeCredit = !this.demandeCredit
               this.demande.post('api/demande-access-credit').then((response)=>{
                   this.$vs.notification({
                       icon:`<img class="white-svg" src="assets/icons/status/confirme.svg" />`,
                       classNotification:"vuertl",
                       position:'top-center',
                       duration:'4000',
                       progress: 'auto',
                       color:'success',
                       title: 'حدث خطأ',
                       text:response.data.message
                   })

               }).catch((e)=>{
                 this.demandeCredit = !this.demandeCredit
                   this.$vs.notification({
                       icon:`<img class="white-svg" src="assets/icons/status/annule.svg" />`,
                       classNotification:"vuertl",
                       position:'top-center',
                       duration:'4000',
                       progress: 'auto',
                       color:'danger',
                       title: 'حدث خطأ',
                       text:e.response.data.message || "حدث خطأ يرجى إعادة المحاولة"
                   })

               })
             },
             transfer(){
                 this.loadingBonus = true
                 axios.post('api/convert-bonus',{username:this.$store.state.auth.user.username,bonus:this.$store.state.auth.user.bonus}).then(async (response)=>{
                     this.message = response.data.message
                     bus.$emit('loadSolde')
                     this.loadingBonus = false
                     this.e2 = 2

                 }).catch((e)=>{
                     this.$store.dispatch("loadsna", {
                         text: e.response.data.message || 'حدث خطأ ما يرجى إعادة المحاولة'  ,
                         isSuccess: false,
                     });
                 })
             },
            getProfile,
            getTextColor,

            async   redirectPayment(){
             if (this.$store.state.auth.user.solde >=1000){
               let vm = this
               vm.user = vm.form.phone
               try {
                 const response = await vm.form.post("api/check-user")
                 vm.form.pin = ''
                 vm.e1 = 3
                 await vm.$store.dispatch("loadsna", {
                   text: 'تم إرسال رمز التأكيد إلى بريدك الإلكتروني',
                   isSuccess: true,});
               }catch (e) {
                 this.e1 = 1
                 this.form.pin = ''
                 this.form.Amount = ''
                 await this.$store.dispatch("loadsna", {
                   text: e.response.data.message || 'حدث خطأ ما يرجى إعادة المحاولة',
                   isSuccess: false,
                 });
               }
             }else {
               await this.$store.dispatch("loadsna", {
                 text: "قم بشحن رصيدك",
                 isSuccess: false,
               });
             }


            },
            pickFile(){
                this.$refs.image.click()
                //   document.getElementById('image').click()

            },
            uploadImage(e) {
                this.form.image= []
                this.lastSteppe = false
                let file = e.target.files[0];
                if(file){
                    let reader = new FileReader();
                    reader.onloadend = file => {
                        const checkImage = reader.result.split('/')
                        if (checkImage[0] == 'data:image'){
                            this.form.image = reader.result;
                            this.lastSteppe = true
                            this.e1 = 3
                        }else {
                            this.lastSteppe = false
                            this.$Progress.fail();
                            this.$store.dispatch('loadsna', {
                                text: 'حدث خطأ ما يرجى إعادة المحاولة',
                                isSuccess: false,
                            })
                            this.form.image= []
                        }
                    };
                    reader.readAsDataURL(file);
                }else {
                    this.lastSteppe = false
                    this.$Progress.fail();
                    this.$store.dispatch('loadsna', {
                        text:'حدث خطأ ما يرجى إعادة المحاولة',
                        isSuccess: false,
                    })
                }
            },
            addPayements: async function(){
                if (this.form.pin.length == 4){

                    try{
                        const response = await this.form.post('api/transfer-for-client')
                        this.dataResponse = response.data.transfer
                        this.dataResponse.client = response.data.client
                        this.dataResponse.solde = response.data.solde
                        const user =  await getProfile()
                        await this.$store.commit('auth/SET_USER', user.data)
                        this.success = true
                        this.e1 = 4
                        this.form.clear()
                        this.lastSteppe = false

                    }catch (error) {
                        this.form.pin = ''
                        await this.$store.dispatch("loadsna", {
                            text: error.response.data.message || 'حدث خطأ ما يرجى إعادة المحاولة',
                            isSuccess: false,
                        });


                    }
                }else {
                    this.form.pin = ''
                    await this.$store.dispatch('loadsna', {
                        text: 'قم بإدخال رمز التحقق',
                        isSuccess: false
                    })
                }




            },

        },
         watch:{
             active3:function (newVal) {
            if(newVal == false){

            }
             }
         }
    }
</script>
<style scoped>
    .v-text-field--outlined >>> fieldset {
        border-color: #fff;
    }
    >>> .v-input__slot::before {
        border-style: none !important;
    }
    >>> .v-input__slot::after {
        border-style: none !important;
    }

</style>
