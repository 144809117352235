
export default {
    namespaced: true,
    state:{
        cartes:null
    },
    getters:{
        cartes(state){
            return state.cartes
        },
 
    },
    mutations:{
        SET_CARTES (state, value) {
            state.cartes = value
        },
     
    },
  
}