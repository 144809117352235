<template>
   <v-card   :color="dark?'app-bg':''" flat>

    <v-container v-if="product">

        <v-card :ripple="false" @click="$router.go(-1)"  elevation="0" :dark="dark" :color="dark ? 'app-card' : 'app-card-light'" class="rounded-xl vuertl" >
             <v-card-title  >
                {{ product.Operateur }}
                <v-spacer></v-spacer>
                <v-btn @click="$router.go(-1)"  :dark="dark" icon class="ml-2 v-btn--has-bg" large  active   >
                    <img style="width: 28px" :class="dark ? 'white-svg' : 'black-svg'"
                         src="assets/icons/vuesax/chevron-right.svg" />
                </v-btn>


            </v-card-title>
            <v-list flat   :color="dark ? 'app-card' : 'app-card-light'" >
                <v-list-item style="position: relative; top: -20px">

                    <v-card   class="mx-2" rounded="lg" height="100" >
                        <img height="100%" :src="setImage(product.PhoneN)" />
                    </v-card>
                    <v-list-item-content class="pa-2 mt-8 mr-3 p-1">
                        <v-list-item-title style="line-height: 1.9!important;" :class="$store.state.params.fontBold"
                                           v-text="product.name"></v-list-item-title>
                        <v-list-item-subtitle style="line-height: 1.9!important;"  class="app-bold"></v-list-item-subtitle>
                         <b>{{product.PhoneN}}</b>
                    </v-list-item-content>
                </v-list-item>
            </v-list>


        </v-card>
        <v-alert class="rounded-xl rounded-tr-0 mt-3 text-center app-bold"
                 text
                 prominent
                 :type="AlertIcon(product.Status)"

                 v-text="product.response"
        >
            <template slot="">
                <img :class="colorSvg(product.Status)" :src="iconResponse(product.Status)" />
            </template>
        </v-alert>
        <v-card class="mt-2" flat style="position:relative" height="100%" width="100%" :color="dark?'app-bg':'app-bg-light'">
            <v-card-title   class="vuertl py-1 app-bold">
                <h4 class="mb-2 ">معلومات الفليكسي</h4>
            </v-card-title>
            <v-card :ripple="false"   class="pa-0 vuertl rounded-lg py-0 overflow-hidden mb-2 mt-2" flat  :color="dark?'app-card':'app-card-light'">

                <v-list-item :ripple="false"   two-line  :dark ="dark" :color="dark?'app-card':'app-card-light'" class="pa-0"   >
                    <b class="mr-3">المبلغ</b>
                    <v-list-item-content>

                        <v-list-item-title class="samsung app-medium" > </v-list-item-title>
                    </v-list-item-content>
                    <v-card-title class="py-0">
                        <h3  class="sans-serif">{{ formatToPrice(product.Amount) }}</h3>
                    </v-card-title>
                </v-list-item>

            </v-card>


            <v-card :ripple="false"   class="pa-0 vuertl rounded-lg py-0 overflow-hidden mb-2 mt-2" flat  :color="dark?'app-card':'app-card-light'">

                <v-list-item :ripple="false"   two-line  :dark ="dark" :color="dark?'app-card':'app-card-light'" class="pa-0"   >
                    <b class="mr-3">بعد التخفيض</b>
                    <v-list-item-content>

                        <v-list-item-title class="samsung app-medium" > </v-list-item-title>
                    </v-list-item-content>
                    <v-card-title class="py-0">
                      <h3 class="success--text">{{ formatToPrice(product.Total) }}</h3>
                    </v-card-title>
                </v-list-item>

            </v-card>



            <v-card :ripple="false"   class="pa-0 vuertl rounded-lg py-0 overflow-hidden mb-2 mt-2" flat  :color="dark?'app-card':'app-card-light'">

                <v-list-item :ripple="false"   two-line  :dark ="dark" :color="dark?'app-card':'app-card-light'" class="pa-0"   >
                    <v-list-item-avatar class="mx-3">

                        <vs-avatar>
                            <img height="100%" :src="setImage(product.PhoneN)" />
                        </vs-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <b>{{product.PhoneN}}</b>
                        <v-list-item-title class="samsung app-medium" > </v-list-item-title>
                    </v-list-item-content>
                    <v-card-actions    >
                        <p class="mb-0 ml-2" style="font-size:18px">{{  product.Operateur  + ' - ' + product.Mode}}</p>
                    </v-card-actions>
                </v-list-item>

            </v-card>

            <v-card :ripple="false"   class="pa-0 vuertl rounded-lg py-0 overflow-hidden mb-2" flat  :color="dark?'app-card':'app-card-light'">

                <v-list-item :ripple="false"   two-line  :dark ="dark" :color="dark?'app-card':'app-card-light'" class="pa-0"   >

                    <v-list-item-content>
                       <b class="mr-4">تاريخ الطلب</b>
                        <v-list-item-title class="samsung app-medium" > </v-list-item-title>
                    </v-list-item-content>
                    <v-card-actions    >
                        <p class="mb-0 ml-2" style="font-size:18px">{{product.created_at | datee}}</p>
                    </v-card-actions>
                </v-list-item>

            </v-card>
            <v-card :ripple="false"   class="pa-0 vuertl rounded-lg py-0 overflow-hidden mb-2" flat  :color="dark?'app-card':'app-card-light'">

                <v-list-item :ripple="false"   two-line  :dark ="dark"    class="pa-0"   >

                    <v-list-item-content>
                       <b class="mr-4">الحالة</b>
                        <v-list-item-title class="samsung app-medium" > </v-list-item-title>
                    </v-list-item-content>
                    <v-card-actions    >
                        <p class="mb-0 ml-2" style="font-size:18px" :class="getColorClass(product.Status)" >{{arabResp(product.Status)}}</p>
                    </v-card-actions>
                </v-list-item>

            </v-card>















        </v-card>
        </v-container>
      

    </v-card>
     
  </template>
  
  <script>

 import TitlePages from "@/layout/TitlePages";
 import {
   formatToPrice,
   setBaseUrl,
   iconResponse,
   colorSvg,
   setImage,
   AlertIcon,
   arabResp,
   getColorClass
 } from '@/tools/Services';

 export default {
      components: {TitlePages},

      methods:{setBaseUrl,AlertIcon,iconResponse,colorSvg,setImage,arabResp,getColorClass,
            formatToPrice
        },
        data(){
          return{
              load:false,

              selected:'',
          }
        },

       computed:{

        product(){
             return this.$store.state.product
        }  ,
          dark(){
              return this.$store.state.dark
          }  ,
      },
      
  }
  </script>
  
  <style>
   .v-responsive__content{
    backdrop-filter: blur(3px)
   }
   .icon-right{
    position: absolute!important;
    top: 7px!important;
    right: 11px!important;
   }   
  </style>
  <style lang="sass" scoped>
  #pic .topright
    position: absolute
    top:  11px
    left: 8px
    z-index: 1
    background: rgb(189, 189, 189, 0.4)
  
  </style>