<template>
    <div>

        <v-card  style="overflow: hidden" elevation="0" class="rounded-xl pa-0 scroll" :dark="$store.state.dark"
                 v-if="prices"
                >

            <v-card-title class="pa-0">
                <vs-button size="small" color="warn"  block class="black--text ma-2 mt-0 rounded-t-xl mt-1">

                    <img style="width:25px" class="black-svg mr-3 " src="assets/icons/vuesax/admin.svg">
                    <h4  >{{prices.name}}</h4>
                </vs-button>

            </v-card-title>
            <v-card-title class="pb-0 mt-0 pt-1">
                <v-spacer></v-spacer>

                <span class="app-bold">فليكسي تجزئة</span>
            </v-card-title>
            <v-card-title class="pa-2"  >
                <v-row dense class="justify-center align-center d-flex" align-content="center" >
                    <v-col  class="col-12 " >

                        <v-card   large elevation="0" depressed block class="rounded-lg">
                        <v-card-title class="py-2">
                            <span class="app-regular mx-2" style="font-size: 17px"><b>{{prices.mobilis+ "%"}}</b></span>
                            <v-spacer></v-spacer>
                                <span class="app-regular"> موبيليس</span>
                            <v-spacer></v-spacer>
                            <v-avatar size="40">
                                <img src="assets/img/flexy/mobilis.png" />
                            </v-avatar>
                        </v-card-title>

                            <v-card-title class="py-2">
                                <span class="app-regular mx-2" style="font-size: 17px"><b>{{prices.djezzy+  "%"}}</b></span>
                                <v-spacer></v-spacer>
                                <span class="app-regular">جيزي</span>
                                <v-spacer></v-spacer>
                                <v-avatar size="40">
                                    <img src="assets/img/flexy/djezzy.png" />
                                </v-avatar>
                            </v-card-title>
                            <v-card-title class="py-2">
                                <span class="app-regular mx-2" style="font-size: 17px"><b>{{prices.ooredoo+ "%"}}</b></span>
                                <v-spacer></v-spacer>
                                <span class="app-regular">أوريدو</span>
                                <v-spacer></v-spacer>
                                <v-avatar size="40">
                                    <img src="assets/img/flexy/ooredoo.png" />
                                </v-avatar>
                            </v-card-title>


                        </v-card>
                    </v-col>


                </v-row >
            </v-card-title>
            <v-card-title class="pb-0 mt-0 pt-1">
                <v-spacer></v-spacer>

                <span class="app-bold">فليكسي جملة</span>
            </v-card-title>
            <v-card-title class="pa-2"  >
                <v-row dense class="justify-center align-center d-flex" align-content="center" >
                    <v-col  class="col-12 " >
                        <v-card   large elevation="0" depressed block class="rounded-lg">

                            <v-card-title class="py-2">
                                <span class="app-regular mx-2" style="font-size: 17px"><b>{{prices.mobilisGross+  "%"}}</b></span>
                                <v-spacer></v-spacer>
                                <span class="app-regular"> موبيليس</span>
                                <v-spacer></v-spacer>
                                <v-avatar size="40">
                                    <img src="assets/img/flexy/mobilis.png" />
                                </v-avatar>
                            </v-card-title>

                            <v-card-title class="py-2">
                                <span class="app-regular mx-2" style="font-size: 17px"><b>{{prices.djezzyGross+  "%"}}</b></span>
                                <v-spacer></v-spacer>
                                <span class="app-regular">جيزي</span>
                                <v-spacer></v-spacer>
                                <v-avatar size="40">
                                    <img src="assets/img/flexy/djezzy.png" />
                                </v-avatar>
                            </v-card-title>
                            <v-card-title class="py-2">
                                <span class="app-regular mx-2" style="font-size: 17px"><b>{{prices.ooredooGross+  "%"}}</b></span>
                                <v-spacer></v-spacer>
                                <span class="app-regular">أوريدو</span>
                                <v-spacer></v-spacer>
                                <v-avatar size="40">
                                    <img src="assets/img/flexy/ooredoo.png" />
                                </v-avatar>
                            </v-card-title>


                        </v-card>
                    </v-col>



                </v-row >
            </v-card-title>
            <v-card-title class="pb-0">
                <v-spacer></v-spacer>

                <span class="app-bold">سعر البطاقات</span>
            </v-card-title>
            <v-card-title class="pb-0 mt-0 pt-1">
                <v-row dense class="justify-center align-center d-flex" align-content="center" >
                    <v-col  class="col-12 " >
                        <v-card   large elevation="0" depressed block class="rounded-lg">

                            <v-card-title class="py-2">
                                <span class="app-regular mx-2" style="font-size: 17px"><b>{{prices.idoom+  "%"}}</b></span>
                                <v-spacer></v-spacer>
                                <span class="app-regular"> إيدوم</span>
                                <v-spacer></v-spacer>
                                <v-avatar size="40">
                                    <img src="assets/img/flexy/mobilis.png" />
                                </v-avatar>
                            </v-card-title>

                            <v-card-title class="py-2">
                                <span class="app-regular mx-2" style="font-size: 17px"><b>{{prices.card+  "%"}}</b></span>
                                <v-spacer></v-spacer>
                                <span class="app-regular">بطاقات</span>
                                <v-spacer></v-spacer>
                                <v-avatar size="40">
                                    <img src="assets/img/flexy/djezzy.png" />
                                </v-avatar>
                            </v-card-title>



                        </v-card>
                    </v-col>



                </v-row >
            </v-card-title>
        </v-card>
    </div>
</template>

<script>
    import { formatToPrice, colorSvg} from "@/tools/Services";

    export default {
        props:['pricesToday'],

        computed:{
          prices:function(){
              return this.pricesToday[0]
          }  ,
          user:function () {
            return this.$store.state.auth.user || null
          }
        },
        methods:{colorSvg,formatToPrice}
    }
</script>

<style scoped>

</style>