<template>
    <div>

        <v-menu max-width="350px"
            class="toolbar-menu-item rounded-xl overflow-hidden"
            offset-y
            origin="center center"  style="border-radius: 25px;!important;"
            :nudge-bottom="10"
            transition="scale-transition"
        >
            <template v-slot:activator="{ on }">
                <v-btn v-on="on"   @click="setToRead" icon :class="$store.state.dark?'app-card':'app-card-light'" >

                    <v-badge v-if="not==false"
                             absolute
                             right
                             style="z-index: 100000"
                             color="#ED1C24"
                             :content="filters"
                             overlap
                             :offsetY="-7"
                             :offsetX="-15"
                             :value="filters"

                             :color="filters == 0 ? '#6200EA' : '#ED1C24'"
                    ></v-badge>
                    <img style="height: 20px" :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/bell.svg">
                </v-btn>


            </template>

            <v-card :dark="$store.getters.dark" :class="$store.state.dark?'app-card':'app-card-light'" class="elevation-0 rounded-xl overflow-hidden">
                <v-toolbar flat dense color="transparent">
                    <v-spacer></v-spacer>
                    <v-toolbar-title><h5 class=" app-regular">{{ filters }} إشعـــارات</h5></v-toolbar-title>
                </v-toolbar>
                <v-list two-line >

                    <v-list-item-group
                        v-model="selected"

                    >
                        <template v-for="(item, index) in notification" >
                            <v-list-item    :color="item.read_at == null?'purple':''"  dense :key="index.id" to="/notifications" style="direction: ltr!important;">
                                <template v-slot:default="{ active }">
                                    <vs-avatar class="mx-2">
                                        <img :src="NotificationsImg(item.data.method)" alt="">
                                    </vs-avatar>

                                    <v-list-item-content  >
                                        <v-list-item-title class="app-bold" style="direction: ltr;line-height: normal;" >

                                            {{item.data.method}}
                                        </v-list-item-title>

                                        <v-list-item-subtitle
                                            class="app-regular vueltr "  style="direction: ltr!important;" v-text="item.data.message">

                                             </v-list-item-subtitle>

                                        <v-list-item-subtitle class="app-regular" v-text="item.status"></v-list-item-subtitle>
                                    </v-list-item-content>

                                    <v-list-item-action>
                                        <v-list-item-action-text class="app-regular"  >{{ (item.created_at) | fromNow }}</v-list-item-action-text>


                                            <img :class="item.read_at==null?'purple-svg':$store.state.dark?'white-svg':'black-svg'"   src="assets/icons/vuesax/star.svg" />


                                    </v-list-item-action>
                                </template>
                            </v-list-item>

                            <v-divider class="my-0"
fb                                                                                                                    v-if="index < notification.length - 1"
                                       :key="index.id"
                            ></v-divider>
                        </template>
                    </v-list-item-group>
                </v-list>
                <v-card-actions>
                    <v-btn  color="#FFC107" class="v-btn--active rounded-lg app-regular" text block  to="/notifications">إشعارات</v-btn>
                </v-card-actions>
            </v-card>
        </v-menu>

    </div>
</template>
<script>
    import axios from "axios"
    import { bus } from "@/tools/bus";
    import {getColorr, NotificationsImg} from "@/tools/Services";
    export default {
        data() {
            return {
                not:true,
                stopUserCommit: false,
                notification: [],
                selected:[]
            };
        },

        computed: {

            filters() {
                let unreaded = this.notification.filter(  (notify) => {
                        return  notify.read_at == null
                    }
                ).length;
                let selecr = this.notification.filter( (notify,index) => {
                    if(notify.read_at === null){
                        this.selected.push(index)
                    }
                });

                if (unreaded > 0) {
                    return unreaded;
                } else return 0;
            },
        },
        methods: {
            getColorr,
            invokUserUpdate(messages) {
                messages.forEach((msg) => {
                    if (

                        msg.data.method === "Rechargement" &&
                        msg.data.status === "Confirmé" &&
                        msg.read_at === null &&
                        this.stopUserCommit === false
                    ) {
                        let vm = this;
                        vm.$store.dispatch("loadUser");
                        this.stopUserCommit = true;
                    }else if(msg.read_at === null){
                        this.not = false
                    }
                });
            },

            NotificationsImg,

            setToRead() {
                this.not = true
                axios({ url: "api/notify", method: "post" })
                    .then(() => {
                        bus.$emit("updateNotifications");
                    })
                    .then(() => {
                        this.selected = []

                    });
            },

            notifications() {
                axios.get('api/notifications')
                    .then((response) => {
                        this.notification = response.data;
                        this.invokUserUpdate(this.notification);
                    })

            },
        },
        beforeMount() {
          setTimeout(()=>{

              if (this.$store.state.auth.user != null && this.$route.fullPath != '/auth'){
                  let vm = this;
                  vm.notifications();
                  setInterval(() => vm.notifications(), 120000);
              }
          },5000)

        },

    };
</script>

<style scoped>
    .pink--text {
        color: #673AB7!important;
        caret-color: #673AB7!important;
    }
</style>
