<template>
    <v-row class="vuerlt"     >

        <v-col cols="12" sm="12">
            <v-card   class="rounded-lg  " link  :to="$store.state.mdAndUp?'/historique-mobile':'/historique-flexy'"  flat :dark="$store.state.dark" color="rgb(82, 78, 237)">
                <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <span class="caption  mt-n4 white--text "><b> Flexy aujourd'hui</b></span>
                    <v-spacer></v-spacer>
                    <vs-button style="cursor:pointer"        color="#fff" dark     class=" rounded-lg"
                    ><span class="black--text">Historique</span></vs-button>
                </v-app-bar>
                <v-app-bar flat color="rgba(0, 0, 0, 0)" class="mt-n12"  >

                    <h4   class="orange--text" v-text="formatToPrice(counterSpendMoney.flexy_total || '0')"> </h4>
                 </v-app-bar>
                <v-app-bar flat color="rgba(0, 0, 0, 0)" class="mt-n10">
                    <v-progress-linear
                            color="orange"
                            height="6"
                            value="30"
                    ></v-progress-linear>
                </v-app-bar>
                <v-card-title class="pt-0 mt-n4">
                        <span class="text-caption white--text font-weight-light"
                        >Bonus aujourd'hui</span >
                    <v-spacer></v-spacer>
                    <b class="white--text">{{formatToPrice(counterSpendMoney.bonus_total || '0')}}</b></v-card-title>
            </v-card>
        </v-col>
        <v-col cols="12" sm="12">
            <v-card  :color="$store.state.dark?'#141417':'#ebecf0'" link to="/idoom" class="rounded-lg  " flat  :dark="$store.state.dark" >
                <v-app-bar flat color="rgba(0, 0, 0, 0)">
                    <span class="caption  mt-n6 app-regular">Idoom et les cartes aujourd'hui</span>
                </v-app-bar>
                <v-card-title class="pt-0 mt-n7">
                        <span class="text-caption   font-weight-bold ml-2  "
                        >IDOOM</span >
                    <v-spacer></v-spacer>
                    <b class="orange--text">{{formatToPrice(counterSpendMoney.idoom || '0') }}<sup>DA</sup></b> </v-card-title>

                <v-app-bar flat color="rgba(0, 0, 0, 0)" class="mt-n10">
                    <v-progress-linear
                            color="orange"
                            height="6"
                            value="45"
                    ></v-progress-linear>
                </v-app-bar>
                <v-card-title class="pt-0 mt-n4">
                        <span class="text-caption   font-weight-bold ml-2"
                        >CARTES</span >
                    <v-spacer></v-spacer>
                    <b class="app-purple--text">{{formatToPrice(counterSpendMoney.card || '0' )}}<sup>DA</sup></b> </v-card-title>
            </v-card>
        </v-col>
        <v-col cols="12" class="col-12">
            <v-toolbar flat color="transparent" class="mt-n5">
                <v-toolbar-title ><b>Flexy  Aujourd'hui</b></v-toolbar-title>

            </v-toolbar>
                <v-card   link  :to="$store.state.mdAndUp?'/historique-mobile':'/historique-flexy'" class="rounded-lg" :color="$store.state.dark?'#141417':'#ebecf0'" flat  :dark="$store.state.dark" >
                    <v-list-item-title></v-list-item-title>
                    <v-list    color="transparent"  dense  >
                        <v-list-item
                        >
                            <v-list-item-avatar>
                                <img src="assets/img/flexy/mobilis.png" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title ></v-list-item-title>
                                <v-list-item-subtitle >Mobilis Aujourd'hui</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <h4 class=" success--text" >{{formatToPrice(counterSpendMoney.mobilis || '0'  )}}</h4>

                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item
                        >
                            <v-list-item-avatar>
                                <img src="assets/img/flexy/djezzy.png" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title ></v-list-item-title>
                                <v-list-item-subtitle >Djezzy Aujourd'hui</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <h4 class=" success--text" >{{formatToPrice(counterSpendMoney.djezzy || '0' )}}</h4>

                            </v-list-item-action>
                        </v-list-item>
                        <v-list-item
                        >
                            <v-list-item-avatar>
                                <img src="assets/img/flexy/ooredoo.png" />
                            </v-list-item-avatar>
                            <v-list-item-content>
                                <v-list-item-title ></v-list-item-title>
                                <v-list-item-subtitle >Ooredoo Aujourd'hui</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <h4 class=" success--text" >{{formatToPrice(counterSpendMoney.ooredoo || '0' )}}</h4>

                            </v-list-item-action>
                        </v-list-item>
                    </v-list>
                </v-card>


        </v-col>
    </v-row>
</template>

<script>
    import {formatToPrice} from "@/tools/Services";
    import axios from "axios";
    import {bus} from "@/tools/bus";

    export default {

        data: () => ({counterSpendMoney:[],
            date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

        }),
        methods:{
            formatToPrice,
            getFlexydate(){

                axios
                    .post("api/spend-money-mobile",{date:this.date})
                    .then(data => {
                        this.counterSpendMoney = data.data.today

                        bus.$emit('todayBonus',this.counterSpendMoney)
                    })
                    .catch(() => {
                        try {

                        }catch (e) {

                        }
                    });


            },
        },
          mounted() {
              this.getFlexydate()
                 }

    };
</script>
<style></style>