import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Vuesax from 'vuesax'
 import moment from 'moment';
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueSocialauth from 'vue-social-auth'

    axios.defaults.baseURL = "https://app.shipilistore.dz";

  axios.defaults.withCredentials = true;

const token = window.localStorage.getItem('token')
if(token){
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token
}
 import "./tools/directives"
 import "@/plugins/clipboard"
 import "@/plugins/infinity-scrolle"
import 'vuesax/dist/vuesax.css'
 import Swal from 'sweetalert2'
 import VueOffline from 'vue-offline'
import {
    Button,
    HasError,
    AlertError,
    AlertErrors,
    AlertSuccess
} from 'vform/src/components/tailwind'


 Vue.component(Button.name, Button)
Vue.component(HasError.name, HasError)
Vue.component(AlertError.name, AlertError)
Vue.component(AlertErrors.name, AlertErrors)
Vue.component(AlertSuccess.name, AlertSuccess)


window.Swal = Swal;
const Toast = Swal.mixin({ 
    toast: true,
    position: 'center',
    showConfirmButton: false,
    timer: 2000,
    timerProgressBar: true,

})
window.Toast = Toast;
Vue.filter('date', function (created) {
    moment.locale('ar-dz')
    return moment(created).format('HH:mm');
});
Vue.filter('fromNow', function (date) {
    moment.locale('ar-dz')
    return moment(date).fromNow();
});

Vue.use(Vuesax, {
  theme:{
    colors:{
      primary:'#5b3cc4',
      success:'rgb(23, 201, 100)',
      danger:'#E40814v',
      warning:'#ffce3d',
      dark:'rgb(36, 33, 69)'
    }
  }
})




Vue.component('not-found', () => import('@/components/comp-tool/NotFound.vue'));
Vue.filter('datee', function (created) {
    moment.locale('ar-dz')
    return moment(created).format('LLL');

});
Vue.filter('dateTable', function (created) {
    moment.locale('fr-fr')
    return  moment(created).format('ll');

});
Vue.filter('splitNumber', function (value) {
    try{
        return  value.replace(/\B(?=(\d{2})+(?!\d))/g, " "); 
    }
    catch{return}
})
Vue.filter('datea', function (created) {
    moment.locale('ar-dz')
    return moment(created).format('YYYY-MM-DD');
});


Vue.config.devtools = false
import {Clipboard} from 'v-clipboard'
Vue.config.devtools= false
Vue.use(VueAxios, axios)
Vue.use(VueSocialauth, {
    property: '$auth',
    providers: {
        google: {
            clientId: "125397256688-m1ojs312l3nll2207o5514jogtr9bo2i.apps.googleusercontent.com",
            redirectUri: 'http://localhost:3001/login' // Your client app URL

        },

    }
})
Vue.use(Clipboard)
Vue.use(VueOffline)
new Vue({

  router,
  axios,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
