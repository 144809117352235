import Vue from 'vue'
import Vuex from 'vuex'
 import auth from './auth'
 import App from './App'
 import flexy from './flexy'
 import sidbar from './sidbar'
 import conditions from './conditions'
import itemsProd from "@/store/itemsProd";
import open from "@/store/open";
import caroucel from "@/store/caroucel";
import sendSms from "@/store/sendSms";
import axios from "axios";
Vue.use(Vuex)

const store = new Vuex.Store({
 
  state: {
    numbers:['0676578055',"0671918064"],
    sna:{show:false,text:'',colorr:'',isSucces:false,fail:false,timout:3000,wifi:{},textShow:true},
    broadCrump:{title:'',broadCrump:'',link:''},
    params:{
      clientId:'826902055786-q505uq8jg8chfs9v3dve00qhqviu6ev0.apps.googleusercontent.com',
      baseUrl:"https://app.shipilistore.dz",
      logo:'assets/img/logo.svg',
      Telegram:'',
      WatsApp:'',
      playStore:'https://play.google.com/store/apps/details?id=com.shipili.store',
      AppName:'DZtechno',
      curency:'دينار',
      description:'ديزاد تاك موقع لبيع بطاقات الشحن في الجزائر ',
      loading:'points',
      bg:'#ecb611',background:'warning',color:'warning--text',theme:'light', font:'app-regular',fontMedium:'app-medium',fontBold:'app-bold',webImage:'assets/logo.png'},
      product:null,
      drawer:false,
      dark:false,
      contacts:[],
      savedContact:[],
      selectedContact:null,
      flexyCard:[],
      sideRight:true,
      info:{},
    isCardActive:false,

     message:{show:false},
  },
  getters: {
    isCardActive:state => {
      return state.isCardActive
    },

    message:state => {
      return state.message
    },
    info: state =>{
      return state.info
    },
    sideRighr: state =>{
      return state.sidbar
    },
    flexyCard: state => {
      return state.flexyCard
    },
    savedContact: state => {

      return state.savedContact
    },
    selectedContact: state => {

      return state.selectedContact
    },
    contacts: state => {
      return state.contacts
    },
    product: state =>{
      return state.product
    },
    params: state => {
      return state.params
  },
  drawer : state =>{
    return state.drawer
  },
  dark : state =>{
    return state.dark
  },
    broadcrump : state => {
      return state.broadcrump;
    },
    sna: state => {
      return state.sna;
    },
  },
  mutations: {
    SET_ICARDACTIVE(state , payload){
      return state.isCardActive = payload
    },
    SET_MESSAGE (state , payload){
          return state.message = payload
      },
    SET_RIGHTSIDE (state, payload){
      if (payload == false){
        state.sideRight = false
      }else {state.sideRight = !state.sideRight}

    },
    SET_INFO (state , info){
      return state.info =  info
    },
    SET_FLEXY_CARD (state , Card){
      return state.flexyCard =  Card
    },
    SET_SELECTED_CONTACT ( state , newVal){
    return   state.selectedContact = newVal

    },
    SET_SAVED_Contact (state,savedContact) {

     return  state.savedContact = savedContact


    },
    SET_CONTACTS (state,searchQuery) {
      state.contacts = searchQuery
    },
    SET_BROADCRUMP(state , payload){
      return state.broadcrump = payload
    },
    SET_PRODUCT(state , payload){
      return state.product = payload
    },
    SET_PARAMS(state , payload){

      const params = {
        Telegram:payload.telegram,
        WatsApp:payload.watsapp,
        playStore:payload.playStore,

        loading:'points',

        theme:'light',
        font:'app-regular',
        fontMedium:'app-medium',
        fontBold:'app-bold'
      }


      return state.params = params
    },
    SET_DRAWER(state ,payload){
      return state.drawer = payload
    },
    SET_DARK(state ,payload){
      return state.dark = payload
    },
    SET_SNA (state,payload) {
      state.sna = payload
    },
  },
  actions: {
    loadFlexyOfToday({commit}){
      axios.get('api/flexy-parts').then((response)=>{
        commit('SET_FLEXY_CARD',response.data.flexyCard)
      })
    }   ,
    getContacts({commit }){
      if(store.state.contacts == ""){

        axios.get('api/contacts').then((response)=>{
          commit('SET_CONTACTS',response.data)
          commit('SET_SAVED_Contact',response.data)

        })
      }

    },
    getNavbarItems({commit , state}){
      axios.get('api/admin-navbar-items').then((response)=>{
       commit("sidbar/SET_SIDBARITEMS",response.data)
      })
    },
    loadontacts({commit,state } ,searchQuery){
      const contacts =  state.savedContact

      if(searchQuery){
        commit('SET_CONTACTS',contacts.filter((conta) => {
          return (
              conta.number.toString()
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase())
          );
        }))
      }else{
        commit('SET_CONTACTS',state.savedContact)
      }
    },
     loadsna({commit} ,sna){
      sna.colorr= sna.isSuccess==true?'#00C851':'#E53935'
      sna.fail = sna.isSuccess==true?false:true
      sna.timout = sna.timout?sna.timout:3000
      if(sna.hasOwnProperty('wifi')){
        sna.fail=false
        sna.isSuccess=false
        sna.colorr= sna.wifi.value==true?'#313131':'#00C851'
        sna.wifi = {value:sna.wifi.value,icon:sna.wifi.icon}
      }
      sna.show = true;
      commit('SET_SNA',sna)
    },
  },
  modules: {
    auth,
    App,
    flexy,
     sidbar,
    open,
    itemsProd,
    caroucel,
    conditions,
    sendSms
  }
})
export default store