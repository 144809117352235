<template>
    <v-card   flat :color="dark?'app-bg':''" class="app-mb-10 vuertl">

        <div class="pa-2" v-if="current_page >0">
            <TitlePages title="إيداعات">
                <v-btn slot="btn"  to="/home"  large   style="float: left!important;" icon depressed><img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>

            </TitlePages>
            <v-fab-transition>
                <v-btn   @click="newModel()" :dark="!$store.state.dark"
                         elevation="0" fixed bottom right fab
                         style="bottom: 69px; position: fixed; right: 12px;"  >
                    <img width="50px"   src="assets/img/depot.png"/>
                </v-btn>
            </v-fab-transition>

            <v-card-subtitle class="app-regular pt-0">يمكنك الاطلاع على حساباتنا البنكية <router-link to="/home-account">من هنا</router-link></v-card-subtitle>
            <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
                <v-col :class="$vuetify.breakpoint.smAndDown?'mb-3':'mb-n1'"  class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"  v-for="(item,index) in historiqueData" :key="index" >
                    <v-card   @click.prevent="showDialogImage(item.image)"  flat  ripple     style="height: 90px;cursor: pointer" class=" rounded-xl" :color="dark?'app-card':'app-card-light'">

                        <v-card-title style="margin-right: 60px;" class=" py-0" :class="$store.state.params.font">
                            <img  :class="colorSvg(item.status)" class="pt-1 " :src="iconResponse(item.status)" />



                            <v-card :loading="item.status == 'En attente'"       style="position: absolute; right: 0;top: 0;z-index: 1;"  class="mr-n1 mt-n3 overflow-hidden" rounded="lg" height="80" width="60" >
                                <v-img
                                        class="img-border-bottom "
                                        height="100%"  width="100%"  :src="item.image" ></v-img>

                            </v-card>

                            <span :class="getColorClass(item.status)" v-text="arabResp(item.status)" class="mr-3"></span>

                            <v-spacer></v-spacer>
                        </v-card-title>
                        <v-card-title style="margin-right: 60px;    font-size: 17px;" class="pt-0">
                         <span   style="direction: ltr;font-size: 16px;" class="app-regular mr-4"
                                  v-text=" item.method" > </span>
                            <v-spacer></v-spacer>
                            <h3 class="success--text" >{{ formatToPrice(item.amount) }}</h3>

                        </v-card-title>
                        <v-card-subtitle >
                            <p  class="text-center app-regular " :class="dark?'grey--text':''">{{ item.created_at | datee }}</p>

                        </v-card-subtitle>
                    </v-card>
                </v-col>
            </v-row>
            <infinite-loading  @infinite="infiniteHandler"   >
                <div :style="{marginTop:!historiqueData.length?'25vh':''}"  class="mb-5 app-medium"  slot="spinner" :class="$store.state.params.color">جارٍ البحث</div>
                <div     slot="no-more" class="app-medium" :class="[$store.state.params.color]">لا يوجد المزيد</div>
                <div style="margin-top: 15vh!important;"   class="mb-5 app-medium"  slot="no-results" :class="$store.state.params.color">
                    <v-card   class="center" :dark="dark" flat :color="dark?'app-bg':''">

                        <v-card-title >
                            <v-spacer></v-spacer>
                            <img style="width: 100px" src="assets/notifications/pay.png">
                            <v-spacer></v-spacer>

                        </v-card-title>
                        <h5 class="app-bold text-center">لا يوجد أي إيداع للمال</h5>
                      <vs-button  color="rgb(254, 44, 85)" class="mx-auto mt-3 rounded-lg  "   @click="newModel()"  >
                        إشحن الان
                        <img width="17px" src="assets/icons/vuesax/email.svg" class="white-svg mx-2" >
                      </vs-button>
                    </v-card>
                    </div>

            </infinite-loading>

        </div>

        <component :is="$vuetify.breakpoint.smAndDown?'v-bottom-sheet':'vs-dialog'" blur overflow-hidden not-padding   v-model="groupeShow"   >
            <v-card v-if="!sheet" :class="$vuetify.breakpoint.smAndDown?'rounded-t-xl':'rounded-xl'"     :dark="dark" max-width="550" >
                <v-container >
                    <div class="vuertl app-regular"  :dark ="dark">
                        <v-card-title  >
                          <span class="app-bold" v-text="editMode?'تغيير الصورة':'إضافة صورة'"></span>
                        </v-card-title>
                        <v-card-subtitle>إجباري إرفاق وصل الدفع أو رقم التحويل </v-card-subtitle>
                        <v-card-text class="pb-0">
                            <form >
                                <v-row>
                                    <v-col class=" col-sm-12 col-md-12 col-lg-6 col-12">
                                        <v-text-field  v-model="form.amount" type="number" filled hide-details solo flat
                                                      class="app-regular rounded-lg input-class overflow-hidden"
                                                      :class="dark?'black--text':'white--text'"   placeholder="ادخل المبلغ بـ دج"
                                                      :background-color="dark?'#2d2d39':'#EEF2F5'" block name="amount"
                                                     >

                                            <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/money.svg" >


                                        </v-text-field>

                                    </v-col>
                                  <v-col class=" col-sm-12 col-md-12 col-lg-6 col-12">
                                        <v-select
                                                :items="accounts"
                                                  item-text="feild" item-value="feild"  v-model="form.method"

                                                type="text" filled hide-details solo flat
                                                class="app-regular rounded-lg input-class overflow-hidden"
                                                :class="dark?'black--text':'white--text'"   placeholder="ادخل طريقة الدفع"
                                                :background-color="dark?'#2d2d39':'#EEF2F5'" block name="amount"





                                        ></v-select>

                                    </v-col>
                                    <v-col class="col-12 py-0" v-if="message!=''" >
                                        <p v-text="message[0].comment"></p>
                                    </v-col>
                                    <v-col class="col-12">
                                        <v-text-field  v-model="form.description" filled hide-details solo flat
                                                       class="app-regular rounded-lg input-class overflow-hidden"
                                                       :class="dark?'black--text':'white--text'"  type="text" placeholder="رقم التحويل أو اي تعليق"
                                                       :background-color="dark?'#2d2d39':'#EEF2F5'" block name="description"
                                        >

                                            <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/text.svg" >


                                        </v-text-field>

                                    </v-col>
                                    <v-col class="col-12" >
                                    <v-card  :color="dark?'app-card':'app-card-light'"   class="elevation-0 rounded-xl  overflow-hidden"
                                             @click.prevent="pick"
                                    >
                                        <v-card-title>

                                            <v-spacer></v-spacer>
                                            <img    :class="form.image != ''?'success-svg':dark?'white-svg':'grey-svg'" width="35px"  :src="form.image == ''?'assets/icons/vuesax/upload.svg':'assets/icons/vuesax/success.svg'">

                                            <v-spacer></v-spacer>
                                        </v-card-title>
                                    </v-card>

                                    </v-col>


                                </v-row>
                                <div class="modal-body">

                                    <div>

                                        <input v-show="false"
                                               type="file" ref="test"
                                               name="image"
                                               @change="uploadImage"
                                               class="form-control"

                                        />
                                    </div>
                                </div>

                            </form>
                        </v-card-text>
                        <v-card-actions class="pb-0 mx-3">
                            <v-btn block :loading="form.busy" :dark ="dark"
                                   v-show="editMode"
                                   type="submit" class="white--text  rounded-lg"
                                   color="#03A9F4"
                            >
                                تحديث التغييرات
                            </v-btn>
                            <v-btn block :loading="form.busy" :disabled="form.method == '' || form.amount =='' " :dark ="dark"
                                   v-show="!editMode"
                                   type="submit" class="white--text  my-3 rounded-lg"
                                   color="primary" @click="AddVersement"
                            >
                                إضافة إيداع
                            </v-btn>

                        </v-card-actions>
                    </div>

                </v-container>
            </v-card>
            <v-card :dark="dark" v-else  ref="target" flat  class="rounded-xl" :color="dark ? 'black-vuesax' : ' '">
                <v-card-title class="p-0 pb-0">
                    <v-spacer></v-spacer>
                    <img class="success-svg" src="assets/icons/vuesax/success-response.svg" />
                    <v-spacer></v-spacer>


                </v-card-title>
                <v-card-title class="app-regular  text-center pt-1" style="font-size: 22px;display: block;">تم إرسال طلب شحن رصيد بنجاح</v-card-title>
                <v-card-subtitle  class="d-block app-regular text-center">سوف يتم معالجة طلبك في أقرب وقت ممكن</v-card-subtitle>
                <v-card-actions class="app-regular">
                    <v-spacer></v-spacer>
                    <vs-button to="/" success gradient   >العودة الى الصحة الرئيسية</vs-button>

                    <vs-button @click="groupeShow = !groupeShow" warn gradient >إغلاق</vs-button>
                </v-card-actions>
            </v-card>
        </component>
        <vs-dialog :dark="dark" auto-width not-padding  v-model="dialogImage">
           <div class="con-image">
               <img class="image-st" :src="setBaseUrl(image)">
           </div>
        </vs-dialog>
    </v-card>
</template>

<script>
    import axios from 'axios';
    import { formatToPrice ,iconResponse ,colorSvg,getColorClass,arabResp,setBaseUrl} from '@/tools/Services';
    import Form from "vform";
    import TitlePages from "@/layout/TitlePages";
    import {VBottomSheet,} from "vuetify/lib/components";
     export default  {
        components:{TitlePages,'v-bottom-sheet':VBottomSheet},
        computed:{
          dark:function () {
                   return this.$store.state.dark
          }  
        },
        watch:{
          "form.method"(newVal){
              let vm = this
            this.message = this.accounts.filter(function (prod) {
                 return  vm.form.method == prod.feild
              })
          }  ,
        },
        data(){
            return{

                message:'',
                dialogImage:false,
                groupeShow:false,
                color: "#4CAF50",
                editMode: false,
                login:false,
                form: new Form({
                    id: "",
                    amount: "",
                    method:'',
                    description:"",
                    image:"",

                }),
                imagee: "",
                imageMode: 0,
                accounts:[],
                show:false,
                current_page:1,
                historiqueData:[],
                image:'',
                sheet:false,
            }
        },
        methods:{getColorClass,arabResp,setBaseUrl,
            showDialogImage(image){

              this.image= image
                this.dialogImage =  !this.dialogImage
            },
            AddVersement(){
             if(this.form.amount >990000 || this.form.amount < 100){
                 this.$store.dispatch("loadsna", {
                     text: "المبلغ الادنى هو 1000 دج و الاقصى 990000 دج",
                     isSuccess: false,
                 });
             }else {
                 this.form.post('api/versement').then((response)=>{
                     this.sheet = true
                     this.historiqueData.unshift(response.data.product)

                 }).catch((error)=>{
                     this.sheet = false
                     this.groupeShow = !this.groupeShow
                     this.$store.dispatch("loadsna", {
                         text: error.response.data.message,
                         isSuccess: false,
                     });
                 })


             }
            },
            getVersementAccount(){
            if (this.$store.state.auth.user != null){
                axios.get('api/get-versement-account').then((response)=>{
                    this.accounts = response.data.accounts
                })
            }

            },
            pick(){
                this.$refs.test.click()
            },
            uploadImage(e) {

                let file = e.target.files[0];

                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if (file["size"] > limit) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "You are uploading a large file",
                    });
                    return false;
                }
                reader.onloadend = (file) => {
                    this.form.image = reader.result;

                };
                reader.readAsDataURL(file);

            },
            newModel() {
             if (this.$store.state.auth.user != null){
                this.sheet = false
                this.editMode = false;
                this.form.reset();
                this.groupeShow = !this.groupeShow
            }else{
                const noti = this.$vs.notification({
                    icon: `<img class="white-svg" src="assets/icons/vuesax/message.svg" />`,
                    classNotification:'vuertl',
                    color:'primary',
                    position:'top-center',
                    title: 'الادارة',
                    text: 'قم بتسجيل الدخول ثم قم بإيداع المال'
                })
                setTimeout(()=>noti.close(),3000)
            }


            },










            formatToPrice,iconResponse,colorSvg,
            getHistoriqueData($state){
                axios.get('api/card-request', {page: this.current_page}
                ).then((response)=>{
                    if(response.data.data.length >0){
                        this.current_page +=1
                        this.historiqueData.push(...response.data.data);

                    } else{
                        $state.complete();
                    }
                    this.show = true
                }).catch(()=>{
                    $state.complete();
                })
            },
            infiniteHandler($state){

                if (this.$store.state.auth.user != null){
                    axios.get('api/versement-request?page='+this.current_page
                    ).then((response)=>{
                        if(response.data.data.length >0){
                            this.current_page +=1

                            this.historiqueData.push(...response.data.data)
                            $state.loaded()
                        }else{
                            $state.complete();
                        }
                        this.show = true
                    }) .catch(()=>{
                        $state.complete();
                    })
                }else{
                    this.login = true
                }
            }
        },
        mounted() {
            this.getVersementAccount()

        }

    }
</script>

<style lang="scss" scoped>
    .v-ripple-container{
        z-index: 10!important;
    }
    .v-card--link:before {
        background: none!important;
    }
    .con-image{
        border-radius: inherit
    }
    .image-st{
        display :block;
        position :relative;
        border-radius :inherit;
        max-width :850px
    }
    .notifica{
        direction: ltr!important;text-align: left!important;
        font-size: 15px!important;
        font-family: app-bold!important;
    }
</style>