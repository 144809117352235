<template>

   <div>
     <v-card v-if="$store.state.auth.user.type == 'admin'"  flat class="vuertl">






       <v-container fluid   >
         <TitlePages title="الاعدادات">
           <v-btn slot="btn" @click="$router.go(-1)" large style="float: left!important;" icon depressed><img
               :class="dark?'white-svg':'black-svg'"
               src="assets/icons/vuesax/chevron-right.svg"/></v-btn>

         </TitlePages>

         <v-row class="mx-2">

           <v-col class="col-12 pa-0">
             <vs-alert    relief   closable   primary  class=" mt-2 app-regular"     >
               <template #title>
                 مرحبا بك في إعدادات الموقع
               </template>
               يرجى التحقق بعناية للمعلومات المدخلة قبل الحفظ
             </vs-alert>
             <Dash<U/>
             <v-card flat :dark="dark" :color="dark?'app-card':'app-card-light'" class="rounded-xl  " >
               <v-card flat :dark="dark" :color="dark?'app-card':'app-card-light'" class="rounded-xl mt-4" >
                 <v-card-title class="app-regular pb-2 mb-3">
                   شكل البطاقات
                   <v-spacer></v-spacer>
                   <vs-button class="app-regular"   primary :loading="InterDialog.busy" @click="UpdateAllForms('45')">

                     تحديث  صورة الترحيب
                     <img class="white-svg mr-2 " src="assets/icons/vuesax/send.svg">
                   </vs-button>

                 </v-card-title>
                 <v-subheader inset class="app-bold">تفعيل وإيقاف صورة الترحيب  </v-subheader>
                 <v-container fluid class=" d-flex  ">

                   <v-hover  v-slot="{hover}">
                     <v-card color="transparent"
                             :elevation="form.ModeCarte=='0'?'10':'0'"
                             @click="form.ModeCarte = '0' "
                             :style="{border:form.ModeCarte=='0'?'2px solid' + $store.state.App.config.colorBackground+'':''}"
                             max-width="200px"
                             class="rounded-xl pb-0 ml-3 overflow-hidden"    style="cursor: pointer;" :dark="dark" >
                       <img   :src="setBaseUrl('/images/card-type/card-1.png')"    style="max-height: 100%!important;max-width:100%;margin-top: 65px;">
                     </v-card>
                   </v-hover>


                   <v-hover  v-slot="{hover}">
                     <v-card color="transparent"
                             :elevation="form.ModeCarte=='5'?'10':'0'"
                             @click="form.ModeCarte = '5' "
                             :style="{border:form.ModeCarte=='5'?'2px solid'+$store.state.App.config.colorBackground+'':''}"
                             max-width="200px"
                             class="rounded-xl pb-0 ml-3 overflow-hidden"    style="cursor: pointer;" :dark="dark" >
                       <img   :src="setBaseUrl('/images/card-type/card-5.png')"    style="max-height: 100%!important;max-width:100%;margin-top: 65px;">
                     </v-card>
                   </v-hover>
                   <v-hover  v-slot="{hover}">
                     <v-card color="transparent"
                             :elevation="form.ModeCarte=='1'?'10':'0'"
                             @click="form.ModeCarte = '1' "

                             max-width="170px"
                             :style="{border:form.ModeCarte=='1'?'2px solid'+$store.state.App.config.colorBackground+'':''}"
                             class="rounded-xl pb-0 overflow-hidden"   style="cursor: pointer;" :dark="dark" >
                       <img  :src="setBaseUrl('/images/card-type/card-2.png')"    style="max-height: 100%!important;max-width:100%">
                     </v-card>
                   </v-hover>

                 </v-container>
               </v-card>
               <v-card flat :dark="dark" :color="dark?'app-card':'app-card-light'" class="rounded-xl mt-2 mt-2" >
                 <v-card-title class="app-regular pb-0">
                   صورة الترحيب
                   <v-spacer></v-spacer>
                   <vs-button class="app-regular"   primary :loading="InterDialog.busy" @click="UpdateInterDialog">

                     تحديث  صورة الترحيب
                     <img class="white-svg mr-2 " src="assets/icons/vuesax/send.svg">
                   </vs-button>

                 </v-card-title>
                 <v-subheader inset class="app-bold">تفعيل وإيقاف صورة الترحيب  </v-subheader>
                 <v-container fluid>
                   <v-row dense>


                     <v-col class="col-lg-6 col-sm-6 col-md-6 col xl-6 ol-xs-12 col-12" >
                       <v-select :items="dialodInter"
                                 v-model="InterDialog.value"
                                 item-value="mode"
                                 persistent-hint hint="تفعيل صورة الترحيب"  item-text="name"
                                 :background-color="dark?'#242426':'#fff'" flat filled
                                 class="app-regular rounded-lg" :placeholder="InterDialog.value == 'true'?'الصورة مفعلة':'الصورة غير مفعلة' ">
                         <template style="direction: ltr!important;" v-slot:item="{item}"
                         ><span class="app-regular" style="float:right">{{ item.name }}  </span>
                         </template>
                       </v-select>
                     </v-col>
                     <v-col class="col-lg-6 col-sm-6 col-md-6 col xl-6 ol-xs-12 col-12" >
                       <v-text-field :disabled="InterDialog.value != 'true'" hide-details :dark ="dark"  @click.prevent="pick3" style="cursor: pointer!important;"
                                     :placeholder="InterDialog.image!=''?'تم التحميل':'حمل الصورة'"
                                     :background-color="dark?'#242426':'#fff'" flat filled
                                     :success="InterDialog.image!=''"
                                     class="app-regular"

                       >
                         <vs-avatar  style="cursor:pointer" fab slot="append" class="mt-n3" @click="dial = !dial" >
                           <img style="width: 25px;height: 25px" :src="InterDialog.image==''?'assets/icons/vuesax/image.svg':InterDialog.image" />
                         </vs-avatar>
                       </v-text-field>
                       <input v-show="false"
                              type="file" ref="test3"
                              name="image"
                              @change="uploadImageInterDialog"
                              class="form-control"
                              :class="{ 'is-invalid': depot.errors.has('method') }"
                       />
                     </v-col>


                   </v-row>

                 </v-container>
               </v-card>
               <v-card flat :dark="dark" :color="dark?'app-card':'app-card-light'" class="rounded-xl mt-2" >
                 <v-card-title class="app-regular pb-0">
                   بطاقات IDOOM
                   <v-spacer></v-spacer>
                   <vs-button class="app-regular"   primary :loading="idoomTopup.busy" @click="UpdateIdoomTopup">

                     تحديث إيدوم
                     <img class="white-svg mr-2 " src="assets/icons/vuesax/send.svg">
                   </vs-button>

                 </v-card-title>
                 <v-subheader inset class="app-bold">تفعيل وإيقاف الشحن الفوري لبطاقات IDOOM</v-subheader>
                 <v-container fluid>
                   <v-row dense>


                     <v-col class="col-lg-4 col-sm-12 col-12 col-xl-4 col-md-4">
                       <v-select :items="idoom"
                                 v-model="idoomTopup.value"
                                 item-value="mode"
                                 persistent-hint hint="تفعيل وتعطيل إيدوم"  item-text="name"
                                 :background-color="dark?'#242426':'#fff'" flat filled
                                 class="app-regular rounded-lg" placeholder="إيدوم">
                         <template style="direction: ltr!important;" v-slot:item="{item}"
                         ><span class="app-regular" style="float:right">{{ item.name }}  </span>
                         </template>
                       </v-select>
                     </v-col>
                     <v-col class="col-lg-8 col-sm-12 col-12 col-xl-8 col-md-8">
                       <v-textarea rows="1" :disabled="idoomTopup.value=='true'" :background-color="dark?'#242426':'#fff'"  v-model="idoomTopup.comment"  hide-details   flat filled class="app-regular rounded-lg"
                                   placeholder="الرسالة الموجهة للزبائن عند التوقيف  "></v-textarea>
                     </v-col>


                   </v-row>

                 </v-container>
               </v-card>

               <v-card flat   class="rounded-xl my-2" :dark="dark" :color="dark?'app-card':'app-card-light'">
                 <v-card-title class="app-regular">
                   أدخل أرقام الهاتف المراد التعامل بها في المنصة
                   <v-spacer></v-spacer>
                   <vs-button class="app-regular"   primary :loading="mobileN.busy" @click="AddNumberPhone('1')">
                     إضافة رقم هاتف
                     <img class="white-svg mr-2 " src="assets/icons/vuesax/send.svg">
                   </vs-button>

                 </v-card-title>
                 <v-row>
                   <v-container fluid>
                     <v-col class="col-12">
                       <v-row>
                         <v-col class="col-lg-6 col-sm-6 col-md-6 col xl-6 ol-xs-12 col-12" >
                           <v-text-field :background-color="dark?'#242426':'#fff'"  v-model="mobileN.value"  hide-details   flat filled class="app-regular rounded-lg"
                                         placeholder="رقم الهاتف"></v-text-field>
                         </v-col>

                         <v-col class="col-lg-6 col-sm-6 col-md-6 col xl-6 ol-xs-12 col-12" >
                           <v-text-field  v-model="mobileN.comment"  hide-details    class="app-regular rounded-lg" :background-color="dark?'#242426':'#fff'" flat filled
                                          placeholder="الاسم"></v-text-field>
                         </v-col>
                       </v-row>
                     </v-col>
                     <v-col cols="12">
                       <v-subheader inset class="app-bold">أرقام هاتف الادمين</v-subheader>
                       <v-row class="mx-2">
                         <v-container>
                           <v-row  >
                             <v-col class="col-6 pa-1"  v-for="(number,index) in numbers" :key="index" >
                               <v-card    link @click.prevent="showDeletebank(number)" flat class="overflow-hidden   rounded-lg mb-1"
                               >
                                 <v-card-title class="py-1  " style="font-size: 15px">
                                   <v-btn icon class="ml-2"    :dark="dark" >
                                     <img  src="assets/icons/vuesax/mobile.svg">
                                   </v-btn>
                                   <div  class="">
                                     <span class="app-regular">رقم الهاتف</span><br/><span class="app-regular" v-text="number.value"></span>
                                   </div>
                                   <v-spacer></v-spacer>

                                   <v-btn icon text color="error" class="v-btn--has-bg" @click="showDeletebank(number)">
                                     <img  :class="dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/close-1.svg">
                                   </v-btn>
                                 </v-card-title>

                               </v-card>

                             </v-col>
                           </v-row>
                         </v-container>
                       </v-row>
                     </v-col>

                   </v-container>
                 </v-row>
               </v-card>

               <v-card flat   class="rounded-xl mt-5" :dark="dark" :color="dark?'app-card':'app-card-light'">
                 <v-card-title class="app-regular">
                   أدخل الحسابات البنكية المراد التعامل بها في المنصة
                   <v-spacer></v-spacer>
                   <vs-button class="app-regular"   primary :loading="depot.busy" @click="AddAccountVersement('1')">
                     إضافة حساب
                     <img class="white-svg mr-2 " src="assets/icons/vuesax/send.svg">
                   </vs-button>

                 </v-card-title>
                 <v-row>
                   <v-container fluid>
                     <v-col class="col-12">
                       <v-row>
                         <v-col class="col-lg-4 col-sm-6 col-md-6 col xl-4 ol-xs-12 col-12" >
                           <v-text-field :background-color="dark?'#242426':'#fff'"  v-model="depot.feild"  hide-details   flat filled class="app-regular rounded-lg"
                                         placeholder="إسم البنك أو التعامل"></v-text-field>
                         </v-col>
                         <v-col class="col-lg-4 col-sm-6 col-md-6 col xl-4 ol-xs-12 col-12" >
                           <v-text-field  v-model="depot.value"  hide-details   :background-color="dark?'#242426':'#fff'" flat filled class="app-regular rounded-lg"
                                          placeholder="رقم أو إسم البنك"></v-text-field>
                         </v-col>
                         <v-col class="col-lg-4 col-sm-12 col-md-12 col xl-4 ol-xs-12 col-12" >
                           <v-text-field hide-details :dark ="dark"  @click.prevent="pick" style="cursor: pointer;"
                                         :placeholder="depot.image!=''?'تم التحميل':'حمل الصورة'"
                                         :background-color="dark?'#242426':'#fff'" flat filled
                                         :success="depot.image!=''"
                                         class="app-regular"
                                         append-icon="mdi-file-image-outline"
                           ></v-text-field>
                           <input v-show="false"
                                  type="file" ref="test"
                                  name="image"
                                  @change="uploadImage"
                                  class="form-control"
                                  :class="{ 'is-invalid': depot.errors.has('method') }"
                           />
                         </v-col>
                         <v-col class="col-12" >
                           <v-text-field  v-model="depot.comment"  hide-details    class="app-regular rounded-lg" :background-color="dark?'#242426':'#fff'" flat filled
                                          placeholder="تفاصيل أكثر"></v-text-field>
                         </v-col>
                       </v-row>
                     </v-col>
                     <v-col cols="12">
                       <v-subheader inset class="app-bold">حساباتي البنكية</v-subheader>
                       <v-row>
                         <v-container>
                           <v-card link @click.prevent="showDeletebank(bank)" flat v-for="(bank,index) in accountsBank" class="overflow-hidden col-12 py-0 rounded-lg mb-2"
                                   :key="index" >
                             <v-card-title class="py-1">
                               <vs-avatar circle class="ml-2"  pointer size="40" :dark="dark" >
                                 <img  style="height: 100%" :src="setBaseUrl(bank.image)">
                               </vs-avatar>
                               <div  class="">
                                 <span class="app-regular">{{bank.comment}}</span><br/><span class="app-regular" v-text="bank.value"></span>
                               </div>
                               <v-spacer></v-spacer>

                               <v-btn icon text color="error" class="v-btn--has-bg" @click="showDeletebank(bank)">
                                 <img  :class="dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/close-1.svg">

                               </v-btn>
                             </v-card-title>
                             <v-card-subtitle class="mt-1 app-regular">{{bank.comment}}</v-card-subtitle>

                           </v-card>
                         </v-container>
                       </v-row>
                     </v-col>

                   </v-container>
                 </v-row>
               </v-card>




               <v-card flat :dark="dark" :color="dark?'app-card':'app-card-light'" class="rounded-xl mt-5" >
                 <v-card-title class="app-regular">
                   إعدادات الموقع
                   <v-spacer></v-spacer>
                   <vs-button class="app-regular"   primary :loading="form.busy && num == '2'" @click="UpdateAllForms('2')">
                     تحديث إعدادات الموقع
                     <img class="white-svg mr-2 " src="assets/icons/vuesax/send.svg">
                   </vs-button>

                 </v-card-title>

                 <v-container fuild>
                   <v-row dense>

                     <v-subheader inset class="app-bold">العنوان العلوي للصفحة الرئيسية</v-subheader>
                     <v-col class="col-12 app-regular py-0">
                       <v-textarea rows="2"   class="py-0"   :background-color="dark?'#242426':'#fff'" flat filled
                                   name="input-7-1"
                                   :value="form.topTitle"
                                   v-model="form.topTitle"
                                   hide-details
                       ></v-textarea>
                     </v-col>

                   </v-row>
                 </v-container>
               </v-card>
               <v-card flat :dark="dark" :color="dark?'app-card':'app-card-light'" class="rounded-xl mt-2">
                 <v-card-title class="app-regular">
                   إعدادات الموقع
                   <v-spacer></v-spacer>
                   <vs-button class="app-regular"   primary :loading="form.busy && num == '3'" @click="UpdateAllForms('3')">
                     تحديث إعدادات للزبائن
                     <img class="white-svg mr-2 " src="assets/icons/vuesax/send.svg">
                   </vs-button>

                 </v-card-title>

                 <v-container fluid>
                   <v-row dense>
                     <v-col class="col-lg-6 col-sm-6 col-12 col-xl-6 col-md-6">
                       <v-select :items="['sms','email']" v-model="form.codeDactivation" hide-details
                                 :background-color="dark?'#242426':'#fff'" flat filled class="app-regular rounded-lg"
                                 placeholder="إرسال كود تعيل الحساب">
                         <template style="direction: ltr!important;" v-slot:item="{item}"
                         ><span class="app-regular" style="float:right">{{ item }}  </span>
                         </template>
                       </v-select>
                     </v-col>
                     <v-col class="col-lg-6 col-sm-6 col-12 col-xl-6 col-md-6">
                       <v-select :items="activation" v-model="form.directActivation" item-value="mode"
                                 item-text="name" hide-details
                                 :background-color="dark?'#242426':'#fff'" flat filled
                                 class="rounded-lg app-regular"
                                 placeholder="تفعيل حسابات الزبائن">

                         <template style="direction: ltr!important;" v-slot:item="{item}"
                         ><span class="app-regular" style="float:right">{{ item.name }}  </span>
                         </template>
                       </v-select>
                     </v-col>

                   </v-row>
                 </v-container>
               </v-card>
               <v-card flat :dark="dark" :color="dark?'app-card':'app-card-light'" class="rounded-xl mt-2">
                 <v-card-title class="app-regular">
                   حسابات الادمين
                   <v-spacer></v-spacer>
                   <vs-button class="app-regular"   primary :loading="form.busy && num == '4'" @click="UpdateAllForms('4')">
                     تحديث حسابات الادمين
                     <img class="white-svg mr-2 " src="assets/icons/vuesax/send.svg">
                   </vs-button>

                 </v-card-title>

                 <v-container fluid>
                   <v-row dense>


                     <v-col class="col-lg-6 col-sm-6 col-12 col-xl-6 col-md-6">
                       <v-text-field v-model="form.watsapp" hide-details
                                     :background-color="dark?'#242426':'#fff'" flat filled
                                     class="app-regular rounded-lg"
                                     placeholder="واتي اب "></v-text-field>
                     </v-col>
                     <v-col class="col-lg-6 col-sm-6 col-12 col-xl-6 col-md-6">
                       <v-text-field v-model="form.telegram" hide-details
                                     :background-color="dark?'#242426':'#fff'" flat filled
                                     class="app-regular rounded-lg"
                                     placeholder="تيليغرام"></v-text-field>
                     </v-col>
                     <v-col class="col-12">
                       <v-text-field v-model="form.playStore" hide-details
                                     :background-color="dark?'#242426':'#fff'" flat filled
                                     class="app-regular rounded-lg"
                                     placeholder="تطبيقك على البلاي ستور"></v-text-field>
                     </v-col>
                   </v-row>
                 </v-container>
               </v-card>
               <v-card flat :dark="dark" :color="dark?'app-card':'app-card-light'" class="rounded-xl mt-2">
                 <v-card-title class="app-regular">
                   إضافة شروط أو قوانين أو معلومات
                   <v-spacer></v-spacer>
                   <vs-button class="app-regular"   primary :loading="loading2"  @click="addConditions" >
                     إضافة
                     <img class="white-svg mr-2 " src="assets/icons/vuesax/send.svg">
                   </vs-button>

                 </v-card-title>
                 <v-container fluid>
                   <v-row no-gutters>

                     <v-col class="col-12 pb-2">
                       <v-text-field  v-model="consition.title" hide-details
                                      :background-color="dark?'#242426':'#fff'" flat filled
                                      class="app-regular rounded-lg"
                                      placeholder="العنوان">
                         <template slot="append-outer">
                           <v-btn style="bottom: 10px;" v-if="consition.comment != ''"  class="mx-2"   large   icon depressed><img :class="dark?'white-svg':'black-svg'"
                                                                                                                                   class="mx-2" :src="consition.comment"

                           /></v-btn>
                         </template>
                       </v-text-field>
                     </v-col>
                     <v-col class="col-12">
                       <v-row no-gutters class="justify-center py-2">
                         <v-col  v-for="(icon,index) in icons" :key="index" >
                           <v-btn   class="mx-2"   large   icon depressed><img :class="dark?'white-svg':'black-svg'"
                                                                               class="mx-2" :src="'assets/icons/vuesax/'+icon+'.svg'"
                                                                               @click="[consition.comment = 'assets/icons/vuesax/'+icon+'.svg']"
                           /></v-btn>
                         </v-col>
                       </v-row>

                     </v-col>
                     <v-col class="col-12">
                       <VueEditor  v-model="consition.text"/>
                     </v-col>


                   </v-row>

                 </v-container>
               </v-card>
               <v-container>
                 <v-row>
                   <v-col class="col-12">

                     <v-expansion-panels flat class="mt-3 app-regular "   >
                       <v-expansion-panel     v-for="(config ,index) in allConditions" :key="index"    class="mt-4 rounded-lg" :class="dark?'app-card':'app-card-light '" style="border-radius: 14px;">
                         <v-expansion-panel-header     style="height: 65px;text-align: right;"  >
                           <div>
                             <v-btn icon  >
                               <img :class="dark?'white-svg':'black-svg'" :src="config.icon"/>
                             </v-btn>
                             <v-btn   icon @click="showDeleteDialoag(config)" >

                               <img  class="error-svg" :src="'assets/icons/vuesax/trash.svg'"/>

                             </v-btn>
                             <!--                                                       <v-btn   icon @click="showEditDialoag(config)" >-->

                             <!--                                                           <img  class="primary-svg" :src="'assets/icons/vuesax/edit.svg'"/>-->

                             <!--                                                       </v-btn>-->
                           </div>
                           <span   > {{ config.title }}  </span>
                         </v-expansion-panel-header>
                         <v-expansion-panel-content  >
                           <div v-html="config.text">

                           </div>
                         </v-expansion-panel-content>
                       </v-expansion-panel>
                     </v-expansion-panels>
                   </v-col>
                 </v-row>
               </v-container>
             </v-card>
           </v-col>
         </v-row>
       </v-container >
       <vs-dialog overflow-hidden not-padding not-center auto-width v-model="dialogColor">
         <v-container class="p-2">
           <v-color-picker  :value="form.webColor" v-model="form.webColor"
                            class="ma-2"
                            dot-size="30"
           ></v-color-picker>
           <v-btn :dark="dark"  :color="form.webColor" @click="dialogColor = !dialogColor"  class=" p-2  app-regular rounded-lg" block>إختيار اللون</v-btn>

         </v-container>
       </vs-dialog>

       <vs-dialog width="350px" not-center v-model="deleteDialogBank" no-margin class="app-regular">

         <h2 slot="header" class="text-center py-0  ">
           تأكيد الحذف
         </h2>
         <div class="text-right mb-0">
           <p class="mb-1" style="direction: rtl;" v-text="'هل أنت متأكد لحذف حسابك البنكي '">
           </p>
         </div>
         <v-card-actions slot="footer" class="py-0">
           <vs-button   transparent danger @click.prevent="deleteBnk(BankDelete)">
             موافق
           </vs-button>
           <vs-button @click="deleteDialogBank=false"  transparent>
             الغاء
           </vs-button>
         </v-card-actions>

       </vs-dialog>
       <vs-dialog width="350px" not-center v-model="deleteDialog" no-margin class="app-regular">

         <h2 slot="header" class="text-center py-0  ">
           تأكيد الحذف
         </h2>
         <div class="text-right mb-0">
           <p class="mb-1" style="direction: rtl;" v-text="'هل أنت متأكد لحذف المنشور '">
           </p>
           <h3 style="text-align: center">{{ConditionDelete.title}}</h3>
         </div>
         <v-card-actions slot="footer" class="py-0">
           <vs-button   transparent danger @click.prevent="deleteCondition(ConditionDelete)">
             موافق
           </vs-button>
           <vs-button @click="deleteDialog=false"  transparent>
             الغاء
           </vs-button>
         </v-card-actions>

       </vs-dialog>
       <vs-dialog width="350px" not-center v-model="numberDialog" no-margin class="app-regular">

         <h2 slot="header" class="text-center py-0  ">
           تأكيد الحذف
         </h2>
         <div class="text-right mb-0">
           <p class="mb-1" style="direction: rtl;" v-text="'هل أنت متأكد لحذف  '">
           </p>
           <h3 style="text-align: center">{{NumberDelete.title}}</h3>
         </div>
         <v-card-actions slot="footer" class="py-0">
           <vs-button   transparent danger @click.prevent="numberCondition(numberDialog)">
             موافق
           </vs-button>
           <vs-button @click="numberDialog=false"  transparent>
             الغاء
           </vs-button>
         </v-card-actions>

       </vs-dialog>

       <vs-dialog   blur     style="z-index: 1000" prevent-close :dark="$store.state.dark"     auto-width not-padding  v-model="dial">
         <div   class="con-image"  >
           <img   style="width:100%" class="overflow-hidden" :src="InterDialog.image" alt="">
         </div>
         <template #footer>
           <v-spacer></v-spacer>
           <vs-button class="app-regular"   color="#2c3e50" :loading="InterDialog.busy" @click="UpdateInterDialog">

             تحديث  صورة الترحيب
             <img class="white-svg mr-2 " src="assets/icons/vuesax/send.svg">
           </vs-button>
         </template>
       </vs-dialog>
     </v-card>
     <NotFound v-else/>
   </div>
</template>

<script>
    import Form from 'vform';
    import { VueEditor } from "vue2-editor";
    import axios from 'axios'
    import {getTextColor,setBaseUrl} from "@/tools/Services";
    import  {bus} from "vue";
    import {CkeckWeb} from "@/plugins/authUser";
    import TitlePages from "@/layout/TitlePages";
    import strMixins from '@/plugins/Mixins'
    import DashU from "@/layout/DashU";
    import NotFound from "@/components/comp-tool/NotFound.vue";
    export default {
        components:{TitlePages, VueEditor,DashU,NotFound},
        mixins:[strMixins],
        name: "AdminConfig",
        data(){
            return{
                dial:false,
                load:false,
                index:'',
                deleteDialog:false,
                numberDialog:false,
                deleteDialogBank:false,
                deleteDialogNumber:false,
                ConditionDelete:'',
                BankDelete:'',
                NumberDelete:'',
                collectionColor:[
                    {text:'shipili--text',value:'rgb(115, 103, 240)'},
                    {text:'grey--text',value:'#9E9E9E'},
                    {text:'pink--text',value:'#E91E63'},
                    {text:'red--text',value:'#F44336'},
                    {text:'deep-purple--text',value:'#673AB7'},
                    {text:'blue--text',value:'#2196F'},
                    {text:'indigo--text',value:'#3F51B5'},
                    {text:'blue-light--text',value:'#03A9F4'},
                    {text:'cyan--text',value:'#00BCD4'},
                    {text:'teal--text',value:'#009688'},
                    {text:'green--text',value:'#4CAF50'},
                    {text:'lime--text',value:'#CDDC39'},
                    {text:'yellow--text',value:'#FFEB3B'},
                    {text:'amber--text',value:'amber'},
                    {text:'amber--text',value:'amber'},
                    {text:'orange--text',value:'#FF9800'},
                    {text:'deep-orange--text',value:'#FF5722'},
                    {text:'brown--text',value:'#795548'},
                    {text:'blue-grey--text',value:'#607D8B'},
                ],
                icons:['add','admin','backspace','bank','bell','blog','book','code','commerce','copy',
                    'like','dislike','copy','edit','trash','image','home','mail','music','nut',
                    'search','star','simcard','success','users','wallet','userss','user-securite'],

                server:{
                    mobilis:true,
                    djezzy:true,
                    ooredoo:true,
                },
                showCard:false,
                accountsBank:[],
                numbers:[],
                dialogColor:false,
                consition: new Form({
                    title:'',
                    text:'',
                    isActive:false,
                    comment:'',
                    type:'',
                }),
                idoomTopup: new Form({
                    feild:'',
                    value:'',
                    isActive:false,
                    comment:'',
                    type:'',
                }),
                InterDialog: new Form({
                    feild:'',
                    value:'',
                    isActive:false,
                    image:''
                }),
                num:"",
                depot: new Form({
                    feild:'',
                    value:'',
                    image:'',
                    comment:'',
                    type:''
                }),
                mobileN: new Form({
                    feild:'numbers',
                    value:'',
                    comment:'',
                })
                , avatar: new Form({
                    image:'',

                }),
                allConditions:[],
                active1:false,
                form: new Form({
                    ModeCarte:'',
                    AdminRapportActivation:null,
                    prixMobilis:'',
                    prixDjezzy:'',
                    prixOoredoo:'',
                    prixMobilisGross:'',
                    prixDjezzyGross:'',
                    prixOoredooGross:'',
                    webCurency:'',
                    webColor:'',
                    webDescription:"",
                    codeDactivation:'',
                    directActivation:'',
                    watsapp:'',
                    telegram:'',
                    playStore:'',
                    theme:'',
                    topTitle:'',
                    msgActive:'',
                    msgFlexy:'',
                    msgColor:'',
                    msgRelief:'',
                    msgGradient:'',


                }),
                activation:[
                    {name:'يجب تفعيل الحساب',mode:'false'}  ,
                    {name:'فتح حساب بدون تفعيل',mode:'true'}  ,
                ],
                accounts:[
                    {name:'واتس أب',link:'false'}  ,
                    {name:'تلغرام',link:'true'}  ,
                ],
                idoom:[
                    {name:'الشحن الفوري مفعيل',mode:'true'}  ,
                    {name:'الشحن الفوري غير مفعل',mode:'false'}  ,
                ],  dialodInter:[
                    {name:'صورة الترحيب مفعلة',mode:'true'}  ,
                    {name:'صورة الترحيب غير مفعلة',mode:'false'}  ,
                ],
                items: [
                    {
                        text:'الاعدادات',
                        disabled: false,
                        href: '/admin-config',
                    },
                    {
                        text:this.$store.state.params.AppName,
                        disabled: true,
                        href: '/',
                    },


                ],
                dialogFlexy:{
                    isActive:false,
                    data:'',
                    message:'',
                    server:'',
                },
                loading:false,
                loading2:false,
            }
        },
        watch:{
            active1:function (newVal) {
                if(newVal==true){this.form.AdminRapportActivation = 'true'}else {this.form.AdminRapportActivation = 'false'}
            },
            'form.prixMobilis':function () {
                if (this.form.prixMobilis >100 || this.form.prixMobilis <=0 ){
                    this.form.prixMobilis = 100
                }
            },
            'form.prixDjezzy':function () {
                if (this.form.prixDjezzy >100 || this.form.prixDjezzy <=0 ){
                    this.form.prixDjezzy = 100
                }
            },
            'form.prixOoredoo':function () {
                if (this.form.prixOoredoo >100 || this.form.prixOoredoo <=0 ){
                    this.form.prixOoredoo = 100
                }
            }
        },
        methods:{
            setBaseUrl,
            UpdateIdoomTopup(){
              this.idoomTopup.post('api/admin-update-idoom-topup').then((response)=>{
                  this.idoomTopup.fill(response.data.data)
              })
            },
            UpdateInterDialog(){
              this.InterDialog.post('api/admin-update-inter-dialog').then((response)=>{
                  this.InterDialog.fill(response.data.data)
                  this.dial = false
              })
            },
            AddAccountVersement(num){

              this.depot.post('api/admin-add-account-versement').then((response)=>{
                    this.accountsBank= response.data.accounts
                  this.$store.dispatch("loadsna", {
                      text: "تمت الاضافة بنجاح",
                      isSuccess: true,
                  });
              }).catch((error)=>{
                  this.$store.dispatch("loadsna", {
                      text: error.response.data.message,
                      isSuccess: false,
                  });
              })
            },
            AddNumberPhone(num){

                this.mobileN.post('api/admin-add-number-phone').then((response)=>{
                    this.numbers= response.data.numbers
                    if (num== '1'){
                        this.$store.dispatch("loadsna", {
                            text: "تمت الاضافة بنجاح",
                            isSuccess: true,
                        });
                    }

                }).catch((error)=>{
                    if (num== '1'){
                        this.$store.dispatch("loadsna", {
                            text: error.response.data.message,
                            isSuccess: false,
                        });
                    }

                })
            },
            CkeckWeb,
            pick(){
                this.$refs.test.click()
            }, pick2(){
                this.$refs.pick2.click()
            },
            pick3(){
                this.$refs.test3.click()
            },
            uploadImage(e) {
                let file = e.target.files[0];

                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if (file["size"] > limit) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "You are uploading a large file",
                    });
                    return false;
                }
                reader.onloadend = (file) => {
                    this.depot.image = reader.result;
                };
                reader.readAsDataURL(file);

            },
            uploadImageInterDialog(e) {
            let file = e.target.files[0];

            let reader = new FileReader();
            let limit = 1024 * 1024 * 2;
            if (file["size"] > limit) {
                Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "You are uploading a large file",
                });
                return false;
            }
            reader.onloadend = (file) => {
                this.InterDialog.image = reader.result;
                this.dial = true
            };
            reader.readAsDataURL(file);

        },
            uploadImageAvatar(e) {
                let file = e.target.files[0];

                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if (file["size"] > limit) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "You are uploading a large file",
                    });
                    return false;
                }
                reader.onloadend = (file) => {
                    this.avatar.image = reader.result;
                    this.avatar.post('api/upload-avatar-web',).then((response)=>{
                        this.$store.dispatch("loadsna", {
                            text: "تمت الاضافة الصورة بنجاح",
                            isSuccess: true,
                        });
                    }).catch(()=>{
                        this.$store.dispatch("loadsna", {
                            text: "حدث خطأ أعد المحاولة",
                            isSuccess: true,
                        });
                    })

                };
                reader.readAsDataURL(file);

            },
            showEditDialoag(item){
              this.condition.fill()
            },
            showDeleteDialoag(condition){
                this.ConditionDelete = condition
                this.deleteDialog = !this.deleteDialog

            },
            showDeletebank(bank){
                this.BankDelete = bank
                this.deleteDialogBank = true
            },
            showDeletenumbers(number){
                this.NumberDelete = number
                this.deleteDialogNumber = true
            },
            deleteNumber(bank){

                axios.post('api/admin-delete-number',this.NumberDeleteDelete).then((response)=>{
                    this.accountsBank = response.data.accounts
                    this.deleteDialogNumber = false
                    let params = {
                        show:true,
                        text:'تم الحذف بنجاح',colorr:'success',isSucces:false,fail:false,timout:5000
                    }
                    this.$store.commit('SET_SNA',params)
                }).catch((error)=>{
                    let params = {
                        show:true,
                        text:error.response.data.message,colorr:'error',isSucces:false,fail:false,timout:5000
                    }
                    this.$store.commit('SET_SNA',params)
                })
            },
            deleteBnk(bank){

                axios.post('api/admin-delete-bank',this.BankDelete).then((response)=>{
                    this.accountsBank = response.data.accounts
                    this.deleteDialogBank = false
                    let params = {
                        show:true,
                        text:'تم الحذف بنجاح',colorr:'success',isSucces:false,fail:false,timout:5000
                    }
                    this.$store.commit('SET_SNA',params)
                }).catch((error)=>{
                    let params = {
                        show:true,
                        text:error.response.data.message,colorr:'error',isSucces:false,fail:false,timout:5000
                    }
                    this.$store.commit('SET_SNA',params)
                })
               },   deleteCondition(condition){
                axios.post('api/admin-delete-condition',{condition:condition.title}).then((response)=>{
                    this.allConditions = response.data.conditions
                    this.deleteDialog = false
                    let params = {
                        show:true,
                        text:'تم الحذف بنجاح',colorr:'success',isSucces:false,fail:false,timout:3000
                    }
                    this.$store.commit('SET_SNA',params)
                }).catch(()=>{
                    let params = {
                        show:true,
                        text:'حدث خطأ ما',colorr:'error',isSucces:false,fail:false,timout:3000
                    }
                    this.$store.commit('SET_SNA',params)
                })
               },
            addConditions(){
                this.loading2 = true
                    this.consition.post('api/admin-add-conditions').then((response)=>{
                        this.allConditions = response.data.conditions
                        this.consition.clear()
                        let params = {
                            show:true,
                            text:'تمت الاضافة بنجاح',colorr:'success',isSucces:true,fail:false,timout:3000
                        }
                        this.$store.commit('SET_SNA',params)
                    }).catch(()=>{
                        this.loading2 = false
                        let params = {
                            show:true,
                            text:'حدث خطأ تحقق من حقول الادخال ',colorr:'error',isSucces:false,fail:false,timout:3000
                        }
                        this.$store.commit('SET_SNA',params)
                    })
                this.loading2 = false

            },
            UpdateAllForms(num){
                this.num = num
              this.form.post('api/admin-update-config').then((response)=>{
                  this.form.fill(response.data)
              })
                localStorage.removeItem('web')
                CkeckWeb()
                this.getInfo()
            },
            getTextColor,
            showDialogFlexy(opperateur,server){
                this.loading = true
                axios.post('api/check-info-flexy',{opperateur:opperateur}).then((response)=>{
                    this.dialogFlexy.message = response.data.comment
                    this.dialogFlexy.server = response.data.isActive
                    this.dialogFlexy.data = opperateur
                })
                this.dialogFlexy.isActive = !this.dialogFlexy.isActive
                this.loading = false
            },

            handelCards(){
                this.showCard = !this.showCard
                localStorage.setItem("flexy_cards",JSON.stringify(this.showCard))
            },
            getInfo(){
                axios.get('api/admin-meta-info').then((response)=>{
                    let vm = this;
                vm.form.fill(response.data.data)
                     if(this.form.AdminRapportActivation=='true'){this.active1 = true}else{this.active1 = false}
                vm.allConditions = response.data.conditions
                vm.accountsBank =    response.data.accounts
                vm.numbers =    response.data.numbers
                vm.idoomTopup.fill(response.data.idoomTopup)
                vm.InterDialog.fill(response.data.dialog)

                }).then(()=>{

                })
                    .catch((e)=>{
                        this.$store.dispatch("loadsna", {
                            text: "حدث خطأ ما",
                            isSuccess: false,
                        });
                })
            }
        },

        mounted() {
            this.showCard = localStorage.getItem('flexy_cards')==='true'?true:false
            this.getInfo()
        }
    }
</script>

<style scoped>
  .v-list-item{
      float: right!important;
      direction: ltr!important;
  }
    .for-allert{
        height: 40px!important;
        text-align: center!important;
        padding-top: 7px!important;
    }
    .vs-dialog__header{
        direction: ltr!important;
    }
  >>> .v-input__slot::before {
      border-style: none !important;
  }
  .v-list-item--link{
      direction: rlt;
  }

  img{
      display :block;
      position :relative;
      border-radius: inherit;

  }

  .con-image{
      border-radius :inherit
  }

  img{
      display :block;
      position :relative;
      border-radius: inherit;

  }
</style>