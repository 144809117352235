<template>
  <v-card elevation="0"  :dark="dark" flat :color="dark?'#14151A':'#fff'" style="heigh:100vh">
      <v-card flat v-if="!verified"  class="center"  :dark="dark" :color="dark?'#14151A':'#fff'">
          <v-card-title class="   " style="display: block;text-align: center">
              <h5 class="app-regular text-center mb-2"   v-text="info.sms=='SMS'?'تم إرسال رمز التفعيل الى الهاتف':'تم إرسال رمز التفعيل الى بريدك الالكتروني'"> </h5>

          </v-card-title>
          <v-card-subtitle class="app-regular py-0 " style="text-align: center;display: block"> قم بتفعيل حسابك بإدخال رقم التفعيل المرسل </v-card-subtitle>
          <v-card-text style="min-width:300px;  max-width: 400px;    justify-content: center;display: flex;margin: auto;" class="pb-0">
              <v-otp-input  :color="dark?'app-card-lighten rounded-lg':'app-card-light'"   v-model="activationCode.code" :value="activationCode.code"  :dark="dark"  length="5" type="number"></v-otp-input>
          </v-card-text>
        <v-card-text style="min-width:300px;  max-width: 400px;    justify-content: center;display: flex;margin: auto;" class="pb-0">
          <vue-recaptcha
              :class="{ 'is-invalid': activationCode.errors.has('recaptcha') }"
              v-model="activationCode.recaptcha"
              ref="recaptchaLog"
              @verify="onVerifyLo"
              :sitekey="sitekey"
              class="g-recaptcha my-2 "
          ></vue-recaptcha>
        </v-card-text>
          <v-card-actions>
              <vs-button :active-disabled="!passeCaptchaLog" color="rgb(254, 44, 85)" block  type="submit" @click="submitCode"   :loading="activationCode.busy"
                          class=" px-6  rounded-lg app-bold white--text" style="height: 42px;max-width: 400px;margin: auto">
                  <img class="white-svg mx-3" src="assets/icons/vuesax/send.svg">
                  <strong>تفعيل الحساب</strong>

              </vs-button>
          </v-card-actions>
          <v-card-subtitle v-if="activateBtn == true" class=" primary--text pt-0 vuertl app-regular text-decoration-underline " style="cursor: pointer;max-width: 400px;margin: auto;" @click="SendCodeTo">إرسال رمز التفعيل</v-card-subtitle>
          <v-card-subtitle v-else class=" grey--text pt-0 vuertl app-regular   " style="max-width: 400px;margin: auto;"  >

            تم إرسال رمز التفعيل

            <span class="mx-1">{{loop}}</span>
          </v-card-subtitle>
          <v-card-text class="py-0" style="text-align: center;display: block;max-width: 400px;margin: auto;">
              <h4 v-if="error !=''" class="error--text app-regular" v-text="error"></h4>
          </v-card-text>
          <v-card-title>
              <vs-button  transparent active :dark="$store.state.dark" :class="$store.state.dark?'white--text':'black--text'" class="app-bold   mx-auto mx-3" @click="window.location.href = '/login'"  >إعادة المحاولة</vs-button>

          </v-card-title>

      </v-card>
      <v-card v-else class="center" flat  :color="dark?'#14151A':'#fff'"  style="display: block;text-align: center" >
          <v-card-title class="   " style="display: block;text-align: center">
              <h3 class="app-bold text-center mb-2" >تم تسجيل الدخول بنجاح</h3>

          </v-card-title>
          <v-card-subtitle class="app-regular ">نتشرف بزيارتكم لمنصتنا, يمكننا خدمتكم و شحن رصيدكم في أي وقت</v-card-subtitle>
          <v-card-text style="max-width: 400px;display: block;text-align: center" class="pb-0 mx-auto " >
              <img style="width: 70px" class="success-svg  mx-auto" src="assets/icons/vuesax/success-response.svg">
          </v-card-text>



      </v-card>

  </v-card>
</template>

<script>
    import Form from 'vform'
    import axios from 'axios'
    import * as auth from "@/plugins/authUser";
    import store from "@/store";
    import VueRecaptcha from "vue-recaptcha";
    export default {
        name: "Validation",
        components:{VueRecaptcha},
        computed:{
            dark:function () {
                return this.$store.state.dark
            },
            info:function () {
                return this.$store.state.info
            }
        },
        data(){
            return{
              sitekey:'6LeXWxUqAAAAAMTfpynU4tVXgzFYB9o0ghaU_82k',
              verified:false,
                error:'',
              passeCaptchaLog:false,
              activateBtn:true,
                activationCode:new Form({
                    code:'',
                    info:'',
                  recaptcha:''

                }),
              loop:60,
            }

        },
        methods:{
          onVerifyLo(response) {
            this.activationCode.recaptcha = response;
            if (response) {
              this.passeCaptchaLog = true;

            } else {
              this.passeCaptchaLog = false;
            }
          },
            submitCode(){
              if(this.passeCaptchaLog){
                let sendTo ="email"
                let link = 'api/activation-'+sendTo
                this.activationCode.info = this.info
                this.activationCode.post(link).then((response)=>{
                  if(response.data.user != null){
                    this.verified = true
                    this.$store.commit('auth/SET_USER', response.data.user)
                    auth.setToken(response.data.token)
                    setInterval(()=>{
                      location.href = "/"
                    },4000)
                  }else{
                    location.href = "/"
                  }
                }).catch(()=>{
                  this.$refs.recaptchaLog.reset();
                  this.passeCaptchaLog = false
                  this.activationCode.code = ""
                  this.verified = false
                  this.error = "حدث خطأ ما يرجى إعادة المحاولة"
                })
              }else {
                this.$refs.recaptchaLog.reset();
                this.passeCaptchaLog = false
              }

            },
          countDownTimer () {
            if (this.loop > 0) {
              setTimeout(() => {
                this.loop -= 1
                this.countDownTimer()
              }, 1000)
            }
            if(this.loop == 0){
              this.activateBtn = true
            }

          },

           async SendCodeTo(){
                this.activateBtn = false
                // if (this.info[0] != undefined){
                    this.activationCode.code = ''
                    let sendTo = !this.info.sms?'sms':'email'
                    let link = 'api/send-activation'
                    this.error = ""
                    axios.post(link,this.info).then((response)=>{
                      this.countDownTimer()
                      this.$vs.notification({
                        title:"Activation code",

                        text: response.data.message,
                        color:"success",
                        classNotification: 'app-regular',
                        duration:3000,
                        icon:`<img class="white-svg"  style="width: 100px"  src="assets/icons/vuesax/code.svg">`
                      })

                    }).catch((error)=>{
                        this.error = error.response.data.message
                    })

                // }else {
                //     localStorage.removeItem('token')
                //     store.commit('auth/SET_USER',null)
                //     store.commit('auth/SET_ISADMIN',false)
                //     store.commit('auth/SET_AUTHENTICATED',false)
                // }

            }
        },

        mounted() {
            this.SendCodeTo()
            this.activationCode.info = this.info

        },

        beforeRouteEnter(to , from ,next){
            next()
            if (from.name == "Configuration"){
               next()
            }else {
                this.$router.push("/login")
            }  next()

        }
    }
</script>

<style scoped>
    .center {
        margin-top: 30vh;
        margin-left: 30px;
        margin-right: 30px;

    }
    >>> .v-input__slot::before {
        border-style: none !important;
    }

</style>