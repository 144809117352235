<style scoped>
    .text-center {
        text-align: center !important;
    }
    .vs-dialog__content{
        padding-top: 0px!important;
        padding-bottom: 0px!important;
    }
</style>
<template>
    <div class="">
       <div  v-if="$store.state.auth.user.type == 'admin'">
         <div  class="pa-2">
           <v-card   :dark="$store.getters.dark" flat color="transparent" class="rounded-xl py-5" >
             <v-card-title>
               <v-text-field
                   :class="dark?'black--text':'white--text'"
                   :background-color="dark?'#2d2d39':'#EEF2F5'"
                   :dark="dark" v-model="search"
                   class=" overflow-hidden rounded-lg transi app-regular"
                   hide-details="true"  filled     dir="rtl"
                   placeholder="بحث"

               >
                 <img   :class="dark?'white-svg':'black-svg'"    style="cursor: pointer"  slot="prepend-inner" src="assets/icons/vuesax/search.svg">
               </v-text-field>


               <v-spacer></v-spacer>
               <vs-button   x-large :class="dark?'app-card':'app-card-light'"   @click="newModel()" color="transparant" flat   >
                 <span class="app-regular mx-3"> إضافة منتج</span>
                 <img :class="dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/users.svg"/>
               </vs-button>


             </v-card-title>
             <v-card-text  >
               <v-row class="justify-content-center align-center" v-if="products.length >0 && showEmpty== false">
                 <v-col class="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xs-4  " v-for="(product , index) in products"  :key="index">
                   <v-card @click.stop="ajouterCartes(product)"  flat class="rounded-lg" style="cursor: pointer;"  :dark="!dark"   :color="product.count== 0 ?'tik':dark?'app-card-lighten rounded-lg card-shadow':' card-shadow app-card-light'"   >

                     <v-container class="app-regular pt-2">
                       {{product.name}}
                       <v-card-title   class="pa-0">
                         <vs-avatar>
                           <img width="100%" height="100%" :src="setBaseUrl(product.image)" />
                         </vs-avatar>
                         <v-spacer></v-spacer>
                         <v-btn @click.stop="showDialogEdit(product)"  icon  >
                           <img :class="product.count == 0?'white-svg':$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/edit.svg"/>
                         </v-btn>

                       </v-card-title>
                       <v-card-title  v-if="product.count >0" class="pa-0 px-3" style="font-size:16px">
                         <span class="mx-2"><b> {{product.notUsed}}</b></span>
                         <span  >  غير مستعملة</span>
                         <v-spacer></v-spacer>
                         <span class="mx-2"><b> {{product.used}}</b></span>
                         <span  >  مستعملة</span>
                       </v-card-title>
                       <v-card-title  v-else class="pa-0 px-3 m" style="font-size:16px;display: block;text-align:  center">
                         لا يوجد بطاقات في لمخزون
                       </v-card-title>
                     </v-container>

                   </v-card>
                 </v-col>
               </v-row>
               <v-row v-if="showEmpty" class="justify-content-center align-center">
                 <v-col class="col-12 mt-5">
                   <v-card flat class="center">
                     <v-card-title class=" mt-8">
                       <v-spacer></v-spacer>
                       <img src="assets/icons/vuesax/empty-products.svg">
                       <v-spacer></v-spacer>

                     </v-card-title>
                     <h3 class="app-bold text-center">لا تتوفر منتوجات</h3>

                   </v-card>
                 </v-col>
               </v-row>
             </v-card-text>




           </v-card>



           <v-dialog width="600" v-model="showimg">
             <v-card width="600" class="">
               <img :src="setBaseUrl(form.image)" style="width: 170px; margin-left: 30%" />
             </v-card>
           </v-dialog>

         </div>
         <vs-dialog width="350px" not-center v-model="deleteDialogCode" no-margin class="app-regular">

           <h2 slot="header" class="text-center py-0  ">
             تأكيد الحذف
           </h2>



           <div class="text-right mb-0">
             <p class="mb-1" style="direction: rtl;" v-text="`هل أنت متأكد لحذف رقم البطاقة ${ ''}` ">

             </p>
           </div>


           <v-card-actions slot="footer" class="py-0">
             <vs-button   transparent danger @click="DestroyIdoom(deletedCode)">
               موافق
             </vs-button>
             <vs-button @click="deleteDialogCode=false"  transparent>
               الغاء
             </vs-button>
           </v-card-actions>

         </vs-dialog>
         <vs-dialog width="350px" not-center v-model="dialogDeleteAll" no-margin class="app-regular">

           <h2 slot="header" class="text-center py-0  ">
             تأكيد الحذف
           </h2>



           <div class="text-right mb-0">
             <p class="mb-1" style="direction: rtl;" v-text="`هل أنت متأكد لحذف جميع البطاقات المستعملة` ">

             </p>
           </div>


           <v-card-actions slot="footer" class="py-0">
             <vs-button  :loading="load"  transparent danger @click="DestroyUsedCard()">
               موافق
             </vs-button>
             <vs-button @click="dialogDeleteAll= false"  transparent>
               الغاء
             </vs-button>
           </v-card-actions>

         </vs-dialog>
         <v-dialog :dark="dark" auto-width overflow-hidden not-padding blur
                   v-model="dialogCard" max-width="850"



         >
           <v-card  max-width="850" :dark="dark" class="rounded-xl overflow-hidden position-relative">
             <v-card-title class="app-regular pt-0">
               <v-btn @click="dialogCard = !dialogCard"  large   style="float: left!important;" icon depressed><img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close-1.svg" /></v-btn>

               <v-btn v-if="tab == 'tab-2'" @click="showFile()" color="primary"  depressed elevation="0" class="white--text rounded-lg">
                 <img class="white-svg" src="assets/icons/vuesax/file.svg" />
                 تحميل ملف

               </v-btn>
               <v-spacer></v-spacer>
               إضافة أكواد المنتجات
             </v-card-title>
             <div  >
               <v-tabs  :class="dark?'app-card':'app-card-light'"
                        v-model="tab"


                        fixed-tabs
                        slider-color="warning" class="app-regular"
                        icons-and-text
               >

                 <v-tabs-slider :color="$store.state.params.bg"></v-tabs-slider>
                 <v-tab href="#tab-1">
                   البطاقات المتوفرة
                 </v-tab>
                 <v-tab href="#tab-2">
                   <span> إضافة أكواد بطاقات</span>
                 </v-tab>
               </v-tabs>
               <v-tabs-items  v-model="tab" class="vuertl" :dark="dark">
                 <v-tab-item

                     :value="'tab-1'"
                 >
                   <v-card flat  :ripple="false"
                           loader-height="5"  :dark="dark"

                           :class="dark?'app-card':'app-card-light'"

                   >
                     <template slot="progress">
                       <v-progress-linear color="#2196F3" indeterminate></v-progress-linear>
                     </template>
                     <v-card-title class="py-0 pt-2">
                       <vs-button   danger  color="rgb(254, 44, 85)"  @click="dialogDeleteAll = !dialogDeleteAll">حذف البطاقات المستعملة</vs-button>
                     </v-card-title>
                     <v-virtual-scroll
                         :items="cards"
                         height="300"
                         item-height="64"
                     >

                       <template v-slot:default="{ item }">
                         <v-list    >

                           <v-list-item-group>
                             <v-list-item :ripple="false" :key="item.id" style="height: 60px" >
                               <template v-slot:default="{ active }">
                                 <v-list-item-content>
                                   <v-row>
                                     <v-col class="col-3">
                                       <vs-avatar >
                                         {{item.id}}
                                       </vs-avatar>
                                     </v-col>
                                     <v-col v-if="item.message != null" :class="item.message !=''?'col-7':''">
                                       <vs-tooltip    >
                                         <vs-button      flat >
                                           {{splitName(item.message)}}
                                         </vs-button>
                                         <template #tooltip>
                                           <span class="white--text app-regular">{{item.message}}</span>
                                         </template>
                                       </vs-tooltip>
                                     </v-col>
                                   </v-row>
                                 </v-list-item-content>
                                 <v-list-item-content class="p-0">
                                   <div v-if="textFeildValue != item.code">
                                     <v-list-item-title style="font-family: sans-serif" v-text="item.code"></v-list-item-title>
                                   </div>
                                   <div v-if="textFeildValue == item.code">
                                     <v-text-field :autofocus="textFeildValue == item.code" hide-details class="pt-n3" style="padding-top: 0px!important;font-size:17px"    v-model="textofFeild">
                                       <v-template  v-slot:append-outer-icon>
                                         <v-icon>mdi-account</v-icon>
                                       </v-template>
                                     </v-text-field>

                                   </div>
                                 </v-list-item-content>
                                 <v-list-item-action  >
                                   <div>
                                     <vs-button-group class="mt-0 app-regular" style="direction: ltr">
                                       <vs-button :dark="!dark" :color="dark?'#2d2d39':'#EEF2F5'"     icon @click="editFeild(item)">

                                         <img style="font-size: 12px" :class="textFeildValue == item.code?'primary-svg':dark?'white-svg':'black-svg'" src="assets/icons/vuesax/edit.svg">
                                         <span class="ml-2" :class="!dark?'black--text':'white--text'">تعديل</span>
                                       </vs-button>
                                       <vs-button  :dark="!dark"  :color="dark?'#2d2d39':'#EEF2F5'" icon  @click="showDialogDeleteCode(item)">
                                         <img style="font-size: 12px" :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/trash.svg">
                                         <span  class="ml-2"  :class="!dark?'black--text':'white--text'">حذف</span>
                                       </vs-button>

                                       <vs-button  :dark="!dark"  :color="dark?'#2d2d39':'#EEF2F5'"  icon @click="Updateidoom(item)">
                                         <img style="font-size: 12px" :class="textFeildValue == item.code?'purple-svg':dark?'white-svg':'black-svg'" src="assets/icons/vuesax/send.svg">
                                         <span class="ml-2"  :class="!dark?'black--text':'white--text'">حفض</span>
                                       </vs-button>
                                       <vs-switch v-model="item.isRecharged==1" class="ml-2" @click="switchToNotRecharged(item)">
                                         <template #off>

                                         </template>
                                         <template #on>
                                           Recharged
                                         </template>
                                       </vs-switch>
                                     </vs-button-group>



                                   </div>
                                 </v-list-item-action>
                               </template>
                             </v-list-item>
                           </v-list-item-group>
                         </v-list>
                       </template>
                     </v-virtual-scroll>
                   </v-card>
                 </v-tab-item>
                 <v-tab-item
                     :value="'tab-2'"
                 >

                   <v-card flat :dark="dark"  :class="dark?'app-card':'app-card-light'"  >
                     <v-container>
                       <form @submit.prevent="addCart">
                         <v-card-actions>
                           <v-row>
                             <v-col class="col-6 py-0">

                               <v-text-field :background-color="dark?'#000':'#fff'"  hide-details filled  style="font-size: 18px" class="mt-0 app-regular rounded-lg" label="الاسم" name="name" v-model="form.name"

                                             :error-messages="form.errors.name"  >

                               </v-text-field>

                             </v-col>
                             <v-col class="col-6  py-0">
                               <v-text-field :background-color="dark?'#000':'#fff'"  hide-details filled style="font-size: 18px" class="rounded-lg mt-0 app-regular" label="السعر"   v-model="form.amount"
                                             :error-messages="form.errors.amount"   name="amount">
                               </v-text-field>

                             </v-col>


                             <v-col class="col-12 py-0 mt-2" v-if="!form.isFileActive">
                               <v-text-field :error-messages="form.errors.code"
                                             :background-color="dark?'#000':'#fff'"  hide-details filled    style="font-size: 30px"  class="app-regular rounded-lg"  label="كود البطاقة" v-model="form.code" type="text" name="code" :class="{ 'is-invalid': form.errors.has('code') }" ></v-text-field>

                             </v-col>



                             </v-file-input>
                             <input v-show="false" ref="files" style="display:none" type="file" class="form-control" v-on:change="onFileChange">



                           </v-row>




                         </v-card-actions>
                         <v-card-actions>


                           <vs-button color="rgb(254, 44, 85)" active block :color="form.isFileActive?'success':'primary'" class="app-regular rounded-lg white--text" :loading="form.busy"      type="submit" >
                             <span v-if="!form.busy" v-text="form.isFileActive?'إرفاق ملف':'إضافة كود ENTER'"> </span>
                             <img class="white-svg mx-2" :src="form.isFileActive?'assets/icons/vuesax/file.svg':'assets/icons/vuesax/send.svg'">
                           </vs-button>

                         </v-card-actions>
                       </form>
                     </v-container>

                   </v-card>
                 </v-tab-item>
               </v-tabs-items>
             </div>

           </v-card>

         </v-dialog>
       </div>
       <NotFound v-else />










    </div>
</template>
<script>
    import axios from "axios";
    import Form from 'vform'
     import {setBaseUrl,splitName} from "@/tools/Services";

    export default {

        data() {
            return {
                load:false,
                dialogDeleteAll:false,
                deletedCode:'',
                deleteDialogCode:false,
                showEmpty:false,
                cardTypes:[],
                textFeildValue:'',
                textofFeild:'',
                tab:'',
                dialogCard:false,
                deleteDialog:false,
                sousCategorie:[],
                categories:[],
                errors:[],
                showimg: false,

                links: null,
                search: "",
                headers: [
                    { text: "البطاقة", value: "name", filterable: true },
                    { text: "الصورة", value: "image", filterable: true },
                    { text: "السعر", value: "CardPrice", filterable: true },
                    { text: "تفصيل", value: "description_ar", filterable: true },
                    { text: "الانتماء", value: "groupe_id", filterable: true },
                    { text: "التفعيل", value: "isActive", filterable: true },
                ],
                mode:'',
                form: new Form({
                    id:'',
                    type:'',
                    name:'',
                    code:'',
                    amount:'',
                    isFileActive:false,
                    file:'',


                }),

                item: [],
                sum: 0,
                imageMode: 0,
                editMode: false,
                loading: true,
                formPost: new Form({
                    id:'',
                    name:"",
                    image:[],
                    type:"Mobilis",
                    amount:"",
                    isActive:false,


                }),
                getedecard: [],
                products: [],
                cards:[],
                name: "",
                type: window.user,
                prodDelete:'',
            };
        },
        computed:{
            dark:function () {
                return this.$store.state.dark
            }
        },
        async created() {
            await this.getProducts();
            // await this.getcardgroupe();
        },
        watch: {
            search(after) {
                this.searchProduct(after);
            },

            dialogCard(){
                if(this.dialogCard == false){
                    // this.CountCards()
                }
            },
          tab:function () {

            if (this.tab !='tab-2'){
              axios.get('api/get-info-mobilis/'+this.form.name).then((response)=>{
                this.cards = response.data

              }).catch(()=>{
                this.cards = []
              })
            }


          },


        },
        methods: {splitName,
            DestroyUsedCard(){
                 this.load = true
                 axios.post("api/destroy-all-used-cards-mobilis",{name:this.form.name}).then(()=>{
                     axios.get('api/get-info-mobilis/'+this.form.name).then((response)=>{
                         this.cards = response.data


                     })
                 })

                this.load = false
                this.dialogDeleteAll = false
            },
           async switchToNotRecharged(item){
            await  axios.post('api/update-mobilis-recharged',item)
            await  axios.get('api/get-info-mobilis/'+item.name).then((response)=>{
                    this.cards = response.data

                })
            },
            showFile(){
            if(this.form.isFileActive == true){
                this.form.isFileActive = false
            }else{
                this.form.isFileActive = true
                this.pickFiles()
            }

            },
            formSubmit() {
                const config = {
                    headers: { 'content-type': 'multipart/form-data' }
                }
                this.form.post('api/upload-file-code-mobilis', config)
                    .then((response) => {

                        this.form.code = ""
                        Toast.fire({
                            timer:7000,
                            background:this.dark?'#000':'#fff',
                            color:this.dark?'#fff':'#000',
                            customClass:"app-regular vuertl",
                            icon: "success",
                            title:  "تمت الاضافة بنجاح - "+ ' ' + 'مجموع أكواد الملف'+' ' +response.data.fileCount   ,
                        });
                        this.getProducts();
                    })
                    .catch((e) => {
                        this.form.code = ""
                        Toast.fire({
                            timer:7000,
                            background:this.dark?'#000':'#fff',
                            color:this.dark?'#fff':'#000',
                            customClass:"app-regular vuertl",
                            icon: "error",
                            title: e.response.data.message   ,
                        });


                    });
            },
            setBaseUrl,
            onFileChange(e){
                 this.form.file = e.target.files[0];
            },
            pickFiles(){
            this.$refs.files.click()
            },
            async getCardFromDataBase(card){

                axios.post('api/get-mobilis-from-database', {
                    name: card.name,
                })
                    .then((response)=>{
                        this.tab = 'tab-1'
                        this.textofFeild = ''
                        this.cards = response.data.mobilis;
                        this.selectedName = response.data.name
                        // this.form.id = response.data.id
                        // this.form.type = response.data.id
                        this.dialogCard = true
                        this.deleteDialogCode = false

                    })
                    .catch(function (error) {


                    });

            },
            showDialogDeleteCode(product){

                this.deletedCode = product
                this.deleteDialogCode = ! this.deleteDialogCode
            },

            DestroyIdoom(deletedCode){
                axios.delete('api/delete-code-mobilis/'+this.deletedCode.id).then(r => {
                    this.deleteDialog = false
                    this.$store.dispatch("loadsna", { text:"تم التحديث بنجاح", isSuccess: true});
                    this.getCardFromDataBase(deletedCode);
                    this.loading = false
                    this.deleteDialogCode = false

                }).then( this.textFeildValue = '', this.loading = false).catch(()=>{
                    this.deleteDialog = false
                    this.deleteDialogCode = false
                    this.$store.dispatch("loadsna", {
                        text: error.response.data.message || 'حدث خطأ مجهول يرجى إعادة المحاولة',
                        isSuccess: false,
                    });
                })
            },
            searchProduct(after) {
                if(after !=''){
                    axios.get("api/findProducts?q=" + after).then(response => {

                        if (response.data.length >0){
                            this.showEmpty = false
                            this.products = response.data;
                        }else {
                            this.showEmpty = true
                        }

                    })
                        .catch(() => {});
                }else {
                    this.showEmpty = false
                    this.getProducts();
                }
            },
            Addproduct(){
                this.formPost.post('api/mobilis').then((response)=>{
                    this.getProducts()
                    this.formPost.clear()
                    this.$store.dispatch("loadsna", {
                        text:"تم إضافة المنتج بنجاح",
                        isSuccess: true
                    });
                    this.formPost.clear()
                 }).catch((err)=>{
                    this.$store.dispatch("loadsna", {
                        text: err.response.data.message || "حدث خطأ ما يرجى المحاولة " ,
                        isSuccess: false
                    });

                })
            }  ,

            UpdateCard(item){
                if (this.textofFeild != item.code){
                    axios({
                        url:'api/update-card-id/'+item.id,
                        method:'put',
                        data:{
                            code:item.code,
                            name:this.selectedName,
                            textFeild:this.textofFeild

                        }
                    }).then(r => {
                        this.$store.dispatch("loadsna", {
                            text:"تم التحديث بنجاح",
                            isSuccess: true,
                        });

                        this.loading = false

                    }).then( this.textFeildValue = '', this.loading = false).catch(()=>{
                        this.$store.dispatch("loadsna", {
                            text: error.response.data.message || 'حدث خطأ مجهول يرجى إعادة المحاولة',
                            isSuccess: false,
                        });
                    })
                }else {
                    this.textFeildValue = ''
                }

            },
            Updateidoom(item){
                if (this.textofFeild != item.code && this.textFeildValue != ""){
                    axios({
                        url:'api/update-mobilis-id/'+item.id,
                        method:'put',
                        data:{
                            code:item.code,
                            name:this.selectedName,
                            textFeild:this.textofFeild

                        }
                    }).then(r => {
                        this.$store.dispatch("loadsna", {
                            text:"تم التحديث بنجاح",
                            isSuccess: true,
                        });

                        this.loading = false
                        this.getCardFromDataBase(item);
                    }).then( this.textFeildValue = '', this.loading = false).catch(()=>{
                        this.$store.dispatch("loadsna", {
                            text: error.response.data.message || 'حدث خطأ مجهول يرجى إعادة المحاولة',
                            isSuccess: false,
                        });
                        this.getCardFromDataBase(item);
                    })

                }else {
                    this.textFeildValue = ''
                }

            },
            editFeild(item){
                this.textFeildValue = item.code
                this.textofFeild = this.textFeildValue
            },
          async  ajouterCartes(product){
                this.form.clear()
              this.textFeildValue = ''
              this.form.file = ''
              this.form.isFileActive =false

              this.form.fill(product)
              this.form.type = product.type
                axios.get('api/get-info-mobilis/'+product.name).then((response)=>{
                    this.cards = response.data

                    return product
                }).catch((error)=>{
                    this.tab = ('tab-2')
                    this.$store.dispatch("loadsna", {
                        text: error.response.data.message,
                        isSuccess: false,
                    }).finally(()=>{

                    });

                })

              this.dialogCard = !this.dialogCard
            },

            showDialogEdit(product){
                this.editMode = true
                this.formPost.fill(product)

                this.textFeildValue = ""
            },
            showDialogDelete(product){

                this.prodDelete = product
                this.deleteDialog = true
            },

            getCardType(){
                axios.get('api/admin-mobilie-type').then((response)=>{
                    this.cardTypes = response.data
                })
            },

            pickFile(){
                this.$refs.image.click()

            },
            updatePicture(){
                let fileInput = this.$refs.image
                let imgFile = fileInput.files

                if (imgFile && imgFile[0]) {
                    let reader = new FileReader
                    reader.onload = e => {
                        this.formPost.image = e.target.result
                    }
                    reader.readAsDataURL(imgFile[0])


                }

            },

            updateisActive(carte) {
                const carteID = carte.isActive;
                axios({
                    method: "post",
                    url: "api/update-is-active-card/" + carte.id,
                    data: {
                        isActive: carteID,
                    },
                }).then(() =>
                    this.getProducts()

                );
            },
            getcardgroupe() {
                axios.get("api/products-admin").then((response) => {
                    this.getedecard = response.data;
                });
            },
            showImage(image) {
                this.form.image = "";
                this.form.image = image;
                (this.imageMode = 0), (this.imageMode = 1);
                this.showimg = !this.showimg;
            },
            uploadImage(e) {
                let file = e.target.files[0];
                let reader = new FileReader();
                if (file["size"] < 5111745) {
                    reader.onloadend = () => {
                        this.form.image = reader.result;
                    };
                    reader.readAsDataURL(file);
                } else {
                    Swal.fire("تنبيه", 'حجم الصورة أكبر من 5 ميغابايت', "warning");
                }

            },

            newModel() {


                this.editMode = false;
                this.formPost.reset();
                 this.getCardType();

            },

            getProducts() {
                axios.get("api/admin-mobilis").then((response) => {
                    this.products = response.data;
                    if (this.products.length <=0){
                        this.showEmpty = true
                    }else {
                        this.showEmpty = false
                    }


                });
            },
            addCart() {
               if(this.form.isFileActive == true){
                   let vm = this
                   vm.formSubmit()
               }else{
                   this.form.post("api/add-code-mobilis")
                       .then((response) => {
                           this.form.code = ""
                           Toast.fire({
                               background:this.dark?'#000':'#fff',
                               color:this.dark?'#fff':'#000',
                               customClass:"app-regular vuertl",
                               icon: "success",
                               title:  "تمت الاضافة بنجاح - "+ ' ' + 'المجموع'+' ' +response.data.count   ,
                           });
                           this.getProducts();
                       })
                       .catch((e) => {
                           this.form.code = ""
                           Toast.fire({
                               background:this.dark?'#000':'#fff',
                               color:this.dark?'#fff':'#000',
                               customClass:"app-regular vuertl",
                               icon: "error",
                               title: e.response.data.message   ,
                           });


                       });
               }
            },

        },
    };
</script>
<style scoped>
    >>> .v-input__slot::before {
        border-style: none !important;
    }
    >>> .v-input__slot::before {
      border-style: none !important;
    }
    .center {
        display: block;
        margin-left: auto;
        margin-right: auto;
        width: 50%;
    }
    .swal2-modal{
        font-family: 'app-regular'!important;
    }
</style>