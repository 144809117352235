<template>
    <v-card   flat :color="$store.state.dark?'app-bg':''" class="app-mb-10 vuertl">

        <div class="ma-4" v-if="current_page >0" >
            <TitlePages title="مشتريات البطاقات">
                <v-app-bar-nav-icon slot="btn"  to="/home"  large   style="float: left!important;" icon depressed><img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-app-bar-nav-icon>
            </TitlePages>
            <v-card-title class="px-0 pt-0" v-if=" historiqueData.length">

                <v-text-field  clearable :dark="$store.state.dark" v-model="search"     class="transi app-regular " style=" border-radius: 14px!important" hide-details="true"  filled rounded   dir="rtl"        :placeholder="'  بحث'" :background-color="$store.state.dark?'app-card':'app-card-light'">
                    <img   :class="$store.state.dark?'white-svg':'black-svg'"  class="ml-2"    style="cursor: pointer"  slot="prepend-inner" src="assets/icons/vuesax/search.svg">
                </v-text-field>
                <v-spacer></v-spacer>
                <v-menu
                        :dark="$store.state.dark"

                        ref="menu"
                        transition="slide-x-transition"
                        bottom
                        right
                        v-model="menu"
                        :close-on-content-click="false"
                        :return-value="dates"

                        min-width="auto"
                >
                    <template v-slot:activator="{ on }">
                        <vs-button class="app-regular"  v-on="on" primary icon>
                            <img class="white-svg " src="assets/icons/vuesax/calendar.svg">
                        </vs-button>

                    </template>

                    <v-date-picker

                            v-model="dates"
                            no-title
                            scrollable

                    >
                        <v-spacer></v-spacer>

                        <v-btn
                                text
                                color="#03A9F4"
                                @click="menu = false"
                        >
                            Annulé
                        </v-btn>

                        <v-btn :disabled="dates  > getNow "
                               @click="getWithdate()"
                               text
                               color="#03A9F4"
                         >
                            OK
                        </v-btn>
                    </v-date-picker>
                </v-menu>





            </v-card-title>

           <v-row  v-if="!show" >
            <v-col  class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 "  v-for="(item,index) in historiqueData" :key="index" >
                <v-card   flat  ripple  @click="HandelProduct(item)"     style="height: 83px;cursor: pointer" class=" rounded-xl" :color="$store.state.dark?'app-card':'app-card-light'">
                <v-card-title style="margin-right: 60px;" class=" py-0" :class="$store.state.params.font">
                    <img :class="colorSvg(item.Status)" :src="iconResponse(item.Status)" />

                    <v-spacer></v-spacer>
                    <span style="font-size: 16px" v-text="item.CardType" class="text-truncate"  ></span>
                    <v-card color="orange"   :loading="item.Status=='En attente'" loader-height="3" style="background-color: transparent!important; position: absolute; right: 0;top: 0;z-index: 1;"  class="mr-n1 mt-n3" rounded="lg" height="80" width="60" >
                  <v-img

                    height="100%"  width="100%"  :src="setBaseUrl(item.image)" ></v-img>
                </v-card>
            </v-card-title>
            <v-card-title v-if="item.readed =='1'" style="margin-right: 60px;    font-size: 17px;" class="pt-0 pb-2">
                <span   style="direction: ltr;font-size: 16px;" class="app-regular mr-4" :class="getTextColor(item.Status)"
                v-text="formatToPrice(item.SoldePrice) +' x '+ item.count" > </span>
                    <v-spacer></v-spacer>
                <span  ><b :class="getTextColor(item.Status)">{{ formatToPrice(item.Total) }}</b></span>

            </v-card-title>
                    <v-card-title v-else  style="text-align: center;display: block"  class="pt-0">
                        <img   src="assets/images/gifta.png" />
                    </v-card-title>
                <v-card-subtitle  v-if="item.readed =='1'" >
                    <p  class="text-center app-regular " :class="$store.state.dark?'grey--text':''">{{ item.created_at | datee }}</p>

                </v-card-subtitle>
           </v-card>
            </v-col>
           </v-row>
            <v-card v-if="show" style="margin-top: 25vh;"  class="center" :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''">
                <v-card-title class="pb-1"  >
                    <v-spacer></v-spacer>
                    <img style="width: 90px" src="assets/notifications/search.png">
                    <v-spacer></v-spacer>

                </v-card-title>
                <h5 class="app-bold text-center" v-text="'لا توجد بيانات '"> </h5>

            </v-card>

            <infinite-loading  @infinite="infiniteHandler" :style="{marginTop:!historiqueData.length?'35vh':''}" >
                <div :style="{marginTop:!historiqueData.length?'25vh':''}"  class="mb-5 mt-3 app-medium"  slot="spinner" :class="$store.state.params.color">جار البحث ...</div>
                <div     slot="no-more" class="app-medium mt-3" :class="[$store.state.params.color]"  >لا يوجد المزيد</div>
                <div    class="mb-5 app-medium"  slot="no-results" :class="$store.state.params.color">

                    <v-card style="margin-top: 25vh;"  class="center" :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''">
                        <v-card-title class="pb-1"  >
                            <v-spacer></v-spacer>
                            <img style="width: 90px" src="assets/notifications/search.png">
                            <v-spacer></v-spacer>

                        </v-card-title>
                        <h5 class="app-bold text-center" v-text="user!=null?'لا يوجد أي مشتريات':'قم بتسجيل الدخول'"> </h5>

                    </v-card>
                 </div>

            </infinite-loading>
        </div>


    </v-card>
</template>

<script>
import axios from 'axios';
 import StatusComp from '@/components/comp-tool/StatusComp.vue';
import {formatToPrice, iconResponse, colorSvg, setBaseUrl, getColor, getTextColor} from '@/tools/Services';
import TitlePages from "@/layout/TitlePages";
    export default {
        components:{TitlePages, StatusComp},
        data(){
            return{
                date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                dates:'',
                menu:false,
                search:'',

                show:false,
                current_page:1,
                historiqueData:[],
            }
        },
        computed:{
            getNow: function() {
                const date = new Date()
                return date.toISOString().split('T')[0]
            },
          user:function () {
            return this.$store.state.auth.user
          }
        },
        watch:{
            search(after) {
                this.searchProduct(after);
            },
        },
        methods:{
          getTextColor,
          getColor, setBaseUrl,
            getWithdate(){
            if (this.dates != ''){
                axios.post('api/get-card-dates',{date:this.dates}).then((response)=>{
                    if (response.data.length){
                        this.show = false
                        this.historiqueData = response.data
                    }else {
                        this.show = true
                    }

                })
            }
            },
            searchProduct(after) {
                if(after !=''){
                    axios.get("api/findCards?q=" + after).then(response => {
                        if(response.data.length === 0){
                            this.show = true
                        }else{
                            this.show = false
                            this.historiqueData = response.data
                        }

                    })
                        .catch(() => {});
                }else {

                     this.infiniteHandler();
                }
            },
         async   HandelProduct(item){
              await axios.post('api/update-status-readed', item)
              await  this.$store.commit('SET_PRODUCT',item)
              this.$router.push({name:"Info",params:{id:item.id}}).catch(()=>{})

            },
            formatToPrice,iconResponse,colorSvg,
            getHistoriqueData($state){
                axios.get('api/card-request', {page: this.current_page}
          ).then((response)=>{

                    if(response.data.data.length >0){
                        this.current_page +=1
                        this.historiqueData.push(...response.data.data);

                    } else{

                        $state.complete();
                    }

                }).catch(()=>{

                    $state.complete();
                })
            },
            infiniteHandler($state){

                if(this.user!=null ){
                    axios.get('api/card-request?page='+this.current_page
                    ).then((response)=>{

                        if(response.data.data.length >0){
                            this.current_page +=1
                            this.historiqueData.push(...response.data.data)

                        }else{
                            $state.complete();
                        }

                    }) .catch(()=>{
                        $state.complete();
                    })
                }
                else {
                    $state.complete();
                }

            }
        },

    }
</script>

<style lang="scss" scoped>
.v-ripple-container{
    z-index: 10!important;
}
.v-card--link:before {
    background: none!important;
}
</style>