<template>
    <v-card   flat :color="$store.state.dark?'app-bg':''" class="app-mb-10 vuertl">

        <div class="pa-2" v-if="current_page >0" >
            <TitlePages title="مشتريات إيدوم">

                <v-app-bar-nav-icon slot="btn"  @click="$router.go(-1)" large   style="float: left!important;" icon depressed><img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-app-bar-nav-icon>
            </TitlePages>

           <v-row  >
            <v-col  class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 "  v-for="(item,index) in historiqueData" :key="index" >

                <v-card  flat  ripple  @click="HandelProduct(item)"     style="height: 86px;cursor: pointer" class=" rounded-xl" :color="$store.state.dark?'app-card':'app-card-light'">
                <v-card-title style="margin-right: 60px;" class=" py-0 app-bold" >
                    <img :class="colorSvg(item.status)" :src="iconResponse(item.status)" />
                    <h4 :class="getTextColor(item.status)"  class="app-bold mr-2 ">{{ formatToPrice(item.total)  }}</h4>
                    <v-spacer></v-spacer>
                    <span style="font-size: 15px" v-text="item.method" ></span>
                    <v-card style="position: absolute; right: 0;top: 0;z-index: 1;"  class="mr-n1 mt-n3" rounded="lg" height="60" width="60" >
                  <v-img

                    height="100%"  width="100%"  :src="item.name.includes('adsl')?'assets/idoom/idooom.png':'assets/idoom/ltee.png'" ></v-img>
                </v-card>
            </v-card-title>
            <v-card-title b  style="margin-right: 60px;" class="pt-0">
                <span   style="direction: ltr;font-size: 16px" class="app-regular mr-4"   > {{item.num_recharge}}</span>
                    <v-spacer></v-spacer>
                <span class="success--text vueltr" >{{ formatToPrice(item.amount) + " x " + item.count }}</span>
            </v-card-title>
                <v-card-subtitle  >
                    <p  class="text-center app-regular " :class="$store.state.dark?'grey--text':''">{{ item.created_at | datee }}</p>
                </v-card-subtitle>
           </v-card>
            </v-col>
           </v-row>
            <infinite-loading  @infinite="infiniteHandler"  >
                <div :style="{marginTop:!historiqueData.length?'25vh':''}"  class="mb-5 app-medium"  slot="spinner"  >جارٍ البحث</div>
                <div     slot="no-more" class="app-medium"   >لا يوجد المزيد</div>
                <div    class="mb-5 app-medium"  slot="no-results" >

                    <v-card style="margin-top: 25vh;"  class="center" :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''">

                        <v-card-title class="pb-1"  >
                            <v-spacer></v-spacer>
                            <img style="width: 90px" src="assets/notifications/search.png">
                            <v-spacer></v-spacer>

                        </v-card-title>

                        <h5 class="app-bold text-center" v-text="user!=null?'لا يوجد أي مشتريات':'قم بتسجيل الدخول'"> </h5>
                      <vs-button  color="rgb(254, 44, 85)"  class="mx-auto mt-3 rounded-lg  "     @click="idoomDialog =true">
                        إشحن الان
                        <img width="17px" src="assets/icons/vuesax/email.svg" class="white-svg mx-2" >
                      </vs-button>
                    </v-card>

                </div>

            </infinite-loading>
        </div>

        <v-fab-transition>
            <v-btn   @click="shoWindowHidSidbar()" :dark="!$store.state.dark"
                      elevation="0" fixed bottom right fab
                     style="bottom: 69px; position: fixed; right: 12px;"  >
                <img width="50px"   src="assets/img/idoom-btn.png"/>
            </v-btn>
        </v-fab-transition>
        <v-bottom-sheet persistent     v-model="idoomDialog"   fullscreen :dark="$store.state.dark" style="height: 100vh">
            <v-card  :color="$store.state.dark?'app-bg':''" flat  class="rounded-t-xl">
            <v-card-title class=pb-4>
                <v-btn icon @click="idoomDialog = false">
                    <img :class="$store.state.dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/close-1.svg"/>
                </v-btn>
                <v-spacer></v-spacer>
                <span class="app-regular"> شحن إيدوم</span>

            </v-card-title>
                <v-card-subtitle class="vuertl app-regular pb-2">يمكنك شحن بطاقات يدوم بضغطة زر واحدة كذلك يمكنك معرفة ديون الهاتف و كل معلومات الزبون</v-card-subtitle>
                 <v-container fill-height class="px-0">
                     <v-row align="center"
                            justify="center">
                         <v-col>
                             <idoom-view style="margin-top: 10vh"></idoom-view>
                         </v-col>
                     </v-row>
                 </v-container>
            </v-card>
        </v-bottom-sheet>
    </v-card>
</template>

<script>
import axios from 'axios';
import {formatToPrice, iconResponse, colorSvg, getTextColor} from '@/tools/Services';
import TitlePages from "@/layout/TitlePages";
import IdoomView from "@/views/IdoomView";
    export default {
        components:{IdoomView, TitlePages},
        data(){
            return{
                idoomDialog:false,
                show: false,
                current_page:1,
                historiqueData:[],
            }
        },
        computed:{
          user:function () {
            return this.$store.state.auth.user
          }
        },
        methods:{
          getTextColor,
            shoWindowHidSidbar(){
                this.idoomDialog = true
                this.$store.commit('SET_ICARDACTIVE',true)
            },
         async   HandelProduct(item){
              await  this.$store.commit('SET_PRODUCT',item)
              this.$router.push({name:"IdoomProduct",params:{id:item.id}}).catch(()=>{})

            },
            formatToPrice,iconResponse,colorSvg,
            getHistoriqueData($state){
              if(this.user!=null && this.user.type !="new"){
                axios.get('api/idoom-request', {page: this.current_page}
          ).then((response)=>{
                    if(response.data.data.length >0){
                        this.current_page +=1    
                        this.historiqueData.push(...response.data.data);
                        
                    } else{
                        $state.complete();
                    }
                    this.show = true
                }).catch(()=>{
                    $state.complete();
                })
              }
            },
            infiniteHandler($state){
                if(this.user!=null && this.user.type !="new"){
                    axios.get('api/idoom-request?page='+this.current_page
                    ).then((response)=>{

                        if(response.data.data.length >0){
                            this.current_page +=1

                            this.historiqueData.push(...response.data.data)
                            $state.loaded()
                        }else{
                            $state.complete();
                        }
                        this.show = true
                    }) .catch(()=>{
                        $state.complete();
                    })
                }else {  }

            }
        },

    }
</script>

<style lang="scss" scoped>
.v-ripple-container{
    z-index: 10!important;
}
.v-card--link:before {
    background: none!important;
}
</style>