<template>
  <v-snackbar  tile
    :text="sna.textShow"
      max-width="95%"
    bottom rounded
    :timeout="sna.timout"


    :color="sna.colorr"
    class="vuertl app-regular"
    style=" z-index: 3000!important;margin-bottom: 62px"
    v-model="sna.show"
  >

    <img style="width: 20px"  v-if="sna.wifi" class="ml-3 white-svg"    src="assets/icons/vuesax/wifi.svg">
    {{ sna.text }}
    <template
      v-slot:action="{ attrs }"
      class="vuertl"
    >
      <v-btn :color="sna.isSuccess?'success':'error'" text v-bind="attrs"  v-if="!sna.wifi">

        <img class="white-svg" v-if="sna.isSuccess" src="assets/icons/vuesax/like.svg">
        <img class="white-svg" v-if="sna.fail" src="assets/icons/vuesax/alert.svg">

      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "SnabB",
  computed: {
    ...mapState(["sna"]),
  },
};
</script>
