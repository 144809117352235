<template>
  <div>
    <div v-if="$store.state.auth.user.type == 'admin'" class="pa-2"     >
      <v-row    >

        <v-col class="col-12  mb-2 vuertl" >
          <v-card   width="100%" img="assets/img/footer-bg.png" class="rounded-xl  py-4" elevation="3">

            <v-card-actions>
              <v-row dense no-gutters>
                <v-col class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0">
                  <v-list-item class="overflow-hidden" >
                    <v-list-item-icon class="mx-2">
                      <vs-avatar size="40" color="primary "  >
                        <img src="assets/notifications/credit.png" />
                      </vs-avatar>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <p class="grey--text text--lighten-2 font-weight-bold app-bold">مجموع مدفوعات اليوم</p>
                      <v-list-item-subtitle class="white--text font-weight-bold"  v-text="formatToPrice('0')"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
                <v-col class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0">
                  <v-list-item>
                    <v-list-item-avatar class="mx-2">
                      <vs-avatar size="40" color="primary "  >
                        <img src="assets/notifications/pay.png" />
                      </vs-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <p class="grey--text text--lighten-2 font-weight-bold app-bold">مجموع الديون</p>
                      <v-list-item-subtitle class="white--text font-weight-bold" v-if="mouvment.total?mouvment.total:'0'" v-text="formatToPrice(mouvment.total?mouvment.total:'0')"></v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>


                <v-col class="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 pt-0">
                  <v-list-item>
                    <v-list-item-avatar class="mx-2">
                      <vs-avatar size="40" color="primary "  >
                        <img src="assets/notifications/historique-bonus.png" />
                      </vs-avatar>
                    </v-list-item-avatar>
                    <v-list-item-content>
                      <p class="grey--text text--lighten-2 font-weight-bold app-bold">مجموع الارباح</p>
                      <v-list-item-subtitle class="font-weight-bold white--text"  v-text="mouvment.bonus?mouvment.bonus:'/'" > </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>

            </v-card-actions>
          </v-card>
        </v-col>
        <v-col class="col-12">
          <v-card flat elevation="0" color="transparent" :dark="dark" >
            <v-tabs v-model="tab" background-color="transparent" :dark="dark"
                    color="orange accent-2"
                    align-with-title
            >
              <v-spacer></v-spacer>
              <v-tab :class="$store.state.dark?'app-card':'app-card-light'"  class="app-bold rounded-lg ">جميع التحويلات</v-tab>
              <v-tab  :class="$store.state.dark?'app-card':'app-card-light'" class="app-bold rounded-lg mx-1">تحويلات  اليوم</v-tab>
              <v-tabs-slider style="max-width: 90%;margin: auto"></v-tabs-slider>
              <v-tab-item       :class="dark?'app-bg':''"

              >
                <v-container>

                  <v-row class="justify-center" v-if="mouvment.length">

                    <v-col   class="pa-0 col-12 mt-2" v-for="(item,index) in mouvment" :key="index">

                      <v-card :dark="$store.state.dark"    link  tile  @click="handeMouvmentToday(item)"  class="rounded-lg overflow-hidden elevation-0 my-1 mx-1 " >

                        <v-list class="py-0">
                          <v-list-item     :class="dark?'app-card':'app-card-light '"     >
                            <v-list-item-content class="py-0">

                              <v-list-item-title class="mb-1"  >
                                <span  class="primary--text text--darken-1 item-config" >{{item.username}}</span>
                                <v-list-item-subtitle class="app-bold"  style="text-align: center" > الزبون</v-list-item-subtitle>
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title class="mt-n2 " >
                                <span style="color:#4CAF50;font-size: 16px" class="mt-2  item-config"><b>{{item.oldSolde}}</b><sub class="mx-2">DA</sub></span>
                              </v-list-item-title>
                              <v-list-item-subtitle class="app-bold "  style="text-align: center"  >ديون قديمة</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-content>
                              <v-list-item-title :class="getTextColor(item.status)" class="mt-1"  >
                                <span style="color: #ED1C24  "  class="item-config"><span class="mx-1" v-text="item.operation"></span> {{formatToPrice(item.solde)}} <sub class="mx-2">DA</sub></span>

                                <v-list-item-subtitle >
                                  <span class="app-bold item-config"  style="text-align: center;line-height: normal" >{{item.updated_at | fromNow}}</span>
                                </v-list-item-subtitle>
                              </v-list-item-title>

                            </v-list-item-content>

                            <v-list-item-content class="py-">
                              <v-list-item-title >
                                <span style="color:#4CAF50;font-size: 20px" class=" text--h5"><b>{{item.total}}</b><sub class="mx-2">DA</sub></span>
                              </v-list-item-title>
                              <v-list-item-subtitle class="app-bold"  >مجموع الديون</v-list-item-subtitle>


                            </v-list-item-content>

                          </v-list-item>
                        </v-list>
                      </v-card>









                    </v-col>

                  </v-row>
                  <v-row  v-else  align="center" class="justify-center mt-5 ">
                    <v-col class="col-5" >

                      <div   >
                        <h4 style="text-align: center" class="mb-2 app-bold">لا يوجد تحويلات</h4>
                        <div  class="mt-8" style="    display: grid;
                                                    justify-content: center;">
                          <img height="75px" width="75px" src="assets/notifications/credit.png"/>
                        </div>

                      </div>
                    </v-col>
                  </v-row>
                </v-container>
              </v-tab-item>
              <v-tab-item  :class="dark?'app-bg':''"
              >
                <v-row dense v-if="mouvmentToday.hasOwnProperty('today')">

                  <v-col class="col-12 pt-2" v-for="(item,index) in mouvmentToday" :key="index">
                    <v-card    :dark="$store.state.dark"  tile   class="rounded-lg overflow-hidden elevation-0 my-1 mx-1 " >

                      <v-list class="py-0">
                        <v-list-item     :class="dark?'app-card':'app-card-light '"     >
                          <v-list-item-content class="py-0">

                            <v-list-item-title class="mb-1"  >
                              <span style="font-size: 16px;font-weight: bold;font-family: sans-serif" class="primary--text text--darken-1">{{item.username}}</span>

                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title >
                              <span style="color:#4CAF50;font-size: 20px" class="mt-2 text--h5"><b>{{item.oldSolde}}</b><sub class="mx-2">DA</sub></span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="app-bold"  >حساب قديم</v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content>
                            <v-list-item-title :class="getTextColor(item.status)" class="mt-1"  >
                              <span style="font-weight: bold;font-size: 17px;font-family: sans-serif;color: #ED1C24  "><span class="mx-1" v-text="item.operation"></span> {{formatToPrice(item.solde)}} <sub class="mx-2">DA</sub></span>

                              <v-list-item-subtitle >
                                <span class="app-bold">{{item.updated_at | fromNow}}</span>
                              </v-list-item-subtitle>
                            </v-list-item-title>

                          </v-list-item-content>

                          <v-list-item-content class="py-">
                            <v-list-item-title >
                              <span style="color:#4CAF50;font-size: 20px" class=" text--h5"><b>{{item.total}}</b><sub class="mx-2">DA</sub></span>
                            </v-list-item-title>
                            <v-list-item-subtitle class="app-bold"  >بعد الشحن</v-list-item-subtitle>


                          </v-list-item-content>

                        </v-list-item>
                      </v-list>
                    </v-card>
                  </v-col>

                </v-row>
                <v-row v-else  align="center" class="justify-center mt-5 ">
                  <v-col class="col-5" >

                    <div   >
                      <h4 style="text-align: center" class="mb-2 app-bold">لا يوجد ديون</h4>
                      <div  class="mt-8" style="    display: grid;
                                                    justify-content: center;">
                        <img height="75px" width="75px" src="assets/notifications/credit.png"/>
                      </div>

                    </div>
                  </v-col>
                </v-row>
              </v-tab-item>

            </v-tabs>
          </v-card>
        </v-col>
      </v-row>

     </div >
    <NotFound v-else/>
  </div>
</template>

<script>
import { formatToPrice, iconR,getTextColor,iconResponse,getColorr,scrollToElement} from "@/tools/Services";
import strMixins from '@/plugins/Mixins'
import NotFound from "@/components/comp-tool/NotFound.vue";
import axios from 'axios'
export default {
  mixins:[strMixins],
  components:{NotFound},
  data () {
    return {
      tab:"tab-1",
      date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      bonus:false,
      active:false,
      lastPage: null,
      curentPage: 1,
      singleSelect: false,
      selected: [],

      mouvment: [],
      mouvmentToday: [],

    }
  },
  methods:{

    handeMouvmentToday(user){
      axios.get('api/mouvement-user-today') .then((response)=>{
        this.mouvmentToday = response.data.today
        this.bonus = false
        this.active = true
      })
    },


    iconR,getTextColor,iconResponse,getColorr,scrollToElement,formatToPrice,
    paginatePage(pageNumber) {
      axios.get("api/mouvment-total?page=" + pageNumber).then((response) => {
        this.mouvment = response.data

        // this.lastPage = response.data.last_page;
        // this.curentPage = response.data.current_page;
      });
    },
    getcredits(){
      axios.get('api/mouvment-total?page=0').then((response)=>{
        this.mouvment = response.data.total.data

        // this.lastPage = response.data.last_page;
        // this.curentPage = response.data.current_page;
      })
    },
  },
  watch: {
    curentPage(newVal, oldVal) {
      this.paginatePage(newVal)
    },
  },
  created() {
    this.handeMouvmentToday()
    this.getcredits();
  }
}
</script>
<style>
 .item-config{
   line-height: normal;
   font-size: 16px;
   font-weight: bold;
   font-family: sans-serif;
   text-align: center;
   display: block
 }
</style>
