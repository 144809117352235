<template>
    <div   >
        <div v-if="$store.state.auth.user.type == 'admin'" class="cont pa-3"  style="direction: ltr" >
            <v-card  :dark="$store.state.dark" class="  elevation-0 rounded-xl" v-if="userInf.today">

                    <v-row class="pa-0" >
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card   class="elevation-0 rounded-xl overflow-hidden"
                                      :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="warning"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center;position:relative">

                                    <v-btn   class="v-btn--has-bg" icon color="warning" style="position: absolute;left: 13px;top: 13px;">
                                        <img class="warning-svg" src="assets/icons/vuesax/calendar.svg" />
                                    </v-btn>
                                    <span> @Balance</span>

                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                         <span class="grand-number"   >
                                                             <animated-number
                                                                     :value="userInf.today.solde"
                                                                     :formatValue="formatToPrice"

                                                                     :duration="1000"
                                                             />
                                                         </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" > الحساب</span>
                                    <p class="app-regular">
                                         الفوائد
                                        <span>{{userInf.today.bonus}}</span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>


                        </v-col>
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="primary"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center">
                                    <span> @Flexy</span>
                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"  >
                                                       <animated-number
                                                               :value="userInf.today.flexy_total"
                                                               :formatValue="formatToPrice"

                                                               :duration="1000"
                                                       />
                                                 </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" >مجموع الفليكسي</span>
                                    <p class="app-regular">
                                        <span v-text="dates==''?'اليوم':dates"></span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="purple"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center">

                                    <span> @Cartes</span>
                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"  >
                                                       <animated-number
                                                               :value="userInf.today.card_total"
                                                               :formatValue="formatToPrice"

                                                               :duration="1000"
                                                       />
                                                 </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" >مجموع إيدوم</span>
                                    <p class="app-regular">
                                        <span v-text="dates==''?'اليوم':dates"></span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="success"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center">

                                    <span> @Idoom</span>
                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"   >
                                                         <animated-number
                                                                 :value="userInf.today.idoom_total"
                                                                 :formatValue="formatToPrice"

                                                                 :duration="1000"
                                                         />
                                                 </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" >مجموع إيدوم</span>
                                    <p class="app-regular">
                                        <span v-text="dates==''?'اليوم':dates"></span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>

            </v-card>

            <v-card :dark="$store.getters.dark" shaped flat  color="transparent" class="px-0 mt-0">


                <v-row>
                    <v-col class="col-12" >
                        <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
                            <v-col class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xs-4 col-12 mt-2  " v-for="(user , index) in users"  :key="index">

                                <v-card dark   flat class="rounded-xl" style="cursor: pointer;"    :class="user.type== 'admin' ?'purple':$store.state.dark?'app-card-lighten rounded-lg card-shadow':' card-shadow app-card-light'"   >

                                    <v-container class="app-regular pt-2">
                                         <v-app-bar dense class="pa-1 rounded-lg elevation-0 mb-2" color="transparent" :dark="dark">
                                             <v-switch class="mt-3" color="warning" active :dark="dark" dense @click.stop="CloseRefferal(user)"
                                                       v-model="user.refferal"
                                                       inset

                                             >
                                                 الاحالة مفعلة
                                             </v-switch>

                                             <v-spacer></v-spacer>
                                             <span :class="user.type== 'admin'?'white--text':dark?'white--text':'black--text'"  style="font-size:15px" class="mt-n3"><b>{{user.username}}</b></span>

                                           <v-spacer></v-spacer>
                                             <vs-avatar>
                                                 <img style="width:25px"  src="assets/img/user.png">
                                             </vs-avatar>

                                          </v-app-bar>
                                        <v-card-title   class="pa-0">

                                             <v-row>

                                                 <v-col class="col-6 pl-0">
                                                     <vs-button size="small"  color="rgb(254, 44, 85)"    block class="white--text ma-2 mt-0 " @click.stop="showmodelupstatus(user)">

                                                         <img style="width:25px" class="white-svg mr-3 " src="assets/icons/vuesax/admin.svg">
                                                         <h4>معلومات</h4>
                                                     </vs-button>
                                                 </v-col>

                                                 <v-col class="col-6  pl-0" :class="$vuetify.breakpoint.smAndDpwn?'pt-0':''">
                                                     <vs-button size="small" color="warn"  gradient  block class="black--text ma-2 mt-0 "  @click.stop="showAddSolde(user)">

                                                         <img style="width:25px" class="black-svg mr-3 " src="assets/icons/vuesax/moneyo.svg">
                                                         <h4>محاسبة</h4>
                                                     </vs-button>
                                                 </v-col>
                                             </v-row>





                                        </v-card-title>
                                        <v-card-title   class="pa-0" style="font-size: 16px">
                                                {{'Balance' + ' ' +user.solde}}
                                            <v-spacer></v-spacer>
                                            <span  > {{'Versement' + ' ' +user.addsolde}}</span>
                                        </v-card-title>
                                    </v-container>

                                </v-card>
                            </v-col>



                        </v-row>
                    </v-col>

                </v-row>

            </v-card>
            <v-dialog   :fullscreen="$vuetify.breakpoint.mdAndDown"
                        width="700px" transition="fade-transition"
                        max-width="800"

                        v-model="showSolde"
            >
                <v-card class="vuertl"

                            :dark="$store.state.dark" max-width="850">
                    <v-card-title class="headline">

                        <v-btn     @click="showSolde = !showSolde" large   style="float: left!important;" icon depressed><img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close-1.svg" /></v-btn>

                    </v-card-title>
                    <v-card-text class="pb-0" >
                        <v-row>
                            <v-col class="col-6">
                                <h6  class="text-center app-regular mb-2"  style="font-size: 17px;" >رصيد الزبون</h6>

                                <v-text-field :value="solding.solde" disabled readonly
                                              type="number" filled hide-details solo flat
                                              class="app-regular rounded-lg input-class overflow-hidden"
                                              :class="dark?'black--text':'white--text'"
                                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                              placeholder="الحساب">  </v-text-field>
                            </v-col>
                            <v-col class="col-6">
                                <h6  class="text-center app-regular mb-2"  style="font-size: 17px;" >مبلغ الايداع</h6>

                                <v-text-field v-model="solding.tosolde" :disabled="solding.symbole==''"
                                              type="number" filled hide-details solo flat
                                              class="app-regular rounded-lg input-class overflow-hidden"
                                              :class="dark?'black--text':'white--text'"
                                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                              placeholder="إضافة الى الرصيد">



                                </v-text-field>

                            </v-col>
                            <v-col class="col-12">
                                <v-row class="justify-center">
                                    <v-col class="col-12  ">
                                        <v-btn large class="mr-0 rounded-xl" :color="solding.symbole == '+'?'#03A9F4':''"     block @click="solding.symbole = '+'">
                                            <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/plus.svg">
                                        </v-btn>
                                    </v-col>

                                </v-row>




                            </v-col>
                        </v-row>

                    </v-card-text>

                    <v-card-text  >
                        <v-row>
                         <v-col class="col-12 pa-0">
                           <h6  class="text-center app-regular mb-2"  style="font-size: 17px;" >نوعية الايداع</h6>
                         </v-col>

                          <v-col class="col-12">
                                <v-card-actions class="justify-center py-0">
                                    <vs-radio   v-model="solding.reason" val="Un bonus">
                                        Bonus
                                    </vs-radio>
                                    <vs-radio   v-model="solding.reason" val="Récupération">
                                        Récupération
                                    </vs-radio>
                                    <vs-radio   v-model="solding.reason" val="Un credit">
                                        Credit
                                    </vs-radio>
                                    <vs-radio v-model="solding.reason" val="Rechargement">
                                        Versement
                                    </vs-radio>
                                </v-card-actions>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-text class="py-0">
                        <v-row>
                            <v-col class="col-12">
                                <v-row class="">
                                    <v-col class="col-6">
                                        <v-btn  large class="mr-0 rounded-xl"   :color="solding.cymboleCredit == '+'?'#03A9F4':''"     block @click="solding.cymboleCredit = '+'">
                                          <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/plus.svg">

                                        </v-btn>
                                    </v-col>
                                    <v-col class="col-6">
                                        <v-btn   large class="mr-0 rounded-xl" :color="solding.cymboleCredit == '-'?'#03A9F4':''"     block @click="solding.cymboleCredit = '-'">
                                          <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/minus.svg">

                                        </v-btn>
                                    </v-col>
                                </v-row>





                            </v-col>
                        </v-row>

                        <v-row >

                            <v-col class="col-lg-6 col-sm-12 col-xs-12 col-12 pt-0">
                                <h6 class="text-center app-regular mb-2" style="font-size: 17px" >ديون قديمة</h6>
                                <v-text-field v-model="credit.total" disabled
                                              filled hide-details solo flat
                                              class="app-regular rounded-lg input-class overflow-hidden"
                                              :class="dark?'black--text':'white--text'"
                                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                              placeholder="إضافة الى الرصيد">
                                </v-text-field>
                            </v-col>
                            <v-col class="col-lg-6 col-sm-12 col-xs-12 col-12 pt-0">
                                <h6 class="text-center app-regular mb-2" style="font-size: 17px" >إضافة الملغ الى الديون</h6>
                                <v-text-field v-model="solding.addToCredit" :disabled="solding.cymboleCredit==''"
                                              type="number" filled hide-details solo flat name="addToCredit"
                                              class="app-regular rounded-lg input-class overflow-hidden"
                                              :class="dark?'black--text':'white--text'"
                                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                              placeholder="إضافة الى الرصيد">
                                </v-text-field>
                                <has-error :form="solding" field="addToCredit"></has-error>
                            </v-col>
                            <v-col class="col-12 " v-if="solding.cymboleCredit !=''">
                                <vs-input placeholder="Description" success v-model="solding.message" block></vs-input>
                            </v-col>

                          <v-col class="col-12" v-if="credit != 0" >
                            <v-card outlined    :dark="$store.state.dark" tile  style="border-radius: 15px!important;" class="card-sharow my-1 pb-3 " >
                              <v-list class="py-0">
                                <v-list-item    dense     >

                                  <v-list-item-content>
                                    <v-list-item-title  style="text-align: center;display: block">
                                      <span style="color:#4CAF50;font-size: 20px" class="mt-2 text--h5  " ><b>{{credit.oldCredit}}</b><sub class="mx-2">DA</sub></span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-center app-regular">دين قديم</v-list-item-subtitle>
                                  </v-list-item-content>
                                  <v-list-item-content>
                                    <v-list-item-title  style="text-align: center;display: block" :class="getTextColor(credit.status)"  >
                                      <span style="font-weight: bold;font-size: 17px;font-family: sans-serif;color: #ED1C24  "><span class="mx-1" v-text="credit.operation"></span> {{credit.credit}} <sub class="mx-2">DA</sub></span>

                                      <v-list-item-subtitle class="app-regular">
                                        {{credit.updated_at | fromNow}}
                                      </v-list-item-subtitle>
                                    </v-list-item-title>

                                  </v-list-item-content>

                                  <v-list-item-content class="py-0">
                                    <v-list-item-title  style="text-align: center;display: block">
                                      <span style="color:#4CAF50;font-size: 20px" class=" text--h5"><b>{{credit.total}}</b><sub class="mx-2">DA</sub></span>
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-center app-regular">مجموع الديون</v-list-item-subtitle>


                                  </v-list-item-content>

                                </v-list-item>
                              </v-list>
                            </v-card>

                          </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions >
                        <v-spacer></v-spacer>
                        <v-btn large block   :loading="solding.busy" :disabled="solding.symbole=='' && solding.symboleCredit==''" text color="#4CAF50"   class="app-regular mx-2 fonta rounded-xl mr-0 v-btn--active" @click="SoldeSymbole">
                            إرسال
                            <v-icon>mdi-telegram</v-icon>
                        </v-btn>

                    </v-card-actions>
                </v-card>



            </v-dialog>
            <v-dialog  transition="fade-transition" :fullscreen="$vuetify.breakpoint.mdAndDown"
                      v-model="activeuserModal"

                      max-width="900"
                      hide-overlay

            >
                <v-card :dark="$store.state.dark" max-width="900">
                    <v-card-title>
                        {{form.username}}
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                          <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                              <v-text-field v-model="form.username" type="text" filled   solo flat
                                            class="app-regular rounded-lg input-class overflow-hidden"
                                            :class="dark?'black--text':'white--text'"
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block readonly
                                            hint="إسم المستخدم" persistent-hint
                                            placeholder="إسم المستخدم">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/user.svg" >


                              </v-text-field>
                          </v-col>


                          <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-12">
                              <v-text-field v-model="form.phone" type="number"  solo flat filled hint="رقم الهاتف" persistent-hint readonly
                                            class="app-regular rounded-lg input-class overflow-hidden"
                                            :class="dark?'black--text':'white--text'"
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                            placeholder="رقم الهاتف">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/mobile.svg" >


                              </v-text-field>

                          </v-col>


                          <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12 col-12">
                              <v-text-field v-model="form.solde" type="number" filled   solo flat readonly
                                            class="app-regular rounded-lg input-class overflow-hidden"
                                            :class="dark?'black--text':'white--text'"
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                            hint="رصيد" persistent-hint
                                            placeholder="رصيد">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/moneyo.svg" >


                              </v-text-field>

                          </v-col>
                          <v-col class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                              <v-text-field v-model="form.categorie" type="text" filled   solo flat
                                            class="app-regular rounded-lg input-class overflow-hidden"
                                            :class="dark?'black--text':'white--text'"
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block readonly
                                            hint="نوع العرض" persistent-hint
                                            placeholder="نوع العرض">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/user.svg" >


                              </v-text-field>
                          </v-col>
                          <v-col class="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12 col-12">
                              <v-text-field v-model="form.refferal" type="text" filled   solo flat
                                            class="app-regular rounded-lg input-class overflow-hidden"
                                            :class="dark?'black--text':'white--text'"
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block readonly
                                            hint="إسم الموزع" persistent-hint
                                            placeholder="إسم الموزع">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/user.svg" >


                              </v-text-field>
                          </v-col>
                      </v-row>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn   class="primary  text-capitalize app-regular" :dark="$store.state.dark"   type="button" @click="activeuserModal = !activeuserModal">
                            إغلاق
                            <v-icon>mdi-close</v-icon>
                        </v-btn>

                    </v-card-actions>



                </v-card>

            </v-dialog>






        </div>
      <NotFound v-else/>
    </div>
</template>

<script>

    import Form from 'vform'
    import NotFound from "@/components/comp-tool/NotFound.vue";
    import {mapState} from "vuex";
    import {getColorr, iconChange, iconR , userColor,statusTypeOfUser,setBaseUrl,formatToPrice} from "@/tools/Services.js";
    import {VueEditor} from "vue2-editor";
    import AnimatedNumber from "animated-number-vue";
    import {getProfile} from "@/plugins/authUser";
    import axios from 'axios'
    import {bus} from "@/plugins/bus";
    export default {

        components: {
            VueEditor,AnimatedNumber , NotFound
        },
        computed:{
          dark:function (){
            return this.$store.state.dark
          },
            ...mapState([
                'loading'
            ]),
            getNow: function() {
                const date = new Date()
                return date.toISOString().split('T')[0]
            },
        },
        data(){
            return {

                typeOfUser:'',

                dates:'',
                menu:false,
                date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

                image:'',
                dialogImage:false,
                lstVrsmnt:null,
                updateStatus:'',
                collectionIcon:['mdi-update','mdi-checkbox-marked-circle-outline','mdi-alert-circle-outline','mdi-do-not-disturb','mdi-spin\n','mdi-camera-outline','mdi-facebook','mdi-facebook-messenger','mdi-email-edit-outline','mdi-comment-processing-outline','mdi-close-circle-outline','mdi-clock-alert-outline','mdi-shield-star-outline','mdi-cart-plus'],
                collectionColor:['#9E9E9E','#000000','#FFF','#795548','#607D8B','#FFC107','#FF9800','#4CAF50','#CDDC39','#00BCD4','#009688','#2196F3','#3F51B5','#673AB7','#F44336'],
                switchMessagEmail:false,
                credit:0,
                sendTousers:false,
                Online:{},

                addsolde:false,
                head: [
                    {
                        text: 'Numéro de téléphone',
                        align: "start",
                        sortable: false,
                        value: "PhoneN",
                    },
                    { text:  "Montant", value: "price", filterable: true },

                    { text:  'Payment', value: "payment" },
                    { text:  'Username', filterable: true, value: "username" },
                    { text:  "Status", filterable: true, value: "statusstatus" },
                    { text:  'Sms', filterable: true, value: "response" },
                    { text:  'Date', value: "date" },
                ],
                activeuserModal:false,
                UserProfile:'',
                isRight:false,
                sum:0,
                imageMode: 0,
                editMode:false,

                messageUser: new Form({
                    position:'bottom-center',
                    credit:false,
                    id:'',
                    message:'',
                    title:'',
                    username:'',
                    icon:'',
                    color:'',
                    background:'',
                    all:false
                }),
                emailUser: new Form({
                    id:'',
                    message:'',
                    title:'',
                    username:'',
                    all:false
                }),
                solding: new Form({
                    reason:'Rechargement',
                    id:'',
                    solde:'',
                    tosolde:0,
                    addToCredit:0,
                    symbole:'',
                    cymboleCredit:'',
                }),
                form: new Form({
                    id:'',
                    categorie:'',
                    refferal:'',
                    username:'',
                    solde:'',
                    phone:'',
                    type:'',
                    addsolde:'',



                }),
                users:[],
                userTypes:{},
                maxlengthPhone: 6,
                countusrs:'',
                allcardscount:{},
                userid:'',
                search: '',
                colorChip:'#E0E0E0',

                language:'',
                pages:1,
                curentPage:1,
                type:window.user,
                userInf:[],

                showSolde:false,


                getIfVersement:null
            }
        },

        beforeMount() {
            this.loaduser()

        },
        mounted() {
            this.getadminAchat(this.$route.params.id,this.date)
        },
        methods: {
          getProfile,
          userColor,setBaseUrl,formatToPrice,

            getTypeUser(){
            axios.post('api/admin-get-type-user',{type:this.typeOfUser}).then((response)=>{

            })
            },
           getadminAchat(date){
                       axios.post('api/admin-achat-date',{date:date}).then((response)=>{
                    this.userInf = response.data
                })



            },
            editModel(user){
                this.$router.push({name:"ProfileHistorique",params:{id:user.id}}).catch(()=>{})

            },

            CloseRefferal(user){
               axios.put("api/refferal-close/"+user.id).then((response)=>{
                   this.loaduser()
               })
            },
            statusTypeOfUser,

            iconR,
            async  SoldeSymbole(){

                this.solding.put('api/solde-symbole-user/'+this.solding.id).then( (response)=>{
                        this.lstVrsmnt = response.data.user.addsolde
                        this.solding.tosolde = 0
                        this.solding.bonus = 0
                        if(this.solding.addToCredit != 0 ){
                            this.showAddSolde(response.data.user)
                        }
                        getProfile()
                        this.solding.id = response.data.user.id
                        this.solding.solde = response.data.user.solde
                        this.lstVrsmnt =    response.data.user.addsolde
                        this.solding.cymboleCredit = ""
                        bus.$emit('getProfile')
                    this.$vs.notification({
                        classNotification:'vuertl',
                        title:  'تمت العملية بنجاح',
                        text: response.data.message || "تم تحويل المبلغ بنحاج",
                        duration:'none',
                        icon:`<img class="white-svg" src="assets/icons/status/success-response.svg" />`,
                        color: 'success',
                        position:'top-center',
                        buttonClose:true,
                    })

                    }
                ) .catch(
                    (e)=>{
                        this.$vs.notification({
                            classNotification:'vuertl',
                            title:  'حدث خطأ',
                            text: e.response.data.message || " حدث خطأ يرجى إعادة المحاولة",
                            duration:'none',
                            icon:`<img class="white-svg" src="assets/icons/status/annule.svg" />`,
                            color: 'danger',
                            position:'top-center',
                            buttonClose:true,
                        })
                    }
                );
            },
            showAddSolde(user){
                this.solding.reset()
                this.credit = 0
                axios.get('api/credit-user-credit/'+user.username).then((response)=>{
                    if(response.data.credit != false ){
                        this.credit = response.data.credit
                    }else {
                        this.credit = 0
                    }

                    this.solding.id = response.data.user.id
                    this.solding.addsolde = response.data.user.addsolde
                    this.solding.solde = response.data.user.solde



                })


                if( this.showSolde != true){
                    this.showSolde = !this.showSolde
                }

            },

            getColorr,
            iconChange,

            setName(name){

                if (name == this.$store.getters.user.id){
                    return this.user.username;
                }else {
                    return 'admin';
                }
            },
            setUserType(user){
                if (user.type === 'new' && user.willaya === '') return 'N'
                else if (user.type === 'new' && user.willaya !== '') return 'M'
                else if (user.type === 'user') return 'U'
                else if (user.type === 'admin') return 'A'
            },
            setType(user){
                if (user.type === 'new' && user.willaya === '') return '#ED1C24'
                else if (user.type === 'new' && user.willaya !== '') return '#2196F3'
                else if (user.type === 'user') return '#4CAF50'
                else if (user.type === 'admin') return '#8c65f8'
            },
            getColor(res) {
                if (res === 'admin') return ' #FF1976'
                else if (res === 'user') return '#4CAF50'
                else   return '#FFC107'

            },
            getStatus(stat){
                if (stat === "En attente"){
                    return  this.enattante
                }else if (stat === "Confirmé"){
                    return  this.Confirme
                }else if (stat === "Annulé"){
                    return  this.Annule
                }

            },
            getTextColor(res) {
                if (res === "En attente") return 'text-warning'
                else if (res === "Confirmé") return 'text-web-success'
                else if (res === "Annulé") return 'text-web-error'
            },


            showmodelupstatus(user){
                this.form.sendToEmail = false
                    this.imageMode = 1
                this.form.reset()
                this.form.fill(user)
                this.form.categorie = user.categorie
                this.form.categorie = user.categorie
                this.form.refferal = user.refferal
                this.activeuserModal = !this.activeuserModal
            },

            addSolde(user){

                this.form.reset();
                $('#addsoldeModal').modal('show');
                this.form.fill(user);
            },
            newModel(){
                this.editMode = false;
                this.form.reset();
                $('#exampleModal').modal('show');
            },

            loaduser(){

                axios.get('api/User?page=0').then(data=> {
                    this.users = data.data
                } );

            },

        },

        watch: {


        },
    }
</script>
<style scoped>
    .con-image{
        border-radius: inherit
    }
    .image-st{
        display :block;
        position :relative;
        border-radius :inherit;
        max-width :850px
    }
    .grand-number{
        font-size: 2.05rem;
        font-weight: 600;
        font-family: app-regular;
    }
    .text-info{
        font-weight: 100;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.4375rem;
    }
</style>