<template>
   <div>
     <v-card v-if="$store.state.auth.user.type == 'admin'"   flat :color="dark?'app-bg':''" class="app-mb-10 vuertl">

       <div class="pa-2" v-if="current_page >0">
         <TitlePages title="إيداعات الزبائن">
           <v-btn slot="btn"  to="/home"  large   style="float: left!important;" icon depressed><img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>

         </TitlePages>

         <v-row :no-gutters="$vuetify.breakpoint.smAndDown" >
           <v-col :class="$vuetify.breakpoint.smAndDown?'mb-3':'mb-n1'"  class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4"  v-for="(item,index) in historiqueData" :key="index" >


             <v-card @click.stop="showToolip(item)"    flat  ripple     style="height: 90px;cursor: pointer" class=" rounded-xl" :color="dark?'app-card':'app-card-light'">

               <v-card-title style="margin-right: 60px;" class=" py-0" :class="$store.state.params.font">
                 <img  :class="colorSvg(item.status)" class="pt-1 " :src="iconResponse(item.status)" />



                 <v-card :loading="item.status == 'En attente'"      @click.prevent="showDialogImage(item.image)" style="position: absolute; right: 0;top: 0;z-index: 1;"  class="mr-n1 mt-n3 overflow-hidden" rounded="lg" height="80" width="60" >
                   <v-img
                       class="img-border-bottom "
                       height="100%"  width="100%"  :src="item.image" ></v-img>

                 </v-card>

                 <span :class="getColorClass(item.status)" v-text="arabResp(item.status)" class="mr-3"></span>

                 <v-spacer></v-spacer>
               </v-card-title>
               <v-card-title style="margin-right: 60px;    font-size: 17px;" class="pt-0">
                         <span   style="direction: ltr;font-size: 16px;" class="app-regular mr-4"
                                 v-text=" item.method" > </span>
                 <v-spacer></v-spacer>
                 <h3 class="success--text" >{{ formatToPrice(item.amount) }}</h3>

               </v-card-title>
               <v-card-subtitle >
                 <p  class="text-center app-regular " :class="dark?'grey--text':''">{{ item.created_at | datee }}</p>

               </v-card-subtitle>
             </v-card>

           </v-col>
         </v-row>
         <infinite-loading  @infinite="infiniteHandler"   >
           <div :style="{marginTop:!historiqueData.length?'25vh':''}"  class="mb-5 app-medium"  slot="spinner" :class="$store.state.params.color">جارٍ البحث</div>
           <div     slot="no-more" class="app-medium" :class="[$store.state.params.color]">لا يوجد المزيد</div>
           <div style="margin-top: 15vh!important;"   class="mb-5 app-medium"  slot="no-results" :class="$store.state.params.color">
             <v-card   class="center" :dark="dark" flat :color="dark?'app-bg':''">
               <v-card-title >
                 <v-spacer></v-spacer>
                 <img style="width: 100px" src="assets/notifications/pay.png">
                 <v-spacer></v-spacer>

               </v-card-title>
               <h5 class="app-bold text-center">لا يوجد أي إيداع للمال</h5>

             </v-card>
           </div>

         </infinite-loading>

       </div>


       <vs-dialog :dark="dark" auto-width not-padding  v-model="dialogImage">

         <div class="con-image">
           <img class="image-st" :src="setBaseUrl(image)">
         </div>
       </vs-dialog>
       <vs-dialog overflow-hidden blur v-model="activeTooltip"  v-if="itemSelected !=null" :loading="formUpdateVersement.busy">
         <v-card flat   :dark="dark" color="transparent">
           <v-card-title class="py-0">

             <v-row no-gutters>
               <v-col class="col-5">
                 <span  style="text-align:center;display:block " class="app-bold">Versement</span>

                 <vs-input

                     v-model="formUpdateVersement.versement  "   >

                 </vs-input>
               </v-col>

               <v-col class="col-2">
                 <vs-avatar  class="mt-n10"circle size="60" @click="activeTooltip1=!activeTooltip1">
                   <img style="width:35px;height:35px" src="/assets/img/logo.svg" alt="">
                 </vs-avatar>
               </v-col>


               <v-col class="col-5">
                 <span  style="text-align:center;display:block " class="app-bold">Balance</span>
                 <vs-input styl="text-align:center"  v-model="formUpdateVersement.solde"  />

               </v-col>
             </v-row>
           </v-card-title>
           <v-card-actions class="justify-center pb-0">


             <vs-radio   v-model="formUpdateVersement.reason" val="Un bonus">
               Bonus
             </vs-radio>
             <vs-radio   v-model="formUpdateVersement.reason" val="Récupération">
               Récupération
             </vs-radio>
             <vs-radio   v-model="formUpdateVersement.reason" val="Un credit">
               Credit
             </vs-radio>
             <vs-radio    v-model="formUpdateVersement.reason" val="Rechargement">
               Versement
             </vs-radio>
           </v-card-actions>
           <v-card-actions class="justify-center pb-0">
             <vs-button      circle icon border @click="formUpdateVersement.opperator = '-'">
               <img  :class="formUpdateVersement.opperator=='-'?'white-svg':'primary-svg'"  style="width:22px"  src="/assets/icons/vuesax/minus.svg" alt="">

             </vs-button>
             <vs-button :disabled="formUpdateVersement.opperator ==null "    circle  :color="$store.state.App.config.colorBackground"   @click="updateVersement">
               <span v-text="formUpdateVersement.opperator== null? 'قم بإختيار طريقة الشحن':formUpdateVersement.opperator=='+'?'إضافة الى الرصيد':'خفض من الرصيد'"></span>
             </vs-button>
             <vs-button  :color="$store.state.App.config.colorBackground"  circle icon border @click="formUpdateVersement.opperator = '+'">

               <img :class="formUpdateVersement.opperator=='+'?'white-svg':'primary-svg'"    style="width:22px"  src="/assets/icons/vuesax/plus.svg" alt="">
             </vs-button>

           </v-card-actions>



           <v-card-actions v-if="formUpdateVersement.loading" >
             <vs-alert    class="vuertl" :class="!formUpdateVersement.successful?'vs-component--danger':'vs-component--success'" >
               <template #title>
                 <span v-text="!formUpdateVersement.successful?'حدث خطأ':'تم بنجاح'"></span>
               </template>
               <span>
                          تم إضافة رصيد بقيمة <b>{{formUpdateVersement.versement}}</b>
                       </span>
             </vs-alert>
           </v-card-actions>

         </v-card>



       </vs-dialog>


     </v-card>
     <NotFound v-else/>
   </div>
</template>

<script>
    import axios from 'axios';
    import NotFound from "@/components/comp-tool/NotFound.vue";
    import { formatToPrice ,iconResponse ,colorSvg,getColorClass,arabResp,setBaseUrl,vuesaxColor} from '@/tools/Services';
    import Form from "vform";
    import TitlePages from "@/layout/TitlePages";
    // import "@/assets/style.styl"
    export default  {
        components:{TitlePages , NotFound},
        computed:{
            dark:function () {
                return this.$store.state.dark
            }
        },
        watch:{
            "form.method"(newVal){
                let vm = this
                this.message = this.accounts.filter(function (prod) {
                    return  vm.form.method == prod.feild
                })
            }  ,
        },
        data: function () {
            return {
                deleteDialogConfirmation: false,
                itemSelected: null,
                index: '',
                activeTooltip: false,
                message: '',
                dialogImage: false,
                groupeShow: false,
                color: "#4CAF50",
                editMode: false,
                login: false,
                form: new Form({
                    id: "",
                    amount: "",
                    method: '',
                    description: ""

                }),
                formUpdateVersement: new Form({
                    user_id: '',
                    versement: "",
                    solde: "",
                    opperator: null,
                    addsolde: '',
                    bonus: "",
                    itemId: '',
                    loading: false,
                    reason:"Rechargement",

                }),
                imagee: "",
                imageMode: 0,
                accounts: [],
                show: false,
                current_page: 1,
                historiqueData: [],
                image: '',
                sheet: false,
                userInfo: null

            }
        },
        methods:{getColorClass,arabResp,setBaseUrl,vuesaxColor,

            updateVersement(){

            if(this.formUpdateVersement.versement >0){
                this.formUpdateVersement.post('api/versement-for-user').then((response)=>{

                    this.$vs.notification({
                        duration:3000,
                        progress: 'auto',
                        icon: `<img class="white-svg" src="assets/icons/vuesax/message.svg" />`,
                        classNotification:'vuertl',
                        color:'primary',
                        position:'top-center',
                        title: 'الادارة',
                        text: 'تم شحن رصيد الزبون بنجاح',

                    })
                }).catch(()=>{
                    this.$vs.notification({
                        duration:3000,
                        progress: 'auto',
                        icon: `<img class="white-svg" src="assets/icons/vuesax/message.svg" />`,
                        classNotification:'vuertl',
                        color:'danger',
                        position:'bottom-center',
                        title: 'الادارة',
                        text: 'حدث خطأ ما تحقق من رصيد الزبون',

                    })

                })
            }

            },
            showToolip(item){

                axios.post('api/user-info/'+item.user_id).then((response)=>{
                    this.formUpdateVersement.fill(response.data.user)
                    this.formUpdateVersement.versement = item.amount
                    this.formUpdateVersement.bonus = response.data.bonus
                    this.formUpdateVersement.itemId = item.id
                    this.formUpdateVersement.user_id = item.user_id
                })
            this.itemSelected = item

            this.form.fill(item);
            this.activeTooltip = true
            this.deleteDialogConfirmation =false

            },


            showDialogImage(image){

                this.image= image
                this.dialogImage =  !this.dialogImage
            },


            pick(){
                this.$refs.test.click()
            },
            uploadImage(e) {
                let file = e.target.files[0];

                let reader = new FileReader();
                let limit = 1024 * 1024 * 2;
                if (file["size"] > limit) {
                    Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "You are uploading a large file",
                    });
                    return false;
                }
                reader.onloadend = (file) => {
                    this.form.image = reader.result;
                };
                reader.readAsDataURL(file);

            },


            formatToPrice,iconResponse,colorSvg,
            getHistoriqueData($state){
                axios.get('api/card-request', {page: this.current_page}
                ).then((response)=>{
                    if(response.data.data.length >0){
                        this.current_page +=1
                        this.historiqueData.push(...response.data.data);

                    } else{
                        $state.complete();
                    }
                    this.show = true
                }).catch(()=>{
                    $state.complete();
                })
            },
            infiniteHandler($state){
                 if (this.$store.state.auth.user != null){
                    axios.get('api/versement-request?page='+this.current_page
                    ).then((response)=>{
                        if(response.data.data.length >0){
                            this.current_page +=1

                            this.historiqueData.push(...response.data.data)
                            $state.loaded()
                        }else{
                            $state.complete();
                        }
                        this.show = true
                    }) .catch(()=>{
                        $state.complete();
                    })
                }else{
                    this.login = true
                }
            }
        },


    }
</script>

<style lang="scss" scoped>
    .v-ripple-container{
        z-index: 10!important;
    }
    .v-card--link:before {
        background: none!important;
    }
    .con-image{
        border-radius: inherit
    }
    .image-st{
        display :block;
        position :relative;
        border-radius :inherit;
        max-width :850px
    }
    .notifica{
        direction: ltr!important;text-align: left!important;
        font-size: 15px!important;
        font-family: app-bold!important;
    }
</style>
