<template>
     <v-card  :class="$store.state.dark?'dashboard':'app-card-light'" class="pa-3 rounded-xl mt-3 elevation-0">


         <v-card color="transparent" flat >
             <v-card-title class="app-regular">
                   إيقاف و تشغيل السرفيرات من الموقع فقط
                 <v-spacer></v-spacer>


             </v-card-title>
             <v-row dense >

                 <v-col class="col-12">
                     <v-row  v-if="server.djezzy" >
                       <v-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                         <vs-button :class="server.djezzy.isActive?'vs-button--upload ':''"
                                    block gradient primary
                                    @click="showDialogFlexy('djezzy',server.djezzy.isActive)"
                                    class="rounded-xl overflow-hidden  py-2">
                           <v-app-bar  class="white--text" color="transparent" elevation="0">
                             <vs-avatar pointer size="40" active class="ml-2 app-regular"
                                        @click="showDialogFlexy('djezzy',server.djezzy.isActive)" badge
                                        style="cursor: pointer">
                               <img style="width: 37px" src="/images/flexy/djezzy_carte.png" alt="">
                             </vs-avatar>
                             <v-spacer></v-spacer>
                             <h2 class="app-bold">Djezzy</h2>
                             <v-spacer></v-spacer>

                             <vs-avatar pointer size="40" class="mr-0">
                               <img style="width: 35px" class="app-success-svg" :src="server.djezzy.isActive?'assets/icons/vuesax/success-response.svg':'assets/icons/vuesax/close-1.svg'">
                             </vs-avatar>
                           </v-app-bar>

                         </vs-button>
                       </v-col>
                       <v-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                         <vs-button :class="server.mobilis.isActive?'vs-button--upload ':''"
                                    block gradient primary
                                    @click="showDialogFlexy('mobilis',server.mobilis.isActive)"
                                    class="rounded-xl overflow-hidden py-2">
                           <v-app-bar  class="white--text  " color="transparent" elevation="0">
                             <vs-avatar pointer size="40" active class="ml-2  app-regular"
                                        @click="showDialogFlexy('mobilis',server.mobilis.isActive)" badge
                                        style="cursor: pointer">
                               <img style="width: 37px" src="/images/flexy/mobilis_carte.png" alt="">
                             </vs-avatar>
                             <v-spacer></v-spacer>
                             <h2 class="app-bold">Mobilis</h2>
                             <v-spacer></v-spacer>

                             <vs-avatar pointer size="40" class="mr-0">
                               <img style="width: 35px" class="app-success-svg" :src="server.mobilis.isActive?'assets/icons/vuesax/success-response.svg':'assets/icons/vuesax/close-1.svg'">
                             </vs-avatar>
                           </v-app-bar>

                         </vs-button>
                       </v-col>
                       <v-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                         <vs-button :class="server.ooredoo.isActive?'vs-button--upload ':''"
                                    block gradient primary
                                    @click="showDialogFlexy('ooredoo',server.ooredoo.isActive)"
                                    class="rounded-xl overflow-hidden py-2">
                           <v-app-bar  class="white--text  " color="transparent" elevation="0">
                             <vs-avatar pointer size="40" active class="ml-2  app-regular"
                                        @click="showDialogFlexy('ooredoo',server.ooredoo.isActive)" badge
                                        style="cursor: pointer">
                               <img style="width: 37px" src="/images/flexy/ooredoo_carte.png" alt="">
                             </vs-avatar>
                             <v-spacer></v-spacer>
                             <h2 class="app-bold">Ooredoo</h2>
                             <v-spacer></v-spacer>

                             <vs-avatar pointer size="40" class="mr-0">
                               <img style="width: 35px" class="app-success-svg" :src="server.ooredoo.isActive?'assets/icons/vuesax/success-response.svg':'assets/icons/vuesax/close-1.svg'">
                             </vs-avatar>
                           </v-app-bar>

                         </vs-button>
                       </v-col>
                     </v-row>
                   <v-row  v-if="server.djezzy" >
                     <v-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                       <vs-button :class="server.djezzy_gross.isActive?'vs-button--upload ':''"
                                  block  gradient primary
                                  @click="showDialogFlexy('djezzy_gross',server.djezzy_gross.isActive)"
                                  class="rounded-xl overflow-hidden  py-2">
                         <v-app-bar  class="white--text" color="transparent" elevation="0">
                           <vs-avatar pointer size="40" active class="ml-2 app-regular"
                                      @click="showDialogFlexy('djezzy_gross',server.djezzy_gross.isActive)" badge
                                      style="cursor: pointer">
                             <img style="width: 37px" src="/images/flexy/djezzy_carte.png" alt="">
                           </vs-avatar>
                           <v-spacer></v-spacer>
                           <h2 class="app-bold">Djezzy Gross</h2>
                           <v-spacer></v-spacer>

                           <vs-avatar pointer size="40" class="mr-0">
                             <img style="width: 35px" class="app-success-svg" :src="server.djezzy_gross.isActive?'assets/icons/vuesax/success-response.svg':'assets/icons/vuesax/close-1.svg'">
                           </vs-avatar>
                         </v-app-bar>

                       </vs-button>
                     </v-col>
                     <v-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                       <vs-button :class="server.mobilis_gross.isActive?'vs-button--upload ':''"
                                  block gradient primary
                                  @click="showDialogFlexy('mobilis_gross',server.mobilis_gross.isActive)"
                                  class="rounded-xl overflow-hidden py-2">
                         <v-app-bar  class="white--text  " color="transparent" elevation="0">
                           <vs-avatar pointer size="40" active class="ml-2  app-regular"
                                      @click="showDialogFlexy('mobilis_gross',server.mobilis_gross.isActive)" badge
                                      style="cursor: pointer">
                             <img style="width: 37px" src="/images/flexy/mobilis_carte.png" alt="">
                           </vs-avatar>
                           <v-spacer></v-spacer>
                           <h2 class="app-bold">Mobilis Gross</h2>
                           <v-spacer></v-spacer>

                           <vs-avatar pointer size="40" class="mr-0">
                             <img style="width: 35px" class="app-success-svg" :src="server.mobilis_gross.isActive?'assets/icons/vuesax/success-response.svg':'assets/icons/vuesax/close-1.svg'">
                           </vs-avatar>
                         </v-app-bar>

                       </vs-button>
                     </v-col>
                     <v-col class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12">
                       <vs-button :class="server.ooredoo_gross.isActive?'vs-button--upload ':''"
                                  block gradient primary
                                  @click="showDialogFlexy('ooredoo_gross',server.ooredoo_gross.isActive)"
                                  class="rounded-xl overflow-hidden py-2">
                         <v-app-bar  class="white--text  " color="transparent" elevation="0">
                           <vs-avatar pointer size="40" active class="ml-2  app-regular"
                                      @click="showDialogFlexy('ooredoo_gross',server.ooredoo_gross.isActive)" badge
                                      style="cursor: pointer">
                             <img style="width: 37px" src="/images/flexy/ooredoo_carte.png" alt="">
                           </vs-avatar>
                           <v-spacer></v-spacer>
                           <h2 class="app-bold">Ooredoo Gross</h2>
                           <v-spacer></v-spacer>

                           <vs-avatar pointer size="40" class="mr-0">
                             <img style="width: 35px" class="app-success-svg" :src="server.ooredoo_gross.isActive?'assets/icons/vuesax/success-response.svg':'assets/icons/vuesax/close-1.svg'">
                           </vs-avatar>
                         </v-app-bar>

                       </vs-button>
                     </v-col>
                   </v-row>
                 </v-col>




             </v-row>
         </v-card>

          <vs-dialog blur :dark="$store.state.dark" :loading="loading" width="500px" overflow-hidden v-model="dialogFlexy.isActive">
               <template #header>
                    <b><span class="app-regular" style="direction: ltr!important;" >يمكنك ترك رسالة للزبائن في صفحة الفليكسي  </span></b>
               </template>
               <v-container fluid class="pb-0">
                    <v-row dense>
                         <v-col class="col-12">

                              <v-textarea  clearable
                                            solo-inverted :disabled="dialogFlexy.server=='1'"
                                           rows="2" outlined class="py-0 app-regular" style="direction: rtl"
                                          name=""
                                          :value="dialogFlexy.message"
                                          v-model="dialogFlexy.message"
                                          hide-details
                              ></v-textarea>
                         </v-col>
                         <v-col class="col-12">
                              <v-card-title  class="app-regular py-0"   >

                                   <vs-switch :value="dialogFlexy.server=='1'?true:false"    @click="dialogFlexy.server==1?dialogFlexy.server=0:dialogFlexy.server=1"  class="app-regular"    :color="$store.state.params.bg"  style="width: 100px">
                                        <template #off>
                                             <span class="mx-1">غير مفعل</span>

                                             <img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/dislike.svg"/>
                                        </template>
                                        <template #on>
                                             <span class="mx-1">مفعل</span>
                                             <img  class="white-svg" src="assets/icons/vuesax/like.svg"/>

                                        </template>
                                   </vs-switch>
                                   <v-spacer></v-spacer>
                                   <span style="font-size: 14px">هل الفليكسي مفعل حاليا</span>


                              </v-card-title>
                         </v-col>
                         <v-col class="col-12">
                              <v-btn :loading="loading2"     color="primary" @click="HandlFlexy(dialogFlexy.data,dialogFlexy.server,dialogFlexy.message)"  class="white--text app-regular rounded-lg" block>تطبيق الاعدادات</v-btn>

                         </v-col>
                    </v-row>
               </v-container>


               <div slot="footer"  class="text-center app-regular pt-0" style="direction: rtl">
                    أنت الان في إعدادات  <b href="#">{{dialogFlexy.data}}</b>
               </div>


          </vs-dialog>
          <vs-dialog not-padding auto-width v-model="dialogDate">
               <v-date-picker :dark="$store.state.dark" no-title color="#2196F3" v-model="date"></v-date-picker>
                <template #footer>

                     <vs-button block class="mx-2 app-regular" @click="getFlexydate">إرسال</vs-button>

                </template>
          </vs-dialog>
     </v-card>
</template>

<script>
      import TableChecks from "@/components/dashboard/TableChecks";
     import Form from 'vform'
     import axios from "axios";
     import {CkeckWeb} from "@/plugins/authUser";
     import TestDashboardCard from "@/admin/TestDashboardCard";
     import Overview from "@/components/dashboard/Overview";
     import BottomCard from "@/admin/BottomCard";
     export default {
          components: {BottomCard, Overview, TestDashboardCard, TableChecks},
          data:() => ({
              form:new Form({
                  msgActive:'',
                  msgFlexy:'',
                  msgColor:'',
                  msgRelief:'',
                  msgGradient:'',
              }),
               dialogDate:false,
               switshValue:new  Form({
                    mobilis:{solde:'0.00', bonus:'0.00', SimSolde:'0.00', internet:'0.00',loading:false},
                    mobilis_gross:{solde:'0.00', bonus:'0.00', SimSolde:'0.00', internet:'0.00',loading:false},
                    djezzy:{solde:'0.00', bonus:'0.00', SimSolde:'0.00', internet:'0.00',loading:false},
                    djezzy_gross:{solde:'0.00', bonus:'0.00', SimSolde:'0.00', internet:'0.00',loading:false},
                    ooredoo:{solde:'0.00', bonus:'0.00', SimSolde:'0.00', internet:'0.00',loading:false},
                    ooredoo_gross:{solde:'0.00', bonus:'0.00', SimSolde:'0.00', internet:'0.00',loading:false},
                    pixx:{solde:'0.00', bonus:'0.00', SimSolde:'0.00', internet:'0.00',loading:false},
               }),
               date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
               counterSpendMoney:[],
               active: 'home',
               activeSidebar: true,

              server:[],
               dialogFlexy:{
                    isActive:false,
                    data:'',
                    message:'',
                    server:'',
               },
               loading:false,
               loading2:false,
          }),
          methods:{

              switchPixx(isActive){
                axios.post('api/admin-switch-pixx',{isActive:isActive}).then((response)=>{
                    this.isActive = response.data.pixx_activation.value
                    this.HandlFlexy('null','null','null')
                    this.$store.dispatch("loadsna", {
                        text: response.data.message,
                        isSuccess: true,
                    });
                })
              },
               getFlexydate(){
                    axios
                            .post("api/spend-money",{date:this.date})
                            .then(data => {
                                 this.counterSpendMoney = data.data

                            })
                            .catch(() => {});


               },
               checkSolde(opperator){
                   if (opperator == "SoldeDjezzy"){ this.switshValue.djezzy.loading=true}else if(opperator == "SoldeMobilis"){this.switshValue.mobilis.loading=true}
                   else if(opperator == "SoldeOoredoo"){this.switshValue.ooredoo.loading=true}
                   else if(opperator == "SoldePiXx"){this.switshValue.pixx.loading=true}
                       this.switshValue.SimSolde = "..."
                    axios.post('api/check-solde-from-app',{opperator:opperator}).then((response)=>{
                         if(opperator == "SoldeDjezzy"){
                             this.server.djezzy = response.data
                             this.server.djezzy.loading = false
                         }
                          else if(opperator == "SoldeMobilis") {
                             this.server.mobilis = response.data
                             this.server.mobilis.loading = false
                             this.switshValue.mobilis.loading=false
                         }
                         else if(opperator == "SoldeOoredoo") {
                             this.server.ooredoo = response.data
                             this.server.ooredoo.loading = false
                             this.switshValue.ooredoo.loading=false
                         }else if(opperator == "SoldePiXx") {
                             this.server.pixx = response.data
                             this.server.pixx.loading = false
                             this.switshValue.pixx.loading=false
                         }

                    }).catch(()=>{
                        this.switshValue.djezzy.loading = false
                        this.switshValue.ooredoo.loading = false
                        this.switshValue.mobilis.loading = false
                        this.switshValue.pixx.loading = false
                        this.switshValue.solde = "0.00"
                        this.switshValue.SimSolde =  "0.00"
                        this.switshValue.bonus = "0.00"
                        this.$store.dispatch("loadsna", {
                            text: error.response.data.message || 'حدث خطأ مجهول يرجى إعادة المحاولة',
                            isSuccess: false,
                        });
                    })
               },

               HandlFlexy(opperateur,server,message){
                    this.loading = true
                    if ((server == "null")){
                         axios.post('api/update-flexy',{opperateur:opperateur,server:this.dialogFlexy.server,message:this.dialogFlexy.message  }).then((response)=>{
                              this.server = response.data
                             this.isActive = response.data.pixx_Activation.value
                             this.form.fill(this.server.msgFlexy);
                         }).then(()=>{
                           this.loading = false
                           this.dialogFlexy.isActive = false
                         })

                    }else {
                         axios.post('api/update-flexy',{opperateur:opperateur,server:server,message:message}).then((response)=>{
                              this.server = response.data
                             this.form.fill(this.server.msgFlexy);
                         }).then(()=>{
                           this.loading = false
                           this.dialogFlexy.isActive = false
                         })
                        this.HandlFlexy('null','null','null')

                    }



               },
               showDialogFlexy(opperateur,server){
                    this.loading = true
                    axios.post('api/check-info-flexy',{opperateur:opperateur}).then((response)=>{
                         this.dialogFlexy.message = response.data.comment
                         this.dialogFlexy.server = response.data.isActive
                         this.dialogFlexy.data = opperateur
                    })
                    this.dialogFlexy.isActive = !this.dialogFlexy.isActive
                    this.loading = false
               },
          },
         async mounted() {
               this.getFlexydate()
               this.HandlFlexy('null','null','null')


          }
     }
</script>

<style scoped>
    .v-list-item{
        float: right!important;
        direction: ltr!important;
    }
    .for-allert{
        height: 40px!important;
        text-align: center!important;
        padding-top: 7px!important;
    }
    .vs-dialog__header{
        direction: ltr!important;
    }
</style>