<template>
    <v-card      :dark="dark" flat :color="dark?'app-bg':''" class="elevation-0" >
        <v-app-bar color="transparent" elevation="0">

          <v-spacer></v-spacer>

        </v-app-bar>
        <v-container :fluid="!$vuetify.breakpoint.mdAndUp" class="pb-0">
          <v-card  style="display: block;text-align: center"   color="transparent"  class="elevation-0 rounded-lg  "

          >
            <v-card-title class="pb-3">
              <v-spacer></v-spacer>
              <vs-avatar size="70" pointer @click="$router.push('/profile')" >
                <img width="50px" src="assets/img/logo.svg">
              </vs-avatar>
              <v-spacer></v-spacer>

            </v-card-title>
            <v-card-title class="pt-0 px-0">
              <v-spacer></v-spacer>
              <div>
                <h5>{{formatToPrice(user.solde)}}</h5>
                <p  class="grey--text"> Dépôt</p>
              </div>
              <v-spacer></v-spacer>
              <v-divider style="max-height: 30px" class="mx-3" vertical inset></v-divider>

              <div>
                <h5 class="pb-0">{{formatToPrice(user.solde)}}</h5>
                <p class="grey--text"> Balance</p>
              </div>
               <v-divider style="max-height: 30px" class="mx-3" vertical inset></v-divider>
              <v-spacer></v-spacer>
              <div>
                <h5>{{formatToPrice(user.solde)}}</h5>
                <p  class="grey--text">Bonus</p>
              </div>
              <v-spacer></v-spacer>
            </v-card-title>
            <v-card-title class="pt-0 px-1">
              <v-spacer></v-spacer>
              <v-btn depressed class="v-btn--has-bg mr-1  rounded-lg " style="text-transform: unset" to="/notifications" >Notifications</v-btn>
              <v-btn depressed  class="v-btn--has-bg mr-1  rounded-lg "  style="text-transform: unset" to="/profile">Edit profile</v-btn>
              <v-btn depressed class=" rounded-lg mr-1" style="text-transform: unset">
                <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/bank.svg">
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-title>

          </v-card>
          <v-card  style=" display: block;text-align: center"    :color="dark?'#2d2d39':'#EEF2F5'" class="elevation-0 rounded-lg my-3 "  >
            <v-card-text>
              <v-row style="position: relative " class="justify-space-around ">

                <v-col class="col-3 pb-0 mb-3 px-1  ">
                  <vs-avatar class="mx-auto" @click="$router.push('/home-deposit')"   history-gradient history pointer circle size="50" >
                    <img width="50px" src="assets/notifications/credit.png">
                  </vs-avatar>
                  <span style="font-size: 12px" class="app-bold">شحن رصيد</span>
                </v-col>

                <v-col class="col-3 pb-0 px-1">

                    <vs-avatar class="mx-auto" history-gradient history pointer circle size="50" @click="emitDemandeCredit()">
                      <img width="50px" src="assets/notifications/pay.png">
                    </vs-avatar>

                      <span style="font-size: 12px" class="app-bold"> طلب إحالة</span>

                </v-col>
                <v-col class="col-3 pb-0 px-1">
                     <vs-avatar class="mx-auto" history-gradient history pointer circle size="50" @click="emitTransfer()">
                      <img width="50px" src="assets/notifications/deposit.png">
                    </vs-avatar>
                       <span style="font-size: 12px" class="app-bold">تحويل رصيد</span>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>




         </v-container>

        <v-container :fluid="!$vuetify.breakpoint.mdAndUp" class="mt-0">
               <v-card flat elevation="0" class="mb-aup rounded-lg px-1" v-if="todayOfBonus != null">
                <v-row>
                  <v-col class="col-6">
                    <v-card flat class="rounded-lg"  style="cursor: pointer;" link to="/historique-bonus"
                            :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                      <v-card-title class="pb-1" >
                        <v-spacer></v-spacer>
                        <h3 class="pb-0" v-text="formatToPrice(todayOfBonus.bonus_total)"></h3>
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <p class="app-regular text-center" :class="dark?'white--text':'black--text'">Bonus Total</p>

                    </v-card>
                  </v-col>
                  <v-col class="col-6">
                    <v-card flat class="rounded-lg"  style="cursor: pointer;" link to="/home-deposit"
                            :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                      <v-card-title class="pb-1" >
                        <v-spacer></v-spacer>
                        <h3 class="pb-0" v-text="formatToPrice(todayOfBonus.bonus_today)"></h3>
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <p class="app-regular text-center" :class="dark?'white--text':'black--text'">Bonus aujourd'hui</p>

                    </v-card>
                  </v-col>
                  <v-col class="col-12 py-0">
                    <v-card @click="emitTransferBonus" dark  outlined elevation="5"  :img="'assets/img/back.png'"  class="  app-bold  ml-0  rounded-lg"    block  >
                      <v-card-title>
                        <v-spacer></v-spacer>
                          <span class="white--text"> تحويل جميع الارباح</span>
                        <v-spacer></v-spacer>
                      </v-card-title>
                    </v-card>
                  </v-col>
                  <v-col class="col-6">
                    <v-card flat class="rounded-lg"  style="cursor: pointer;" link to="/historique-bonus"
                            :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                      <v-card-title class="pb-1" >
                        <v-spacer></v-spacer>
                        <h3 class="pb-0" v-text="formatToPrice(user.bonus)"></h3>
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <p class="app-regular text-center" :class="dark?'white--text':'black--text'">Bonus</p>

                    </v-card>
                  </v-col>
                  <v-col class="col-6">
                    <v-card flat class="rounded-lg"  style="cursor: pointer;" link to="/home-deposit"
                            :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                      <v-card-title class="pb-1" >
                        <v-spacer></v-spacer>
                        <h3 class="pb-0" v-text="formatToPrice(user.addsolde)"></h3>
                        <v-spacer></v-spacer>
                      </v-card-title>
                      <p class="app-regular text-center" :class="dark?'white--text':'black--text'">Versement</p>

                    </v-card>
                  </v-col>
                </v-row>
              </v-card>
              <v-card class="rounded-lg elevation-0 px-1" flat>
                <v-row >
                  <v-col class="col-12 ">
                    <Overview  />
                  </v-col>
                  <v-col class="col-12 ">
                    <BottomCard/>
                  </v-col>
                </v-row>


              </v-card>

        </v-container>

            <DialogAddTansfer/>

    </v-card>
</template>

<script>
    import TitlePages from "@/layout/TitlePages";
    import {formatToPrice} from "@/tools/Services";
    import {bus} from '@/tools/bus';
    import Overview from "@/components/dashboard/Overview";
    import axios from "axios";
    import DialogAddTansfer from "@/components/DialogAddTransfer";
    import BottomCard from "@/admin/BottomCard";
    import Menue from "@/layout/Menue.vue";
    export default {
        name: "TestDash",
        components: {Menue, BottomCard, DialogAddTansfer, Overview, TitlePages},
        data(){
            return{
                active3:false,
                todayOfBonus:null,
                items:[
                    {title:"فليكسي",link:'/historique-mobile',icon:"assets/notifications/mobile.png",color:'#5026EF'},
                    {title:"مشتريات",link:'/historique-cartes',icon:"assets/notifications/historique.png",color:'#fff'},
                    {title:"إيداعات",link:'/home-deposit',icon:"assets/notifications/send-money.png",color:'#fff'},
                    {title:"مدونة",link:'/blog',icon:"assets/notifications/blog.png",color:'#fff'},
                    {title:"خصائص",link:'/config',icon:"assets/notifications/config.png",color:'#fff'},
                    {title:"إشعارات",link:'/notifications',icon:"assets/notifications/notification.png",color:'#fff'},
                    {title:"إيدوم",link:'/idoom',icon:"assets/notifications/idoom.png",color:'#fff'},

                ]
            }
        },
        computed:{
            user:function () {
                return this.$store.state.auth.user
            },
            dark:function () {
                return this.$store.state.dark
            }
        },
        methods:{
            emitTransfer(){
              if (this.$store.state.auth.user.type != 'new' && !this.$store.state.auth.user.blocked){
                bus.$emit('loadDialogTransfer')
              }else {
                this.$vs.notification({
                  classNotification:"vuertl",
                  icon:'<img  class="white-svg" src="assets/icons/vuesax/alert.svg" />',
                  title: 'حدث خطأ',
                  position:"top-center",
                  color:"danger",
                  text:"رصيدك غير كاف / حسابك مغلق",
                  duration:"2000"

                })
              }


            },   emitTransferBonus(){
            if (this.$store.state.auth.user.type != 'new' && !this.$store.state.auth.user.blocked){
              bus.$emit('loadDialogTransferBonus')
            }else {
              this.$vs.notification({
                classNotification:"vuertl",
                icon:'<img  class="white-svg" src="assets/icons/vuesax/alert.svg" />',
                title: 'حدث خطأ',
                position:"top-center",
                color:"danger",
                text:"رصيدك غير كاف / حسابك مغلق",
                duration:"2000"

              })
            }

            },
            emitDemandeCredit(){
              if (this.$store.state.auth.user.type != 'new' && !this.$store.state.auth.user.blocked){
                bus.$emit('loadDialogDemandeCredit')
              }else {
                this.$vs.notification({
                  classNotification:"vuertl",
                  icon:'<img  class="white-svg" src="assets/icons/vuesax/alert.svg" />',
                  title: 'حدث خطأ',
                  position:"top-center",
                  color:"danger",
                  text:"رصيدك غير كاف / حسابك مغلق",
                  duration:"2000"

                })
              }

            },
            formatToPrice,

        },
        mounted() {
            bus.$on('todayBonus',(data)=>{
                this.todayOfBonus = data
            })
        }
    }
</script>

<style scoped>
    .con-footer{
        display :flex;
        align-items: center;
        justify-content :flex-end;

    }
    .footer-dialog{
        display: flex;
        align-items :center;
        justify-content: center;
        flex-direction :column;
        width :calc(100%);

    }
    .not-margin{
        margin :0px;
        font-weight: normal;
        padding :10px;
        padding-bottom :0px;
        text-align: end!important;
    }

    .con-content{
        width :100%;
    }

    p{
        font-size :.8rem;
        padding :0px 10px;
    }
    .vs-input-parent{
        width :100%;
    }

    .vs-input-content{
        margin :10px 0px;
        width :calc(100%);
    }

    .vs-input{
        width :100%;
    }




</style>