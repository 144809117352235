import axios from "axios"
import CryptoJS from 'crypto-js'
import store from '../store'
import router from "@/router";
const secret = '82f2ceed4c75872503896gyhfhfghc8a291e56052352bd4325' // change to your key
export function register(user){
    return axios.post('api/register',user,)
}
 

export function logOut(data = "logout"){
    if(data == 'logout'){
        localStorage.removeItem('token')
        localStorage.removeItem('profile')
        localStorage.removeItem('web')
        store.commit('auth/SET_USER',null)
        store.commit('auth/SET_ISADMIN',false)
        store.commit('auth/SET_AUTHENTICATED',false)
        window.location.hash = "/"
    }else {
        router.push("/login")
    }








}



export function setToken(data){

    var token =  Encrypt(data)
    localStorage.setItem('token',JSON.stringify(token))
    axios.defaults.headers.common = {'Authorization': `bearer ${token}`}

}


 export function isLoggedIn(){
    const token =  localStorage.getItem('token')
    return token != null
}
export function isRealToken(){
    const token = localStorage.getItem('token');
    if(!token){
        return false ;
    }
    var  tokenData = Decrypt(JSON.parse(token))

    return (typeof tokenData) == "string" && tokenData.length <= 50

}
export function dcrUrr() {
    const token =  localStorage.getItem('token')

}
export async function getProfile(){
    await getAccessToken()
    return axios.get('api/user')
 }

 export function getAccessToken(){
    const token = localStorage.getItem('token');
    if(!token){
        return null ;
    }
    var  tokenData = Decrypt(JSON.parse(token))
    axios.defaults.headers.common = {'Authorization': `Bearer  ${tokenData}`}
        
}
export function Encrypt (text) {
    const b64 = CryptoJS.AES.encrypt(JSON.stringify(text), secret).toString()
    const e64 = CryptoJS.enc.Base64.parse(b64)
    return e64.toString(CryptoJS.enc.Hex)
}
export function Decrypt (text) {
    const reb64 = CryptoJS.enc.Hex.parse(text)
    const bytes = reb64.toString(CryptoJS.enc.Base64)
    const decrypt = CryptoJS.AES.decrypt(bytes, secret)
    return JSON.parse(decrypt.toString(CryptoJS.enc.Utf8))
}
export async function CkeckWeb() {
    const tokenData = localStorage.getItem('web');
     if(tokenData == null || tokenData == undefined){
    await  axios.get('api/config-web').then((response)=>{
            var encryptConfig = Encrypt(response.data.data)
           localStorage.setItem('web',JSON.stringify(encryptConfig))
            store.commit('SET_PARAMS',response.data.data)
        })
        }else {
         var  tokenDdddata = Decrypt(JSON.parse(tokenData))

         if(tokenDdddata.dark){
             store.commit('SET_PARAMS',tokenDdddata)
         }else{

            // store.commit('SET_PARAMS',tokenDdddata)
         }

     }



    }


export async function getDecryptedProfile(){
    let profile = localStorage.getItem('profile')
    if (profile == null){
        return false
    }
    let dec = Decrypt(JSON.parse(profile))
    return dec.phone != null
}
export function setProfile(data){
    var token =  Encrypt(data)
    window.localStorage.setItem('profile',JSON.stringify(token))
}
