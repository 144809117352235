import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"dark":_vm.$store.state.dark,"flat":"","color":_vm.$store.state.dark?'app-bg':''}},[(_vm.blog!=null)?_c(VContainer,{staticStyle:{"direction":"rtl"}},[_c(VBreadcrumbs,{staticClass:"app-regular py-0",staticStyle:{"direction":"rtl"},attrs:{"large":"","items":_vm.items,"divider":"\\"}}),_c('TitlePages',{attrs:{"title":_vm.blog.title}},[_c(VBtn,{staticStyle:{"float":"left!important"},attrs:{"slot":"btn","large":"","icon":"","depressed":""},on:{"click":function($event){return _vm.$router.go(-1)}},slot:"btn"},[_c('img',{class:_vm.$store.state.dark?'white-svg':'black-svg',attrs:{"src":"assets/icons/vuesax/chevron-right.svg"}})])],1),_c(VCardSubtitle,{staticClass:"app-regular pt-0"},[_vm._v(_vm._s(_vm.blog.subtitle))]),_c(VCard,{staticClass:"app-regular mt-5",staticStyle:{"color":"#fff!important"},attrs:{"elevation":"0","color":"transparent","dark":_vm.$store.state.dark}},[_c(VCardText,{domProps:{"innerHTML":_vm._s(_vm.blog.text)}})],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }