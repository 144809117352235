<template>
    <v-card :color="dark?'app-bg':''" flat :class="$vuetify.breakpoint.smAndDown?'rounded-t-xl':' '">

                    <v-container >



                        <v-row no-gutters dense class="vuertl"  >


                            <v-col class="col-12  mt-5">
                                <v-card   :color="dark?'#2d2d39':'#EEF2F5'" elevation="0" style="border-radius: 17px!important;" >
                                    <v-row class="px-1 justify-content-between" >

                                        <v-col class="col-6 py-2 ">
                                            <v-btn retain-focus-on-click     @click="HandelBtnLength('Idoom adsl')"   :color="activeBtn==='Idoom adsl'?'primary ':'transparent-grad blue-grey--text'"  depressed style="border-radius: 17px!important;    transition: all 0.25s ease;
                                              position: relative; " block large class=" rounded-xl"><b>Idoom adsl</b></v-btn>

                                        </v-col>
                                        <v-col  class="col-6 py-2" >
                                            <v-btn retain-focus-on-click     @click="HandelBtnLength('Idoom 4g')"   :class="activeBtn==='Idoom 4g'?'primary':'transparent-grad blue-grey--text'"  depressed style="border-radius: 17px!important;    transition: all 0.25s ease;
                                              position: relative; " block large class=" rounded-xl"><b>Idoom 4g</b></v-btn>



                                        </v-col>
                                    </v-row>

                                </v-card>
                            </v-col>
                            <v-col class="col-12 mt-4">
                                <v-expand-transition mode="in-out"  >
                                    <v-col class="col-12 pa-0"  >
                                        <v-card width="100%"  flat  img="assets/img/back-one.svg" class="rounded-xl  " elevation="0"  >


                                            <v-container fill-height class="pa-0 ">
                                            <v-row dense no-gutters>

                                                 <v-col class="col-12   ">

                                                     <div>
                                                         <v-combobox    :dark="$store.state.dark"  :background-color="$store.state.dark?'#2d2d39':'#EEF2F5'"
                                                                     height="50" v-model="form.number" :hide-selected="show"
                                                                     maxlength="12"  class="rounded-xl mb-2 elevation-0" id="text-field-edit"  :items="contacts" :clearable="false" type="num" @keyup="EnterValue($event)" :search-input.sync="search" :menu-props="prosa"
                                                                     auto-select-first filled  item-text="number" item-value="number" hide-details placeholder="أدخل رقم الهاتف" >
                                                              <img  slot="append" :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/search.svg" />

                                                           <v-btn  style="position: relative; bottom: 5px;  left: 5px;margin-left:5px" slot="prepend-inner"   @click="clearCode"   icon  class="  v-btn--has-bg">
                                                               <img style=" "      :class="$store.state.dark?'white-svg':'black-svg'" :src="show?'assets/icons/vuesax/up.svg':'assets/icons/vuesax/down.svg'" />
                                                             </v-btn>


                                                             <template   v-slot:item="{ item , dark }"  style="background-color: #000!important;">
                                                                 <v-list-item-avatar
                                                                         color="indigo"
                                                                         class="text-h5 font-weight-light white--text"
                                                                 >
                                                                     <span style="font-size: 18px;font-width: bold;">{{   item.name.charAt(0) }}</span>
                                                                 </v-list-item-avatar>
                                                                 <v-spacer></v-spacer>
                                                                 <v-list-item-content class="py-0">
                                                                 <v-list-item-title    style="font-size: 13px" class="text-capitalize caption" ><b  v-text="item.name"></b></v-list-item-title>
                                                                 </v-list-item-content>
                                                                 <v-list-item-content class="py-0">
                                                                      <v-list-item-subtitle  style="text-align: center;font-size: 14px" >
                                                                          <b style="font-size: 14px" v-text="item.number"></b>
                                                                      </v-list-item-subtitle>
                                                                 </v-list-item-content>

                                                             </template>
                                                         </v-combobox>

                                                     </div>
                                                    <h3 class="app-regular pb-2 mt-0 vuertl"   style="margin-bottom: 0px;color:#E40814;text-align: center!important;direction: ltr;font-size: 13px;" v-text="error!=null?error:''"></h3>
                                                    <v-expand-transition mode="in-out">
                                                    <v-col class="col-12 pa-0 mt-0" v-if="show">

                                                        <v-text-field   :class="$store.state.params.font"

                                                                        style="border-radius: 17px"
                                                                        height="55" ref="code" type="number" :dark="dark" dense
                                                                        v-model="form.code" maxlength="16"
                                                                        class="rounded-lg overflow-hidden" id="text-field-card"
                                                                        :background-color="dark?'#2d2d39':'#EEF2F5'"
                                                                        placeholder="كود البطاقة ان وجد"
                                                                        hide-details
                                                        >
                                                            <v-btn slot="prepend-inner" icon @click="clearCode" class="mt-3 ml-4 mr-3 v-btn--has-bg">
                                                              <img style="position: relative"      :class="$store.state.dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/carta.svg" />

                                                            </v-btn>

                                                        </v-text-field>
                                                        <h3 class="app-regular pb-2 mt-2 vuertl"   style="margin-bottom: 0px;color:#E40814;text-align: center!important;direction: ltr;font-size: 13px;" v-text="errorCode!=null?errorCode:''"></h3>


                                                    </v-col>
                                                    </v-expand-transition>
                                                </v-col>


                                            </v-row>
                                        </v-container>




                                        </v-card>
                                    </v-col>
                                </v-expand-transition>
                            </v-col>

                            <v-col class="col-12 py-0 pt-2 ">
                                                       <v-row dense >
                                                           <v-col
                                                                  :class="lan.method =='CHECK' || lan.method =='CREDIT'?'col-6 col-lg-6 col-sm-6 col-xs-6':'col-12'"
                                                                  v-for="(lan, index) in card" :key="lan.index">
                                                               <v-hover>
                                                                   <template v-slot:default="{ hover  }">

                                                                       <v-card ripple flat :elevation="hover?6:0" @click.passive="lan.clicked()" :color="dark?'#2d2d39':'#EEF2F5'" :dark="dark"
                                                                               :class="activated==lan.title?'primary white-text':``"
                                                                                active-class=""
                                                                                style="cursor:pointer"
                                                                               class="rounded-xl card-sharow text-decoration-none">

                                                                           <v-card-title style="display: block" class=" text-center    justify-center">
                                                                               <v-avatar large :class="activated==lan.title?'white--text':'text-gradient'"
                                                                               >
                                                                                   <img :src="lan.icon"/>
                                                                               </v-avatar>
                                                                           </v-card-title>
                                                                           <v-card-subtitle

                                                                                             class="text-center px-1 app-medium">
                                                                             <span :class="activated==lan.title?' white-text':`${dark?'enable':'disable'}-btn `">{{lan.title}}</span>

                                                                           </v-card-subtitle>
                                                                       </v-card>

                                                                   </template>
                                                               </v-hover>


                                                           </v-col>

                                                       </v-row>
                                                   </v-col>





















                        </v-row>
                     </v-container>


             <v-dialog :fullscreen="$vuetify.breakpoint.smAndDown"  eager class="rounded-card px-0"
                      transition="dialog-bottom-transition"
                     :dark="dark"
                       persistent max-width="600"
                      v-model="dialog"

                 >
                 <v-card :dark="dark"  :class="$vuetify.breakpoint.smAndDown?'':'rounded-xl '"   class="  overflow-hidden"  >
                     <v-card-title class="pb-2 pt-2 rounded-t-xl overflow-hidden">
                         <v-btn  @click="dialog = !dialog" style="float: left!important;" icon depressed><img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close-1.svg" /></v-btn>
                          <v-spacer></v-spacer>
                       <h4 style="text-align: center" class="app-bold mb-4 ">{{title}}</h4>
                     </v-card-title>
                     <v-container class="pa-2 overflow-hidden">
                         <v-card  :color="$store.state.dark?'#000':'#EEF2F5'" class="mr-2 rounded-xl elevation-0 "  v-if="items && directTopup">
                             <v-list-item
                                          class="pr-0 rounded-lg mb-2 elevation-0 vuertl" >
                                 <v-list-item-avatar >
                                     <vs-avatar size="38px" circle>
                                         <img style="height:38px;width:38px" :src="img"/>
                                     </vs-avatar>
                                 </v-list-item-avatar>
                                 <v-list-item-content class="mr-2">
                                     <v-list-item-title ><h3>{{activeBtn}}</h3></v-list-item-title>

                                     <h5  >شحن فوري</h5>
                                 </v-list-item-content>
                                 <v-list-item-content>
                                     <h3 v-text="form.number" ></h3>
                                     <h3   style="text-align: center ">
                                       <b  style="font-family: sans-serif" v-text="form.price!=''?form.price:'0.00' "> </b>
                                       <sup>DA</sup>

                                       <span  v-if="form.amount != form.price " class="ml-3 error--text text-decoration-line-through">{{form.amount}}</span>
                                     </h3>
                                 </v-list-item-content>

                             </v-list-item>

                         </v-card>
                       <div v-if="form.count <= 0">
                         <div class="p-0" style="display: block" v-if="directTopup==false">
                           <v-row class=" mb-2"  >
                             <v-col class="col-6">
                               <v-card   flat class="rounded-xl"   style="cursor: pointer;"

                                         @click="directTopup = true"
                                       :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                                 <v-card-title style="display:block;text-align: center" class="  mx-auto pb-1">
                                   <img :class="dark?'white-svg':'black-svg'"
                                        src="assets/img/logo.svg"/>
                                 </v-card-title>
                                 <p class="app-regular text-center" :class="dark?'white--text':'black--text'">شحن فوري</p>

                               </v-card>
                             </v-col>
                             <v-col class="col-6">
                               <v-card   @click="HandelRechrgeWithCode"
                                        flat class="rounded-xl" style="cursor: pointer;"
                                        :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                                 <v-card-title style="display:block;text-align: center"  class="  mx-auto pb-1">
                                   <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/code-1.svg"/>
                                 </v-card-title>
                                 <p  :class="dark?'white--text':'black--text'"  class="app-regular text-center"  >رقم تعبئة</p>

                               </v-card>
                             </v-col>

                           </v-row>

                         </div>
                         <v-card-text class="pa-0  ml-1" style="display: block" v-if="items != null  && directTopup">
                           <v-row class="justify-center mr-3 mt-1"   >
                             <v-hover v-slot="{ hover }" v-for="item in items" :key="item.id">
                               <v-col :class="$vuetify.breakpoint.smAndDown?'py-0':''" class="pr-2  pb-2 ">
                                 <v-btn style="height: 80px;position: relative"
                                        block
                                        :disabled="!item.isActive"
                                        :hover="hover"

                                        :color="form.name == item.name?'primary':$store.state.dark?'#000':'#EEF2F5'"
                                        :elevation="hover?'2':'0'" class=" rounded-xl overflow-hidden mr-1"
                                       @click="switchTab(item)">
                                   <v-row dense no-gutters class="mr-1">
                                     <v-col v-if="!item.isActive" class="col-12 ">
                                       <h5   class="app-medium error--text pb-2"><b>{{' نفذت الكمية'}}</b></h5>
                                       <h4  >{{item.name.replace(item.amount,'')}}</h4>

                                     </v-col>
                                     <v-col v-else class="col-12">
                                       <h5  >{{item.name.replace(item.amount,'')}}</h5>
                                       <h3 class="mt-2 pb-0 px-1" v-text="item.amount" style="display: block;text-align: center;font-size: 20px"></h3>
                                     </v-col>
                                   </v-row>





                                 </v-btn>

                               </v-col>
                             </v-hover>
                           </v-row>


                         </v-card-text>
                         <v-card-text class="p-0 pt-2" v-if="!items && directTopup==true">

                             <v-card-title  class="  pb-0 app-regular error--text"><strong class="mx-auto">تم نفاذ الكمية</strong></v-card-title>
                             <p style="text-align: center"><strong class="app-regular">سوف يتم تعبئة السرفير</strong></p>
                         </v-card-text>

                         <v-layout v-if="directTopup" class="mr-3">
                           <v-col class="col-12 px-0   pr-5 " :class="$vuetify.breakpoint.smAndDown?'small-size':''">
                                 <vs-button   @click="HandelServer('RECHARGEMENT')"    size="lg"
                                              class="white--text rounded-lg py-2   " animation-type="scale"
                                              :disabled="form.name == ''"   :color="$store.state.App.config.colorBackground"
                                              block  >
                                                        <span class="app-regular" >تعبئة البطاقة</span>
                                     <img class="white-svg" src="assets/icons/vuesax/send.svg"/>
                                 </vs-button>

                             </v-col>


                         </v-layout>

                       </div>

                         <div v-if="form.count > 0" class="p-0" style="display: block" >
                           <v-card-text class="pa-0  ml-1" style="display: block"  >
                             <v-card  :color="$store.state.dark?'#000':'#EEF2F5'" class="mr-2 rounded-xl elevation-0 "  >
                               <v-list-item
                                   class="pr-0 rounded-lg mb-2 elevation-0 vuertl" >
                                 <v-list-item-avatar >
                                   <vs-avatar size="38px" circle>
                                     <img  style="height:38px;width:38px"  :src="'assets/img/logoxl.png'"/>
                                   </vs-avatar>
                                 </v-list-item-avatar>
                                 <v-list-item-content class="mr-2">
                                   <v-list-item-title class="app-bold" ><h3>{{form.name==''?'إختر البطاقة':form.name}}</h3></v-list-item-title>
                                   <h5  >شراء بطاقات فورية</h5>
                                 </v-list-item-content>
                                 <v-list-item-content>
                                   <h2   style="text-align: center ">
                                     <b class="success--text" style="font-family: sans-serif" v-text="form.total!=''?form.price * form.count:'0.00' "> </b>
                                     <sup class="success--text">DA</sup>
                                     <span v-if="form.amount != form.price " class="ml-3 error--text text-decoration-line-through">{{form.total}}</span>
                                   </h2>
                                   <h3   style="text-align: center ">
                                     <b class="app-bold" style="font-family: sans-serif"  > الكمية</b>
                                     <span class="mx-3     ">{{form.count}}</span>
                                   </h3>
                                 </v-list-item-content>
                               </v-list-item>
                               <v-card-text class="pa-2 pt-0  ">
                                <v-layout fill-height  justify-end align-end>
                                  <v-col class="col-5 pt-0">
                                    <v-btn block @click="switchItems" height="43"
                                           elevation="0" depressed class="rounded-lg text-capitalize  white--text pr-2"
                                            color="primary"
                                           :dark="!dark" v-text="allItems[0].type=='adsl'?'Idoom 4G':'Idoom adsl'">
                                    </v-btn>
                                  </v-col>
                                  <v-col class="col-7 pt-0">
                                    <v-select hide-details flat chips persistent-hint  placeholder="قم بإختيار الكمية"

                                              :items="['1','2','3','4','5','6','7','8','9','10','11','12','13','14','15','16','17','18','19','20','30','40','50','60','70','80','90','100']"
                                              class="rounded-lg pt-0 pt-0 vuertl app-regular"
                                              style="font-size: 15px!important;width: 100%"
                                              v-model="form.count"
                                              color="grey"

                                              height="43"
                                              :background-color="dark?'app-card':'#fff'"
                                    >

                                    </v-select>
                                  </v-col>
                                </v-layout>
                                </v-card-text>
                             </v-card>
                             <v-row class="justify-center mr-1 mt-1"   >
                               <v-hover v-slot="{ hover }" v-for="item in allItems" :key="item.id">
                                 <v-col :class="$vuetify.breakpoint.smAndDown?'py-0':''" class="pr-2  pb-2 ">
                                   <v-btn style="height: 80px;position: relative"
                                          block
                                          :disabled="!item.isActive"
                                          :hover="hover"

                                          :color="form.name == item.name?'primary':$store.state.dark?'#000':'#EEF2F5'"
                                          :elevation="hover?'2':'0'" class=" rounded-xl overflow-hidden "
                                          @click="switchTab(item)">
                                     <v-row dense no-gutters class="mr-1">
                                       <v-col v-if="!item.isActive" class="col-12 ">
                                         <h5   class="app-medium error--text pb-2"><b>{{' نفذت الكمية'}}</b></h5>
                                         <h4  >{{item.name.replace(item.amount,'')}}</h4>

                                       </v-col>
                                       <v-col v-else class="col-12">
                                         <h5  >{{item.name.replace(item.amount,'')}}</h5>
                                         <h3 class="mt-2 pb-0 px-1" v-text="item.amount" style="display: block;text-align: center;font-size: 20px"></h3>
                                       </v-col>
                                     </v-row>





                                   </v-btn>

                                 </v-col>
                               </v-hover>
                             </v-row>

                             <v-layout >
                               <v-col class="col-12 px-0   pr-5 " :class="$vuetify.breakpoint.smAndDown?'small-size':''">
                                 <vs-button    @click="HandelServer('ACHAT')"    size="lg"
                                              class="white--text rounded-xl py-2 mr-2  " animation-type="scale"
                                              :disabled="form.name == ''" :color="$store.state.App.config.colorBackground"
                                              block  >
                                   <span class="app-regular" >شراء</span>
                                   <img class="white-svg" src="assets/icons/vuesax/send.svg"/>
                                 </vs-button>

                               </v-col>


                             </v-layout>

                           </v-card-text>


                       </div>
                     </v-container>

                 </v-card>


            </v-dialog>
            <v-overlay
                color="#3c3e5f" @click="dial = false"
                opacity="0.20"
                :value="dial"
                 :style="{backdropFilter:dial?'blur(3px)':''}"   >
        <v-bottom-sheet eager
            content-class="rounded-"
            open-delay="0"

             persistent
            scrollable

            :dark="dark"
            v-model="dial" max-width="500"

        >

            <v-card  class="bottom-sheet-radius app-regular"  :color="dark?'black-vuesax':''"

            >

                <v-card-text v-if="checkInfo.ncli " class="py-2">

                    <v-row class="justify-center" dense no-gutters v-if=" checkInfo.succes == '1'">

                        <v-col class="col-12 col-sm-12 col-md-4 col-lg-6 col-xl-6 pt-0 " v-if="checkInfo.num_trans">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold">رقم العملية</p>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                                      >{{checkInfo.num_trans}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 " v-if="checkInfo.offre">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold">نوع الاشتراك</p>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                                       >
                                        {{checkInfo.offre.replaceAll('_',' ')}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 "
                               v-if="checkInfo.type_client">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold">نوع الزبون</p>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                                        >{{checkInfo.type_client}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>
                        <v-col :class="checkInfo.type=='4GLTE'?'col-lg-6 col-xl-6':'col-lg-6 col-xl-6' "
                               class="col-12 col-sm-12 col-md-6   pt-0 " v-if="checkInfo.type">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold">نوع العرض</p>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                                        >{{checkInfo.type}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 "
                               v-if="checkInfo.num_recharge">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold">رقم الهاتف</p>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                                           >{{checkInfo.num_recharge}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 "
                               v-if="checkInfo.date_transaction">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold">تاريخ التعبئة</p>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                                          >
                                        {{checkInfo.date_transaction + ' ' +  checkInfo.heure_transaction }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>
                        <v-col :class="checkInfo.type=='4GLTE'?'col-lg-6 col-xl-6':'col-lg-6 col-xl-6' "
                               class="col-12 col-sm-12 col-md-6   pt-0 " v-if="checkInfo.ncli && !checkInfo.montant">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold">معرف الزبون</p>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                                          >{{checkInfo.ncli}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>

                    </v-row>

                    <v-row class="justify-center"  dense no-gutters v-if="checkInfo.montant">

                        <v-col class="col-12 col-sm-12 col-md-4 col-lg-6 col-xl-6 pt-0 " v-if="checkInfo.type">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold">تسمية الاشتراك</p>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                                        >{{checkInfo.type}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0 mt-2">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold"
                                       v-text="checkInfo.montant > '0'?'هذا الزبون لدية ديون':'هذا الزبون ليس لدية ديون '"></p>
                                    <v-list-item-subtitle class="font-weight-bold "
                                                           > ( فاتورة الهاتف )
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                                         >{{ 'دج  '+ checkInfo.montant }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>
                        <v-col class="col-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 pt-0 " v-if="checkInfo.offre">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold">نوع الاشتراك</p>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                                           >
                                        {{checkInfo.offre?checkInfo.offre.replaceAll('_',' ') : checkInfo.offre}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>

                    </v-row>
                </v-card-text>

                <v-container v-if="checkInfo.message" class="py-0">

                    <v-alert text class="mt-3"
                        style="display: block;text-align: center"
                        :icon="checkInfo.status=='Confirmé'?'mdi-check-circle-outline':'mdi-close-circle-outline'"
                        :color="checkInfo.status=='Confirmé'?'#20B471':'#ED1C24'  ">
                        <p   class="mb-0"
                           v-text="checkInfo.message"></p>
                    </v-alert>
                    <v-row v-if="checkInfo.succes=='1' || checkInfo.status == 'Confirmé'">
                        <v-col class="col-md-4 pt-0 " v-if="SelectedItem.num_trans || checkInfo.hasOwnProperty('num_trans')">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold">رقم العملية</p>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                    >{{checkInfo.num_trans}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>
                        <v-col class="col-md-4 pt-0 "
                               v-if="SelectedItem.num_recharge || checkInfo.hasOwnProperty('num_recharge')">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold">رقم الهاتف</p>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                    >{{SelectedItem.num_recharge}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>
                        <v-col class="col-md-4 pt-0 "
                               v-if="checkInfo.date_transaction || checkInfo.hasOwnProperty('time')">

                            <v-list-item>
                                <v-list-item-content style="text-align: center" class="py-0">
                                    <p class="blue-grey--text text--lighten-2 font-weight-bold">تاريخ التعبئة</p>
                                    <v-list-item-subtitle class="font-weight-bold telecom-text-success"
                                    >
                                        {{checkInfo.heure_transaction ||checkInfo.time }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-col>
                    </v-row>

                    <v-card-title class="vuertl py-2 mt-2" v-if="checkInfo.status=='Confirmé' && checkInfo.hasOwnProperty('amount') && checkInfo.count == 1">
                      <h5 class="app-bold"> تحميل الفاتورة من هنا</h5>
                        <v-spacer></v-spacer>
                        <vs-avatar @click="getPdf(checkInfo.id)"  class="ml-1" style="margin-right: 8px; cursor: pointer;" size="45" :dark ="dark"    >
                            <img  style="width: 20px"   class="error-svg" src="assets/icons/vuesax/pdf.svg" />
                        </vs-avatar>
                    </v-card-title>
                  <v-card-title class="vuertl py-2 mt-2" v-if="checkInfo.status=='Confirmé' && checkInfo.hasOwnProperty('amount') && checkInfo.count > 1">
                    <h5 class="app-bold">تحميل ملف TEXT من هنا</h5>
                    <v-spacer></v-spacer>
                    <vs-avatar @click="downloadTxt(checkInfo.id)"  class="ml-1" style="margin-right: 8px; cursor: pointer;" size="45" :dark ="dark"    >
                      <img  style="width: 20px"   class="error-svg" src="assets/icons/vuesax/text.svg" />
                    </vs-avatar>
                  </v-card-title>
                </v-container>
                <v-card-actions>
                    <v-layout >

                        <v-col :class="checkInfo.ncli?'col-6':'col-12'">
                            <v-btn @click="dial = !dial" block class="  rounded-lg v-btn--active   " :class="dark?'app-card':'app-card-light '" text elevation="0" depressed large
                                   >
                              إلغاء
                            </v-btn>
                        </v-col>
                        <v-col v-if="checkInfo.method != 'ACHAT' " class="col-6">
                            <v-btn :disabled="!checkInfo.ncli" large block @click="HandelRechargement"
                                   :color="$store.state.params.bg" class="  rounded-lg pt-0" v-text="form.busy?'':'تعبئة البطاقة'" ></v-btn>

                        </v-col>
                      <v-col v-else class="col-6">
                            <v-btn outlined  large block @click="HandelProduct(checkInfo)"
                                   :color="$store.state.App.config.colorBackground" class="  rounded-lg pt-0"  >المنتج من هنا</v-btn>

                        </v-col>

                    </v-layout>
                </v-card-actions>
            </v-card>

        </v-bottom-sheet>


        </v-overlay>
        <ContactSidBar/>
    </v-card>

</template>
<script>

    const ContactSidBar  = ()=> import (/* webpackPrefetch: true */ "@/components/fordesktop/ContactSidBar");

    import Form from 'vform'
    import axios from 'axios';
    import {getProfile} from "@/plugins/authUser";
    import {
        getColorClass,
        checkAdsl,
        getColorr,
        iconR,
        splitCard,
        formatToPrice,

        excuteSound
    } from "@/tools/Services";
    import {bus} from "@/tools/bus";
    import TitlePages from "@/layout/TitlePages";
    import {mapState} from "vuex";
     export default {
        name: "TelecomRechargemenntMobile",
         components: {TitlePages,ContactSidBar},
         computed:{
            dark:function (){
              return this.$store.state.dark
            },
             contacts:{
                 get:function () {
                     return  this.$store.state.contacts
                 },
               set:function (nexValue) {
                   return  this.$store.state.contacts
               }

             },
            ...mapState(["selectedContact"]),
         },
         data() {
            return {
                title:"",
                errorCode:null,
                activated:'',
                maxLength:9,
                activeBtn:'Idoom adsl',
                activeBtnSolde:'Solde',
                dial:false,
                card :  [

                    {
                        title:"معرفة إشتراك الزبون",
                        icon:"assets/icon/check.svg",
                        method:'CHECK',
                        clicked:()=>{
                            this.count = 0
                            this.activated ="معرفة إشتراك الزبون"
                            this.form.method = "CHECK"
                            this.HandelServer('CHECK')
                        }
                    },
                    {
                        title:"إظهار فاتورة الهاتف",
                        icon:"assets/icon/credit.svg",
                        method:'CREDIT',
                        clicked:()=>{
                          this.count = 0
                            this.activated ="إظهار فاتورة الهاتف"
                            this.form.method = "CREDIT"
                            this.HandelServer('CREDIT')
                        }
                    },
                    {
                        title:"تعبئة البطاقة",
                          icon:"assets/img/logo.svg",
                        method:'RECHARGEMENT',
                        clicked:()=>{
                          this.activated = "تعبئة البطاقة"
                          this.form.count = 0
                          this.title = "قم باختيار بطاقة التعبئة"
                            this.form.method = "RECHARGEMENT"
                            this.HandelRechargement()
                        }
                    },
                  {
                    title:"شراء بطاقات",
                    icon:"assets/icon/rechargement.svg",
                    method:'ACHAT',
                    clicked:()=>{
                      this.title = "قم بإختيار النوع و الكمية"
                      this.form.number = ""
                      this.form.count = 1
                      this.activated = "شراء بطاقات"
                      this.form.method = "ACHAT"
                      this.HandelRechargement('CREDIT')
                    }
                  },
                ],
                isActive:'',
                model:null,
                prosa:{

                },
                items:null,
              allItems:null,
                active:false,
                tab: 0,
                bar: false,
                rechargement: {},
                respDialog:null,
                dialog:false,
                load:false,
             
                form: new Form({
                    count:0,
                    code:"",
                     price:'',
                    name:'',
                    amount:'',
                    method: '',
                    total:'',
                    number:'',
                }),
                show:false,
                checkInfo:[],
                SelectedItem:[],
                directTopup:false,
                img:'',
                isLoading: false,

                error:null,
                search: null,
                solde:'',
            } },

        mounted() {

             this.$store.dispatch('getContacts')
            // if (this.$vuetify.breakpoint.mdAndUp){
            //     this.$router.push('/telecom')
            // }
          //  this.solde = this.$store.state.user.solde
          //  this.getPriceAdsl()
          
        },
        methods: {getProfile,
          downloadTxt(id){
            this.load = true
            axios.get('api/txt/'+id, { headers: {
                'Content-Type': 'application/json',
              },
              'responseType': 'blob' // responseType is a sibling of headers, not a child
            })
                .then(response=>{
                  const url = window.URL.createObjectURL(new Blob([response.data]));
                  const link = document.createElement('a');
                  link.href = url;
                  link.setAttribute('download', id+'_carte.txt');
                  document.body.appendChild(link);
                  link.click();
                  this.load = false
                })
                .catch(error=>{
                  this.load = false

                  this.$store.dispatch("loadsna", {
                    text:"حدث خطأ ما يرجى إعادةالمجالة",
                    isSuccess: false,
                  });
                })
          },
          async   HandelProduct(item){
            await  this.$store.commit('SET_PRODUCT',item)
            this.$router.push({name:"IdoomProduct",params:{id:item.id}}).catch(()=>{})

          },
          switchItems(){
          this.form.reset()
          this.form.count = 1
          if(this.allItems[0].type == 'adsl'){
            this.allItems = this.items.lte
          }else {
            this.allItems = this.items.adsl
          }

          },
            HandelBtnLength(item){
              if(item == 'Idoom adsl'){
                  this.maxLength = 9
                  this.activeBtn='Idoom adsl'
              }  if(item == 'Idoom 4g'){
                    this.maxLength = 12
                    this.activeBtn='Idoom 4g'
                }
                if(item == 'Solde'){
                    this.solde = this.$store.state.auth.user.solde
                    this.activeBtnSolde='Solde'
                }
                if(item == 'Bonus'){
                    this.solde = this.$store.state.auth.user.bonus
                    this.activeBtnSolde='Bonus'
                }




            },
             formatToPrice,
            HandelRechrgeWithCode(){
                this.dial = false
                this.show = true
                this.dialog = false
                this.directTopup = false

            },
            EnterValue(event){
                let vm = this;
                if (event.key == "Enter"){
                    if(vm.form.number.length == 9 || vm.form.number == 12 && this.checkInfo == []  ){
                        this.HandelServer('CHECK')
                    }
                }

            },
          
            clearCode(){
                this.show = !this.show
                this.form.code = ''
            },
            
            checkAdsl,getColorr,iconR,splitCard,
            switchTab(item){
                this.isActive = item.id
                this.form.name = item.name
                this.form.amount = item.amount
                this.form.price = item.price
                this.form.total = item.amount * this.form.count
            },
            async  HandelRechargement(){

                this.isActive = ''
                this.directTopup = false
                this.form.name =""
                this.dialog = false
                this.dial = false
                this.checkInfo = []
                 if(this.form.number.length == '9' ){
                    this.img = "assets/idoom/idoom.png"
                    if (this.form.code.length =='16'){
                        this.HandelServer('RECHARGEMENT')
                    }else if(this.form.code.length =='0'){
                        this.getPriceAdsl()
                        this.form.amount = ''
                        this.form.price = ''
                        this.dialog = true
                    }else{
                        this.checkInfo.success= '0'
                      this.error =  'تحقق من رقم بطاقة التعبئة'
                    }
                }else if(this.form.number.length == '12' ){
                    if(this.form.number.startsWith("213")){
                        this.img = "assets/idoom/lte.png"
                        if (this.form.code.length =='16'){
                            this.HandelServer('RECHARGEMENT')
                        }else if(this.form.code.length =='0'){
                            await  this.getPriceAdsl()
                            this.form.amount = ''
                            this.form.price = ''
                            this.dialog = !this.dialog
                        }else{
                            this.checkInfo.success= '0'
                          this.error =  'تحقق من رقم بطاقة التعبئة'
                        }
                    }else {
                        this.checkInfo.success= '0'
                      this.error = "قم بكتابة 213 قبل 47 مثلا : 213471112223"
                    }


                } else if(this.form.count >=1){
                   await this.getPriceAdsl()

                   this.dialog = true

                 } else{
                    this.checkInfo.success= '0'
                   this.error = "تحقق من رقم الهاتف"
                }


            },
            getPdf(id){
                this.load = true
              axios.post('api/download-pdf-idoom/'+id, { headers: {
                        'Content-Type': 'application/json'
                    },
                    'responseType': 'blob'
                })
                    .then(response=>{
                        const url = window.URL.createObjectURL(new Blob([response.data]));
                        const link = document.createElement('a');
                        link.href = url;
                        link.setAttribute('download', 'carte.pdf');
                        document.body.appendChild(link);
                        link.click();
                        this.load = false
                    })
                    .catch(error=>{

                        this.load = false
                        this.$store.dispatch("loadsna", {
                            text: "Un problème est survenu. Veuillez réessayer",
                            isSuccess: false,
                        });
                    })



            },
            showDialog(selected){
                this.form.method = selected
                if(selected === "ADSL"){this.form.method = 'ADSL',this.img = "assets/idoom/idoom.png"}
                else if(selected === "LTE"){this.form.method = 'LTE',this.img = "assets/idoom/lte.png"}
                else if(selected === "FACTURE"){this.form.method = 'FACTURE',this.img = "assets/idoom/idoom.png" }
                this.checkInfo = null
                this.rechargement = {}
                this.form.amount = ''
                this.form.price = ''
                this.dialog = !this.dialog

            },

            async   getPriceAdsl(){
                this.items= null
                this.allItems = null
                await  axios.get('api/price-adsl').then((response)=>{
                    if(this.form.number.length == 9){
                        this.items = response.data.adsl
                    }else if (this.form.number.length == 12) {
                        this.items = response.data.lte
                    }else{
                      this.items = response.data
                      this.allItems = response.data.adsl
                    }
                    this.tab = null

                })
            },
            resetForm(){
                this.form.reset()

            },
            loadContact(){
                axios.get('api/contacts').then((response)=>{
                    this.contacts = response.data
                })
            },
            HandeLastStep() {
                this.form.reset();
            },
            getColorClass,
             HandelServer(mode) {
                this.errorCode = null
                this.error = null
                this.dial = false
                this.dialog = false
                this.checkInfo = []
              if(this.$store.state.auth.user != null){
                this.form.method = mode
                const loading = this.$vs.loading({
                  color:"rgb(254, 44, 85)",
                  type:this.$store.state.params.loading,
                  background:this.dark?"#000":"#fff",
                  opacity:0.5,
                })
                if (this.form.number.length == this.maxLength || mode == "ACHAT"){
                  if(this.form.code.length == 16 || this.form.code.length == 0  || mode == "ACHAT"){

                    this.form.post("api/check-info-telecom").then(({data}) => {
                      this.checkInfo = data.data;
                      this.SelectedItem = data;
                      if(this.checkInfo.succes == '1' && this.form.method == "RECHARGEMENT" || (this.checkInfo.method == "ACHAT" && this.checkInfo.status == 'Confirmé')){
                        excuteSound("topup")
                        this.form.code = ''
                        this.checkInfo.montant = false

                      }else{
                        excuteSound("connected")
                      }
                      bus.$emit('loadSolde')
                      this.dial = true
                      this.form.method = ''
                      try { loading.close()  } catch (error) {   }

                    }).catch(()=>{

                      try { loading.close()  } catch (error) {   }
                      this.show = false

                      this.checkInfo.message = "حدث خطئ ما يرجى اعادة المحاولة"

                      this.dial = true
                    });
                  }else {
                    try { loading.close()  } catch (error) {   }
                    this.error = "تحقق من رقم التعبئة"

                  }

                }else {
                  try { loading.close()  } catch (error) {   }
                  if(this.form.number.length == 12 && this.activeBtn =='Idoom adsl'){
                    this.error = 'قم بتغيير الخدمة في الاعلى الى Idoom 4G'
                  }else if (this.form.number.length == 9 && this.activeBtn =='Idoom 4g'){
                    this.error = 'قم بتغيير الخدمة في الاعلى  الى Idoom adsl'
                  }else{
                    this.error = "تحقق من رقم الهاتف"
                  }

                }
              }else {
                this.$vs.notification({
                  title:"Activer le compte ",
                  text:"قم بشحن رصيدك لتفعيل الخدمة",
                  classNotification: 'app-regular',
                  duration:3000,
                  color:'danger',
                  icon:`<img class="white-svg"  style="width: 100px" src="assets/icons/vuesax/alert.svg">`
                })
              }


            },
        },

        watch: {
          "form.count":function (){
            this.form.total = this.form.count * this.form.amount
          },
          dialog:function (newVal) {
             if (newVal == true){
               this.form.clear()
             }
          },
            'selectedContact'(newVal){
                if(newVal != null){
                    this.form.number = newVal
                }

            },
            'form.number'(val){
                if(this.form.number.length == 9 && this.form.number[0]=='4'&& this.form.number[1]=='47'){
                    this.error = "قم بكتابة 213 قبل 47 مثلا : 213471112223"
                }
            },
            search (val) {
                this.form.number = val

                this.isLoading = true
                if(val.length >1){
                    axios.get("api/findContact?q=" + val).then(response => {
                        this.contacts = response.data

                    })
                        .catch(() => {});
                }else {
                    this.loadContact()
                }

            },

        },

    };
</script>

<style scoped>
   .v-input .v-input__prepend-inner{
        text-align: center!important;
        margin-top: 12px!important;
    }
    input {
        text-align: center!important;
        display: flex;
        justify-content: center;
    }
    label {
        font-size: 15px!important;
        display: flex;
        justify-content: center;
    }
    .v-text-field {
        color: #fff !important;
        font-size: 23px !important;
        text-align: center;
    }
    .card {
        border-radius: 35px;

        box-shadow: 0 15px 30px -8px rgb(0 0 0 / 8%) !important;
        display: flex;

        padding: 8px 10px;
        transition: all 0.25s ease;
        -webkit-animation: componentAnimate-data-v-3c127e16 15s ease infinite;
        animation: componentAnimate-data-v-3c127e16 15s ease infinite;

        top: 0;
        left: 0;
    }
    .vs-input__label {
        font-size: 15px !important;
    }
    .vs-input__label--placeholder {
        font-weight: 300 !important;
        font-size: 15px !important;
    }
    .vs-input__label--label {
        font-weight: 300 !important;
        font-size: 15px !important;
    }
    .label {
        font-weight: 300 !important;
        font-size: 15px !important;
    }
    .resp-success{
        color: #4CAF50;font-weight: bold;
    }
    input{
        text-align: center!important;
    }
</style>


<style scoped>
    div.vs-table__th__content{
        display: grid;
        justify-content: center;
        font-size: 15px;
    }
    input label .vs-input__label{
        font-size: 15px!important;
    }
    .vs-table__th__content{
        text-align: start!important;
        display: block!important;
    }
    .arabic-rtl{
        direction: initial;
        justify-content: center;
        text-align: center;
        display: inherit;
    }
    .telecom-text-success{
        color: #20B471!important;
    }
    .telecom-text-primary{
        color: #0D407D!important;
    }
    .telecom-bg-success{
        background-color: #20B471!important;
    }
    .telecom-db-primary{
        background-color: #0D407D!important;
    }
    #text-field-edit  {
        text-align: center!important;
        font-size: 20px!important;
        
    }
    #text-field-card  {
        text-align: center;
        font-size: 20px;
      

    }
    #text-field-card::placeholder {
        font-size: 16px;
    }
    .vs-avatar{
        background: transparent!important;
    }
    .new-purple{
        color: #4100f4!important;
    }
    .v-menu__content{
        border-radius: 2px 2px 2px 2px!important;
    }
    .v-select__slot{
        display: inline-block;
        text-align: center!important;
    }
    .v-autocomplete.v-select--is-menu-active .v-input__icon--append .v-input__icon--append {
        transform: none;
    }

    .active-class{
        border: 2px solid #000!important;
    }
    .purple-grad{
    color: #fff!important;
    background: linear-gradient(262deg, rgba(35,125,231,1) 0%, #4100f4 100%);
     border: 1px solid #fff;
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05abe0', endColorstr='#8200f4',GradientType=1 );
    transition-duration:0.6s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    }
    .grey-grad{

        background:#E2E4E7
    }
    .transparent-grad{

        background: transparent!important;

    }
    .selector{

        border-radius:50px;
        transition-duration:0.6s;
        transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);

        background: #05abe0;
        background: -moz-linear-gradient(45deg, #05abe0 0%, #4100f4 100%);
        background: -webkit-linear-gradient(45deg, #05abe0 0%,#4100f4 100%);
        background: linear-gradient(45deg, #05abe0 0%,#4100f4 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#05abe0', endColorstr='#8200f4',GradientType=1 );
    }
    .v-text-field > .v-input__control > .v-input__slot:before {
        border-style: none;
    }

    .disable-btn{
        background:#EEF2F5!important;
        color:#000!important;
    }
    .enable-btn{
        background: #2d2d39 !important;
        color: #e7e7e7 !important;
    }
    .v-messages__wrapper .v-messages__message{
        text-align: center!important;
    }
    .active-b{
        transition: none!important;
        border:2px solid #fff!important;
        border-radius:20px!important;
        color:#fff!important;
    }
.v-dialog__content {
    z-index: 1002 !important;

}
.bottom-sheet-radius{
  border-top-left-radius: 20px!important;
  border-top-right-radius: 20px!important;
}
.vs-loading__load__text{
    font-family:inherit;
}
    /*.v-sheet{*/
    /*    border-radius: 20px!important;*/
    /*}*/
    .rounded-card{
        border-radius:10px!important;
    }
    >>> .v-input__slot::before {
        border-style: none !important;
    }
    >>> .v-input__slot::after {
        border-style: none !important;
    }
     .v-text-field--outlined >>> fieldset {
      border-color: #fff;
    }
    .v-sheet.v-list  {
        padding-top: 0px;
        padding-bottom: 0px;
        border-radius: 8px!important;
        overflow: hidden!important;
        box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
    }
    .v-menu__content--fixed {

        border-radius: 8px!important;
        overflow: hidden!important;
    }
</style>
