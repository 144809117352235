import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VBottomSheet,{staticClass:"rounded-t-lg",attrs:{"dark":_vm.$store.state.dark,"retain-focus":false},model:{value:(_vm.contactsDialog),callback:function ($$v) {_vm.contactsDialog=$$v},expression:"contactsDialog"}},[_c(VSheet,{staticClass:"radius-bottom-sheet",attrs:{"dark":_vm.$store.state.dark,"color":_vm.$store.state.dark?'black-vuesax':''}},[_c(VCardTitle,{staticClass:"app-regular"},[_c('img',{class:_vm.$store.state.dark?'white-svg':'black-svg',staticStyle:{"cursor":"pointer"},attrs:{"src":"assets/icons/vuesax/close-1.svg"},on:{"click":function($event){_vm.contactsDialog = !_vm.contactsDialog}}}),_c(VSpacer),_c('h4',[_vm._v(" إضافة زبون")])],1),_c(VCardText,{staticClass:"vuertl"},[_c(VTextField,{staticClass:"app-regular",attrs:{"outlined":"","placeholder":"الاسم"},model:{value:(_vm.contact.name),callback:function ($$v) {_vm.$set(_vm.contact, "name", $$v)},expression:"contact.name"}},[_c('template',{slot:"append-outer-icon"},[_c('img',{attrs:{"src":"assets/icons/vuesax/users.svg"}})])],2),_c(VTextField,{staticClass:"app-regular",attrs:{"error-messages":_vm.error,"outlined":"","maxlength":"10","placeholder":"رقم الهاتف"},model:{value:(_vm.contact.number),callback:function ($$v) {_vm.$set(_vm.contact, "number", $$v)},expression:"contact.number"}},[_c('template',{slot:"append-outer-icon"},[_c('img',{attrs:{"src":"assets/icons/vuesax/users.svg"}})])],2),_c(VCardActions,{staticClass:"pt-0"},[_c('vs-button',{staticClass:"app-bold",staticStyle:{"font-size":"17px"},attrs:{"dark":!_vm.$store.state.dark,"size":"lg","loading":_vm.contact.busy,"block":""},on:{"click":function($event){return _vm.AjouterContact()}}},[_c('span',{staticClass:"ml-3 white--text app-regular py-2",staticStyle:{"font-size":"18px"}},[_vm._v("إضافة رقم الهاتف")]),_c('img',{staticClass:"white-svg",attrs:{"src":"assets/icons/vuesax/add-user.svg"}})])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }