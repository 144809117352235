import Vue from 'vue'
import store from '../store/index'
import VueRouter from 'vue-router'
import HomeHistorique from '../views/HomeHistoriqueCard.vue'
 import HomeCard from '../views/HomeCard.vue'
import HomeView from '../views/HomeView.vue'
import IdoomView from '../views/IdoomView.vue'
import DashU from "@/layout/DashU";
import * as authUser from "@/plugins/authUser";
import HomeDeposit from "@/views/HomeDeposit";
import HomePrivacyPolicy from "@/views/HomePrivacyPolicy";
import HomeTerms from "@/views/HomeTerms";
import Validation from "@/layout/Validation";
 import ComptBNK from "@/views/ComptBNK";
import TestDash from "@/layout/TestDash";
import HomeSearch from "@/views/HomeSearch";
import HomeHistoriqueIdoom from "@/views/HomeHistoriqueIdoom";
import HomeHistoriqueBonus from "@/views/HomeHistoriqueBonus";
import IdoomInfo from "@/layout/IdoomInfo";
import AdminConfig from "@/admin/AdminConfig";
import ProductsView from "@/admin/ProductsView";
import IdoomProducts from "@/admin/IdoomProducts";
import AddCaroucel from "@/admin/AddCaroucel";
import AddBlog from "@/admin/AddBlog";
import Groupe from "@/admin/Groupe";
import Users from "@/admin/Users";
import HomeMobilePc from "@/views/HomeFlextPc";
import ConfigView from '../views/ConfigView.vue'
import HomeProfile from '../views/HomeProfile.vue'
 import HomeCardInfo from '../views/HomeCardInfo.vue'

 import HistoriqueCartes from '../views/HomeHistoriqueCard'
import Info from '@/layout/ProductInfo'
import Notifications from '@/components/NotifPage'
import HomeBlog from '@/views/HomeBlog'
import HomeBlogDetail from '@/views/HomeBlogDetail'
import HistoriqueFlexy from "@/views/HistoriqueFlexy";
import HomeOptions from "@/views/HomeOptions";
import Check from "@/admin/Check";
import HomeDepotAdmin from "@/admin/HomeDepotAdmin";
import Bonus from "@/admin/UserDetail";
import AdminDetail from "@/admin/AdminDetail";
import Credit from "@/admin/Credit";
import MobilisProducts from "@/admin/MobilisProducts";
import FlexytInfo from "@/layout/FlexytInfo";
import UsersRef from "@/admin/UsersRef";
import SoldeMouvment from "@/admin/SoldeMouvment.vue";
import HomeLogin from "@/layout/HomeLogin.vue";
import Offers from "@/admin/Offers.vue";
import Metas from "@/admin/Metas.vue";
  Vue.use(VueRouter)
const routes = [
  { path: '/info',  name: 'Informations',  component:TestDash ,meta: {isAdmin:false, requiresAuth:true}},
  { path: '/',  name: 'Dashboard',  component:HomeCard ,meta: {isAdmin:false, requiresAuth:false}},
  { path: '/login',  name: 'Login',  component:HomeLogin ,
    meta: {isAdmin:true , requiresAuth:false}},
  { path: '/admin-check',  name: 'Admin Users', component: Check ,meta: { isAdmin:true , requiresAuth:true}},
   { path: '/idoom',  name: 'Idoom', component: HomeHistoriqueIdoom,  meta:{isAdmin:true ,   title:`Idoom` ,requiresAuth:true} },
   {  path: '/historique-cartes',  name: 'Historique Cartes',  component:HistoriqueCartes,  meta:{ isAdmin:false ,  middleware:"guest", title:`Historique Cartes`  ,requiresAuth:true}  },
     {  path: '/historique-bonus',  name: 'Historique Bonus',  component:HomeHistoriqueBonus,  meta:{ isAdmin:false ,  middleware:"guest", title:`Historique Bonus`  ,requiresAuth:true}  },
     // {  path: '/dashboard', name: 'Dashboard',   component: HomeView, meta:{   middleware:"guest",  title:`Login`  }  },
    {  path: '/historique-flexy',  name: 'Historique Flexy',  component:HistoriqueFlexy,  meta:{ isAdmin:false ,  middleware:"guest", title:`Historique Flexy`  ,requiresAuth:true}  },

  { path: '/config',  name: 'Configuration', component: ConfigView, meta: { isAdmin:false , requiresAuth:false} },
   {   path: '/profile',  name: 'Profile',  component: HomeProfile, meta: { isAdmin:false , requiresAuth:true} },
   {   path: '/blog',  name: 'Blog',  component: HomeBlog , meta: { isAdmin:false , requiresAuth:false} },
   {   path: '/home-deposit',  name: 'Deposit',  component: HomeDeposit, meta: {isAdmin:false ,requiresAuth:false} },
   {   path: '/home-account',  name: 'Accounts',  component: ComptBNK , meta: {isAdmin:false ,requiresAuth:false} },
  {   path: '/search',  name: 'Accounts',  component: HomeSearch , meta: { isAdmin:false ,requiresAuth:false} },

  {   path: '/notifications',  name: 'Notifications',  component: Notifications , meta: { isAdmin:false ,requiresAuth:true} },
    ////////////////////// ADMIN ROUTERS   //////////////////////
  {   path: '/admin-users',  name: 'Users',  component: Users, meta: { isAdmin:true ,requiresAuth:true}
    // beforeEnter: (to, from, next) => {if (from.path === "/") {  next()  } else {  next("/")  }  }
    },
  {   path: '/refferal-client',  name: 'Users',  component: UsersRef, meta: { isAdmin:false ,requiresAuth:true}
    // beforeEnter: (to, from, next) => {if (from.path === "/") {  next()  } else {  next("/")  }  }
  },
   {   path: '/admin-groupe',  name: 'GROUPE',  component: Groupe , meta: { isAdmin:true ,requiresAuth:true} },
   {   path: '/admin-offers',  name: 'OFFERS',  component: Offers , meta: { isAdmin:true ,requiresAuth:true} },
   {   path: '/admin-metas',  name: 'OFFERS',  component: Metas , meta: { isAdmin:true ,requiresAuth:true} },
  {   path: '/admin-blog',  name: 'Blog',  component: AddBlog,meta: { isAdmin:true ,requiresAuth:true} },
  {   path: '/admin-deposit',  name: 'Depot',  component: HomeDepotAdmin, meta: {isAdmin:true , requiresAuth:true}  },
  {   path: '/admin-caroucel',  name: 'Caroucel',  component: AddCaroucel,  meta: {isAdmin:true , requiresAuth:true}  },
  { path: '/admin-products',  name: 'Admin Products', component: ProductsView , meta: {isAdmin:true , requiresAuth:true}  },
  { path: '/admin-options',  name: 'Admin Options', component: HomeOptions , meta: {isAdmin:true , requiresAuth:true}
    // beforeEnter: (to, from, next) => {
    //   if (from.path === "/") {  next()  } else {  next("/")  }  }
      },
  { path: '/admin-config',  name: 'Admin Options', component: AdminConfig , meta: {isAdmin:true , requiresAuth:true}
    // beforeEnter: (to, from, next) => {
    //
    //   if (from.path === "/") {  next()  } else {  next("/")  }  }
  },
  { path: '/admin-idoom',  name: 'Idoom Products', component: IdoomProducts , meta: {isAdmin:true , requiresAuth:true} },
  { path: '/admin-mobilis',  name: ' Mobilis Products', component: MobilisProducts ,  meta: {isAdmin:true , requiresAuth:true}  },
  // { path: '/admin-users',  name: 'Admin Users', component: Users },
  { path: '/admin-check',  name: 'Admin Users', component: Check ,  meta: {isAdmin:true , requiresAuth:true} },
  { path: '/admin-dash',  name: 'Admin Dashboard', component: DashU,  meta: {isAdmin:true , requiresAuth:true} },
  { path: '/admin-credit',  name: 'Admin Credit', component: Credit,  meta: {isAdmin:true , requiresAuth:true}  },
  { path: '/admin-movment',  name: 'Admin Credit', component: SoldeMouvment,  meta: {isAdmin:true , requiresAuth:true} },
  { path: '/total-detail',  name: 'Admin Detail', component: AdminDetail ,  meta: {isAdmin:true , requiresAuth:true} },




  { path: '/config',  name: 'Configuration', component: ConfigView,   meta: {isAdmin:false , requiresAuth:false} },
  { path: '/flexy-pc',  name: 'Flexy',  component: HomeMobilePc, meta:{isAdmin:false , middleware:"user", title:`Flexy`,requiresAuth:true  }
   },
   {   path: '/blog',  name: 'Blog',  component: HomeBlog, meta:{ isAdmin:false ,middleware:"guest", title:`Blog`,requiresAuth:false  } },
  // {   path: '/home-deposit'window.user,  name: 'Deposit',  component: HomeDeposit, },
  {   path: '/home-account',  name: 'Accounts',  component: ComptBNK, meta:{ isAdmin:false ,middleware:"guest", title:`Accounts`,requiresAuth:false  } },

  ////////////////////// ADMIN ROUTERS   //////////////////////
   // {  path: '/rechargement',  name: 'Rechargement',  component: DepositView,  meta:{  middleware:"guest",   title:`Login` }
  // },

  {  path: '/privacy-policy',  name: 'PrivacyPolicy', component: HomePrivacyPolicy,  meta:{ isAdmin:false , middleware:"guest",  title:`Privacy Policy`,requiresAuth:false  }  },
  {  path: '/terms',  name: 'Terms', component: HomeTerms,  meta:{ isAdmin:false , middleware:"guest",  title:`Terms` ,requiresAuth:false }  },
    {  path: '/home', name: 'Cards',  component: HomeCard,   meta:{ isAdmin:false , middleware:"guest",  title:`Cards`,requiresAuth:false } },
   {  path: '/historique', name: 'Historique', component: HomeHistorique,  meta:{ isAdmin:false , middleware:"guest", requiresAuth:false }  },
   {  path: '/auth', name: 'Validation', component: Validation,  meta:{ isAdmin:false ,  middleware:"guest",requiresAuth:false  }  },
   // {  path: '/historique-flexy', name: 'Historique Flexy', component: HomeHistoriquePC,  meta:{  middleware:"guest",  }  },
  {
    path: '/:id', name: 'InfoFlexy',  component: FlexytInfo,
    beforeEnter: (to, from, next) => {
      if (from.path === "/flexy-pc" || from.path == '/historique-flexy') {  next()  } else {  next(from)  }  }      , meta:{ middleware:"gest",  title:`Flexy  Info`  ,requiresAuth:true}  },

    {
        path: '/:id', name: 'Info',  component: Info
          , meta:{ isAdmin:false ,middleware:"user",  title:`Card product`  ,requiresAuth:true}  },

  {
    path: '/:id', name: 'IdoomProduct',  component: IdoomInfo,
    beforeEnter: (to, from, next) => {
      if (from.path === "/idoom") {  next()  } else {  next(from)  }  }      , meta:{ middleware:"gest",  title:`Idoom Product`  ,requiresAuth:true}  },


  {  path: '/about', name: 'about',    component: function () {
      // return import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
    } ,meta: {requiresAuth:false }  },
  {
    path: '/:id', name: 'HomeCardInfo',  component: HomeCardInfo,requiresAuth:false,
    beforeEnter: (to, from, next) => {
      if (from.path === "/" || from.path === "/home" || from.path === '/historique-cartes' || from.path === '/admin-groupe' || from.fullPath === '/search') {  next()  } else {  next(from)  }  }      , meta:{ middleware:"gest",  title:`Product`  }
  },
    {
      path: '/:id', name: 'HomeCardInfo',  component: HomeCardInfo,requiresAuth:false,
      beforeEnter: (to, from, next) => {
        if (from.path === "/" || from.path === "/home" || from.path === '/historique-cartes' || from.path === '/admin-groupe' || from.fullPath === '/search') {  next()  } else {  next(from)  }  }      , meta:{ middleware:"gest",  title:`Product`  }  },
  {
    path: '/:slug', name: 'HomeBlogDetail',  component: HomeBlogDetail,
    meta:{ middleware:"gest",  title:`Blog Detail`,  meta:{requiresAuth:false }}  },
  {
    path: '/:id', name: 'ProfileHistorique',  component: Bonus,requiresAuth:true,
    beforeEnter: (to, from, next) => {
      if ( from.path === "/admin-users") {  next()  } else {  next(from)  }  }
    , meta:{ isAdmin:false , middleware:"admin",  title:`Profile Historique`  }
      },


  {
    path: '/:name', name: 'HomeCardInfoSearch',  component: HomeCardInfo,
    beforeEnter: (to, from, next) => {
      if (from.path === "/" || from.path === "/home") {  next()  } else {  next(from)  }  }      , meta:{ middleware:"gest",  title:`Product`,requiresAuth:false  }  },


    {   path: '/:pathMatch(.*)*', component: ()=> import(/* webpackPrefetch: true */'@/components/comp-tool/NotFound.vue'),

},
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return window.scrollTo(0, 0)
  },

})
// router.beforeEach((to, from, next) => {
//
//    if (to.matched.some(record => record.meta.requiresAuth)) {
//
//      if (!isRealToken()) {
//
//       next({
//         path: "/",
//         params: { nextUrl: to.fullPath },
//       });
//     } else {
//        if (!store.state.auth.authenticated ) {
//         next({
//           path: "/login",
//           params: { nextUrl: from.fullPath },
//         });
//       } else {
//          if(to.matched.some(record => record.meta.isAdmin)){
//              console.log(store.state.auth.user.type)
//            if(store.state.auth.user.type == 'admin'){
//              next();
//            }else {
//              next({
//                path: "/",
//                params: { nextUrl: from.fullPath },
//              });
//            }
//          }else {
//            if(getDecryptedProfile()){
//              next();
//            }else {
//              next({
//                path: "/login",
//
//              });
//            }
//          }
//
//       }
//     }
//
//   } else {
//        next();
//   }
// });

export default router
