<template>
    <v-card   :dark="$store.state.dark" tile  style="border-radius: 15px!important;" class="card-sharow my-1 mx-1 " >
        <v-list class="py-0">
            <v-list-item     dense     >
                <v-list-item-avatar>
                    <vs-avatar  size="45px">
                        <img style="width: 25px" src="assets/icons/vuesax/money.svg">
                    </vs-avatar>

                </v-list-item-avatar>
                <v-list-item-content class="py-0">
                    <v-list-item-title class="mb-1"   >
                        <span style="font-size: 16px;font-weight: bold;font-family: sans-serif" class="  ">Normal</span>
                    </v-list-item-title>
                    <v-list-item-title>
                        <v-list-item-subtitle class="text-nowrap"  >
                            <span style="font-size: 16px;font-weight: bold; font-family: sans-serif"  > 0676578055 </span>
                        </v-list-item-subtitle>
                    </v-list-item-title>

                </v-list-item-content>

                <v-list-item-content class="py-0">


                    <v-list-item-title   >
                        <span class="text-h6" style="font-weight: bold;font-size: 15px;font-family: sans-serif">{{ '1500 '  }}</span>

                        <v-list-item-subtitle>
                            10:66 12/10/2023
                        </v-list-item-subtitle>
                    </v-list-item-title>

                </v-list-item-content>

            </v-list-item>
        </v-list>
    </v-card>
</template>

<script>
    export default {
        name: "TableChecks"
    }
</script>

<style scoped>

</style>