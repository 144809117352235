
export default {
    namespaced: true,
    state:{

        navbar: [],
        sidbar: [
            {title:"المتجر",icon:'assets/icons/vuesax/home.svg',link:'/',isAdmin:"new",items:null},
            {title:"المدونة",icon:'assets/icons/vuesax/blog.svg',link:'/blog',isAdmin:"new",items:null},


        ],
    },
    getters:{
        sidbar(state){
            return state.sidbar
        },
        navbar(state){
            return state.navbar
        },

 
    },
    mutations:{

        SET_SIDBARITEMS (state, value) {
            state.navbar = value.navbar
            if(value.sidbar != undefined){
                state.sidbar = value.sidbar
            }

        },
     
    },
  
}