<template>
    <v-card   flat :color="$store.state.dark?'app-bg':''" class="app-mb-10 vuertl">

        <v-container v-if="current_page >0" >
            <TitlePages title="الاربــاح ">
                <v-app-bar-nav-icon slot="btn"  @click="$router.go(-1)"   large   style="float: left!important;" icon depressed>
                    <img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-app-bar-nav-icon>
            </TitlePages>

           <v-row  >
               <v-col class="col-12">
                   <vs-alert :page.sync="page" closable   class="rounded-xl"    primary>
                       <template #icon>
                           <v-img
                                   class="img-border-bottom"
                                   height="40px"  width="40px"  src="assets/notifications/bonus.png" ></v-img>
                       </template>
                       <template #title>
                           تحويل الارباح
                       </template>

                       <template #page-1>
                           1 - إذا كنت تريد تحويل الارباح فقم بالضغط على حسابي
                       </template>

                       <template #page-2>
                          2 -  قم بالضغط على زر تحويل الارباح
                       </template>

                       <template #page-3>
                           3 - قم بالضغط على تحويل الارباح واتبع التعليمات
                       </template>

                   </vs-alert>
               </v-col>
            <v-col  class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 "  v-for="(item,index) in historiqueData" :key="index" >
                <v-card  flat  ripple      style="max-height:  90px;cursor: pointer" class=" rounded-xl" :color="$store.state.dark?'app-card':'app-card-light'">
                <v-card-title style="margin-right: 60px;" class="  py-0 app-regular" >

                    <v-spacer></v-spacer>
                    <h3 class="success--text" style="font-family: sans-serif" >{{ formatToPrice(item.bonus) }}</h3>
                    <v-spacer></v-spacer>
                    <v-card style="position: absolute; right: 0;top: 0;z-index: 1;"  class="mr-n1 mt-n3 overflow-hidden " rounded="lg" height="50" width="50" >
                  <v-img
                    class="img-border-bottom"
                    height="100%"  width="100%"  src="assets/notifications/bonus.png" ></v-img>
                </v-card>
            </v-card-title>
            <v-card-title style="margin-right: 60px;    font-size: 17px;" class="pt-0">
                <span   style="direction: ltr;font-size: 16px;" class="app-regular mr-4"
                  > {{item.created_at | datee}}</span>
                    <v-spacer></v-spacer>
                <span   ><b v-text="item.method"></b></span>
            </v-card-title>
           </v-card>
            </v-col>
           </v-row>
            <infinite-loading  @infinite="infiniteHandler"  >
                <div :style="{marginTop:!historiqueData.length?'25vh':''}"  class="mb-5 app-medium"  slot="spinner" :class="$store.state.params.color">جارٍ البحث</div>
                <div     slot="no-more" class="app-medium" :class="[$store.state.params.color]"  >لا يوجد المزيد</div>
                <div    class="mb-5 app-medium"  slot="no-results" :class="$store.state.params.color">

                    <v-card style="margin-top: 25vh;"  class="center" :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''">
                        <v-card-title class="pb-1"  >
                            <v-spacer></v-spacer>
                            <img style="width: 90px" src="assets/notifications/bonus.png">
                            <v-spacer></v-spacer>

                        </v-card-title>
                        <h4 class="app-bold text-center" v-text="user!=null?'لا يوجد أي أرباح':'قم بتسجيل الدخول'"> </h4>

                    </v-card>

                </div>

            </infinite-loading>
        </v-container>


    </v-card>
</template>

<script>
import axios from 'axios';
 import StatusComp from '@/components/comp-tool/StatusComp.vue';
import { formatToPrice ,iconResponse ,colorSvg} from '@/tools/Services';
import TitlePages from "@/layout/TitlePages";
    export default {
        components:{TitlePages, StatusComp},
        data(){
            return{
                page: 1,
                show:false,
                current_page:1,
                historiqueData:[],
            }
        },
        computed:{
          user:function () {
            return this.$store.state.auth.user
          }
        },
        methods:{

            formatToPrice,iconResponse,colorSvg,
            getHistoriqueData($state){
                axios.get('api/bonus-request', {page: this.current_page}
          ).then((response)=>{
                    if(response.data.data.length >0){
                        this.current_page +=1    
                        this.historiqueData.push(...response.data.data);
                        
                    } else{
                        $state.complete();
                    }
                    this.show = true
                }).catch(()=>{
                    $state.complete();
                }) 
            },
            infiniteHandler($state){

                if(this.user!=null){
                    axios.get('api/bonus-request?page='+this.current_page
                    ).then((response)=>{

                        if(response.data.data.length >0){
                            this.current_page +=1

                            this.historiqueData.push(...response.data.data)
                            $state.loaded()
                        }else{
                            $state.complete();
                        }
                        this.show = true
                    }) .catch(()=>{
                        $state.complete();
                    })
                }else { $state.complete();}

            }
        },

    }
</script>

<style lang="scss" scoped>
.v-ripple-container{
    z-index: 10!important;
}
.v-card--link:before {
    background: none!important;
}
</style>