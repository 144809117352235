import { VCard } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{staticClass:"card-sharow my-1 mx-1",staticStyle:{"border-radius":"15px!important"},attrs:{"dark":_vm.$store.state.dark,"tile":""}},[_c(VList,{staticClass:"py-0"},[_c(VListItem,{attrs:{"dense":""}},[_c(VListItemAvatar,[_c('vs-avatar',{attrs:{"size":"45px"}},[_c('img',{staticStyle:{"width":"25px"},attrs:{"src":"assets/icons/vuesax/money.svg"}})])],1),_c(VListItemContent,{staticClass:"py-0"},[_c(VListItemTitle,{staticClass:"mb-1"},[_c('span',{staticClass:"",staticStyle:{"font-size":"16px","font-weight":"bold","font-family":"sans-serif"}},[_vm._v("Normal")])]),_c(VListItemTitle,[_c(VListItemSubtitle,{staticClass:"text-nowrap"},[_c('span',{staticStyle:{"font-size":"16px","font-weight":"bold","font-family":"sans-serif"}},[_vm._v(" 0676578055 ")])])],1)],1),_c(VListItemContent,{staticClass:"py-0"},[_c(VListItemTitle,[_c('span',{staticClass:"text-h6",staticStyle:{"font-weight":"bold","font-size":"15px","font-family":"sans-serif"}},[_vm._v(_vm._s('1500 '))]),_c(VListItemSubtitle,[_vm._v(" 10:66 12/10/2023 ")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }