<template>
  <v-expand-transition mode="in-out">
    <v-footer :dark="$store.state.dark"
              :class="$store.state.dark?'bg-dark':'bg-light'"
              :style="{marginLeft:$store.state.open.side && $vuetify.breakpoint.mdAndUp?'220px':'',transition:'0.2s'}"
    >
      <v-row
          justify="center" class="align-content-center  "

      >
        <v-col class="col-12 pa-8">
          <v-row justify="center">

            <v-col class="col-lg-3" v-if="$vuetify.breakpoint.lgAndUp">
              <v-row justify="center" >
                <v-col class="col-12 d-flex justify-end">
                  <h3 class="app-bold  grey--text "> طرق الدفع</h3>

                </v-col>
                <v-col class="col-12 d-flex justify-end">
                  <h5 class="app-bold  ">
                    يتم الدفع عن طريق شحن محفظة المنصة
                  </h5>
                </v-col>
                <v-col class="col-12 d-flex justify-end">
                  <h5>
                    <a :class="handelClass"  @click="$router.push('/home-deposit')">يمكنك الاطلاع على حساباتنا البنكية</a>
                  </h5>
                </v-col>


              </v-row>
            </v-col>
            <v-col class="col-lg-3 col-sm-12 col-12">
              <v-row :justify="$vuetify.breakpoint.lgAndUp?'end':'center'" align="center">
                <v-col class="col-12 d-flex " :class="$vuetify.breakpoint.lgAndUp?'justify-end':'justify-center'">
                  <h3 class="app-bold  grey--text ">سوشل ميديا</h3>
                </v-col>
                <v-btn icon >
                  <img style="width: 23px" :class="$store.state.dark?'black-svg':'white-svg'" src="assets/icons/media/telegram.svg">
                </v-btn>
                <v-btn icon class="mx-4">
                  <img style="width: 23px" :class="$store.state.dark?'black-svg':'white-svg'"  src="assets/icons/media/instagram.svg">

                </v-btn>
                <v-btn icon>
                  <img style="width: 23px" :class="$store.state.dark?'black-svg':'white-svg'"  src="assets/icons/media/telegram.svg">

                </v-btn>

              </v-row>
            </v-col>
            <v-col class="col-lg-3 col-sm-12 col-12">
              <v-row :justify="$vuetify.breakpoint.lgAndUp?'end':'center'" align="center">
                <v-col class="col-12 d-flex  "  :class="$vuetify.breakpoint.lgAndUp?'justify-end':'justify-center'">
                  <h3 class="app-bold  grey--text "> روابط مفيدة</h3>

                </v-col>
                <v-col class="col-12 d-flex  "  :class="$vuetify.breakpoint.lgAndUp?'justify-end':'justify-center'">
                  <h5>
                    <a :class="handelClass"  @click="$router.push('/')">شحن البطاقات</a>
                  </h5>
                </v-col>
                <v-col class="col-12 d-flex "  :class="$vuetify.breakpoint.lgAndUp?'justify-end':'justify-center'">
                  <h5>
                    <a :class="handelClass"  @click="$router.push('/privacy-policy')">سياسة الخصوصية</a>
                  </h5>
                </v-col>
                <v-col class="col-12 d-flex "  :class="$vuetify.breakpoint.lgAndUp?'justify-end':'justify-center'">
                  <h5>
                    <a :class="handelClass"  @click="$router.push('/terms')">الشروط و الاحكام</a>
                  </h5>
                </v-col>


              </v-row>
            </v-col>
            <v-col class="col-lg-3 col-sm-12 col-12">
              <v-card color="transparent elevation-0 py-0">
                <v-card-title  >
                  <v-spacer></v-spacer>
                  <img style="height: 80px" src="assets/img/logo.svg">
                  <v-spacer></v-spacer>
                </v-card-title>
                <v-card-text style="display: block;text-align: center" class="pt-1">
                  <h3>{{$store.state.App.config.AppName}}</h3>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>


      </v-row>
    </v-footer>
  </v-expand-transition>
</template>

<script>
    export default {
        name: "Footer",
      computed:{
        handelClass(){
          if(this.$store.state.dark){
            return 'grey--text app-bold '
          }else{
            return "black--text app-bold"
          }
        } ,
      },
        data: () => {
            return {

            };
        },
    }
</script>

<style>
.decoration-css{
  text-decoration: none;
  color: #fff;
}
</style>