<template>
    <v-row>
        <v-col cols="12" >
            <v-toolbar flat color="transparent" class="mt-n5">
                <v-toolbar-title ><b>Historique Bonus</b></v-toolbar-title>

            </v-toolbar>
            <v-card  :color="$store.state.dark?'#141417':'#ebecf0'" to="/idoom"  link :dark="$store.state.dark" class="rounded-lg   mt-n3" flat>
                <h5 class=" ml-2 pa-1">Total Bonus</h5>
                <v-list class="mt-n2" color="transparent"  dense v-if="bonuses.length <0">
                    <v-list-item link
                            v-for="bonus in bonuses"
                            :key="bonus.id"
                    >
                        <v-list-item-avatar>
                            <img src="assets/notifications/historique-bonus.png" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title
                                    v-text="bonus.method"
                                    class=" "
                            ></v-list-item-title>
                            <v-list-item-subtitle
                                    v-text="bonus.updated_at"
                                    class="grey--text"
                            ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <h4 class=" success--text" >{{ '+ '+ formatToPrice(bonus.bonus)}}</h4>

                        </v-list-item-action>
                    </v-list-item>
                </v-list>
                <v-list v-else class="mt-n2" color="transparent"  dense  >
                    <v-list-item link
                                 v-for="(bonus,index) in 2"
                                 :key="index"
                    >
                        <v-list-item-avatar>
                            <img src="assets/notifications/historique-bonus.png" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title
                                    v-text="index==0?'Flexy':'Idoom'"

                            ></v-list-item-title>
                            <v-list-item-subtitle
                                    v-text="'Date de transaction'"
                                    class="grey--text"
                            ></v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <h4 class=" success--text" >{{ '+ 0.00' }}</h4>

                        </v-list-item-action>
                    </v-list-item>
                </v-list>
            </v-card>
            <infinite-loading  @infinite="infiniteHandler"  >
                 <div     slot="no-more" class="app-medium" :class="[$store.state.params.color]"  >

                </div>
                <div    class="mb-5 app-medium"  slot="no-results" :class="$store.state.params.color">
                </div>

            </infinite-loading>
        </v-col>
    </v-row>
</template>

<script>
    import axios from "axios";
    import {formatToPrice} from "@/tools/Services";

    export default {
        data: () => ({
            current_page:1,

            bonuses:[],

        }),
        methods:{formatToPrice,
            infiniteHandler($state){

                if(this.$store.state.auth.user!=null){
                    axios.get('api/bonus-request?page='+this.current_page
                    ).then((response)=>{

                        if(response.data.data.length >0){
                            this.current_page +=1

                            this.bonuses.push(...response.data.data)
                            $state.loaded()
                        }else{
                            $state.complete();
                        }
                        this.show = true
                    }) .catch(()=>{
                        $state.complete();
                    })
                }else { $state.complete();}

            }
        },
        mounted() {

        }
    };
</script>
<style></style>