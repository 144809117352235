<template>
  <v-card

      class="vuertl" flat   :color="dark?'app-bg':'app-bg-light'">

      <v-card style=""  flat  :color="dark?'app-bg':'app-bg-light'"   >

        <v-card   class="center" :dark="$store.state.dark" flat style="margin-top: 35vh;"   :color="dark?'app-bg':'app-bg-light'"   >

          <v-card-title class="py-0 " >
            <v-spacer></v-spacer>
            <vs-avatar   style="cursor: pointer"    circle size="120"    >
              <img style="width:80px;height:80px" src="/logoxl.png" alt="DZtechno"  >
            </vs-avatar>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-card-title class="pb-1"  >
            <v-spacer></v-spacer>
            <h1 class="app-bold blue-grey--text text--darken-2 ">الدخول </h1>
            <v-spacer></v-spacer>
          </v-card-title>
          <h5 class="app-bold mt-2 blue-grey--text" style="text-align: center;display: block"> قم بإدخال رقم الهاتف و كلمة المرور </h5>
          <v-card-title class="pb-1">
            <v-spacer></v-spacer>
            <vs-button @click="sheet = !sheet" warn    :dark="$store.state.dark"
                       :class="$store.state.dark?'white--text':'black--text'"
                       class="app-bold tik  mx-auto px-7 white--text py-0" size="small"  ><span style="font-size: 18px">دخول</span>
              <img style="width: 18px;"  class="white-svg mr-3"   src="assets/icons/vuesax/users.svg"/>
            </vs-button>
             <v-spacer></v-spacer>
          </v-card-title>

        </v-card>
      </v-card>

  


      <vs-dialog   not-close ref="login " :blur="registerInfo.busy" auto-width prevent-close not-padding class="px-1 blur" v-model="sheetTow" :dark="dark">
        <v-sheet max-width="650"
                 :color="dark?'dashboard':'app-bg-light'" :dark="dark"
                 class="text-center bottom-sheet-radius-all vuertl"


        >
          <v-container>

            <v-btn @click="sheetTow = !sheetTow,sheet = !sheet, openEye = false" style="float: left!important;" icon depressed>
              <img class="mt-3"
                   :class="dark?'white-svg':'black-svg'"
                   src="assets/icons/vuesax/chevron-right.svg"/></v-btn>

            <p style="text-align: right;font-size: 22px " class="app-medium mt-3 mb-0"
               :class="dark?'white--text':'black--text'"
               v-text="mode == 'login'?'الدخول برقم الهاتف':'إنشاء حساب جديد'"></p>

            <v-card-text v-if="mode == 'login' && !nextSteep" class="pt-0 px-0" ref="target"
                         style="border-radius: 19px!important;">
              <v-form   @submit.prevent="login">
                <v-row dense>



                  <v-col class="col-12">
                    <p style="text-align: right " :class="dark?'white--text':'black--text'"
                       class="mb-2 app-regular">رقم الهاتف</p>

                    <v-text-field v-model="loginInfo.login" type="number"  filled hide-details solo flat
                                  class="app-regular rounded-lg input-class overflow-hidden"
                                  :class="dark?'white--text':'black--text'"
                                  :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                  placeholder="رقم الهاتف">

                      <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/mobile.svg" >


                    </v-text-field>

                    <p style="text-align: right" :class="[params.font,dark?'white--text':'black--text']"
                       class="mb-2">كلمة المرور</p>

                    <v-text-field  v-model="loginInfo.password" :type="openEye?'password':'text'" filled hide-details solo flat
                                   class="app-regular rounded-lg input-class overflow-hidden"
                                   :class="dark?'white--text':'black--text'"
                                   :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                   placeholder="أدخل كلمة المرور">


                      <img style="cursor: pointer" slot="prepend-inner" @click="openEye = !openEye"  :class="dark?'white-svg':'black-svg'" class=" mx-3" :src="openEye?'assets/icons/vuesax/eye-open.svg':'assets/icons/vuesax/eye-closed.svg'" >

                    </v-text-field>

                  </v-col>
                  <v-col class="col-12 py-0 d-flex  justify-center">
                    <vue-recaptcha
                        :class="{ 'is-invalid': loginInfo.errors.has('recaptcha') }"
                        v-model="loginInfo.recaptcha"
                        ref="recaptchaLog"
                        @verify="onVerifyLo"
                        :sitekey="sitekey"
                        class="g-recaptcha my-2 "
                    ></vue-recaptcha>
                  </v-col>
                  <v-col class="col-12 py-0">
                    <vs-button :active-disabled="!passeCaptchaLog"  block type="submit"
                                 color="rgb(254, 44, 85)" class=" app-regular rounded-md"
                                 style="height: 42px;font-size: 17px">
                      <strong> الدخول</strong>
                      <img class="white-svg mx-3" src="assets/icons/vuesax/send.svg">
                    </vs-button>



                  </v-col>
                  <v-col class="col-12" :class="params.font">
                    <p class="pb-0 mb-0" :class="dark?'grey--text':''"
                       style="text-align: center;font-size: 13px;"> نست كلمة المرور <a @click="switchDialog('resetEmail')" class="orange--text text-decoration-underline">إستعادة حسابي</a></p>
                  </v-col>

                </v-row>
              </v-form>
            </v-card-text>

            <v-card-text v-if="mode == 'register' && !nextSteep" class="py-0 px-0" ref="target"
                         style="border-radius: 19px!important;">
              <v-row dense class="justify-center">
                <v-col class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <p style="text-align: right" :class="[params.font,dark?'white--text':'black--text']"
                     class="mb-2">رقم الهاتف</p>

                  <v-text-field   v-model="registerInfo.phone" type="text" filled hide-details
                                  class="app-regular rounded-lg input-class" solo flat
                                  :class="dark?'white--text':'black--text'"
                                  :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                  placeholder="رقم الهاتف">
                    <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/mobile.svg" >

                  </v-text-field>

                </v-col>
                <v-col class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <p style="text-align: right" :class="[params.font,dark?'white--text':'black--text']"
                     class="mb-2">إسم المستخدم</p>
                  <v-text-field  v-model="registerInfo.username" type="text" filled hide-details
                                 class="app-regular rounded-lg input-class" solo flat
                                 :class="dark?'white--text':'black--text'"
                                 :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                 placeholder="سم المستخدم">
                    <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/user-securite.svg" >

                  </v-text-field>

                </v-col>
                <v-col class="col-12 ">
                  <p style="text-align: right" :class="[params.font,dark?'white--text':'black--text']"
                     class="mb-2">البريد الالكتروني</p>

                  <v-text-field  v-model="registerInfo.email" type="email" filled hide-details
                                 class="app-regular rounded-lg input-class" solo flat
                                 :class="dark?'white--text':'black--text'"
                                 :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                 placeholder="البريد الالكتروني">
                    <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/email.svg" >

                  </v-text-field>

                </v-col>
                <v-col class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <p style="text-align: right" :class="[params.font,dark?'white--text':'black--text']"
                     class="mb-2">كلمة المرور</p>

                  <v-text-field  v-model="registerInfo.password" :type="openEye?'password':'text'" filled solo flat
                                 hide-details class="app-regular rounded-lg input-class"
                                 :class="dark?'white--text':'black--text'"
                                 :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                 placeholder="كلمة السر">
                    <img style="cursor: pointer" slot="prepend-inner" @click="openEye = !openEye"  :class="dark?'white-svg':'black-svg'" class=" mx-3" :src="openEye?'assets/icons/vuesax/eye-open.svg':'assets/icons/vuesax/eye-closed.svg'" >

                  </v-text-field>

                </v-col>
                <v-col class="col-12 col-lg-6 col-md-6 col-sm-12 col-xs-12">
                  <p style="text-align: right" :class="[params.font,dark?'white--text':'black--text']"
                     class="mb-2">إعادة كلمةالمرور</p>

                  <v-text-field  v-model="registerInfo.confirmation_password" :type="openEye?'password':'text'" solo flat
                                 filled hide-details class="app-regular rounded-lg input-class"
                                 :class="dark?'white--text':'black--text'"
                                 :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                 placeholder="إعادة كلمة المرور">
                    <img style="cursor: pointer" slot="prepend-inner" @click="openEye = !openEye"  :class="dark?'white-svg':'black-svg'" class=" mx-3" :src="openEye?'assets/icons/vuesax/eye-open.svg':'assets/icons/vuesax/eye-closed.svg'" >

                  </v-text-field>

                </v-col>
                <v-col class="col-12 d-flex  justify-center">
                  <vue-recaptcha
                      :class="{ 'is-invalid': registerInfo.errors.has('recaptcha') }"
                      v-model="registerInfo.recaptcha"
                      ref="recaptchaReg"
                      @verify="onVerifyReg"
                      :sitekey="sitekey"
                      class="g-recaptcha my-2 "
                  ></vue-recaptcha>
                </v-col>
                <v-col class="col-12">
                  <vs-button :active-disabled="!passeCaptchaReg"  elevation="0" :loading="registerInfo.busy" @click="register()" block
                              color="rgb(254, 44, 85)" class=" app-regular rounded-md"
                              style="height: 42px;font-size: 17px">
                    <strong>فتح حساب</strong>
                    <img class="white-svg mx-3" src="assets/icons/vuesax/send.svg">
                  </vs-button>
                </v-col>
                <v-col class="col-12" :class="params.font">
                  <p class="pb-0 mb-0" :class="dark?'grey--text':''"
                     style="text-align: center;font-size: 13px;"> مع إتمامك للتسجيل أنت توافق عل <a    @click="switchDialog" class="orange--text text-decoration-underline">الشروط
                    و الاحكام</a></p>
                </v-col>

              </v-row>
            </v-card-text>
            <v-card-text v-if="nextSteep" class="py-0" ref="target"
                         style="border-radius: 19px!important;">
              <v-row dense class="justify-center">
                <v-col class="col-12">
                  <v-card-title class="my-0">
                    <vs-avatar class="mx-auto">
                      <img :src="emailProdider.avatar">
                    </vs-avatar>
                  </v-card-title>
                  <v-card-subtitle style="text-align: center" class="app-regular for-avatar"
                                   v-text="emailProdider.name"></v-card-subtitle>

                </v-col>
                <v-col class="col-12">
                  <h4 class="app-regular text-center mb-2" style="display: block">أدخل رقم الهاتف</h4>
                  <v-text-field style="    font-size: 19px;" maxlength="10" minlength="10" solo flat
                                v-model="emailProdider.phone" type="text" filled hide-details
                                class="app-regular rounded-lg input-class"
                                :class="dark?'white--text':'black--text'"
                                :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                placeholder="أدخل رقم الهاتف"></v-text-field>

                </v-col>

                <v-col class="col-12">
                  <vs-button  elevation="0" :loading="emailProdider.busy"
                              @click="registerProvider()" block
                              color="rgb(254, 44, 85)" class="rounded-lg app-regular"
                              style="height: 46px;">
                    <strong style="font-size: 17px">تسجيل </strong>
                    <img class="white-svg mx-3" src="assets/icons/vuesax/send.svg">
                  </vs-button>
                </v-col>
                <v-col class="col-12">

                  <v-alert  class="app-regular" dense text type="error"
                            v-if="error!=''" v-text="error">
                    <template slot="prepend">
                      <img class="white-svg" src="assets/icons/vuesax/close-1.svg">
                    </template>
                  </v-alert>

                </v-col>
              </v-row>
            </v-card-text>


          </v-container>
        </v-sheet>
      </vs-dialog>



      <vs-dialog prevent-close blur not-padding not-close overflow-hidden  v-if="$vuetify.breakpoint.mdAndUp"  :max-width="$vuetify.breakpoint.mdAndUp?'550':''"

                 class="px-1  "  v-model="sheet" :inset="$vuetify.breakpoint.mdAndUp" >
        <v-card :color="dark?'dashboard':'app-bg-light'" :dark="dark" elevation="0"
                class="text-center rounded-xl  "
                :class="$vuetify.breakpoint.mdAndUp?'rounded-lg':'bottom-sheet-radius'"  >
          <v-card-title class="py-0 pr-0 mt-25 pl-0" v-if="!$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <vs-avatar class="mt-n7"     circle size="60"  >
              <img style="width:35px;height:35px" src="/assets/img/logo.svg" alt="DZtechno"  >
            </vs-avatar>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-container class="py-0 pt-2  ">
            <v-btn @click="sheet = !sheet" style="float: left!important;" icon depressed>
              <img  :class="dark?'white-svg':'black-svg'"   src="assets/icons/vuesax/close-1.svg"/>
            </v-btn>
            <h2 :class="[params.font,dark?'white--text':'black--text']" style="text-align: right">  الدخول</h2>
            <v-card-title class="vuertl pb-0 pt-2" :class="params.font">
              <p class="mb-0" :class="dark?'grey--text':''" style="font-size:14px">  نسر بتواجدك معنا إختر طريقة الدخول </p>
            </v-card-title>
            <v-container>
              <v-row dense class="justify-center">
                <v-col class="col-6">
                  <v-card flat class="rounded-lg overflow-hidden" style="cursor: pointer;"
                          @click.stop="HandleClose('register')"
                          :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                    <v-card-title style="display:block" class="  mx-auto pb-1">
                      <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/mail.svg"/>
                    </v-card-title>
                    <p class="app-medium" :class="dark?'white--text':'black--text'">فتح حساب</p>
                  </v-card>
                </v-col>
                <v-col class="col-6">
                  <v-card flat class="rounded-lg overflow-hidden" style="cursor: pointer;"
                          @click.stop="HandleClose('login')"
                          :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                    <v-card-title style="display:block" class="  mx-auto pb-1">
                      <img :class="dark?'white-svg':'black-svg'"
                           src="assets/icons/vuesax/mobile.svg"/>
                    </v-card-title>
                    <p class="app-medium" :class="dark?'white--text':'black--text'"> تسجيل الدخول</p>

                  </v-card>
                </v-col>
                <v-col class="col-12">
                  <v-card flat class="rounded-lg overflow-hidden" style="cursor: pointer;"
                          @click="AuthProvider('google')"
                          :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                    <v-card-title style="display:block" class="  mx-auto pb-0 pt-1">
                      <img width="35px"
                           src="assets/images/gmail.svg"/>
                    </v-card-title>
                    <p class="app-medium" :class="dark?'white--text':'black--text'">البريد الالكتروني</p>

                  </v-card>
                </v-col>
                <v-col class="col-12" :class="params.font">
                  <p :class="dark?'grey--text':''"
                     style="text-align: center;font-size: 13px;"> مع إتمامك للتسجيل أنت توافق عل <a
                      @click="switchDialog"   class="orange--text text-decoration-underline">الشروط و
                    الاحكام</a></p>
                </v-col>
              </v-row>
            </v-container>

          </v-container>
        </v-card>
      </vs-dialog>
      <v-bottom-sheet    v-if="$vuetify.breakpoint.smAndDown"


                         class="px-1 blur   " v-model="sheet" :inset="$vuetify.breakpoint.mdAndUp" >
        <v-card :color="dark?'dashboard':'app-bg-light'" :dark="dark"
                class="text-center  bottom-sheet-radius "
                :class="$vuetify.breakpoint.mdAndUp?'rounded-lg':'bottom-sheet-radius'"  >
          <v-card-title class="py-0 pr-0 mt-25 pl-0" v-if="!$vuetify.breakpoint.mdAndUp">
            <v-spacer></v-spacer>
            <vs-avatar class="mt-n7"     circle size="60"  >
              <img style="width:35px;height:35px" src="/assets/img/logo.svg" alt="DZtechno"  >
            </vs-avatar>
            <v-spacer></v-spacer>
          </v-card-title>
          <v-container class="py-0 pb-n2 mt-n5 ">
            <v-btn @click="sheet = !sheet" style="float: left!important;" icon depressed>
              <img  :class="dark?'white-svg':'black-svg'"   src="assets/icons/vuesax/close-1.svg"/>
            </v-btn>
            <h2 :class="[params.font,dark?'white--text':'black--text']" style="text-align: right">  الدخول</h2>
            <v-card-title class="vuertl pb-0 pt-2" :class="params.font">
              <p class="mb-0" :class="dark?'grey--text':''" style="font-size:14px">  نسر بتواجدك معنا إختر طريقة الدخول </p>
            </v-card-title>
            <v-container>
              <v-row dense class="justify-center">
                <v-col class="col-6">
                  <v-card flat class="rounded-lg overflow-hidden" style="cursor: pointer;"
                          @click.stop="HandleClose('register')"
                          :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                    <v-card-title style="display:block" class="  mx-auto pb-1">
                      <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/mail.svg"/>
                    </v-card-title>
                    <p class="app-medium" :class="dark?'white--text':'black--text'">فتح حساب</p>
                  </v-card>
                </v-col>
                <v-col class="col-6">
                  <v-card flat class="rounded-lg overflow-hidden" style="cursor: pointer;"
                          @click.stop="HandleClose('login')"
                          :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                    <v-card-title style="display:block" class="  mx-auto pb-1">
                      <img :class="dark?'white-svg':'black-svg'"
                           src="assets/icons/vuesax/mobile.svg"/>
                    </v-card-title>
                    <p class="app-medium" :class="dark?'white--text':'black--text'"> تسجيل الدخول</p>

                  </v-card>
                </v-col>
                <v-col class="col-12">
                  <v-card flat class="rounded-lg overflow-hidden" style="cursor: pointer;"
                          @click="AuthProvider('google')"
                          :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                    <v-card-title style="display:block" class="  mx-auto pb-0 pt-1">
                      <img width="35px"
                           src="assets/images/gmail.svg"/>
                    </v-card-title>
                    <p class="app-medium" :class="dark?'white--text':'black--text'">البريد الالكتروني</p>

                  </v-card>
                </v-col>
                <v-col class="col-12" :class="params.font">
                  <p :class="dark?'grey--text':''"
                     style="text-align: center;font-size: 13px;"> مع إتمامك للتسجيل أنت توافق عل <a
                      @click="switchDialog"   class="orange--text text-decoration-underline">الشروط و
                    الاحكام</a></p>
                </v-col>
              </v-row>
            </v-container>

          </v-container>
        </v-card>
      </v-bottom-sheet>
      <vs-dialog overflow-hidden blur v-model="dialogSupport" not-padding class="px-1">
        <v-sheet :color="dark?'black-vuesax':'app-bg-light'" :dark="dark"
                 class="text-center  rounded-xl"
                 :class="component=='v-dialog'?'bottom-sheet-radius-all':'bottom-sheet-radius'"

        >
          <v-container>
            <v-btn @click="dialogSupport = !dialogSupport" style="float: left!important;" icon depressed>
              <img :class="dark?'white-svg':'black-svg'"
                   src="assets/icons/vuesax/chevron-right.svg"/></v-btn>
            <h2 :class="[params.font,dark?'white--text':'black--text']" style="text-align: right ">الدعم
              الفني</h2>
            <v-card-title class="vuertl py-0" :class="params.font">

              <p :class="dark?'grey--text':''" style="font-size:13px">
                يمكنك التواصل معنا في أي وقت تريد عبر وسائل التواصل التالية
              </p>
            </v-card-title>
            <v-row class=" mb-2">
              <v-col class="col-6">
                <v-card flat class="rounded-lg" :to="params.WatsApp" style="cursor: pointer;"
                        :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                  <v-card-title style="display:block" class="  mx-auto pb-1">
                    <img :class="dark?'white-svg':'black-svg'"
                         src="assets/icons/vuesax/Whatsapp.svg"/>
                  </v-card-title>
                  <p :class="[params.font,dark?'white--text':'black--text']"> واتس اب</p>

                </v-card>
              </v-col>
              <v-col class="col-6">
                <v-card
                    flat class="rounded-lg" style="cursor: pointer;" :to="params.Telegram"
                    :color="dark?'app-card-lighten rounded-lg':'app-card-light'" height="90">
                  <v-card-title style="display:block" class="  mx-auto pb-1">
                    <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/send.svg"/>
                  </v-card-title>
                  <p :class="[params.font,dark?'white--text':'black--text']">تلغرام</p>
                </v-card>
              </v-col>

            </v-row>
          </v-container>
        </v-sheet>
      </vs-dialog>

    <v-dialog transition="fade" class="transition-swing" v-model="modelConditions" fullscreen>
      <v-card :dark="dark" flat class="vuertl" :color="dark?'app-bg':'app-bg-light'">


        <v-container>
          <v-card-title :class="params.fontBold">الشروط والاحكام
            <v-spacer>
            </v-spacer>
            <v-btn   @click="switchDialog('conditions')" style="float: left!important;" icon
                     depressed><img :class="dark?'white-svg':'black-svg'"
                                    src="assets/icons/vuesax/close-1.svg"/></v-btn>

          </v-card-title>
          <v-card-subtitle :class="[params.color,params.font]" v-text="params.AppName"></v-card-subtitle>
          <v-card-text :class="params.font">
            <p class="text-justify">بسم الله الرحمن الرحيم</p>
            <p class="text-justify">• في هذه الصفحة تجدون كل مايهمكم بشأن شروط وأحكام متجر "كارتاك"</p>
            <p class="text-justify">• شرائك لأي من المنتجات تعبر عن موافقتك لجميع هذه البنود في الصفحة.</p>
            <p class="text-justify">• جميع المنتجات إلكترونية، غير عينية، وتصل لصفحة “الطلبات” على حسابك
              بالمتجر.</p>
            <p class="text-justify">• قبل الدفع يتوجب على العميل قراءة وصف المنتج بعناية.</p>
            <p class="text-justify">• شراء العميل لاي منتج يعبر عن موافقته لمواصفات وشروط المنتجات المذكورة
              في هذه الصفحة.</p>
            <p class="text-justify">• جميع المنتجات غير قابلة للأسترداد والأسترجاع نهائياُ.</p>
            <p class="text-justify">• أي بيانات يخطئ في تزويدها العميل للمتجر تخص الطلب لايتحمل المتجر أي
              مسؤولية في ذلك.
            </p>
            <p class="text-justify">• في حالة حصول خلل لأي من المنتجات, يجب على العميل توفير فيديو كامل
              اثناء لحظة شراءه يثبت ذلك ( ولن تقبل الشكوى بدون فيديو )
            </p>
            <p class="text-justify">• لا يتحمل متجر إشحنها أي مسؤولية لمشتريات خاطئة قمت بها بذاتك، بسبب
              الاهمال أو إدخال معلومات زائفة /خاطئة، أو أي سبب آخر مما قد يؤدي إلى • أضرار/خسارات كما أن
              متجر إشحنها غير ملزم بتبديل أو أسترجاع اي منتج تم وصول بياناتها إليك وبهذا تكون قد فهمت و
              أقررت وقبلت إخلاء متجر • إشحنها من المسؤولية تماماً.
            </p>
            <p class="text-justify">• بعد التسليم، لا يعتبر متجر إشحنها مسؤول عن أي ضياع أو ضرر للسلع
              الإلكترونية التي تم شرائها من خلال متجر إشحنها، وأي خسارة أو ضرر قد يعاني منه المشتري لهذا
              السبب.
            </p>
            <p class="text-justify">• يتم تغيير الاسعار فالموقع بشكل يومي/اسبوعي/شهري ولا يحق للعميل مطالبة
              الفرق لان هناك عروض يوميا ربما يكون هناك ارتفاع/انخفاض في الاسعار، وليس ملزوم متجر "إشحنها"
              بدفع الفرق او تثبيت السعر
            </p>
            <p class="text-justify">• يحق للمتجر تغيير أو إضافة بنود في هذه الصفحة في اي وقت تراه مناسب و
              يجب على العميل متابعة البنود حتى بدون تنبيه
            </p>

          </v-card-text>
        </v-container>
      </v-card>
    </v-dialog>
    <vs-dialog overflow-hidden blur   not-center v-model="dialogEmail" class="vuertl" >
      <v-sheet max-width="550" color="transparent" :dark="dark"
               class="text-center rounded-t-xl"
               :class="$vuetify.breakpoint.mdAndUp?'bottom-sheet-radius-all':'bottom-sheet-radius'"


      >
        <v-container class="py-0 pt-5">

          <v-btn @click="sheet = !sheet ,dialogEmail = !dialogEmail" style="float: left!important;" icon depressed><img
              :class="dark?'white-svg':'black-svg'"
              src="assets/icons/vuesax/close-1.svg"/></v-btn>
          <h2 :class="dark?'white--text':'black--text'" style="text-align: right">
            إعادة تعيين كلمة المرور</h2>
          <v-card-text class="vuertl pb-0 pt-2" :class="params.font">

            <p class="mb-0" :class="dark?'grey--text':''" style="font-size:14px">
              قم بإدخال البريد الالكتروني لنرسل لك كلمة المرور الجديدة
            </p>
          </v-card-text>
          <v-container>
            <v-row>

              <v-col class="col-12">
                <v-text-field style="    font-size: 19px;"    solo flat
                              v-model="resetMail.email" type="email" for="email"  required filled hide-details
                              class="app-regular rounded-lg input-class"
                              :class="dark?'white--text':'black--text'"
                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                              placeholder="أدخل بريدك الالكتروني">
                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/email.svg" >

                </v-text-field>
              </v-col>
              <v-col class="col-12 py-0 d-flex  justify-center">
                <vue-recaptcha
                    :class="{ 'is-invalid': resetMail.errors.has('recaptcha') }"
                    v-model="resetMail.recaptcha"
                    ref="recaptchaRes"
                    @verify="onVerifyRes"
                    :sitekey="sitekey"
                    class="g-recaptcha my-2 "
                ></vue-recaptcha>
              </v-col>
              <v-col class="col-12 py-0">
                <vs-button :active-disabled="!passeCaptchaRes"  elevation="0" :loading="resetMail.busy"
                            @click="resetEmail()" block
                            color="rgb(254, 44, 85)" class="rounded-lg app-regular"
                            style="height: 46px;">
                  <strong style="font-size: 17px">إعادة كلمة المرور </strong>
                  <img class="white-svg mx-3" src="assets/icons/vuesax/send.svg">
                </vs-button>
              </v-col>
              <v-col class="col-12 py-0" v-if="resetMail.msg!=''">

                <v-alert  class="app-regular" dense text type="error"
                          v-if="resetMail.msg!=''" v-text="resetMail.msg">
                  <template slot="prepend">
                    <img class="white-svg" src="assets/icons/vuesax/close-1.svg">
                  </template>
                </v-alert>

              </v-col>
            </v-row>

          </v-container>
        </v-container>
      </v-sheet>


    </vs-dialog>
  </v-card>

</template>

<script>
import {VBottomSheet , VDialog} from 'vuetify/lib'
import {mapActions, mapState} from 'vuex';
import * as auth from "@/plugins/authUser";
import axios from 'axios';
import {Form} from 'vform';
import TitlePages from "@/layout/TitlePages";
import { Device } from '@capacitor/device';
import VueRecaptcha from 'vue-recaptcha';


import {Decrypt} from "@/plugins/authUser";
import {loadRecaptcha} from "@/tools/Services";
import cookieStorage from "vue-social-auth/src/storage/cookie-storage";

export default {
  components: {TitlePages,VBottomSheet,VDialog ,VueRecaptcha},
  data() {
    return {
      sitekey:'6LeXWxUqAAAAAMTfpynU4tVXgzFYB9o0ghaU_82k',
      dialogEmail:false,
      openEye:true,

      modelConditions: false,
      mode: '',
      sheetTow: false,
      configData: [],
      sheet: false,
      dialogSupport: false,
      nextSteep: false,
      emailProdider: new Form({
        email: '',
        avatar: '',
        id: '',
        phone: '',
        name: ""


      }),
      loginInfo: new Form({
        name:'',
        login: '',
        password: '',
        recaptcha:''
      }),
      registerInfo: new Form({
        phone: '',
        email: '',
        username: '',
        password: '',
        confirmation_password: '',
        recaptcha:''
      }),
      error: '',
      checkTimes: 0,
      passeCaptchaLog:false,
      passeCaptchaRes:false,
      passeCaptchaReg:false,
      processing: false,
      loadingSocial:false,
      resetMail: new Form({
        email:'',
        msg:'',
        recaptcha:'',
      }),
      icon:'',
    }
  },
  methods: {Decrypt,
    loadRecaptcha,
    onVerifyRes(response) {
      this.resetMail.recaptcha = response;
      if (response) {
        this.passeCaptchaRes = true;
      } else {
        this.passeCaptchaRes = false;
      }
    },
    onVerifyReg(response) {
      this.registerInfo.recaptcha = response;
      if (response) {
        this.passeCaptchaReg = true;
      } else {
        this.passeCaptchaReg = false;
      }
    },
    onVerifyLo(response) {
      this.loginInfo.recaptcha = response;
      if (response) {
        this.passeCaptchaLog = true;

      } else {
        this.passeCaptchaLog = false;
      }
    },

    async  getDevice(){
      const device =  await Device.getInfo();
      this.loginInfo.name = device.name

    },
    switchChevron(icon){
      if(this.icon == icon){
        this.icon = ''
      }else{
        this.icon = icon
      }
    },
    resetEmail(){
      this.msg = ''
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email)) {
        this.resetMail.msg = 'تحقق من بريدك الالكتروني';
      } else {
        if (this.passeCaptchaRes){
          this.resetMail.post("api/reset-email").then((response)=>{
            this.resetMail.email= ""
            this.$vs.notification({
              classNotification: 'notif-class',
              duration:3000,
              background: this.dark ? '#000' : '#fff',
              icon:`<img class="white-svg" src="assets/icons/vuesax/success.svg">`,
              color: response.data.status_code == 200?'success':'danger',
              title:"تم بنجاح",
              opacity: 0.8,
              text: response.data.message
            })


          }).catch((e)=>{
            this.resetMail.email= ""
            this.$vs.notification({
              classNotification: 'notif-class',
              duration:3000,
              background: this.dark ? '#000' : '#fff',
              icon:`<img class="${this.$store.state.dark?'white-svg':'black-svg'}" src="assets/icons/vuesax/close.svg">`,
              color: e.response.data.status_code == 200?'success':'danger',
              opacity: 0.8,
              title:"حدث خطأ",
              text: e.response.data.message
            })
            this.$refs.recaptchaRes.reset();
            this.passeCaptchaRes = false
          })
        }else {
          this.$vs.notification({
            classNotification:"vuertl",
            position:'top-center',
            duration:'2000',
            progress: 'auto',
            color:'danger',
            title: 'حدث خطأ',
            text: "تحقق من حقل الروبوت",
          })
          this.$refs.recaptchaRes.reset();
          this.passeCaptchaRes = false
        }
      }
    },
    registerProvider() {
      this.error = ""
      let vm = this;
      if (vm.emailProdider.phone !="") {
        vm.emailProdider.post('socialite-phone').then(async (response) => {
          if(response.data.data.directActivation == true){
            await   auth.setToken(response.data.data.token)
            await   auth.setProfile(response.data.data.user)
            const user = await auth.getProfile();
            await vm.$store.commit('auth/SET_USER', user.data)
            await vm.$router.push({name: 'Dashboard'})
          }else {
            let info = {
              directActivation:response.data.data.directActivation,
              sms:response.data.data.directActivation,
              userAgent:response.data.data.directActivation
            }
            this.$store.commit('SET_INFO',info)
            await this.$router.push({name: 'Validation'})
          }

        }).catch((error) => {
          this.checkTimes++
          if(this.checkTimes  >3){
            this.checkTimes=0
            this.sheetTow=false
          }
          this.error = error.response.data.message
        })
      } else {
        this.error = "تحقق من رقم الهاتف"
      }
    },
    AuthProvider(provider) {
      this.loadingSocial = false
      var self = this
      this.$auth.authenticate(provider).then(response => {
        self.SocialLogin(provider, response)

      }).catch(err => {

      })

    },

    async   SocialLogin(provider, response){
      await axios.get('/sanctum/csrf-cookie')
      this.loadingSocial = true
      this.nextSteep = false
      axios.post('sociallogin/' + provider, response).then(response => {

        if (response.data.success === false) {
          this.emailProdider.fill(response.data.data)
          this.sheet = false
          this.nextSteep = true
          this.sheetTow = true
        } else {
          if(response.data.data.user == null){

            this.$store.commit('SET_INFO',response.data.data)

            this.$router.push("/auth")
          }else {
            const user = auth.getProfile();

            this.$store.commit('auth/SET_USER', user.data)
            auth.setToken(response.data.data.token)
            auth.setProfile(response.data.data.user)
            this.$router.push({name: 'Informations'})

          }
        }

      }).catch(()=>{

        this.loadingSocial = true
        this.$store.dispatch("loadsna", {
          text: "حدث خطأ يرجى إعادة المحاولة",
          isSuccess: false,
        });

      })
    },
    switchDialog(reseved) {
      if(reseved == "conditions"){
        this.modelConditions = !this.modelConditions
        this.sheet = !this.sheet
      }else if(reseved == 'resetEmail'){


        this.sheetTow = !this.sheetTow
        this.dialogEmail = !this.dialogEmail
      }else   {
        this.sheet = !this.sheet
        this.modelConditions = !this.modelConditions
      }


    },
    setApp() {
      window.location.href = this.params.playStore
    },
    HandelUser(login) {
      if (this.auth.user != null) {
        this.$router.push({name: 'Profile'})
      } else {
        this.sheet = !this.sheet
      }
      if(login == "login"){
        this.icon = ''
      }else{
        this.icon = "login"
      }
    },
    async login() {
       if(this.passeCaptchaLog){
         const loading = await this.$vs.loading({
           classNotification: 'notif-class',
           type: 'scale',
           target: this.$refs.target,
           background: this.dark ? '#000' : '#fff',
           opacity: 0.8,
           text: 'يرجى الانتظار'
         })
         try {

           await axios.get('/sanctum/csrf-cookie')
           const response  = await this.loginInfo.post('api/login')
           await loading.close()
           const eLoading =  this.$vs.loading({
             classNotification: 'notif-class',
             type: 'rectangle',
             target: this.$refs.target,
             background: this.dark ? '#000' : '#fff',
             color: '#4CAF50',
             opacity: 0.8,
             text: "تم التحقق من الدخول"
           })
           if(response.data.data.directActivation == true && response.data.data.SomeAgent == true){
             await this.$store.commit('auth/SET_USER', response.data.data.user)
             await auth.setToken(response.data.data.token)
             await auth.setProfile(response.data.data.user)
             const user = await auth.getProfile();
             await this.$store.commit('auth/SET_USER', user.data)
             await this.loginInfo.reset()
             await this.$router.push({name: 'Dashboard'})
           }else {
             let info = {
               token:response.data.data.token,
               directActivation:response.data.data.directActivation,
               sms:response.data.data.directActivation,
               userAgent:response.data.data.directActivation
             }
             this.$store.commit('SET_INFO',info)
             await this.$router.push({path:"/auth",replace:true})
           }
           try {
             eLoading.close()
           }catch (e){}

           this.sheetTow = false
         }catch (e) {
           loading.close()
           this.loginInfo.password = ""
           this.passeCaptchaLog = false
           let errorLoading = this.$vs.loading({
             classNotification: 'notif-class',
             type: 'rectangle',
             target: this.$refs.target,
             timeout:2000,
             background: this.dark ? '#000' : '#fff',
             color: '#E40814',
             opacity: 0.8,
             text:  "تحقق من كلمة السر أو رقم الهاتف"
           })
           setTimeout(() => {
             errorLoading.close()

           }, 2000)
           this.$refs.recaptchaLog.reset();
           this.passeCaptchaLog = false
         }
       }else {
         this.$vs.notification({
           classNotification:"vuertl",
           position:'top-center',
           duration:'2000',
           progress: 'auto',
           color:'danger',
           title: 'حدث خطأ',
           text: "تحقق من حقل الروبوت",
         })
         this.$refs.recaptchaLog.reset();
         this.passeCaptchaLog = false
       }



    },
    ...mapActions({
      signIn: 'auth/login'
    }),

    async register() {
      if(this.passeCaptchaReg) {

        const loading = await this.$vs.loading({
          classNotification: 'notif-class',
          type: 'scale',
          target: this.$refs.target,
          background: this.dark ? '#000' : '#fff',
          color: this.params.bg,
          opacity: 0.8,
          text: 'يرجى الانتظار'
        })

        await axios.get('/sanctum/csrf-cookie')
        this.processing = true

        this.registerInfo.post('api/register').then(response => {
          loading.close()
          const eLoading = this.$vs.loading({
            classNotification: 'notif-class',
            type: 'rectangle',
            target: this.$refs.target,
            background: this.dark ? '#000' : '#fff',
            color: '#4CAF50',
            opacity: 0.8,
            text: "تم إنشاء حساب بنجاح"
          })
          setTimeout(() => {
            try {
              eLoading.close()
            }catch (e) {

            }
            this.sheetTow = false
            this.sheet = !this.sheet
          }, 3000)
          this.$refs.recaptchaReg.reset();
        }).catch((e) => {
          this.$vs.notification({
            classNotification:"vuertl",
            position:'top-center',
            duration:'4000',
            progress: 'auto',
            color:'danger',
            title: 'حدث خطأ',
            text:e.response.data.message || "حدث خطأ يرجى إعادة المحاولة",
          })

          try {
            loading.close()
          }catch (e) { }

        }).finally(() => {
          this.processing = false
          this.$refs.recaptchaReg.reset();
          this.passeCaptchaReg = false
        })

        }else {
        this.$vs.notification({
          classNotification:"vuertl",
          position:'top-center',
          duration:'2000',
          progress: 'auto',
          color:'danger',
          title: 'حدث خطأ',
          text: "تحقق من حقل الروبوت",
        })
        this.$refs.recaptchaReg.reset();
        this.passeCaptchaReg = false
        }


    },
    HandleClose(mode) {
      this.emailProdider.reset()
      this.loginInfo.reset()
      this.registerInfo.reset()
      this.error= ''
      this.mode = mode
      this.sheet = !this.sheet
      this.sheetTow = !this.sheetTow

    }
  },

  computed: {
    ...mapState(['auth']),
    component: function () {
      return this.$vuetify.breakpoint.smAndDown ? 'v-bottom-sheet' : 'v-dialog'
    },
    params: function () {
      return this.$store.state.params
    },
    dark: function () {
      return this.$store.state.dark
    },

  },
 async beforeRouteEnter(to , from , next){

    if(await auth.getDecryptedProfile() && auth.isRealToken() ){
      next("/home")
    }else {

      next()
    }
  },
  mounted() {

    this.getDevice()
    loadRecaptcha();
  }

}
</script>

<style scoped>
.v-dialog__content {
  border-radius: 21px!important;
}
.vs-loading__load .vs-loading__load__animation .vs-loading__load__text {
  font-size: 17px !important;
}

/* .v-text-field--outlined >>> fieldset {
  border-color: #fff;
} */
.v-dialog__content {
  z-index: 1001 !important;
}

.v-expansion-panel-header__icon {
  margin-left: 5px !important;
}

.bottom-sheet-radius-all {
  border-radius: 20px !important;
}

.vs-loading {
  border-radius: 19px !important;
}

.index-z {
  z-index: 1000 !important;
}

.input-class {
  max-height: 50px !important;
  height: 50px !important;
}

>>> .v-input__slot::before {
  border-style: none !important;
}

.for-avatar {
  text-align: center;
  font-size: 20px;
  text-transform: capitalize;
  padding-bottom: 0px;
}
.vs-button{
  margin-right: 0px;
}
.notif-class{
  font-size: 18px!important;
}
.blur {
  -webkit-backdrop-filter: saturate(180%) blur(15px)!important;
  backdrop-filter: saturate(180%) blur(15px)!important;
}


</style>
