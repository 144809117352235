<template>
    <div    >
        <div v-if="$store.state.auth.user.type ==='admin'" class="cont pa-3" style="direction: ltr" >
            <v-card  :dark="$store.state.dark" class="  elevation-0 rounded-xl" v-if="userInf.today">

                    <v-row class="pa-0" >
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card link ripple to="/total-detail"    class="elevation-0 rounded-xl overflow-hidden"
                                      :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="warning"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center;position:relative">

                                    <v-btn   class="v-btn--has-bg" icon color="warning" style="position: absolute;left: 13px;top: 13px;">
                                        <img class="warning-svg" src="assets/icons/vuesax/calendar.svg" />
                                    </v-btn>
                                    <span> @Balance</span>

                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                         <span class="grand-number"   >
                                                             <animated-number
                                                                     :value="userInf.today.solde"
                                                                     :formatValue="formatToPrice"

                                                                     :duration="1000"
                                                             />
                                                         </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                    <span class="app-bold" >  {{`${userInf.today.total_users} مجموع الزبائن`}}</span>
                                    <p class="app-regular">
                                         الفوائد
                                        <span>{{userInf.today.bonus}}</span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>


                        </v-col>
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card link ripple to="/admin-check" class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="primary"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center">
                                    <span> @Flexy</span>
                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"  >
                                                       <animated-number
                                                               :value="userInf.today.flexy_total"
                                                               :formatValue="formatToPrice"

                                                               :duration="1000"
                                                       />
                                                 </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                  <span class="app-bold" >  {{`${userInf.today.total_transactions} مجموع التحويلات`}}</span>
                                  <p class="app-regular">
                                        <span v-text="dates==''?'اليوم':dates"></span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card  link ripple to="/historique-cartes" class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="purple"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center">

                                    <span> @Cartes</span>
                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"  >
                                                       <animated-number
                                                               :value="userInf.today.card_total"
                                                               :formatValue="formatToPrice"

                                                               :duration="1000"
                                                       />
                                                 </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                  <span class="app-bold" >  {{`${userInf.today.total_idoom_transactions} مجموع التحويلات`}}</span>
                                  <p class="app-regular">
                                        <span v-text="dates==''?'اليوم':dates"></span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col class="col-12 col-lg-3 col-md-6 col-sm-6 col-xs-12">
                            <v-card  link ripple to="/idoom" class="elevation-0 rounded-xl overflow-hidden"  :dark="$store.state.dark" :color="$store.state.dark?'app-card':'app-card-light'">
                                <v-progress-linear  top color="success"></v-progress-linear>
                                <v-card-title style="display: block;text-align: center">

                                    <span> @Idoom</span>
                                </v-card-title>
                                <v-card-text class="pb-0" style="text-align: center">
                                                 <span class="grand-number"   >
                                                         <animated-number
                                                                 :value="userInf.today.idoom_total"
                                                                 :formatValue="formatToPrice"

                                                                 :duration="1000"
                                                         />
                                                 </span>
                                </v-card-text>
                                <v-card-actions style="display: block;text-align: center">
                                  <span class="app-bold" >  {{`${userInf.today.total_card_transactions} مجموع التحويلات`}}</span>

                                  <p class="app-regular">
                                        <span v-text="dates==''?'اليوم':dates"></span>
                                        <img class="success-svg" src="assets/icons/vuesax/chevron-top.svg">

                                    </p>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-app-bar class="rounded-lg mt-2 pb-0" flat :class="$store.state.dark?'app-card':'app-card-light'">
                        <vs-avatar size="35">
                            <img src="assets/img/user.png" />
                        </vs-avatar>
                        <p class="mt-5 " style="font-size: 15px">User</p>
                        <vs-avatar  size="35" class="ml-2">
                            <img src="assets/img/profile.png" />
                        </vs-avatar>
                        <p class="mt-5 mr-2" style="font-size: 15px">New</p>
                        <vs-avatar  size="35" >
                            <img style="height100%!important" src="assets/img/admin.png" />
                        </vs-avatar>
                        <p class="mt-5" style="font-size: 15px">Admin</p>
                        <v-spacer></v-spacer>
                        <v-select v-model="typeOfUser"  @change="getTypeUser" :items="['Users','New','Admin','Blocked']"  filled hide-details solo flat
                                      class="app-regular rounded-lg input-class overflow-hidden"
                                      :class="dark?'black--text':'white--text'"
                                      :background-color="dark?'#000':'#fff'"
                                      placeholder="رقم الهاتف">

                            <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/book.svg" >


                        </v-select/>
                    </v-app-bar>
                <v-card-title class="px-0 pt-1">
                    <v-text-field  clearable :dark="$store.state.dark" v-model="search"     class="transi app-regular " style=" border-radius: 14px!important" hide-details="true"  filled rounded   dir="rtl"        :placeholder="'  بحث'" :background-color="$store.state.dark?'app-card':'app-card-light'">
                        <img   :class="$store.state.dark?'white-svg':'black-svg'"  class="ml-2"    style="cursor: pointer"  slot="prepend-inner" src="assets/icons/vuesax/search.svg">
                    </v-text-field>
                    <v-spacer></v-spacer>
                  <vs-button   x-large :class="$store.state.dark?'app-card':'app-card-light'"  @click="showMe()"  color="transparant"   >
                    <span class="app-regular mx-3"  >Message</span>
                    <img :class="$store.state.dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/users.svg"/>
                  </vs-button>

                  <vs-button   x-large :class="$store.state.dark?'app-card':'app-card-light'"   @click="searchCard(search)" color="transparant"    >
                        <span class="app-regular mx-3">بحث</span>
                        <img :class="$store.state.dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/search.svg"/>
                    </vs-button>
                    <vs-button   x-large :class="$store.state.dark?'app-card':'app-card-light'"   @click="liveUsers()"   color="transparant"   >
                        <span class="app-regular mx-3">اونلاين</span>
                        <img :class="$store.state.dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/users.svg"/>
                    </vs-button>


                </v-card-title>
            </v-card>

            <v-card v-if="userInf.today" :dark="$store.getters.dark" shaped flat  color="transparent" class="px-0 mt-0">


                <v-row>
                    <v-col class="col-12" >
                        <v-row :no-gutters="$vuetify.breakpoint.smAndDown">
                            <v-col class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xs-4 col-12 mt-2  " v-for="(user , index) in users"  :key="index">

                                <v-card :dark="$store.state.dark"   flat class="rounded-xl" style="cursor: pointer;"
                                        :class="user.type=='new'?'orange': $store.state.dark?'app-card-lighten rounded-lg card-shadow':' card-shadow app-card-light'"

                                >

                                    <v-container class="app-regular pt-2">
                                         <v-app-bar dense class="pa-1 rounded-lg elevation-0 mb-2" color="transparent" :dark="dark">
                                             <v-switch class="mt-3" color="error" :dark="dark" dense @click.stop="blockUser(user)"
                                                       v-model="user.blocked"
                                                       inset
                                             >

                                             </v-switch>
                                             <span :class="dark?'white--text':'black--text'"  style="font-size:15px" class="mt-n3">{{'Bloqué'+' ' }}<b>{{user.username}}</b></span>

                                             <v-spacer></v-spacer>
                                             <vs-avatar @click="showMessage(user)" class="mr-2" :dark="dark" size="36">
                                                 <img :class="dark?'white-svg':'black-svg'" style="width:25px" src="assets/icons/vuesax/message.svg">
                                             </vs-avatar>
                                           <vs-avatar  >
                                             <img style="width:25px"  :src="statusTypeOfUser(user)">
                                           </vs-avatar>
                                          </v-app-bar>
                                        <v-card-title   class="pa-0">

                                             <v-row>
                                                 <v-col class="col-lg-4 col-sm-6 pl-0">
                                                     <vs-button size="small" color="warn"  gradient block   class="dark--text ma-2 mt-0 px-0 " @click.stop="editModel(user)">

                                                         <img style="width:18px" class="white-svg mr-1 " src="assets/icons/vuesax/edit.svg">
                                                         <h4 class="sans-serif">Config</h4>
                                                     </vs-button>
                                                 </v-col>
                                                 <v-col class="col-lg-4 col-sm-6 pl-0">
                                                     <vs-button size="small"  gradient primary    block class="white--text ma-2 py-0 mt-0  " @click.stop="showmodelupstatus(user)">

                                                         <img style="width:18px" class="white-svg mr-1 " src="assets/icons/vuesax/admin.svg">
                                                         <h4 class="sans-serif">Info</h4>
                                                     </vs-button>
                                                 </v-col>

                                                 <v-col class="col-lg-4 col-12 col-sm-12 pl-0" :class="$vuetify.breakpoint.smAndDpwn?'pt-0':''">
                                                     <vs-button size="small"  gradient primary     block class="white--text ma-1 mt-0 "  @click.stop="showAddSolde(user)">

                                                         <img style="width:18px" class="white-svg mr-1 " src="assets/icons/vuesax/moneyo.svg">

                                                       <h3 class="sans-serif">{{   user.solde}}</h3>
                                                     </vs-button>
                                                 </v-col>
                                             </v-row>





                                        </v-card-title>

                                    </v-container>

                                </v-card>
                            </v-col>



                        </v-row>
                    </v-col>

                </v-row>

            </v-card>
            <v-dialog   :fullscreen="$vuetify.breakpoint.mdAndDown"
                       width="700px"
                       max-width="800"

                       v-model="showSolde"
            >
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">

                        <v-card flat v-bind="attrs"

                                v-on="on"   :dark="$store.state.dark" max-width="850">
                            <v-card-title class="headline">
                               Information  {{solding.username}}
                                <v-spacer></v-spacer>
                                <v-btn     @click="showSolde = !showSolde" large   style="float: left!important;" icon depressed><img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close-1.svg" /></v-btn>

                            </v-card-title>
                            <v-card-text class="pb-0" >
                                <v-row>
                                    <v-col class="col-6">
                                        <h6  class="text-center app-regular"  style="font-size: 17px;" >الحساب</h6>

                                        <v-text-field :value="solding.solde" disabled
                                                      type="number" filled hide-details solo flat
                                                      class="app-regular rounded-lg input-class overflow-hidden"
                                                      :class="dark?'black--text':'white--text'"
                                                      :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                                      placeholder="الحساب">  </v-text-field>
                                    </v-col>
                                    <v-col class="col-6">
                                        <h6  class="text-center app-regular"  style="font-size: 17px;" >أخر إيداع</h6>

                                        <v-text-field :value="lstVrsmnt" disabled
                                                      type="number" filled hide-details solo flat
                                                      class="app-regular rounded-lg input-class overflow-hidden"
                                                      :class="dark?'black--text':'white--text'"
                                                      :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                                      placeholder="أخر إيداع">  </v-text-field>
                                    </v-col>
                                    <v-col class="col-12">
                                        <v-row class="">
                                            <v-col class="col-6">
                                                <v-btn depressed class="rounded-lg" large :color="solding.symbole == '+'?'#03A9F4':''"     block @click="solding.symbole = '+'">
                                                    <v-icon  :color="solding.symbole == '+'?'#fff':'#000'" >mdi-plus-circle-outline</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col class="col-6">
                                                <v-btn depressed class="rounded-lg" large :color="solding.symbole == '-'?'#ec2929':''"     block @click="solding.symbole = '-'">
                                                    <v-icon :color="solding.symbole == '-'?'#fff':'#000'">mdi-minus-circle-outline</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>




                                    </v-col>
                                </v-row>

                            </v-card-text>

                            <v-card-text  >
                                        <v-row>
                                            <v-col class="col-lg-4 col-sm-6 col-xs-12 col-12">
                                                <h6  class="text-center app-regular"  style="font-size: 17px;" >إيداع</h6>

                                                <v-text-field v-model="solding.tosolde" :disabled="solding.symbole==''"
                                                                 type="number" filled hide-details solo flat
                                                              class="app-regular rounded-lg input-class overflow-hidden"
                                                              :class="dark?'black--text':'white--text'"
                                                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                                              placeholder="إضافة الى الرصيد">


                                                    <v-menu slot="prepend-inner"
                                                            transition="scale-transition"
                                                            origin="center center"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon
                                                                   dark
                                                                   color="primary"
                                                                   v-bind="attrs"
                                                                   v-on="on"
                                                            >
                                                                <v-icon>mdi-account</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list >
                                                            <v-list-item selectable

                                                            >
                                                                <v-list-item-title @click="solding.tosolde = '5000'" v-text="'500 mille'"></v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                        <v-list>
                                                            <v-list-item>
                                                                <v-list-item-title @click="solding.tosolde = '10000'" v-text="'1 million'"></v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                        <v-list>
                                                            <v-list-item>
                                                                <v-list-item-title @click="solding.tosolde = '20000'" v-text="'2 million'"></v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                        <v-list>
                                                            <v-list-item-title @click="solding.tosolde = '30000'" v-text="'3 million'"></v-list-item-title>
                                                        </v-list>
                                                        <v-list>
                                                            <v-list-item>
                                                                <v-list-item-title @click="solding.tosolde = '40000'" v-text="'4 million'"></v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                        <v-list>
                                                            <v-list-item-title @click="solding.tosolde = '50000'" v-text="'5 million'"></v-list-item-title>
                                                        </v-list>
                                                        <v-list>
                                                            <v-list-item>
                                                                <v-list-item-title @click="solding.tosolde = '100000'" v-text="'10 million'"></v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>

                                                </v-text-field>

                                            </v-col>
                                            <v-col class="col-lg-4 col-sm-6 col-xs-12 col-12">
                                                <h6  class="text-center app-regular"   style="font-size: 17px;" >سبب دفع فوائد</h6>

                                                <v-text-field v-model="solding.messageBonus" :disabled="solding.symbole==''"
                                                              type="text" filled hide-details solo flat
                                                              class="app-regular rounded-lg input-class overflow-hidden"
                                                              :class="dark?'black--text':'white--text'"
                                                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                                              placeholder="سبب دفع فوائد">


                                                    <v-menu slot="prepend-inner"
                                                            transition="scale-transition"
                                                            origin="center center"
                                                    >
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn icon
                                                                   dark
                                                                   color="primary"
                                                                   v-bind="attrs"
                                                                   v-on="on"
                                                            >
                                                                <v-icon>mdi-account</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list >
                                                            <v-list-item selectable

                                                            >
                                                                <v-list-item-title @click="solding.tosolde = '5000'" v-text="'500 mille'"></v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                        <v-list>
                                                            <v-list-item>
                                                                <v-list-item-title @click="solding.tosolde = '10000'" v-text="'1 million'"></v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                        <v-list>
                                                            <v-list-item>
                                                                <v-list-item-title @click="solding.tosolde = '20000'" v-text="'2 million'"></v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                        <v-list>
                                                            <v-list-item-title @click="solding.tosolde = '30000'" v-text="'3 million'"></v-list-item-title>
                                                        </v-list>
                                                        <v-list>
                                                            <v-list-item>
                                                                <v-list-item-title @click="solding.tosolde = '40000'" v-text="'4 million'"></v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                        <v-list>
                                                            <v-list-item-title @click="solding.tosolde = '50000'" v-text="'5 million'"></v-list-item-title>
                                                        </v-list>
                                                        <v-list>
                                                            <v-list-item>
                                                                <v-list-item-title @click="solding.tosolde = '100000'" v-text="'10 million'"></v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>

                                                </v-text-field>

                                            </v-col>
                                            <v-col class="col-lg-4 col-sm-12 col-xs-12 col-12">
                                                <h6 class="text-center app-regular" style="font-size: 17px;" >فوائد</h6>
                                                <v-text-field v-model="solding.bonus" :disabled="solding.symbole==''"
                                                              type="number" filled hide-details solo flat
                                                              class="app-regular rounded-lg input-class overflow-hidden"
                                                              :class="dark?'black--text':'white--text'"
                                                              :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                                              placeholder="إضافة الى الرصيد">




                                                </v-text-field>

                                            </v-col>
                                            <v-col class="col-12">
                                                <v-card-actions class="justify-center py-0">
                                                    <vs-radio   v-model="solding.reason" val="Un bonus">
                                                        Bonus
                                                    </vs-radio>
                                                    <vs-radio   v-model="solding.reason" val="Récupération">
                                                        Récupération
                                                    </vs-radio>
                                                    <vs-radio   v-model="solding.reason" val="Un credit">
                                                        Credit
                                                    </vs-radio>
                                                    <vs-radio v-model="solding.reason" val="Rechargement">
                                                        Versement
                                                    </vs-radio>
                                                </v-card-actions>
                                            </v-col>
                                        </v-row>
                            </v-card-text>
                            <v-card-text class="py-0">
                                <v-row>
                                    <v-col class="col-12">
                                        <v-row class="">
                                            <v-col class="col-6">
                                                <v-btn :color="solding.cymboleCredit == '+'?'#03A9F4':''"     block @click="solding.cymboleCredit = '+'">
                                                    <v-icon  :color="solding.cymboleCredit == '+'?'#fff':'#000'" >mdi-plus-circle-outline</v-icon>
                                                </v-btn>
                                            </v-col>
                                            <v-col class="col-6">
                                                <v-btn :color="solding.cymboleCredit == '-'?'#03A9F4':''"     block @click="solding.cymboleCredit = '-'">
                                                    <v-icon :color="solding.cymboleCredit == '-'?'#fff':'#000'">mdi-minus-circle-outline</v-icon>
                                                </v-btn>
                                            </v-col>
                                        </v-row>





                                    </v-col>
                                </v-row>

                                <v-row >

                                    <v-col class="col-lg-6 col-sm-12 col-xs-12 col-12 pt-0">
                                        <h6 class="text-center app-regular" style="font-size: 17px" >ديون</h6>
                                         <v-text-field v-model="credit.total" disabled
                                                       filled hide-details solo flat
                                                      class="app-regular rounded-lg input-class overflow-hidden"
                                                      :class="dark?'black--text':'white--text'"
                                                      :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                                      placeholder="إضافة الى الرصيد">
                                        </v-text-field>
                                    </v-col>
                                    <v-col class="col-lg-6 col-sm-12 col-xs-12 col-12 pt-0">
                                        <h6 class="text-center app-regular" style="font-size: 17px" >إضافة دين</h6>
                                        <v-text-field v-model="solding.addToCredit" :disabled="solding.cymboleCredit==''"
                                                      type="number" filled hide-details solo flat name="addToCredit"
                                                      class="app-regular rounded-lg input-class overflow-hidden"
                                                      :class="dark?'black--text':'white--text'"
                                                      :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                                      placeholder="إضافة الى الرصيد">
                                        </v-text-field>
                                        <has-error :form="solding" field="addToCredit"></has-error>
                                    </v-col>
                                    <v-col class="col-12 " v-if="solding.cymboleCredit !=''">
                                        <vs-input placeholder="Description" success v-model="solding.message" block></vs-input>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-card  v-if="getIfVersement != null"  :dark="!$store.state.dark" tile  style="border-radius: 15px!important;" class="card-sharow my-1   " >
                                <v-list  class="py-0">
                                    <v-list-item          >

                                        <v-list-item-content v-if="!$vuetify.breakpoint.xsOnly">
                                            <v-list-item-title >
                                                <span style="font-size: 20px" class="mt-2 text--h5"><b>{{getIfVersement.method}}</b></span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="text-center">أخر إيداع للمال</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                            <v-list-item-title :class="getTextColor(getIfVersement.status)"  >
                                                <span style="font-weight: bold;font-size: 17px;font-family: sans-serif "> {{getIfVersement.amount}}  </span>

                                                <v-list-item-subtitle class="mt-1">
                                                    {{getIfVersement.updated_at | dateStyle}}
                                                </v-list-item-subtitle>
                                            </v-list-item-title>

                                        </v-list-item-content>

                                        <v-list-item-content  >
                                            <v-btn rounded color="#03A9F4"     @click="SelectUpsate(getIfVersement.status)">
                                                تحديث
                                            </v-btn>
                                        </v-list-item-content>

                                    </v-list-item>
                                </v-list>
                            </v-card>

                            <v-card outlined  v-if="credit != 0"  :dark="$store.state.dark" tile  style="border-radius: 15px!important;" class="card-sharow my-1 pb-3 " >
                                <v-list class="py-0">
                                    <v-list-item    dense     >

                                        <v-list-item-content>
                                            <v-list-item-title  style="text-align: center;display: block">
                                                <span style="color:#4CAF50;font-size: 20px" class="mt-2 text--h5  " ><b>{{credit.oldCredit}}</b><sub class="mx-2">DA</sub></span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="text-center app-regular">دين قديم</v-list-item-subtitle>
                                        </v-list-item-content>
                                        <v-list-item-content>
                                            <v-list-item-title  style="text-align: center;display: block" :class="getTextColor(credit.status)"  >
                                                <span style="font-weight: bold;font-size: 17px;font-family: sans-serif;color: #ED1C24  "><span class="mx-1" v-text="credit.operation"></span> {{credit.credit}} <sub class="mx-2">DA</sub></span>

                                                <v-list-item-subtitle class="app-regular">
                                                    {{credit.updated_at | fromNow}}
                                                </v-list-item-subtitle>
                                            </v-list-item-title>

                                        </v-list-item-content>

                                        <v-list-item-content class="py-0">
                                            <v-list-item-title  style="text-align: center;display: block">
                                                <span style="color:#4CAF50;font-size: 20px" class=" text--h5"><b>{{credit.total}}</b><sub class="mx-2">DA</sub></span>
                                            </v-list-item-title>
                                            <v-list-item-subtitle class="text-center app-regular">مجموع الديون</v-list-item-subtitle>


                                        </v-list-item-content>

                                    </v-list-item>
                                </v-list>
                            </v-card>

                            <v-card-actions >
                                <v-spacer></v-spacer>
                                <v-btn  :loading="solding.busy" :disabled="solding.symbole=='' && solding.symboleCredit==''" text color="#4CAF50"   class="app-regular mx-2 fonta v-btn--active" @click="SoldeSymbole">
                                    إرسال
                                    <v-icon>mdi-telegram</v-icon>
                                </v-btn>
                                <v-btn text color="#ED1C24"   class="app-regular mx-2 fonta v-btn--active" @click="showSolde = !showSolde">
                                    إلغاء
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </v-card-actions>
                        </v-card>



                    </template>
                    <span v-text="credit.message != ''? credit.message:'Pas de commantaire!'"> </span>
                </v-tooltip>




            </v-dialog>
            <v-dialog
                      v-model="activeuserModal"

                      max-width="900"
                      hide-overlay

            >
                <v-card :dark="$store.state.dark" max-width="900">
                    <v-card-title>
                        {{form.username}}
                    </v-card-title>
                    <v-card-text>
                      <v-row>
                          <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                              <v-text-field v-model="form.username" type="text" filled  hint="إسم المستخدم" persistent-hint solo flat
                                            class="app-regular rounded-lg input-class overflow-hidden" readonly
                                            :class="dark?'black--text':'white--text'"
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                            placeholder="إسم المستخدم">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/users.svg" >


                              </v-text-field>
                          </v-col>
                          <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                              <v-text-field v-model="form.email" type="email" filled   solo flat
                                            class="app-regular rounded-lg input-class overflow-hidden"
                                            :class="dark?'black--text':'white--text'" hint="البريد الالكتروني" persistent-hint
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                            placeholder="البريد الالكتروني">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/mail.svg" >


                              </v-text-field>

                          </v-col>

                          <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                              <v-text-field v-model="form.phone" type="number" filled  solo flat
                                            class="app-regular rounded-lg input-class overflow-hidden"
                                            :class="dark?'black--text':'white--text'"  hint="رقم الهاتف" persistent-hint
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block readonly
                                            placeholder="رقم الهاتف">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/mobile.svg" >


                              </v-text-field>

                          </v-col>
                          <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12">

                              <v-select :items="['user','new','admin']" v-model="form.type" type="text" filled hint="النوع" persistent-hint  solo flat
                                        class="app-regular rounded-lg input-class overflow-hidden"
                                        :class="dark?'black--text':'white--text'"
                                        :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                        placeholder="النوع">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/userss.svg" >


                              </v-select>

                          </v-col>
                          <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                              <v-text-field v-model="form.solde" type="number" filled   solo flat hint="الرصيد" persistent-hint readonly
                                            class="app-regular rounded-lg input-class overflow-hidden"
                                            :class="dark?'black--text':'white--text'"
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                            placeholder="رصيد">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/wallet.svg" >


                              </v-text-field>

                          </v-col>
                          <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                              <v-text-field v-model="form.refferal" type="number" filled  hint="الموزع" persistent-hint solo flat
                                            class="app-regular rounded-lg input-class overflow-hidden"
                                            :class="dark?'black--text':'white--text'"
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                            placeholder="الموزع">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/userss.svg" >


                              </v-text-field>

                          </v-col>
                          <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                              <v-text-field v-model="form.addsolde" type="number" filled   solo flat
                                            class="app-regular rounded-lg input-class overflow-hidden"
                                            :class="dark?'black--text':'white--text'" hint="أخر إيداع" persistent-hint
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                            placeholder="أخر إيداع">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/wallet.svg" >


                              </v-text-field>

                          </v-col>
                          <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                              <v-text-field v-model="form.verification_code" type="number" filled  solo flat
                                            class="app-regular rounded-lg input-class overflow-hidden"
                                            :class="dark?'black--text':'white--text'"  hint="كود التحقق" persistent-hint
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                            placeholder="كود التحقق">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/code.svg" >


                              </v-text-field>

                          </v-col>
                          <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                              <v-text-field v-model="form.email_verified_at" type="text" filled hint="تاريخ التفعيل" persistent-hint solo flat
                                            class="app-regular rounded-lg input-class overflow-hidden"
                                            :class="dark?'black--text':'white--text'"
                                            :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                            placeholder="تاريخ التفعيل">

                                  <img   slot="prepend-inner" :class="dark?'white-svg':'black-svg'" class=" mx-3" src="assets/icons/vuesax/date.svg" >


                              </v-text-field>

                          </v-col>

                      </v-row>
                    </v-card-text>

                     <v-card-text>
                       <v-alert outlined rounded type="error" class="vuertl white--text" color="error" v-if="typeAdmin" dense>
                         <h3>تحذير</h3>
                         لقد قمت بإختيار أدمين جديد للمنصة إذا كنت موافق قم بالضغظ على موافق
                         <v-card-actions>
                           <v-spacer></v-spacer>
                           <v-btn :dark="dark" class="mx-3 rounded-lg success" flat  @click="typeAdmin = false">
                             موافق
                           </v-btn>
                           <v-btn :dark="dark" class="rounded-lg " @click="form.type = 'user',typeAdmin = false">
                             إلغاء
                           </v-btn>
                         </v-card-actions>
                       </v-alert>
                     </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn   class="text-capitalize app-regular rounded-lg px-3"    type="button" @click="activeuserModal = !activeuserModal">
                            إغلاق
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                        <v-btn :disabled="typeAdmin"  class="text-capitalize app-regular  px-3 rounded-lg white--text " :loading="form.busy" color="#4CAF50" @click="updateStat"   type="button">
                            تحديث
                            <v-icon>mdi-telegram</v-icon>
                        </v-btn>
                    </v-card-actions>



                </v-card>

            </v-dialog>

            <!--Upsate Status-->


            <v-dialog
                    v-model="LiveUsersModel"
                    width="400"
            >
                <v-card  :ripple="false" :dark="$store.state.dark"
                         loader-height="5"
                >
                    <v-virtual-scroll v-if="Online.length!=0"
                                      :items="Online"
                                      height="300"
                                      item-height="64"
                    >

                        <template v-slot:default="{ item }">
                            <v-list   class="fonta"    >
                                <v-list-item-group>
                                    <v-list-item
                                            :key="item.id"
                                    >
                                        <v-list-item-icon>
                                        </v-list-item-icon>
                                        <v-list-item-content>
                                            <v-list-item-title  v-text="item.username"></v-list-item-title>

                                        </v-list-item-content>
                                        <v-spacer></v-spacer>
                                        {{item.phone}}
                                    </v-list-item>
                                </v-list-item-group>

                            </v-list>


                        </template>

                    </v-virtual-scroll>
                    <v-card-title class="app-regular" v-if="Online.length <=0">

                        <v-spacer></v-spacer>
                        لايوجد زبائن أونلاين الان على المنصة
                    </v-card-title>
                    <v-card-actions>

                        <v-spacer></v-spacer>
                        <v-btn large @click="LiveUsersModel=!LiveUsersModel" text class="text-web-success rounded-lg">إغلاق</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>


            <v-dialog fullscreen  v-model="showDialogMessage">
                <v-card :dark="$store.state.dark" class="mt-3 elevation-0" height="100%" width="100%" >
                  <v-app-bar elevation="0" dense class="pb-2">
                    <v-btn     @click="showDialogMessage = !showDialogMessage" large   style="float: left!important;" icon depressed><img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close-1.svg" /></v-btn>
                    <v-spacer></v-spacer>
                    <v-row>
                      <v-col class="col-12 d-flex justify-center">
                        <v-btn class="mx-2 app-regular rounded-xl elevation-0" :color="$store.state.dark?'dashboard':'white'"  :dark="$store.state.dark" border  @click="messageUser.all = !messageUser.all" >
                          ميساج لجميع الزبائن

                          <img   :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close.svg" v-if="!messageUser.all">
                          <img v-else class="success-svg" src="assets/icons/vuesax/success.svg">

                        </v-btn>
                        <v-btn  class="mx-2 app-regular rounded-xl elevation-0" :color="$store.state.dark?'dashboard':'white'" :dark="$store.state.dark" border  @click="messageUser.credit = !messageUser.credit" >
                          ميساج للزبائن المدانين

                          <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close.svg" v-if="!messageUser.credit">
                          <img v-else class="success-svg" src="assets/icons/vuesax/success.svg">
                        </v-btn>
                        <v-btn  class="app-regular rounded-xl elevation-0"  :color="$store.state.dark?'dashboard':'white'" :dark="$store.state.dark" border   @click="messageUser.position = 'top-center'" >
                          فوق منتصف


                          <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close.svg" v-if="messageUser.position != 'top-center'">
                          <img v-else class="success-svg" src="assets/icons/vuesax/success.svg">

                        </v-btn>
                        <v-btn class="app-regular rounded-xl mx-3 elevation-0" :color="$store.state.dark?'dashboard':'white'"   :dark="$store.state.dark" border   @click="messageUser.position = 'bottom-right'" >
                          أسفل يمين
                          <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close.svg" v-if="messageUser.position != 'bottom-right'">
                          <img v-else class="success-svg" src="assets/icons/vuesax/success.svg">
                        </v-btn>
                        <v-btn class="app-regular rounded-xl mx-3 elevation-0" :color="$store.state.dark?'dashboard':'white'"   :dark="$store.state.dark" border   @click="messageUser.position = 'top-right'" >
                          اعلى يمين
                          <img :class="dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close.svg" v-if="messageUser.position != 'top-right'">
                          <img v-else class="success-svg" src="assets/icons/vuesax/success.svg">
                        </v-btn>

                      </v-col>


                    </v-row>
                    <v-spacer></v-spacer>
                  </v-app-bar>
                  <v-card-text>

                    <v-row>
                      <v-col class="col-12 d-flex justify-center mt-3">

                        <v-btn large :class="messageUser.icon == icon?'success-svg':''"  v-for="(icon,index) in collectionIcon " :key="index" class="mr-1" icon small @click="messageUser.icon = icon " >
                          <img :class="$store.state.dark?'white-svg':'black-svg'" :src="'assets/icons/vuesax/'+icon+'.svg'">
                        </v-btn>

                      </v-col>
                      <v-col class="col-12 d-flex justify-center">
                        <v-btn large :class="messageUser.icon == icon?'success-svg':''" v-for="(icon,index) in collectionIcon2 " :key="index" icon small @click="messageUser.icon = icon " >
                          <img :class="$store.state.dark?'white-svg':'black-svg'" :src="'assets/icons/vuesax/'+icon+'.svg'">
                        </v-btn>

                      </v-col>
                      <v-col class="col-12 py-0 d-flex justify-center">

                        <v-btn large v-for="(color,index) in collectionColor " :key="index" icon   @click="messageUser.background = color "  >
                          <v-icon :color="color">mdi-checkbox-blank-circle</v-icon>
                        </v-btn>
                        <b class="app-regular"> تغيير الخلفية </b>
                      </v-col>
                      <v-col class="col-12 py-0 d-flex justify-center">
                        <v-btn large v-for="(color,index) in collectionColor " :key="index" icon   @click="messageUser.color = color "  ><v-icon :color="color">mdi-checkbox-blank-circle</v-icon> </v-btn>
                        <b class="app-regular"> تغيير اللون العلوي</b>
                      </v-col>
                      <v-col class="col-12 py-0 d-flex justify-center">
                        <v-btn large   v-for="(emo,index) in emoji " :key="index" icon small @click="messageUser.message = messageUser.message+emo   " >
                          {{emo}}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row class="align-content-center justify-center">

                      <v-col class="col-8">
                        <v-card   v-if="switchMessagEmail == true" :bordred="$store.getters.dark" :dark="$store.getters.dark" flat >



                          <v-container class="pt-0">
                            <v-row class="">
                              <v-col class="col-12 vuertl">
                                <v-combobox  autofocus :dark="$store.state.dark"  :background-color="$store.state.dark?'#2d2d39':'#EEF2F5'"
                                             height="50" v-model="form.username"
                                             maxlength="12"  class="rounded-xl mb-2 elevation-0" id="text-field-edit"

                                             :items="users"
                                             :clearable="false" type="num"
                                             :search-input.sync="search"
                                             hide-no-data


                                             auto-select-first
                                             filled
                                             item-text="phone"
                                             item-value="phone" hide-details
                                             placeholder="أدخل رقم الهاتف"

                                >

                                  <img  slot="append" :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/search.svg" />
                                  <v-btn slot="prepend-inner"  icon    class="mt-n1 ml-3 v-btn--has-bg">
                                    <img style="position: relative"     class="orange-svgg" src="assets/icons/vuesax/up.svg" />

                                  </v-btn>



                                  <template v-slot:item="{ item }">
                                    <v-list-item-avatar
                                        color="indigo"
                                        class="text-h5 font-weight-light white--text"
                                    >
                                      <span style="font-size: 18px;font-width: bold;">{{ item.username.charAt(0) }}</span>
                                    </v-list-item-avatar>
                                    <v-spacer></v-spacer>
                                    <v-list-item-content class="py-0">
                                      <v-list-item-title    style="font-size: 13px" class="text-capitalize caption" ><b  v-text="item.username"></b></v-list-item-title>
                                    </v-list-item-content>
                                    <v-list-item-content class="py-0">
                                      <v-list-item-subtitle  style="text-align: center;font-size: 14px" >
                                        <b style="font-size: 14px" v-text="item.number"></b>
                                      </v-list-item-subtitle>
                                    </v-list-item-content>

                                  </template>
                                </v-combobox>

                              </v-col>

                              <v-col class=" col-12 vuertl ">
                                <v-text-field   placeholder="Title"
                                                type="text"  filled hide-details solo flat
                                                class="app-regular rounded-lg input-class overflow-hidden"
                                                :class="dark?'black--text':'white--text'"
                                                :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                                v-model="messageUser.title"></v-text-field>
                              </v-col>

                              <v-col class="col-12 vuertl">
                                <v-textarea  type="text"  filled hide-details solo flat
                                             class="app-regular rounded-lg input-class overflow-hidden"
                                             :class="dark?'black--text':'white--text'"
                                             :background-color="dark?'#2d2d39':'#EEF2F5'" block
                                             placeholder="Message"

                                             v-model="messageUser.message"
                                ></v-textarea>
                              </v-col>


                            </v-row>
                          </v-container>

                          <v-card-actions class="px-1">
                            <v-spacer></v-spacer>


                            <v-btn :loading="messageUser.busy"   @click="updateMessage" style="font-family:sans-serif"  text class="success white--text mx-3  rounded-xl "  >إرسال </v-btn>
                            <v-btn   @click="handelNotification" style="font-family:sans-serif"  text class="white--text   primary rounded-xl">إظهار الرسالة</v-btn>

                          </v-card-actions>

                        </v-card>
                      </v-col>
                    </v-row>
                    <v-card v-if="switchMessagEmail == false"  :bordred="$store.getters.dark" :dark="$store.getters.dark" flat  >

                      <v-card-text>
                        <v-row class="mt-5">
                          <v-col class=" col-12 ">
                            <v-text-field  dense placeholder="Title" outlined  v-model="emailUser.title"></v-text-field>
                          </v-col>

                          <v-col class="col-12">
                            <v-textarea  outlined
                                         solo
                                         placeholder="Message"
                                         name="input-7-4"
                                         v-model="emailUser.message"
                            ></v-textarea>
                          </v-col>


                        </v-row>

                      </v-card-text>

                      <v-card-actions class="px-1">
                        <v-container>
                          <v-chip><strong>to all users </strong>
                            <i style="font-size: 37px;
                            margin-left: 9px;cursor: pointer" @click="switchEmail"   :class="emailUser.all==true?'text-success fas fa-toggle-on fa-2x':'text-dark fas fa-toggle-off  fa-2x'" > </i>

                          </v-chip>

                          <v-spacer></v-spacer>
                          <v-btn :loading="emailUser.busy"   @click="updateEmail" style="font-family:sans-serif"   class="bg-web-success text-white mx-2 text-capitalize fonta">Envoyer Email</v-btn>
                          <v-btn @click="showDialogMessage = !showDialogMessage" style="font-family:sans-serif"   class="text-grey fonta">Annulé</v-btn>
                        </v-container>
                      </v-card-actions>

                    </v-card>

                  </v-card-text>
                </v-card>
            </v-dialog>
        </div>
        <NotFound v-else/>
    </div>
</template>

<script>

    import Form from 'vform'
    import {mapState} from "vuex";
    import {getColorr, iconChange, iconR , userColor,statusTypeOfUser,setBaseUrl,formatToPrice} from "@/tools/Services.js";
    import {VueEditor} from "vue2-editor";
    import AnimatedNumber from "animated-number-vue";
    import NotFound from "@/components/comp-tool/NotFound.vue";
    import axios from 'axios'
    export default {
        components: {
            VueEditor,AnimatedNumber , NotFound
        },
        computed:{
          dark:function (){
              return this.$store.state.dark
          },
            ...mapState([
               'dark'
            ]),
            getNow: function() {
                const date = new Date()
                return date.toISOString().split('T')[0]
            },
        },
        data(){
            return {
                typeAdmin:false,
                typeOfUser:'',
                userInf:[],
                dates:'',
                menu:false,
                date:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

                image:'',
                dialogImage:false,
                lstVrsmnt:null,
                updateStatus:'',
                collectionIcon:['add-user',"alert","app","backspace","banking",'bell',"blogger",'book','book-1','calendar',
                "card","carta","cash","chevron-left","chevron-right",'close',"code","code-1","comerce","commerce","copy",'date',"email",

                ],
              emoji:['😂','🌹','💚','💛','✅','😃','👍🏻','🤣','😍','🤍','🖤','🤪','✨','💙','♥️'],
              collectionIcon2:[
                "envoyer","eye-closed","eye-open","file","folder","gift","home","image","inbox","layout","like","menue","moon","music","password" ,
                "plus","point","repeat","sim","simcard","starline","success","user-1","user-add","users","wifi"
              ],
                collectionColor:['#9E9E9E','#000000','#FFF','#795548','#607D8B','#FFC107','#FF9800','#4CAF50','#CDDC39','#00BCD4','#009688','#2196F3','#3F51B5','#673AB7','#F44336'],
                switchMessagEmail:false,
                credit:0,
                sendTousers:false,
                Online:{},
                LiveUsersModel:false,
                addsolde:false,
                activeuserModal:false,
                UserProfile:'',
                isRight:false,
                sum:0,
                imageMode: 0,
                editMode:false,

                messageUser: new Form({
                    position:'bottom-center',
                    credit:false,
                    id:'',
                    message:'',
                    title:'',
                    username:'',
                    icon:'',
                    color:'',
                    background:'',
                    all:false
                }),
                emailUser: new Form({
                    id:'',
                    message:'',
                    title:'',
                    username:'',
                    all:false
                }),
                solding: new Form({
                    reason:'Rechargement',
                    bonus:0,
                    id:'',
                    solde:'',
                    tosolde:0,
                    addToCredit:0,
                    symbole:'',
                    sendsms:false,
                    cymboleCredit:'',
                    message:'',

                }),
                form: new Form({
                    id:'',
                    premium:'',
                    username:'',
                    solde:'',
                    phone:'',
                    type:'',
                    addsolde:'',
                    toaddsolde:'',
                    verification_code:'',
                    email:'',
                    email_verified_at:'',
                    sendToEmail:false,
                    blocked:'',
                }),
                users:[],
                userTypes:{},
                maxlengthPhone: 6,
                countusrs:'',
                allcardscount:{},
                userid:'',
                search: '',
                colorChip:'#E0E0E0',

                language:'',
                pages:1,
                curentPage:1,
                type:window.user,
                showSolde:false,
                showDialogMessage:false,
                getIfVersement:null
            }
        },

        beforeMount() {
            this.loaduser()
            if(this.language !== 'fr'){
                this.isRight = true
            }
        },
        mounted() {
            this.getadminAchat(this.$route.params.id,this.date)
        },
        methods: {userColor,setBaseUrl,formatToPrice,
          showMe(){
            this.messageUser.clear()
            this.emailUser.clear()
            this.messageUser.icon ='close-1'
            this.messageUser.all = true
            this.messageUser.position  = 'top-center'
            this.switchMessagEmail = true
            this.showDialogMessage= !this.showDialogMessage
          },
            getTypeUser(){
            axios.post('api/admin-get-type-user',{type:this.typeOfUser}).then((response)=>{
              this.users = response.data.users
            })
            },
           getadminAchat(date){
                       axios.post('api/admin-achat-date',{date:date}).then((response)=>{
                    this.userInf = response.data
                })



            },
            editModel(user){
                this.$router.push({name:"ProfileHistorique",params:{id:user.id}}).catch(()=>{})

            },

            blockUser(user){
               axios.put("api/block-user/"+user.id).then((response)=>{
                   this.loaduser()
               })
            },
            statusTypeOfUser,
            redirect(phone){

                this.$router.push({name:"UserInfo",params:{phone:phone}})
            },
            iconR,
            SelectUpsate(status){
                axios.post('api/update-versement-btn',{id:this.getIfVersement.id,status:status}).then((response)=>{
                    if(response.data.versement != 0){
                        this.getIfVersement = response.data.versement

                    }

                }).catch(()=>{

                })
            },
            switchEmail(){
                this.emailUser.all = !this.emailUser.all
            },

            updateEmail(){
                this.emailUser.post('api/update-email-user').then((response)=>{

                    this.showDialogMessage= !this.showDialogMessage
                    if(this.$store.getters.user.message==''){
                        Swal.fire('Email Sended Successfully','','success')
                        this.loaduser()
                        if (this.emailUser.all !=true){
                            setTimeout(()=>this.search = this.emailUser.username,2000)
                        }
                    }else {
                        Swal.fire('حدث خطأ ما أعد المحاولة ','','error')
                    }


                }).catch(()=>Swal.fire('حدث خطأ ما أعد المحاولة','','error'))
            },
            updateMessage(){
                if(this.form.username != null){
                  this.messageUser.post('api/update-message-user').then((response)=>{
                    this.showDialogMessage= !this.showDialogMessage
                    Toast.fire({
                      icon: 'success',
                      title: 'تم إرسال الرسالة بنجاح'
                    });
                    this.form.username = ""
                    this.search = ""
                    this.loaduser()
                  }).catch(()=>Swal.fire('حدث خطأ ما أعد المحاولة','','error'))
                }else{
                  this.$vs.notification({
                    classNotification:'arabic',
                    title: "حدث خطأ",
                    text: "قم بإختيار الزبون",
                    duration:'3000',
                    icon:`<img class="white-svg" src="assets/icons/vuesax/close.svg">`,
                    color: "warn",
                    position:"top-center"

                  })
                }

            },

            showMessage(user){
                axios.put('api/user-message/'+user.id).then((data)=>{
                    this.messageUser.clear()
                    this.messageUser.fill(data.data)
                    this.messageUser.id = user.id
                    this.emailUser.clear()
                    this.emailUser.id = data.data.user_id
                    this.messageUser.icon = user.icon
                    this.messageUser.title = user.title
                    this.messageUser.background = user.background
                    this.messageUser.username = user.username
                    this.messageUser.color = user.color
                    this.emailUser.username = user.username
                    this.switchMessagEmail = true
                    this.showDialogMessage= !this.showDialogMessage
                })

            },
            searchCard(after) {
                if(after !=''){
                    axios.get("api/findUser?q=" + after).then(data => {
                        this.users = data.data
                    })
                        .catch(() => {});
                }else {
                    this.loaduser()
                }
            },
            switchSMS(){
                this.solding.sendsms = !this.solding.sendsms
            },
            liveUsers(){
                axios.get('api/live-users').then((response)=>{

                    this.Online = response.data
                    this.LiveUsersModel = true
                })
            },
            async  SoldeSymbole(){

                this.solding.put('api/solde-symbole/'+this.solding.id).then( (response)=>{
                        this.lstVrsmnt = response.data.user.addsolde
                        this.solding.tosolde = 0
                        this.solding.bonus = 0
                        if(this.solding.addToCredit != 0 ){
                            this.showAddSolde(response.data.user)
                        }
                        this.solding.id = response.data.user.id
                        this.solding.solde = response.data.user.solde
                        this.lstVrsmnt =    response.data.user.addsolde
                        this.solding.cymboleCredit = ""
                    this.$store.dispatch("loadsna", {
                        text: 'تمت العملية بنجاح',
                        isSuccess: true,
                        timout:2000,
                    });

                    }
                ) .catch(
                    ()=>{ Swal('Failed','حدث خطأ ما أعد المحاولة','warning')}
                );
            },
            showAddSolde(user){
                this.solding.reset()
                this.credit = 0
                axios.get('api/credit-user-credit/'+user.username).then((response)=>{
                    if(response.data.credit != false ){

                        this.credit = response.data.credit


                    }else {
                        this.credit = 0
                    }

                    if(response.data.versement != 0){

                        this.getIfVersement = response.data.versement
                    }

                    this.solding.id = response.data.user.id
                    this.solding.solde = response.data.user.solde
                    this.lstVrsmnt =    response.data.user.addsolde


                })


                if( this.showSolde != true){
                    this.showSolde = !this.showSolde
                }

            },
            handelNotification(){
                this.$vs.notification({

                    classNotification:'arabic',
                    title:  this.messageUser.title,
                    text: this.messageUser.message,
                    duration:'none',
                    border: this.messageUser.color,
                    icon:`<img class="white-svg" src="assets/icons/vuesax/${this.messageUser.icon}.svg">`,

                    color: this.messageUser.background,
                    position:this.messageUser.position,
                    buttonClose:true,
                })

            },
            getColorr,
            iconChange,
            paginatePage(pagePagination){
                axios.get('api/User?page='+ pagePagination ).then(data  => {
                    this.users = data.data.data
                    this.pages = data.data.last_page
                    this.curentPage = data.data.current_page


                } );

            },

            getColor(res) {
                if (res === 'admin') return ' #FF1976'
                else if (res === 'user') return '#4CAF50'
                else   return '#FFC107'

            },

            getTextColor(res) {
                if (res === "En attente") return 'text-warning'
                else if (res === "Confirmé") return 'text-web-success'
                else if (res === "Annulé") return 'text-web-error'
            },

            showmodelupstatus(user){
                this.form.sendToEmail = false
                    this.imageMode = 1;
                this.form.reset();
                this.form.fill(user);
                this.activeuserModal = !this.activeuserModal
            },
            updateStat(){

                this.form.put('api/updatetype/'+this.form.id)

                    .then(
                        ()=>{
                            this.$vs.notification({
                                classNotification:"vuertl",
                                icon:'<img  class="white-svg" src="assets/icons/vuesax/success.svg" />',
                                title: 'تحديث',
                                position:"top-center",
                                color:"success",
                                text:"تم التحديث بنجاح",
                                duration:"3000"

                            })
                            this.activeuserModal = !this.activeuserModal
                            this.loaduser()
                        }
                    )
                    .catch(
                        ()=>{ Swal('Failed','حدث خطأ ما يرجى إعادة المحالة','warning')}
                    );
            },


            searchUser(after) {
                axios
                    .get("api/search-User?q=" + after)
                    .then(data => {
                        this.users = data.data;

                    })
                    .catch(() => {});

            },

            newModel(){
                this.editMode = false;
                this.form.reset();
                $('#exampleModal').modal('show');
            },

            loaduser(){

                axios.get('api/User?page=0').then(data=> {
                    this.users = data.data.data
                    this.pages = data.data.last_page
                    this.curentPage = data.data.current_page

                } );

            },
            afterUpdateSolde(id){
                axios.put('api/after-update-solde/'+id ).then((response)=>{
                    this.solding.clear()

                    this.solding.solde = response.data.solde
                    this.solding.symbole=''
                    this.solding.cymboleCredit=''
                    this.solding.tosolde = 0
                    this.solding.addToCredit= 0
                    this.$store.dispatch("loadsna", {
                        text: 'Updated Successfuly',
                        isSuccess: true,
                    });
                }).catch((r)=>{
                    this.$store.dispatch("loadsna", {
                        text:  "Un problème est survenu. Veuillez réessayer",
                        isSuccess: false,
                    });
                })
            }
        },

        watch: {
          showDialogMessage:function (){
            if(this.showDialogMessage == false){
              this.loaduser()
            }
          },
          'form.type':function (){
            if(this.form.type == 'admin'){
              this.typeAdmin = true
              this.$vs.notification({

                classNotification:'arabic',
                title: "تحذير ",
                text:" تحقق من إختيارك لنوع الزبون ",
                duration:'none',
                border: "warn",
                icon:`<img class="white-svg" src="assets/icons/vuesax/close-1.svg">`,

                color: "danger",
                position:'bottom-center',
                buttonClose:true,
              })
            }
          },
            search(after) {
                this.searchCard(after);
            },

            curentPage(newVal ){
                this.paginatePage(newVal)
            },
        },
    }
</script>
<style scoped>
    .con-image{
        border-radius: inherit
    }
    .image-st{
        display :block;
        position :relative;
        border-radius :inherit;
        max-width :850px
    }
    .grand-number{
        font-size: 2.05rem;
        font-weight: 600;
        font-family: app-regular;
    }
    .text-info{
        font-weight: 100;
        font-size: 12px;
        text-transform: uppercase;
        letter-spacing: 0.4375rem;
    }
    >>> .v-input__slot::before {
      border-style: none !important;
    }
    >>> .v-input__slot::after {
      border-style: none !important;
    }
</style>