<template>
    <v-card v-if="$store.state.auth.user.type == 'admin'" height="100%" flat :color="$store.state.dark?'app-bg':''" class="app-mb-10 vuertl">

        <v-container v-if="current_page >0">
            <v-card-title class="vuertl" :class="$store.state.params.fontBold">
              رصيد الزبائن قبل و بعد الفليكسي
                <v-spacer></v-spacer>
                <v-btn  @click="$router.go(-1)" class="v-btn--active" large   style="float: left!important;" icon depressed><img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>


            </v-card-title>
            <v-row>
                <v-col class="col-12">
                    <v-text-field :dark="$store.state.dark" v-model="search"    class="transi app-regular" hide-details="true"  filled rounded   dir="rtl"        placeholder="بحث" :background-color="$store.state.dark?'app-card':'app-card-light'">
                        <img   :class="$store.state.dark?'white-svg':'black-svg'"    style="cursor: pointer"  slot="prepend-inner" src="assets/icons/vuesax/search.svg">
                    </v-text-field>




                </v-col>
            </v-row>

            <v-row v-if="historiqueData.length >0" :no-gutters="$vuetify.breakpoint.smAndDown">
                <v-col class="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-4 col-xl-4 mt-4"  v-for="(item,index) in historiqueData" :key="index" >
                    <v-card flat  ripple       style="height: 90px;cursor: pointer" class="rounded-xl" :color="$store.state.dark?'app-card':'app-card-light'">
                        <vs-tooltip v-if="item.status"  border-thick square :success="item.status =='Confirmé'"   :danger="item.status =='Annulé'" :warn="item.status =='En attente'">
                            <v-card-title  class=" py-0" :class="$store.state.params.font">

                                <img :class="colorSvg(item.status)" :src="iconResponse(item.status)" />
                                <v-spacer></v-spacer>
                                <span style="font-size: 15px" class="mr-2" v-text="item.phone" ></span>
                                <span style="font-size: 15px" class="mx-1" :class="$store.state.params.color"> أرسل الى</span>
                                <span style="font-size: 15px" class="mr-2" v-text="item.PhoneN" ></span>
                                <v-spacer></v-spacer>


                            </v-card-title>
                            <v-card-title style="    font-size: 17px;" class="pt-0 app-regular">


                                <span style="font-size: 15px" class="mx-1" :class="$store.state.params.color"> قبل</span>
                                <span style="font-size: 15px" class="mr-2" v-text="formatToPrice(item.solde)" ></span>
                                <span style="font-size: 15px" class="mx-1" :class="$store.state.params.color">وبعد </span>
                                <span style="font-size: 15px" class="mr-1" v-text="formatToPrice(item.new_solde)" ></span>
                                <span style="font-size: 15px" class="mx-1" :class="$store.state.params.color">الفرق </span>
                                <span :class="getColorClass(item.status)" style="font-size: 15px" class="mr-1"  v-text=" formatToPrice(item.solde - item.new_solde)" ></span>
                            </v-card-title>
                            <v-card-subtitle >
                                <p  class="text-center app-regular" :class="$store.state.dark?'grey--text':''">{{ item.created_at | datee }}</p>

                                <span   > </span>
                            </v-card-subtitle>
                            <template #tooltip>
                                <span class="app-regular">{{item.message}}</span>
                            </template>
                        </vs-tooltip>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
        <infinite-loading  @infinite="infiniteHandler"  >
            <div :style="{marginTop:!historiqueData.length?'25vh':''}"  class="mb-5 app-medium"  slot="spinner" :class="$store.state.params.color">جارٍ البحث</div>
            <div     slot="no-more" class="app-medium" :class="[$store.state.params.color]">لا يوجد المزيد</div>
            <div style="margin-top: 25vh!important;"   class="mb-5 app-medium"  slot="no-results" >
              <v-card :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''">
                <v-card-title class=" mt-5">
                  <v-spacer></v-spacer>
                  <img src="assets/icons/vuesax/empty-products.svg">
                  <v-spacer></v-spacer>

                </v-card-title>
                <h4 class="app-bold text-center">لا توجد أي نتائج بحث </h4>

              </v-card>
            </div>

        </infinite-loading>

    </v-card>
    <NotFound v-else />
</template>

<script>
    import axios from 'axios';
    import StatusComp from '@/components/comp-tool/StatusComp.vue';
    import { formatToPrice ,iconResponse ,colorSvg,getColorClass} from '@/tools/Services';
    import NotFound from "@/components/comp-tool/NotFound.vue";
    export default {
        components:{StatusComp , NotFound},
        data(){
            return{
                search:'',
                show:false,
                current_page:1,
                historiqueData:[],
            }
        },
        watch:{
            search(after) {
                this.searchChecks(after);
            },
        },
        methods:{
            getColorClass,
            searchChecks(after,$state) {
                if(after !=''){
                    axios.get("api/findCheck?q=" + after).then(response => {
                            this.historiqueData = response.data
                            this.show = true
                    })
                        .catch(() => {});
                }else {
                    this.getHistoriqueData()
                    this.show = false

                }
            },

            formatToPrice,iconResponse,colorSvg,
            getHistoriqueData($state){
                axios.get('api/Check', {page: this.current_page}
                ).then((response)=>{
                    if(response.data.data.length >0){
                        this.current_page +=1
                        this.historiqueData.push(...response.data.data);

                    } else{
                        $state.complete();
                    }
                    this.show = true
                }).catch(()=>{
                    $state.complete();
                })
            },
            infiniteHandler($state){
                if(this.search == ""){
                    axios.get('api/Check?page='+this.current_page
                    ).then((response)=>{
                        if(response.data.data.length >0){
                            this.current_page +=1
                            this.historiqueData.push(...response.data.data)
                            $state.loaded()
                        }else{
                            $state.complete();
                        }
                        this.show = true
                    }) .catch(()=>{
                        $state.complete();
                    })
                }

            }
        },
        mounted(){
            //    this.getHistoriqueData()
        }
    }
</script>

<style lang="scss" scoped>
    .v-ripple-container{
        z-index: 10!important;
    }
</style>