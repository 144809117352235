<template>
    <div>


           <v-card class="rounded-xl pa-1" elevation="0" :class="$store.state.dark?'app-card':'app-card-light'">
               <v-row   dense dir="ar" class="px-0 app-regular">
               <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12"  >
                   <vs-button block   primary gradient    class="  rounded-xl vuertl" >


                       <v-list-item three-line class="px-0"

                       >
                           <v-list-item-avatar class="ml-2 ">
                               <vs-avatar  dark   >
                                   <img     :src="'assets/img/flexy/mobilis.png'"/>

                               </vs-avatar>
                           </v-list-item-avatar>

                           <v-list-item-content>
                               <v-list-item-title   class="app-regular white--text" style="font-size: 18px;    line-height: 30px;" >فليكسي موبيليس</v-list-item-title>

                               <v-list-item-subtitle class="app-regular white--text" style="font-size: 35px"  v-text="flexyCard.mobilis?formatToPrice(flexyCard.mobilis):'0.00' " > </v-list-item-subtitle>
                           </v-list-item-content>

                           <v-list-item-action class="ml-0">
                               <vs-button color="warn"   class="black--text mx-2 mt-0  rounded-t-xl ">
                                  <b> {{$store.state.auth.user.categorie}}</b>
                                   <img class="black-svg mr-3 " src="assets/icons/vuesax/admin.svg">
                               </vs-button>
                               <span v-if=" pricesToday.mobilis" class="white--text vuertl ml-4"> {{' التخفيض ' + pricesToday.mobilis}}</span>
                           </v-list-item-action>
                       </v-list-item>
                   </vs-button>

               </v-col>
               <v-col class="col-lg-4 col-md-4 col-sm-6 col-xs-12">
                   <vs-button block  primary gradient    class="  rounded-xl vuertl" >
                       <v-list-item three-line class="pr-0"

                       >
                           <v-list-item-avatar class="ml-2 ">
                               <vs-avatar  dark   >
                                   <img  :src="'assets/img/flexy/djezzy.png'"/>

                               </vs-avatar>
                           </v-list-item-avatar>

                           <v-list-item-content>
                               <v-list-item-title   class="app-regular white--text" style="font-size: 18px;    line-height: 30px;" >فليكسي جيزي</v-list-item-title>

                               <v-list-item-subtitle class="app-regular white--text" style="font-size: 35px"  v-text="flexyCard.djezzy?formatToPrice(flexyCard.djezzy):'0.00' "  > </v-list-item-subtitle>
                           </v-list-item-content>

                           <v-list-item-action>
                               <v-btn icon>
                                   <img class="white-svg"  :src="'assets/icons/vuesax/chevron-right.svg'"/>
                               </v-btn>
                               <span v-if=" pricesToday.mobilis" class="white--text vuertl"> {{' التخفيض ' + pricesToday.djezzy}}</span>
                           </v-list-item-action>
                       </v-list-item>
                   </vs-button>


               </v-col>

                   <v-col class="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                       <vs-button block   primary gradient   class="  rounded-xl vuertl" >
                           <v-list-item three-line class="pr-0"

                           >
                               <v-list-item-avatar class="ml-2 ">
                                   <vs-avatar  dark   >
                                       <img  :src="'assets/img/flexy/ooredoo.png'"/>

                                   </vs-avatar>
                               </v-list-item-avatar>

                               <v-list-item-content>
                                   <v-list-item-title   class="app-regular white--text" style="font-size: 18px;    line-height: 30px;" >فليكسي جيزي</v-list-item-title>

                                   <v-list-item-subtitle class="app-regular white--text" style="font-size: 35px"  v-text="flexyCard.ooredoo?formatToPrice(flexyCard.ooredoo):'0.00' "  > </v-list-item-subtitle>
                               </v-list-item-content>

                               <v-list-item-action>
                                   <v-btn icon>
                                       <img class="white-svg"  :src="'assets/icons/vuesax/chevron-right.svg'"/>
                                   </v-btn>
                                  <span v-if=" pricesToday.mobilis" class="white--text vuertl"> {{' التخفيض ' + pricesToday.mobilis}}</span>
                               </v-list-item-action>
                           </v-list-item>
                       </vs-button>

                   </v-col>
               </v-row>
           </v-card>


    </div>
</template>

<script>
    import { formatToPrice} from "@/tools/Services";
    import { mapState } from 'vuex';


    export default {
        name: "CardForPage",
        props:['flexyCard','pricesToday'],


        data(){
            return{

                flexy:'',
            }
        },

        methods:{
            formatToPrice
        },



    }
</script>

<style scoped>

</style>
