<template>
    <div>
        <v-overlay :dark="dark" :value="dialog" v-if="dialog"  style=" backdrop-filter: blur(3px)!important">
        <v-dialog    persistent   hide-overlay     content-class="rounded-xl"
                     v-model="dialog"
                     :persistent="form.busy"
                     :max-width="350"
        >

            <v-card      :color="$store.state.dark?'app-card':'app-card-light'"  style="z-index: 110000"

                         :dark="$store.state.dark"
            >
                <v-toolbar   elevation="0" v-if="form.busy"   :color="$store.state.dark?'app-card':'app-card-light'">
                    <vs-avatar :loading="form.busy"  >
                        <v-img :src="'images/flexy/'+opperator+'_carte.png'"/>
                    </vs-avatar>
                    <v-spacer></v-spacer>
                    <span style="font-size: 15px;direction: rtl" class="app-bold">إنتظر ، الهاتف قيد الشحن ... </span>


                </v-toolbar>
                <div v-else>

                    <v-bottom-navigation style="box-shadow: none" hide-on-scroll     mandatory grow  v-if="respDialog != null  && respDialog.Status != 'Confirmé'"
                                         :dark="dark"
                                         class="app-regular"
                    >
                        <v-btn  @click="TopUp">
                            <span class="app-regular" >إعادة المحاولة</span>

                            <img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/repeat.svg">

                        </v-btn>
                        <v-btn v-if="respDialog.solde!=null">
                            <span class="app-regular">رصيدي</span>

                            <span class="text-web-success">{{respDialog.solde}}</span>
                        </v-btn>

                        <v-btn @click="dialog = false ">
                            <span  class="app-regular">إغلاق</span>

                            <img height="24" :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/close-1.svg">
                        </v-btn>
                    </v-bottom-navigation>
                </div>


                <v-progress-linear :active="form.busy"  height="2"
                                   indeterminate  :color="$store.state.dark?'#8564F7':'warning'"
                                   class="mb-0 pt-1"
                ></v-progress-linear>
                <v-card-text class="py-0">
                    <v-list-item v-if="form !=null">

                        <v-list-item-content>
                            <v-list-item-title style="text-align:start;font-family: sans-serif" class="h5 app-bold" >{{form.PhoneN}}</v-list-item-title>
                            <v-list-item-subtitle style="font-family: sans-serif"  class="text-capitalize app-regular">{{opperator +' ' +form.Mode}}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <h3><strong class="success--text" style="font-family: sans-serif">{{form.Amount}}<span style="font-size: 15px">{{' DA'}}</span> </strong></h3>
                        </v-list-item-action>
                    </v-list-item>
                </v-card-text>
            </v-card>
            <v-expand-transition v-if="respDialog != null"  >
                <v-card   class="rounded-xk mt-1" flat tile

                          :dark="$store.state.dark"
                >
                    <v-toolbar   flat elevation="0" :color="getColorr(respDialog.Status)" >
                        <v-spacer></v-spacer>
                        <v-icon x-large color="#fff" style="font-size: 60px"   v-text="iconR(respDialog.Status)" ></v-icon>
                        <v-spacer></v-spacer>
                    </v-toolbar>

                    <v-card-text class="p-0 pb-0">
                        <v-list-item>
                            <v-list-item-content class="pb-0">

                                <p style="text-align: center" class="app-regular "><strong  >{{respDialog.response}}</strong></p>
                            </v-list-item-content>

                        </v-list-item>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn text block class="v-btn--active rounded-lg text-capitalize app-regular" :color="getColorr(respDialog.Status)"    depressed @click="closeDial"  >
                            <strong>موافق</strong>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-expand-transition>

        </v-dialog>
        </v-overlay>
        <v-card :dark="dark" flat v-if="DialogContacts" class="relative-card" :color="dark?'dashboard-card':''">
            <v-card-title v-if="mode == 'mobile'" style="direction: ltr;">
                <v-btn icon @click="HadelClose">
                    <img :class="dark?'white-svg':'black-svg'"  src="assets/icons/vuesax/close-1.svg"/>
                </v-btn>
                Contacts
                <v-spacer></v-spacer>

            </v-card-title>
            <v-card v-if="mode == 'mobile'" class="px-0 elevation-0" >

                <div v-if="contacts.length >0 " class="mx-1 scroll">
                    <v-card rounded class="mt-1 rounded-lg" flat link two-line v-for="(cont,index) in contacts" :key="index"
                            @click="setPriceAfterNumber(cont.number)"
                            :class="$store.state.dark?'app-card':'app-card-light '"
                    >

                        <v-list-item two-line  >
                            <vs-avatar size="50" class="ml-2">
                                <span class="text-capitalize" style="font-size: 22px;" v-text="cont.name[0]"></span>
                            </vs-avatar>
                            <v-list-item-content class="ml-2">
                                <v-list-item-title  ><strong class="text-capitalize">{{ cont.name }}</strong>
                                </v-list-item-title>
                                <v-list-item-subtitle style="font-size: 15px">{{ cont.number }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <img :class="dark?'white-svg':'primary-svg'" src="assets/icons/vuesax/chevron-right.svg"/>
                            </v-list-item-action>
                        </v-list-item>
                    </v-card>

                </div>
                <v-card v-else  class="mt-1 elevation-0" link two-line flat
                        @click="HandelDialogFlexy"
                        :class="$store.state.dark?'app-card':'app-card-light '"
                >
                    <v-list-item class="elevation-0"  two-line   >
                        <vs-avatar size="50" class="ml-2">
                            <span class="text-capitalize" style="font-size: 22px;" >N</span>
                        </vs-avatar>
                        <v-list-item-content class="ml-2">
                            <v-list-item-title  ><strong class="text-capitalize">رقم الهاتف</strong>
                            </v-list-item-title>
                            <v-list-item-subtitle style="font-size: 15px">{{ form.PhoneN }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <img :class="dark?'white-svg':'primary-svg'" src="assets/icons/vuesax/chevron-right.svg"/>
                        </v-list-item-action>
                    </v-list-item>
                </v-card>

            </v-card>
            <v-card v-else class="px-1 mb-10" >
                <v-row class="justify-content-center align-center" style="overflow-y: scroll;" :style="{height:height-134 +'px'}"
                       v-touch="{
                              up: () => swiping('Up'),
                              down: () => swiping('Down')
                                }"
                >
                    <div class="col-12">
                        <v-card-title  style="direction: ltr;">
                            <v-btn icon @click="HadelClose">
                                <img :class="dark?'white-svg':'black-svg'"   src="assets/icons/vuesax/close-1.svg"/>
                            </v-btn>
                            Close
                            <v-spacer></v-spacer>
                            <h3>{{form.PhoneN}}</h3>
                        </v-card-title>
                    </div>
                    <div

                            v-if="OperateurChar() == 6"
                            class="col-12"
                            v-model="form.Mode"

                    >
                        <v-row justify="center" style="justify-content: center" dense>
<!--                            <v-col class=" col-12"   v-if="form.Amount >=40"   >-->

<!--                                <v-list-item ripple :dark="dark"  :outlined="dark==true"  @click="changeMode('Normal' ,'Normal')"  two-line  :class="selected == 'Normal'?'primary white&#45;&#45;text app-card' :$store.state.dark?'app-card': 'app-card-light'"-->
<!--                                             class="pr-0 rounded-lg  elevation-0" >-->
<!--                                    <v-list-item-avatar >-->
<!--                                        <vs-avatar circle>-->
<!--                                            <img :class="!selected=='Normal'?'black-svg':''"  :src="mobilis.image"/>-->
<!--                                        </vs-avatar>-->
<!--                                    </v-list-item-avatar>-->
<!--                                    <v-list-item-content class="mr-2">-->
<!--                                        <v-list-item-title ><h3 :class="selected=='Normal'?'white&#45;&#45;text':'primary&#45;&#45;text'"  style="font-family: sans-serif">Normal</h3></v-list-item-title>-->

<!--                                        <h5 :class="selected=='Normal'?'white&#45;&#45;text':'primary&#45;&#45;text'"   >Mobilis</h5>-->
<!--                                    </v-list-item-content>-->
<!--                                    <v-list-item-content>-->
<!--                                        <h3 style="font-family: sans-serif" v-text="form.PhoneN" ></h3>-->
<!--                                        <h1 :class="selected=='Normal'?'white&#45;&#45;text':'primary&#45;&#45;text'" style="text-align: center "><b style="font-family: sans-serif" v-text="form.Amount "> </b><sup>DA</sup></h1>-->
<!--                                    </v-list-item-content>-->
<!--                                    <v-list-item-action >-->
<!--                                        <img style="width: 32px " class="white&#45;&#45;text" src="assets/icons/vuesax/chevron-right.svg">-->
<!--                                    </v-list-item-action>-->
<!--                                </v-list-item>-->

<!--                            </v-col>-->
                            <v-col class=" col-12" v-if="form.Amount >=1000 ">
                            <v-list-item ripple :dark="dark"  :outlined="dark==true" @click="changeMode('Facturation','Facturation')"   two-line  :class="selected == 'Facturation'?'primary white--text app-card' :$store.state.dark?'app-primary': 'app-primary'"
                                             class="pr-0 rounded-lg  elevation-0" >
                                    <v-list-item-avatar >
                                        <vs-avatar circle>
                                            <img  :src="mobilis.image"/>
                                        </vs-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="mr-2">
                                        <v-list-item-title ><h3>Facturation</h3></v-list-item-title>
                                        <h5  >Mobilis</h5>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <h3   >Facturation</h3>
                                        <h1 :class="selected=='Facturation'?'white--text':'orange--text'" style="text-align: center "><b style="font-family: sans-serif" v-text="form.Amount "> </b><sup>DA</sup></h1>
                                    </v-list-item-content>
                                    <v-list-item-action >
                                        <img style="width: 32px " :class="selected?'white-svg':'primary-svg'" src="assets/icons/vuesax/chevron-right.svg">
                                    </v-list-item-action>
                                </v-list-item>
                            </v-col>
                            <v-col class=" col-12" v-for="mobi in mobilis.modes" :key="mobi.selected"  v-if="form.Amount == mobi.amount"   >

                                <v-list-item ripple :dark="dark"  :outlined="dark==true"  @click="changeMode(mobi.mode ,mobi.selected)"  two-line  :class="selected == mobi.selected?'orange white--text app-card' :$store.state.dark?'app-card': 'app-card-light'"
                                             class="pr-0 rounded-lg  elevation-0" >
                                    <v-list-item-avatar >
                                        <vs-avatar circle>
                                            <img :class="!selected==mobi.selected?'black-svg':''"  :src="mobilis.image"/>
                                        </vs-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="mr-2">
                                        <v-list-item-title ><h3 :class="selected==mobi.selected?'white--text':'orange--text'"  style="font-family: sans-serif">{{mobi.mode}}</h3></v-list-item-title>

                                        <h5 :class="selected==mobi.selected?'white--text':'orange--text'"  >Mobilis</h5>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <h3 style="font-family: sans-serif" v-text="form.PhoneN" ></h3>
                                        <h1 :class="selected==mobi.selected?'white--text':'orange--text'" style="text-align: center "><b style="font-family: sans-serif" v-text="form.Amount "> </b><sup>DA</sup></h1>
                                    </v-list-item-content>
                                    <v-list-item-action >
                                        <img style="width: 32px " class="orange-svg" src="assets/icons/vuesax/chevron-right.svg">
                                    </v-list-item-action>
                                </v-list-item>

                            </v-col>
                        </v-row>
                    </div>
                    <div
                            v-if="OperateurChar() == 5"
                            class="col-12"
                            v-model="form.Mode"
                    >
                        <v-col class=" col-12" v-if="form.Amount >=100 ">
                            <v-list-item ripple :dark="dark"  :outlined="dark==true" @click="changeMode('Normal','Normal')"   two-line  :class="selected == 'Normal'?'orange white--text app-card' :$store.state.dark?'app-primary': 'app-primary'"
                                         class="pr-0 rounded-lg  elevation-0" >
                                <v-list-item-avatar >
                                    <vs-avatar circle>
                                        <img  :src="ooredoo.image"/>
                                    </vs-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content class="mr-2">
                                    <v-list-item-title ><h3>Normal</h3></v-list-item-title>
                                    <h5  >Ooredoo</h5>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <h3   >Normal</h3>
                                    <h1 :class="selected=='Normal'?'white--text':'orange--text'" style="text-align: center "><b style="font-family: sans-serif" v-text="form.Amount "> </b><sup>DA</sup></h1>
                                </v-list-item-content>
                                <v-list-item-action >
                                    <img style="width: 32px " :class="selected?'orange-svg':'primary-svg'" src="assets/icons/vuesax/chevron-right.svg">
                                </v-list-item-action>
                            </v-list-item>


                        </v-col>
                        <v-col class=" col-12" v-if="form.Amount >=1000 ">
                            <v-list-item ripple :dark="dark"  :outlined="dark==true" @click="changeMode('Facturation','Facturation')"   two-line  :class="selected == 'Facturation'?'orange white--text app-card' :$store.state.dark?'app-primary': 'app-primary'"
                                         class="pr-0 rounded-lg  elevation-0" >
                                <v-list-item-avatar >
                                    <vs-avatar circle>
                                        <img  :src="ooredoo.image"/>
                                    </vs-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content class="mr-2">
                                    <v-list-item-title ><h3>Facturation</h3></v-list-item-title>
                                    <h5  >Ooredoo</h5>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <h3   >Facturation</h3>
                                    <h1 :class="selected=='Facturation'?'white--text':'orange--text'" style="text-align: center "><b style="font-family: sans-serif" v-text="form.Amount "> </b><sup>DA</sup></h1>
                                </v-list-item-content>
                                <v-list-item-action >
                                    <img style="width: 32px " :class="selected?'orange-svg':'primary-svg'" src="assets/icons/vuesax/chevron-right.svg">
                                </v-list-item-action>
                            </v-list-item>
                        </v-col>
                        <v-col class=" col-12" v-for="oore in ooredoo.modes" :key="oore.selected"  v-if="form.Amount == oore.amount"   >

                            <v-list-item ripple :dark="dark"  :outlined="dark==true"  @click="changeMode(oore.mode ,oore.selected)"  two-line  :class="selected == oore.selected?'orange white--text app-card' :$store.state.dark?'app-card': 'app-card-light'"
                                         class="pr-0 rounded-lg  elevation-0" >
                                <v-list-item-avatar >
                                    <vs-avatar circle>
                                        <img :class="!selected==oore.selected?'black-svg':''"  :src="ooredoo.image"/>
                                    </vs-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content class="mr-2">
                                    <v-list-item-title ><h3 :class="selected==oore.selected?'white--text':'orange--text'"  style="font-family: sans-serif">{{mobi.mode}}</h3></v-list-item-title>

                                    <h5 :class="selected==oore.selected?'white--text':'orange--text'"  >Mobilis</h5>
                                </v-list-item-content>
                                <v-list-item-content>
                                    <h3 style="font-family: sans-serif" v-text="form.PhoneN" ></h3>
                                    <h1 :class="selected==oore.selected?'white--text':'orange--text'" style="text-align: center "><b style="font-family: sans-serif" v-text="form.Amount "> </b><sup>DA</sup></h1>
                                </v-list-item-content>
                                <v-list-item-action >
                                    <img style="width: 32px " class="orange-svg" src="assets/icons/vuesax/chevron-right.svg">
                                </v-list-item-action>
                            </v-list-item>

                        </v-col>
                    </div>
                    <div

                            v-if="OperateurChar() == 7"
                            class="col-12"
                            v-model="form.Mode"

                    >
                        <v-row justify="center" style="justify-content: center" dense>
                            <v-col class=" col-12" v-if="form.Amount >=40 ">
                                <v-list-item ripple :dark="dark"  :outlined="dark==true" @click="changeMode('Normal','Normal')"   two-line  :class="selected == 'Normal'?'orange white--text app-card' :$store.state.dark?'app-primary': 'app-primary'"
                                             class="pr-0 rounded-lg  elevation-0" >
                                    <v-list-item-avatar >
                                        <vs-avatar circle>
                                            <img  :src="djezzy.image"/>
                                        </vs-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="mr-2">
                                        <v-list-item-title ><h3>Normal</h3></v-list-item-title>
                                        <h5  >Mobilis</h5>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <h3   >Normal</h3>
                                        <h1 :class="selected=='Normal'?'white--text':'orange--text'" style="text-align: center "><b style="font-family: sans-serif" v-text="form.Amount "> </b><sup>DA</sup></h1>
                                    </v-list-item-content>
                                    <v-list-item-action >
                                        <img style="width: 32px " :class="selected?'orange-svg':'primary-svg'" src="assets/icons/vuesax/chevron-right.svg">
                                    </v-list-item-action>
                                </v-list-item>


                            </v-col>
                            <v-col class=" col-12" v-if="form.Amount >=1000 ">
                                <v-list-item ripple :dark="dark"  :outlined="dark==true" @click="changeMode('Facturation','Facturation')"   two-line  :class="selected == 'Facturation'?'orange white--text app-card' :$store.state.dark?'app-primary': 'app-primary'"
                                             class="pr-0 rounded-lg  elevation-0" >
                                    <v-list-item-avatar >
                                        <vs-avatar circle>
                                            <img  :src="mobilis.image"/>
                                        </vs-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="mr-2">
                                        <v-list-item-title ><h3>Facturation</h3></v-list-item-title>
                                        <h5  >Djezzy</h5>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <h3   >Facturation</h3>
                                        <h1 :class="selected=='Facturation'?'white--text':'orange--text'" style="text-align: center "><b style="font-family: sans-serif" v-text="form.Amount "> </b><sup>DA</sup></h1>
                                    </v-list-item-content>
                                    <v-list-item-action >
                                        <img style="width: 32px " :class="selected?'orange-svg':'primary-svg'" src="assets/icons/vuesax/chevron-right.svg">
                                    </v-list-item-action>
                                </v-list-item>
                            </v-col>
                            <v-col class=" col-12" v-for="djezz in mobilis.modes" :key="djezz.selected"  v-if="form.Amount == djezz.amount"   >

                                <v-list-item ripple :dark="dark"  :outlined="dark==true"  @click="changeMode(djezz.mode ,djezz.selected)"  two-line  :class="selected == djezz.selected?'orange white--text app-card' :$store.state.dark?'app-card': 'app-card-light'"
                                             class="pr-0 rounded-lg  elevation-0" >
                                    <v-list-item-avatar >
                                        <vs-avatar circle>
                                            <img :class="!selected==djezz.selected?'black-svg':''"  :src="mobilis.image"/>
                                        </vs-avatar>
                                    </v-list-item-avatar>
                                    <v-list-item-content class="mr-2">
                                        <v-list-item-title ><h3 :class="selected==djezz.selected?'white--text':'orange--text'"  style="font-family: sans-serif">{{djezz .mode}}</h3></v-list-item-title>

                                        <h5 :class="selected==djezz.selected?'white--text':'orange--text'"  >Djezzy</h5>
                                    </v-list-item-content>
                                    <v-list-item-content>
                                        <h3 style="font-family: sans-serif" v-text="form.PhoneN" ></h3>
                                        <h1 :class="selected==djezz.selected?'white--text':'orange--text'" style="text-align: center "><b style="font-family: sans-serif" v-text="form.Amount "> </b><sup>DA</sup></h1>
                                    </v-list-item-content>
                                    <v-list-item-action >
                                        <img style="width: 32px " class="orange-svg" src="assets/icons/vuesax/chevron-right.svg">
                                    </v-list-item-action>
                                </v-list-item>

                            </v-col>
                        </v-row>
                    </div>
                    <v-col class=" col-12 pt-0" v-for="(acti,index) in allActivation" :key="index"    >

                        <v-list-item ripple :dark="dark"  :outlined="dark==true"  @click="[selected = acti.selected,form.Amount = acti.amount]"  two-line  :class="selected == acti.selected?'primary white--text app-card' :$store.state.dark?'app-card': 'app-card-light'"
                                     class="pr-0 rounded-lg  elevation-0 " >
                            <v-list-item-avatar >
                                <vs-avatar circle>
                                    <img  :src="img"/>
                                </vs-avatar>
                            </v-list-item-avatar>
                            <v-list-item-content class="mr-2">
                                <v-list-item-title ><h3>Normal</h3></v-list-item-title>

                                <h5  >Flexy Rapid</h5>
                            </v-list-item-content>
                            <v-list-item-content>
                                <h3 v-text="form.PhoneN" ></h3>
                                <h1 :class="selected==acti.selected?'white--text':'primary--text'" style="text-align: center "><b style="font-family: sans-serif" v-text="acti.amount "> </b><sup>DA</sup></h1>
                            </v-list-item-content>
                            <v-list-item-action >
                                <img style="width: 32px " class="primary-svg" src="assets/icons/vuesax/chevron-right.svg">
                            </v-list-item-action>
                        </v-list-item>

                    </v-col>

                </v-row>

            </v-card>


        </v-card>
        <HistoriqueFlexy/>
        <div>

            <v-bottom-sheet hide-overlay  :dark="dark"   scrollable open-delay="0"  :fullscreen="fullscreen"
                            :overlay-color="$store.state.dark?'#000':'#fff'"
                            v-model="sheet" :retain-focus="false" :color="dark?'#000':'#fff'">
                <v-sheet class=" radius-bottom-sheet"  >
                    <v-card flat  >

                       <vs-tooltip interactivity :dark="$store.state.dark"  :warn="Tooltip.color =='warn'"   :danger="Tooltip.color =='danger'" not-hover    v-model="Tooltip.isActive">
                           <v-card-title class="py-0 px-2">
                               <img :class="dark?'white-svg':'black-svg'" class="ml-2" :src="hideNumbers?'assets/icons/vuesax/chevron-top.svg':'assets/icons/vuesax/chevron-bottom.svg'" @click="downSheet" />

                               <v-spacer></v-spacer>
                               <input  :style="{color:$store.state.dark?'#fff':'#000'}" maxlength="10" onfocus="blur();"
                                       pattern="[0]{1}[5-7]{1}[0-9]{8}" v-if="mode =='mobile'" ref="flexy"
                                       v-model="form.PhoneN"  class="flexy text-black-lighten-2"/>
                               <input  onfocus="blur();" :style="{color:$store.state.dark?'#fff':'#000'}" max="4000" :min="miniPrice" type="number"
                                       placeholder="أدخل المبلغ" v-if="mode =='amount'" ref="price" v-model="form.Amount"
                                       class="app-regular flexy text-black-lighten-2"/>

                               <v-spacer></v-spacer>
                               <v-btn icon @click="HandelDelete" v-longpress="longPress" >
                                   <img :class="$store.state.dark?'white-svg':'black-svg'"
                                        src="assets/icons/vuesax/backspace.svg"/>
                               </v-btn>
                           </v-card-title>
                        <template #tooltip>
                           <span class="app-regular">{{Tooltip.message}}</span>
                       </template>
                       </vs-tooltip>

                        <v-divider dark></v-divider>
                        <v-card-text class="pa-0">
                            <v-window v-model="tab" >
                                <v-window-item   v-if="!fullscreen" value="mobile" style="width: 90%;margin: auto">
                                    <v-container fluid class="px-0">
                                        <v-row dence no-gutters justify-center>
                                            <v-col v-if="!hideNumbers" class="col-4 pr-1 pb-2" v-for="(number,index) in numbers"
                                                   :key="index">

                                                <v-btn  :retain-focus-on-click="false"     @click.stop="setDial(number.num , index)" color="white"
                                                       depressed rounded
                                                       class="   display-grad "
                                                       :color="$store.state.dark?'#2D2E30':'#EEF2F5'"
                                                       block height="60px">
                                                    <span  v-if="number.num"
                                                          :style="{color:$store.state.dark?'#fff':'#000'}"
                                                          style="font-size: 23px" class="text-primary-darken-4 "
                                                          v-text="number.num"> </span>
                                                    <span :style="{color:$store.state.dark?'#fff':'#000'}"
                                                          style="font-size:8px">{{ number.char }}</span>
                                                    <img  :class="dark?'white-svg':'black-svg'"  :src="number.src"/>
                                                </v-btn>
                                            </v-col>
                                            <vs-button   @click="HandelPrice" color="rgb(254, 44, 85)"  size="xl" :dark="mode!='mobile'&& form.Amount <40"
                                                         class="white--text purple-grad" animation-type="scale"
                                                         :class="mode=='mobile'?'vs-button--primary':'vs-button--success'"
                                                         block style="border-radius: 16px;position: relative">
                                                        <span style="font-size: 23px" class="ml-3 white--text app-regular"
                                                        >{{ mode=='mobile'?'وافق':form.Amount>40?'فليكسي':'أدخل المبلغ' }}</span
                                                        >
                                                <img class="white-svg" src="assets/icons/vuesax/send.svg"/>
                                            </vs-button>
                                        </v-row>
                                    </v-container>
                                </v-window-item>
                                <v-window-item value="amount">
                                    <v-container>
                                        <v-row dence no-gutters>

                                            <v-col class="col-12 pl-1 pt-2">
                                                <v-btn @click="HandelPrice"
                                                       class="rounded-xl white--text"
                                                       color="primary"
                                                       block
                                                       height="60px"
                                                >
                                                    <img class="white-svg" src="assets/icons/vuesax/send.svg"/>

                                                    <span style="font-size: 23px" class="ml-3 white--text"
                                                    >Envoyer</span
                                                    ><sub>Numero</sub>
                                                </v-btn>
                                            </v-col>
                                        </v-row>
                                    </v-container>
                                </v-window-item>

                            </v-window>
                        </v-card-text>

                    </v-card>



                </v-sheet>
            </v-bottom-sheet>


             <v-sheet class=" radius-bottom-sheet" :color="dark?'black-vuesax':''">

                <v-fab-transition>
                    <v-btn   @click="shoWindowHidSidbar()" :dark="!dark"
                           :color="$store.state.params.bg" elevation="0" fixed bottom right fab
                           style="bottom: 69px; position: fixed; right: 12px;"  >
                        <img width="50px"  src="assets/notifications/mobile.png"/>
                    </v-btn>
                </v-fab-transition>
            </v-sheet>


        </div>

        <v-dialog    fullscreen transition="v-scroll-x-transition" v-model="searchContact">
            <v-card :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''" class="vuertl">
                <v-app-bar hide-on-scroll :color="$store.state.dark?'#14151a':'#fff'"   class="  elevation-0 vuertl pa-xl-0" :class="$vuetify.breakpoint.smAndDown?'pa-0':''">



                    <h3   :class="$store.state.dark?'white--text':''"><b>بحث</b></h3>
                    <v-spacer></v-spacer>
                    <v-btn     @click="searchContact = !searchContact"   large   style="float: left!important;" icon depressed>
                        <img style="width: 32px" :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" />
                    </v-btn>

                </v-app-bar>


                <v-container class="px-0" >

                    <v-container>
                        <v-row>
                            <v-col class="col-12">
                                <v-text-field v-model="search" outlined class="rounded-lg  app-bold vuertl" dense placeholder="قم بالبحث عما تريد">
                                    <template slot="append">
                                        <img :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/search.svg">

                                    </template>
                                </v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                    <div v-if="contacts.length >0 " class="mx-1 scroll">
                        <v-card rounded class="mt-1 rounded-lg" elevation="0" flat link two-line v-for="(cont,index) in contacts" :key="index"

                                :class="$store.state.dark?'app-card':'app-card-light '"
                        >
                            <v-list-item two-line @click="setPriceAfterNumber(cont.number)" selectable class="rounded-lg"  >
                                <vs-avatar size="50" class="ml-2">
                                    <span class="text-capitalize" style="font-size: 22px;" v-text="cont.name[0]"></span>
                                </vs-avatar>
                                <v-list-item-content class="ml-2">
                                    <v-list-item-title  ><strong class="text-capitalize">{{ cont.name }}</strong>
                                    </v-list-item-title>
                                    <v-list-item-subtitle style="font-size: 15px">{{ cont.number }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action>
                                    <img width="30px" :class="dark?'white-svg':'primary-svg'" src="assets/icons/vuesax/chevron-right.svg"/>
                                </v-list-item-action>
                            </v-list-item>
                        </v-card>

                    </div>
                    <v-card v-else  class="mt-1 elevation-0" link two-line flat
                            @click="HandelDialogFlexy"
                            :class="$store.state.dark?'app-card':'app-card-light '"
                    >
                        <v-list-item class="elevation-0"  two-line   >
                            <vs-avatar size="50" class="ml-2">
                                <span class="text-capitalize" style="font-size: 22px;" >N</span>
                            </vs-avatar>
                            <v-list-item-content class="ml-2">
                                <v-list-item-title  ><strong class="text-capitalize">رقم الهاتف</strong>
                                </v-list-item-title>
                                <v-list-item-subtitle style="font-size: 15px">{{ form.PhoneN }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action>
                                <img :class="dark?'white-svg':'primary-svg'" src="assets/icons/vuesax/chevron-right.svg"/>
                            </v-list-item-action>
                        </v-list-item>
                    </v-card>

                </v-container>
            <ContactAdd/>
            </v-card>
        </v-dialog>

    </div>

</template>

<script>
    import {setToken} from "@/plugins/authUser";
    import {totalParce, getColorr,iconR} from "@/tools/Services";
    import Form from "vform"
    import axios from "axios";
    import Mobile from "@/components/Mobile.vue";
    import HistoriqueFlexy from "@/views/HistoriqueFlexy";
    import TitlePages from "@/layout/TitlePages";
    import ContactAdd from "@/layout/ContactAdd";
    import {bus} from "@/tools/bus";
    const configHeaders = {
        "content-type": "application/json",
        "Accept": "application/json"
    };
    export default {
        components: {TitlePages, HistoriqueFlexy, Mobile,ContactAdd},

        data: () => ({
            Tooltip:{
                message:'test',
                isActive:false,
                color:'danger'
            },
            hidOverlay:true,
            height:'',
            search:'',
            searchContact:false,
            respDialog:null,
            hideNumbers:false,
            dialogo: false,
            dialog: false,
            mode: 'mobile',
            prices: [
                {price: "50 DA"}, {price: "100 DA"}, {price: "200 DA"}, {price: "500 DA"}, {price: "1000 DA"}, {price: "1300 DA"},
            ],
            numbers: [
                {num: "1", char: ''}, {num: "2", char: 'A B C'}, {num: "3", char: 'D E F'},
                {num: "4", char: "G H I"}, {num: "5", char: "J K L"}, {num: "6", char: "M N O"},
                {num: "7", char: "P Q R S"}, {num: "8", char: "T U V"}, {num: "9", char: "W X Y Z"},
                {src: "assets/icons/vuesax/book-1.svg", disable: true}, {
                    num: "0",
                    char: "+"
                }, {src: "assets/icons/vuesax/users.svg", disable: true}],
            fullscreen: false, DialogContacts: false,
            img: '',
            tab: null,
            miniPrice: 40,
            form: new Form({
                PhoneN: '',
                Amount: '',
                mode: '',
                Total: ''
            }),
            opperator:'',


            selected: undefined,
            sheet: false,
            responsePrice: '',
            djezzy: '',
            ooredoo: '',
            mobilis: '',
            message: '',
            HandelAlert: '',
            color: '',
            allActivation:[
                {amount:"40",selected:'201'},{amount:"50",selected:'203'},{amount:"100",selected:'204'},
                {amount:"200",selected:'205'},{amount:"500",selected:'206'},{amount:"650",selected:'207'},
                {amount:"1000",selected:'208'},{amount:"1500",selected:'209'},{amount:"2000",selected:'210'},
                {amount:"2500",selected:'211'},{amount:"3000",selected:'212'},{amount:"3500",selected:'13'},
                {amount:"4000",selected:'14'}
            ]
        }),

        computed: {
            dark: function () {
                return this.$store.state.dark
            },
            contacts: function () {
                return this.$store.state.contacts
            },
            params: function () {
                return this.$store.state.params
            },
            user: function () {
                return this.$store.state.auth.user
            }
        },
        watch: {
            search:function (newVal) {
                this.$store.dispatch('loadontacts', newVal)
            },
            // "form.Amount": function () {
            //     const vm = this;
            //     if (vm.form.Amount <= 4000 && vm.form.Amount >= 40 && vm.form.PhoneN.length == 10) {
            //         this.color = '#4CAF50'
            //         if (vm.OperateurChar() == 6) {
            //             vm.form.Total = totalParce(vm.form.Amount, this.responsePrice.mobilis)
            //             vm.miniPrice = 40;
            //         } else if (vm.OperateurChar() == 5) {
            //             vm.form.Total = totalParce(vm.form.Amount, this.responsePrice.ooredoo)
            //             vm.miniPrice = 100;
            //         } else if (vm.OperateurChar() == 7) {
            //             vm.form.Total = totalParce(vm.form.Amount, this.responsePrice.djezzy)
            //             vm.miniPrice = 100;
            //         } else {
            //             this.color = '#ED1C24'
            //             vm.form.Total = "";
            //         }
            //     } else if (vm.form.Amount < 10) {
            //         this.color = this.dark == true ? 'rgba(244,244,246,0.7)' : '#000'
            //     } else if (vm.form.Amount > 4000) {
            //         this.color = '#ED1C24', this.form.Amount = 4000
            //     } else {
            //         this.color = '#ED1C24'
            //         vm.form.Total = "";
            //     }
            //
            // },
            "form.PhoneN": function (newVal, oledVal) {
                this.Tooltip.isActive = false
                const vm = this;
                // if (vm.form.Amount <= 4000 && vm.form.Amount >= 40 && vm.form.PhoneN.length == 10) {
                //     if (vm.OperateurChar() == 6) {
                //         vm.form.Total = totalParce(vm.form.Amount, this.responsePrice.mobilis)
                //         vm.miniPrice = 40;
                //     } else if (vm.OperateurChar() == 5) {
                //         vm.form.Total = totalParce(vm.form.Amount, this.responsePrice.ooredoo)
                //         vm.miniPrice = 100;
                //     } else if (vm.OperateurChar() == 7
                //     ) {
                //         vm.form.Total = totalParce(vm.form.Amount, this.responsePrice.djezzy)
                //         vm.miniPrice = 40;
                //     } else {
                //         vm.form.Total = "";
                //     }
                // } else {
                //     vm.form.Total = "";
                // }
                this.DialogContacts = true

                if (this.form.PhoneN.length >= 1) {
                    this.$store.dispatch('loadontacts', newVal)
                } else if (this.form.PhoneN.length == 10) {
                    this.form.PhoneN = this.$store.state.selectedContact
                    this.HandelPrice()

                } else {
                    this.$store.dispatch('loadontacts',)
                }

            },
            // dialog:function(newVal){
            //     if(newVal == false){
            //        this.HadelClose()
            //     }
            // },
            sheet: function () {
                // this.$store.dispatch('contacts/getContacts')
            },

        },
        methods: {getColorr,iconR
            ,
            swiping(data){

                if(data == 'Up'){
                    this.hideNumbers = true
                }
            },
            closeDial(){
                this.dialog = !this.dialog

                this.form.reset()
            },
            TopUp: async function () {
                this.respDialog = null
                if (this.form.Amount[0]!=0 && this.form.busy != true) {
                    this.DialogContacts = false
                    this.sheet = false
                    this.dialogo = false
                    this.dialog =true;
                    // setTimeout(()=>this.hidOverlay = false,400)
                    try {

                         const response = await this.form.post("api/Sendque")
                         this.respDialog = await  response.data.data
                         this.response = response.data
                        this.solde = this.response.data.solde
                        if (this.respDialog.Status!= "En attente"){
                            this.form.Mode= ''
                            this.form.Amount=  ''
                            this.form.PhoneN= ''
                        }
                          audioFile.play().catch(()=>{})
                    } catch (e) {
                        try {
                            if(e.response.data.data.response == 'failed'){
                                this.dialog = false
                                this.hidOverlay = true
                            }  else {
                                this.respDialog = e.response.data.data
                                this.respDialog.solde = e.response.data.solde
                            }
                        }catch (e) {
                            this.respDialog = {
                                Status:"Annulé",
                                response:'حدث خطأ يرجى إعادة المحاولة'

                            }
                        }

                    }
                    if (this.respDialog.Status != "En attente"){
                        // bus.$emit("loadNumbers", null);
                        // await this.$store.dispatch('loadontacts', null)
                    }


                }

            },
            downSheet(){
                if (this.form.PhoneN.length <=8){
                    this.sheet = !this.sheet
                    this.DialogContacts =  false

            }else {
                    this.hideNumbers = !this.hideNumbers
                }
              // this.DialogContacts = false
            },
            setPriceAfterNumber(number) {

                if (number.length === 10 && number[1] == 7 || number[1] == 6 || number[1] == 5 && this.searchContact == true) {
                    this.form.PhoneN = number
                    this.searchContact = false
                    this.mode = "amount"


                }
            },
            shoWindowHidSidbar() {
                if (this.$store.state.auth.user != null) {
                    this.$store.dispatch('getContacts')
                }
                this.form.reset()
                this.selected = undefined
                this.hideNumbers= false
                this.contactsDialog = false
                this.sheet = !this.sheet

            },



            getPice() {

                axios({
                    url: "api/get-price",
                    method: "get", configHeaders
                }).then((response) => {
                    this.responsePrice = response.data.price;
                    this.djezzy = response.data.djezzy;
                    this.ooredoo = response.data.ooredoo;
                    this.mobilis = response.data.mobilis
                    this.message = response.data.message
                    let lastMessage = localStorage.getItem('alert_flexy')
                    //
                    // if(lastMessage == response.data.message.value.message){
                    //     this.HandelAlert = false
                    // }else {this.HandelAlert = true}

                });

            },
            setToken, totalParce,
            changeMode(mode, selected) {
                this.form.Mode = mode;
                this.selected = selected

            },
            HandelDialogFlexy() {
                this.HandelPrice()
            },
            HadelClose() {

                this.sheet = false
                this.fullscreen = false
                this.DialogContacts = false
                this.form.reset()
                this.mode = "mobile"
            },
            longPress(){
                if (this.mode == "mobile"){this.form.PhoneN = ''}else {this.form.Amount = ''}
            },
            HandelDelete() {
                let self = this
                if(self.mode=="amount"){
                    if(self.form.Amount ==''){
                        self.mode ="mobile"
                    }else {
                        self.form.Amount = ""
                    }
                }else {
                    self.form.PhoneN = self.form.PhoneN.substring(0, self.form.PhoneN.length - 1)
                }

            },
            setDial(params, index) {
                let self = this
                if (params) {
                    if (self.mode == "mobile") {
                       if (self.form.PhoneN.length <10){
                           self.form.PhoneN = self.form.PhoneN + params + ''
                           if (self.form.PhoneN.length===10){self.setPriceAfterNumber(this.form.PhoneN)}
                       }
                    } else {
                        self.form.Amount = self.form.Amount + params + ''
                    }

                } else {
                    if (index == 9) {
                        if(self.user != null){
                            self.$store.dispatch('getContacts')
                            self.searchContact = !self.searchContact
                        }else{
                            self.$router.push('config')
                        }

                    }else{
                        bus.$emit('callContacts',self.form.PhoneN)
                    }
                }

            },
            OperateurChar() {

                let output = this.form.PhoneN.split("");
                if (output[1] == 6) {
                    this.img = 'assets/img/flexy/mobilis.png'
                }
                if (output[1] == 7) {
                    this.img = 'assets/img/flexy/djezzy.png'
                }
                if (output[1] == 5) {
                    this.img = 'assets/img/flexy/ooredoo.png'
                }
                return output[1];
            },
            HandelPrice() {
                if(this.user != null){
                    if (this.form.PhoneN.length == 10 && this.OperateurChar()=='7' || this.OperateurChar()=='6' || this.OperateurChar()=='5') {
                        if(this.form.Amount == ''){
                            this.mode = "amount"
                        }else if(this.form.Amount >=40 && this.form.Amount <= 400){
                            this.TopUp()
                        }

                    } else {
                        this.Tooltip.message = "تحقق من رقم الهاتف"
                        this.Tooltip.color = 'danger'
                        this.Tooltip.isActive = true
                    }
                }else {
                    this.HadelClose()
                    this.$store.dispatch("loadsna", { text:"قم بتسجيل الدخول", isSuccess: false});

                }


                //  this.tab = "amount"
                let self = this

                // this.fullscreen = true

            }
        },
        mounted() {
             this.height = Math.max(document.documentElement.clientHeight)
            this.getPice();

        },
    };
</script>

<style lang="scss" scoped>
    .v-text-field.centered-input .v-label {
        left: 50% !important;
        transform: translateX(-50%);

        &.v-label--active {
            transform: translateY(-18px) scale(0.75) translateX(-50%);
        }
    }

    .v-input {

        text-align: center !important;
        border: none
    }

    .input-mobile input {
        text-align: center !important;
    }

    .flexy {
        height: 55px;
        display: flex;
        width: 80%;
        text-align: center;
        font-size: 30px;
    }

    .relative-card {
        position: fixed !important;
        height: 100% !important;
        width: 100% !important;
        left: 0 !important;
        right: 0 !important;
        top: 0 !important;
        bottom: 0 !important;
        z-index: 50 !important;
    }

    .display-grad {
        display: grad !important;
    }

    .v-dialog__content {
        z-index: 1002 !important;
    }

    .bottom-sheet-radius {
        border-top-left-radius: 20px !important;
        border-top-right-radius: 20px !important;
    }
    .scroll {
        overflow-y: scroll
    }
</style>