<template>
    <v-card :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''" > 


    <v-container class="px-0" :fluid="!$vuetify.breakpoint.mdAndUp">
      <v-card :color="$store.state.dark?'app-bg':''" flat class="vuertl">
          <TitlePages title="اختر البطاقة المناسبة لك">
              <v-btn slot="btn" @click="$router.go(-1)"   large   style="float: left!important;" icon depressed><img style="width: 32px" :class="$store.state.dark?'white-svg':'black-svg'" src="assets/icons/vuesax/chevron-right.svg" /></v-btn>

          </TitlePages>

    </v-card>


        <v-row v-show="isShow"  >
            <v-col class="col-12 mt-5">
                <v-card   :dark="$store.state.dark" flat :color="$store.state.dark?'app-bg':''" style="    margin-top: 20vh !important;">
                    <v-card-title  >
                        <v-spacer></v-spacer>
                        <img   style="width: 100px" src="assets/notifications/products.png">
                        <v-spacer></v-spacer>

                    </v-card-title>
                    <h5 class="app-bold text-center ">سوف يتم توفير المنتجات المطلوبة في أقرب وقت</h5>
                    <vs-button
                        @click="$router.go(-1)"
                        :class="$store.state.params.font"
                        :color="$store.state.params.bg" class="rounded-xl mt-4 px-3 ma-auto white--text" >
                      العودة لمتجر البطاقات</vs-button>

                </v-card>
            </v-col>
        </v-row>


     <v-row v-if="cartes.length !=0" align="center" justify="center" class="mx-1 p-0" >
        <v-col  class="col-4 col-xs-4 col-sm-4 col-md-3 col-lg-2 col-xl-2 my-3 " v-for="(cart,index) in cartes" :key="index">
                <Card :cart="cart" style="cursor:pointer;" />

            </v-col>
        </v-row>




    </v-container>
    <v-container v-if="activate" >

      <v-card class="elevation-0 rounded-xl mb-15"   color="transparent"    >
        <v-card-title>
          <v-spacer></v-spacer>
          <h3 class="app-medium">أراء العملاء</h3>
        </v-card-title>
        <v-card-subtitle class="app-regular vuertl mt-3  " >

        <span>   ماذ يقول العملاء عن منتجاتنا</span>
        </v-card-subtitle>
        <ClientComment :id-product="$route.params.id" v-if="$store.state.auth.user" />
        <hooper  :settings="hooperSettings" hover-pause short-drag touch-drag :play-speed="9000" :rtl="true"    auto-play group="groupe">
          <slide  :index="slide.index" v-for="(slide,index) in comments" :key="slide.id" v-if="index % 2 == 0 ">
            <v-card :dark="$store.state.dark"
                    :height="$vuetify.breakpoint.smAndDown?'140':'140px'"
                    :color="$store.state.dark?'dashboard':'#EEF2F5'"
                    class="mr-2 rounded-xl elevation-0 overflow-hidden scroll-x-transition">
              <v-card-title class="app-bold">

                {{slide.username}}</v-card-title>
              <v-card-subtitle class="vuertl pb-1" >
                <v-rating small ripple half-increments hover color="orange"
                     :value="+slide.stars"

                ></v-rating>

              </v-card-subtitle>
              <v-card-text class="vuertl app-medium ">{{slide.message}}</v-card-text>
            </v-card>
          </slide>

        </hooper>
        <hooper :settings="hooperSettings" hover-pause short-drag touch-drag :play-speed="9000" :rtl="true"    auto-play group="groupe">
          <slide  :index="slide.index" v-for="(slide,index) in comments" :key="slide.id" v-if="index % 2 == 1 ">
            <v-card :dark="$store.state.dark"
                    :height="$vuetify.breakpoint.smAndDown?'140px':'140px'"
                    :color="$store.state.dark?'dashboard':'#EEF2F5'"
                    class="mr-2 rounded-xl elevation-0 overflow-hidden scroll-x-transition">
              <v-card-title class="app-bold">

                {{slide.username}}</v-card-title>
              <v-card-subtitle class="vuertl pb-1" >
                <v-rating small ripple half-increments hover color="orange"
                          style="font-size: 18px" :value="+slide.stars"

                ></v-rating>

              </v-card-subtitle>
              <v-card-text class="vuertl app-medium ">{{slide.message}}</v-card-text>
            </v-card>
          </slide>
          <hooper-pagination     slot="hooper-addons"></hooper-pagination>
        </hooper>
      </v-card>
    </v-container>
    </v-card>
  </template>
  
  <script>
  import { Hooper, Slide ,  Pagination as HooperPagination } from 'hooper';
  import 'hooper/dist/hooper.css';
import axios from 'axios'
  import Card from '../components/CardComponent.vue'
import TitlePages from "@/layout/TitlePages";
  import {bus} from "@/plugins/bus";
  import ClientComment from "@/components/ClientComment.vue";

    export default {
      name: 'HomeCardInfo',

      components: {ClientComment, TitlePages, Card,Hooper,Slide, HooperPagination},
      data(){
        return{
          activate:false,
          hooperSettings: {
            itemsToShow: 2,
            centerMode: false,
            breakpoints: {
              300: {
                centerMode: false,
                itemsToShow: 1
              },
              500: {
                centerMode: false,
                itemsToShow: 1
              },
              400: {
                centerMode: false,
                itemsToShow: 1
              },
              600: {
                centerMode: false,
                itemsToShow: 1
              },
              800: {
                centerMode: false,
                itemsToShow: 1
              },
              1000: {
                itemsToShow: 2,
                pagination: 'fraction'
              }
            }
          },
          model:0,
          comments:[],
         isShow:false,
         showEmpty:false,
          cartes:[]
        }
      },
      created(){
        this.getCards(this.$route.params.id)
        bus.$on('loadComments',()=>{
          this.getComments(this.$route.params.id)
        })
        this.getComments(this.$route.params.id)
      },
      methods:{
        getComments(id){
          axios.get('api/get-comment/'+id).then((response)=>{
            this.comments = response.data.comments
          })
        },
        getCards(){
            const  loading = this.$vs.loading()
            if(this.$route.params.id){
                axios.get('api/get-groupe-id/'+this.$route.params.id).then((response)=>{
                    this.cartes = response.data
                    if (this.cartes.length >0){
                        this.isShow = false
                        this.showEmpty = false
                      this.activate = true
                    }else {
                        this.isShow = true
                        this.showEmpty = true
                      this.activate = true
                    }
                })

            }else {
                axios.get('api/get-groupe-name/'+this.$route.params.name.replace(' ',"-")).then((response)=>{
                    this.cartes = response.data
                    if (this.cartes.length >0){
                        this.showEmpty = false
                    }else {
                        this.showEmpty = true
                    }
                })

            }
            loading.close()
        }
      }
    }
  </script>
  
<style lang="scss" scoped>
.b-game-card {
  position: relative;
  z-index: 1;
  width: 100%;
  padding-bottom: 150%;
  perspective: 1000px;
  transition: all 0.2s ease-in-out;
}

.b-game-card__cover {
  border-radius: 10px;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
   background-size: cover;
  perspective-origin: 50% 50%;
  transform-style: preserve-3d;
  transform-origin: top center;
  will-change: transform;
  transform: skewX(0.001deg);
  transition: transform 0.25s ease-in-out;
}

.b-game-card__cover::after {
  display: block;
  content: "";
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 110%;
  background: linear-gradient(226deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.2) 35%, rgba(255, 255, 255, 0.1) 42%, rgba(255, 255, 255, 0) 60%);
  transform: translateY(-50%);
  will-change: transform;
  transition: transform 0.12s ease-in-out, opacity 0.1s ease-in-out;
}

.b-game-card:hover .b-game-card__cover::after {
  transform: translateY(0%);
}

.b-game-card::before {
  display: block;
  content: "";
  position: absolute;
  top: 5%;
  left: 5%;
  width: 90%;
  height: 90%;
  transform: translateY(-20%);
  will-change: transform;
  transition: transform 0.5s cubic-bezier(0.18, 0.9, 0.58, 1);
}

.b-game-card:hover .b-game-card__cover {
  transform: rotateX(10deg) translateY(-8px);
}

.b-game-card:hover .b-game-card__cover::after {
  transform: translateY(0%);
}

.img-border-bottom {
  border-bottom-left-radius: 23px !important;
  border-bottom-right-radius: 23px !important;
  -moz-box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
  -webkit-box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
  box-shadow: 0px 0px 15px 0px rgb(0, 0, 0);
}
.blur{
  backdrop-filter: blur(3px)importan !important
  
}
.v-dialog__content {
  z-index: 1002 !important;
}
.bottom-sheet-radius{
  border-top-left-radius: 20px!important;
  border-top-right-radius: 20px!important;
}
.bottom-sheet-radius-all{
  border-radius: 20px!important;
}
div.v-slide-group__content{
  display: flex!important;
    justify-content: center!important;
}
div.v-slide-group__wrapper{
  display: flex!important;
    justify-content: center!important;
}
.v-skeleton{
  height: 100%!important;
 }

</style>
