import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VMenu,{staticClass:"over",class:_vm.$store.state.dark?'app-card':'app-card-light',staticStyle:{"border-radius":"25px"},attrs:{"dark":_vm.$store.getters.dark,"min-width":"270px","max-width":"350px","offset-y":"","origin":"center center","nudge-bottom":10},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('div',[_c('vs-avatar',_vm._g(_vm._b({staticClass:"text-decoration-none",attrs:{"size":"37","link":"","dark":_vm.$store.state.dark,"large":"","icon":""}},'vs-avatar',attrs,false),on),[_c('img',{attrs:{"width":"30px","height":"30px","src":"assets/img/profile.png"}})])],1)]}}])},[_c(VCard,{staticClass:"rounded-xl mt-0 pt-0 overflow-hidden",class:_vm.$store.state.dark?'app-card':'app-card-light',attrs:{"flat":""}},[_c(VProgressLinear,{attrs:{"height":"3","indeterminate":true,"color":"#8564F7"}}),_c(VCardTitle,{staticClass:"py-1"},[_c('img',{staticStyle:{"cursor":"pointer"},attrs:{"width":"25px","height":"25px","src":"assets/img/logoxl.png"}}),_c('span',{staticStyle:{"font-family":"sans-serif"}},[_c('b',{staticStyle:{"font-size":"13px"}},[_vm._v(_vm._s(_vm.AppName))])]),_c(VSpacer),_c('vs-switch',{attrs:{"color":_vm.$store.state.params.background},on:{"click":function($event){$event.stopPropagation();return _vm.HandelMode.apply(null, arguments)}},scopedSlots:_vm._u([{key:"off",fn:function(){return [_c('img',{class:_vm.$store.state.dark?'white-svg':'black-svg',attrs:{"src":"assets/icons/vuesax/sun.svg"}})]},proxy:true},{key:"on",fn:function(){return [_c('img',{class:_vm.$store.state.dark?'white-svg':'black-svg',attrs:{"src":"assets/icons/vuesax/moon.svg"}})]},proxy:true}]),model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1),(_vm.user != null)?_c(VListItem,{staticClass:"rounded-xl",staticStyle:{"text-decoration":"none","border-radius":"25px"},attrs:{"dense":"","active-class":_vm.$store.state.dark === true
          ? 'text-grey'
          : 'deep-purple--text text--accent-4'}},[_c(VListItemAvatar,{staticClass:"mr-1"},[_c('img',{class:_vm.$store.state.dark?'white-svg':'black-svg',staticStyle:{"width":"20px"},attrs:{"src":"assets/icons/vuesax/users.svg"}})]),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"title text-start",staticStyle:{"text-align":"left"}},[_c('span',{staticClass:"h6"},[_vm._v(_vm._s(_vm.user.phone)+" ")])]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.user.email))])],1)],1):_vm._e(),_c(VDivider,{staticClass:"mt-0"}),(_vm.$vuetify.breakpoint.name == 'xs')?_c(VDivider):_vm._e(),_c(VContainer,[_c(VCard,{staticClass:"rounded-xl overflow-hidden elevation-0",class:_vm.$store.state.dark?'':''},_vm._l((_vm.items),function(item,index){return _c(VListItem,{key:index,staticStyle:{"text-decoration":"none !important"},attrs:{"to":!item.href==false ? { name: item.name } : null},on:{"click":item.click}},[(item.icon)?_c(VListItemAction,[_c('img',{class:_vm.$store.state.dark?'white-svg':'black-svg',attrs:{"src":item.icon}})]):_vm._e(),_c(VListItemContent,[_c(VListItemTitle,{staticClass:"fonta"},[_vm._v(_vm._s(item.title))])],1)],1)}),1)],1),_c(VRow,{attrs:{"justify":"center","align":"center","no-gutters":""}},[_c('vs-button',{attrs:{"to":"/privacy-policy","flat":"","size":"sm"}},[_vm._v("Privacy policy")]),_c('vs-button',{attrs:{"animation-type":"rotate","border":"","color":_vm.$store.state.dark?'#fff':'#000',"dark":_vm.$store.state.dark,"to":"/terms"}},[_vm._v("Terms of service")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }