<template>
  <div  >

    <v-expand-transition    mode="in-out"  v-if="$store.state.caroucel.data.caroucel_show" >
    <v-carousel   class="mx-1 mx-0 rounded-xl " v-if="dataC.data.length >0 && dataC.caroucel_show=='true'"  interval="8000"

    :height="height" :#show-arrows="false"
    hide-delimiter-background  hide-arrow>
    <v-carousel-item v-for="(slide, i) in dataC.data" :key="i" >
    <v-card flat   class="mx-2 rounded-xl" style="height: 100%;">
        <img style="width:100%;height: 100%;backdrop-filter: blur(0px)!important" :src="setBaseUrl(slide.image)"/>
    </v-card>
    </v-carousel-item>
  </v-carousel>
    </v-expand-transition>
  </div>
</template>

<script>
  import {setBaseUrl} from "@/tools/Services";


  export default {
    data(){
        return{
          newVal:'',
        }
    },
    computed:{
      dataC:function(){
        return this.$store.state.caroucel.data
      },
      height:function(){
        switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return '21vh'
        case "sm":
        return '34h'
        case "md":
        return '37vh'
        case "lg":
        return '40vh'
        case "xl":
        return '43vh'
      }
      }},
    watch:{
      dataC:function () {
        this.newVal = this.$store.state.caroucel.data.caroucel_show
      }
    },
    methods:{setBaseUrl,
      loadcaroucel() {
       if (this.dataC.data.length <=0){
         this.$store.dispatch('caroucel/loadcaroucel')
      }
        this.newVal = this.$store.state.caroucel.data.caroucel_show
      },

    },
    mounted() {
      this.loadcaroucel()
    }

  }
</script>