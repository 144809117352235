import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VCard,{attrs:{"elevation":"0","height":"1000px","flat":"","color":_vm.$store.state.dark?'app-bg':'app-bg-light'}},[_c(VContainer,{staticClass:"px-2",attrs:{"fluid":""}},[_c(VCard,{staticClass:"card-shadow rounded-xl",staticStyle:{"z-index":"3","margin-top":"20vh!important"},attrs:{"dark":_vm.dark,"color":_vm.dark?'app-card':'app-card-light'}},[_c(VCardTitle,[_vm._v(" Salut ! "+_vm._s(_vm.auth.user.username)+" "),_c(VSpacer),_c('img',{attrs:{"src":"assets/icons/vuesax/chevron-left.svg"}})],1),_c(VCardSubtitle,[_vm._v(" "+_vm._s(_vm.$store.state.phone)+" ")]),_c(VDivider),_c(VCardSubtitle,{staticClass:"font-weight-bold pb-1",staticStyle:{"font-size":"13px"}},[_vm._v("Parametres des forfaits exclisifs")]),_c(VCardSubtitle,{staticClass:"text-secondary pt-0",staticStyle:{"font-size":"13px"}},[_vm._v(" Parametres des forfaits exclisifs Parametres des forfaits exclisifs ")]),_c(VDivider),_c(VRow,[_c(VCol,{staticClass:"col-6"},[_c(VCardSubtitle,{staticClass:"pb-0"},[_vm._v(" MON CREDIT ")]),_c(VCardTitle,{staticClass:"pt-0 pb-2 font-weight-bold"},[_vm._v(_vm._s(_vm.formatToPrice(_vm.auth.user.solde))+" DA")]),_c(VCardSubtitle,{staticClass:"font-weight-bold",staticStyle:{"font-size":"13px"}},[_vm._v(" Recharger votre compt ")])],1),_c(VCol,{staticClass:"col-6 align-item"},[_c('vs-button',{staticClass:"black--text font-weight-bold",attrs:{"to":"/rechargement","warn":""}},[_vm._v("RECHARGER")])],1)],1)],1)],1),_c('mobile')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }